/**
 * 모델
 * @window w_tb_e601w_02
 * @category 설치된지오래된현장
 */
import { computed } from 'mobx';

export class OldSpaceModel {
  actcd: string;

  actnm: string;

  actpernm: string;

  address: string;

  address2: string;

  amt: string;

  bosunm: string;

  class2: string;

  classnm: string;

  contflag: string;

  contg: string;

  contgubun: string;

  daeamt: string;

  divicd: string;

  divinm: string;

  elno: string;

  equpcd: string;

  equpnm: string;

  evnm: string;

  gigan: string;

  gigan2: string;

  gubun002: string;

  hdate: string;

  jejak: string;

  kcenddate: string;

  kukcd: string;

  mdnm: string;

  monamt: string;

  no: string;

  number: string;

  ordflag: string;

  perid: string;

  pernm: string;

  qty: string;

  remark: string;

  spped: string;

  tel: string;

  totkcdate: string;

  wkactcd: string;

  wkactnm: string;

  yyyymm: string;

  yyyymm2: string;



  /**
   * 신규인지
   *
   * **data type** varchar(1)
   */
  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.actpernm = data.actpernm || '';
    this.address = data.address || '';
    this.address2 = data.address2 || '';
    this.amt = data.amt || '';
    this.bosunm = data.bosunm || '';
    this.class2 = data.class2 || '';
    this.classnm = data.classnm || '';
    this.contflag = data.contflag || '';
    this.contg = data.contg || '';
    this.contgubun = data.contgubun || '';
    this.daeamt = data.daeamt || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.elno = data.elno || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.evnm = data.evnm || '';
    this.gigan = data.gigan || '';
    this.gigan2 = data.gigan2 || '';
    this.gubun002 = data.gubun002 || '';
    this.hdate = data.hdate || '';
    this.jejak = data.jejak || '';
    this.kcenddate = data.kcenddate || '';
    this.kukcd = data.kukcd || '';
    this.mdnm = data.mdnm || '';
    this.monamt = data.monamt || '';
    this.no = data.no || '';
    this.number = data.number || '';
    this.ordflag = data.ordflag || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.qty = data.qty || '';
    this.remark = data.remark || '';
    this.spped = data.spped || '';
    this.tel = data.tel || '';
    this.totkcdate = data.totkcdate || '';
    this.wkactcd = data.wkactcd || '';
    this.wkactnm = data.wkactnm || '';
    this.yyyymm = data.yyyymm || '';
    this.yyyymm2 = data.yyyymm2 || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
