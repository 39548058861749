import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReorder,
  MdPublish,
} from 'react-icons/md';
import {
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import {
  SiteModel,
  MainModel,
  AccountModel,
} from './models';
import { TabModel } from '../../../../models/component';
import { GridLayout, TableLayout } from '../../../../components';
import { ReceivableTemplate } from './Receivable.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  TabHeaderAccount,
  TabHeaderSite,
} from './tabs';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';

export enum ReceivableTabId {
  Account,
  Site,
}

export const ReceivableTabTitles = [
  '거래처별',
  '현장별',
];

export const ReceivableTabModels = [
  AccountModel,
  SiteModel,
];

export const ReceivableTabFunctionNames = [
  'tab_1',
  'tab_2',
];

export const ReceivableTabDataStateNames = [
  'accounts',
  'sites',
];

export interface ReceivableState {
  pushTotalCheck: boolean;

  // 거래처별
  accounts?: Array<AccountModel>;

  // 현장별
  sites?: Array<SiteModel>;
  openChk?: Array<any>;
  focusedTab?: TabModel;
  focused?: TabModel;
  searchQuery: string;
  stdate: string;
  enddate: string;
  billgubun: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  resuck: string;
  textDetailModal: boolean;
  popupData: Array<MainModel>;
  popupData2: Array<MainModel>;
  popupFocused?: MainModel;

  // trail
  beamt_tot: string;
  misamt_tot: string;
  rcvamt_tot: string;
  resuamt_tot: string;
  total: string;

  holderName: string;

  // poup trail
  longmis3_tot: string;
  longmis3_tot2: string;
  p_misamt_tot: string;
  misamt_tot2: string;
  iamt_tot: string;
  rcvamt_tot2: string;
  p_resuamt_tot: string;
  resuamt_tot2: string;
  shotmis_tot: string;
  shotmis_tot2: string;
}

/**
 * 컨트롤러
 * @window w_input_da023w
 * @category 미수금현황
 */
export class Receivable extends PageComponent<PageProps, ReceivableState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  tabs: Array<TabModel>;

  tabHeaders: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinity4?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = ReceivableTabId;
    const titles = ReceivableTabTitles;

    this.tabs = [
      new TabModel(id.Account.toString(), MdReorder, titles[id.Account]),
      new TabModel(id.Site.toString(), MdPublish, titles[id.Site]),
    ];

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      pushTotalCheck: true,
      stdate: '19700101',
      enddate: `${year}${month}${date}`,
      data: [],
      imports: [],
      billgubun: '%',
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      resuck: '1',
      searchQuery: '',
      popupData: [],
      popupData2: [],
      holderName: '거래처로 검색하세요',

      // trail
      beamt_tot: '0',
      misamt_tot: '0',
      rcvamt_tot: '0',
      resuamt_tot: '0',
      total: '0',

      // poup trail
      longmis3_tot: '0',
      longmis3_tot2: '0',
      p_misamt_tot: '0',
      misamt_tot2: '0',
      rcvamt_tot2: '0',
      p_resuamt_tot: '0',
      resuamt_tot2: '0',
      shotmis_tot: '0',
      shotmis_tot2: '0',
      iamt_tot: '0',
    };

    this.table = React.createRef();

    this.tabHeaders = [
      // 거래처별
      TabHeaderAccount,
      // 현장별
      TabHeaderSite,
    ];
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 왼쪽 리스트
    const data = await api.dropdown('wf_da020_code');
    if (!data) return;
    this.setState({ openChk: data.items }, async () => {
      await this.table.current?.update();
      this.table.current?.setFocus(0, 1);
    });
    await this.onTabChange(this.tabs[ReceivableTabId.Account]);
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab }, () => {
      this.onRetrieveEvent();
    });
  }

  @action
  async onHolderNameMake() {
    let text = '';
    switch ((this.tabIndex)) {
      case 0:
        text = '거래처로 검색하세요';
        break;

      case 1:
        text = '현장으로 검색하세요';
        break;

      default:
        text = '';
    }
    this.setState({ holderName: text });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    await this.onHolderNameMake();

    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      if (x.chk === '1') {
        checkData.push({
          ...x,
        });
      }
    });
    if (!checkData.length) {
      ConfirmWarning.show('확인', '매출구분이 아무것도 선택되지 않았습니다.');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        billgubun: this.state.billgubun,
        divicd: this.state.divicd,
        perid: this.state.perid,
        resuck: this.state.resuck,
        items: this.state.openChk,
      },
      (params) => api.fxExec(
        `${ReceivableTabFunctionNames[i]}_retrieve`,
        params,
      ),
      (items, next) => {
        if (items) {
          // @ts-ignore
          this.setState({
            [ReceivableTabDataStateNames[i]]: [
              // @ts-ignore
              ...this.state[ReceivableTabDataStateNames[i]],
              ...items.map((x: any) => new ReceivableTabModels[i](x)),
            ],
          }, next);
        }
      },
      async () => {
        // @ts-ignore
        await this.SS({
          [ReceivableTabDataStateNames[i]]: [],
        });

        await this.infinity2?.retrieveAll();
        // @ts-ignore
        if (this.state[ReceivableTabDataStateNames[i]] && this.state[ReceivableTabDataStateNames[i]]?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      [ReceivableTabDataStateNames[i]]: [],
    }, async () => {
      let index;
      i === 0 ? index = await this.infinity2?.retrieveTo(['cltcd', 'misamt'],
        [this.state.popupFocused?.cltcd, this.state.popupFocused?.misamt], type, true) || 0
        : index = await this.infinity2?.retrieveTo(['actcd', 'misamt'],
          [this.state.popupFocused?.actcd, this.state.popupFocused?.misamt], type, true) || 0;

      // @ts-ignore
      this.state[ReceivableTabDataStateNames[i]] && this.state[ReceivableTabDataStateNames[i]].length > index && this.grid.current?.setFocus(index);

      await this.SS({
        beamt_tot: this.infinity2?.box?.beamt_tot || '0',
        misamt_tot: this.infinity2?.box?.misamt_tot || '0',
        total: this.infinity2?.box?.total || '0',
        rcvamt_tot: this.infinity2?.box?.rcvamt_tot || '0',
        resuamt_tot: this.infinity2?.box?.resuamt_tot || '0',
      });
    });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    const checkData: any = [];
    this.state.openChk?.forEach((x: any) => {
      checkData.push({
        ...x,
        chk: checked === true ? '1' : '0',
      });
    });

    this.setState({
      pushTotalCheck: checked,
      openChk: checkData,
    }, async () => {
      await this.table.current?.update(false);
    });
  }

  @action
  onRowFocusEvent(item: MainModel) {
    this.setState({ popupFocused: item });
  }

  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    this.updatedRows = updatedRows;
    this.setState({ openChk: rows });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[ReceivableTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    const checkData: any = [];

    this.state.openChk?.forEach((x: any) => {
      checkData.push({
        code: x.code,
        chk: x.chk,
      });
    });

    await api.fxPrint(
      `${ReceivableTabFunctionNames[i]}_print`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        billgubun: this.state.billgubun,
        divicd: this.state.divicd,
        perid: this.state.perid,
        resuck: this.state.resuck,
        items: checkData,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // @ts-ignore
    if (this.state[ReceivableTabDataStateNames[i]].length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    const checkData: any = [];
    // @ts-ignore
    this.state.openChk.forEach((x: any) => {
      checkData.push({
        code: x.code,
        chk: x.chk,
      });
    });

    await api.fxExcel(
      `${ReceivableTabFunctionNames[i]}_excel`,
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        billgubun: this.state.billgubun,
        divicd: this.state.divicd,
        perid: this.state.perid,
        resuck: this.state.resuck,
        items: checkData,
      },
    );
  }

  @action
  async textModal(isOpen: boolean) {
    this.setState({ textDetailModal: isOpen });

    await this.modalRetrive(this.state.popupFocused);
  }

  @action
  async modalRetrive(item: any) {
    const { actionStore: api } = this.props;
    const i = this.tabIndex + 1;

    const data = await api.fxExec(`tab_${i}_retrieve`,
      {
        sub: 'w_popup_da023w',
        spjangcd: item.spjangcd,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: item.cltcd,
        actcd: item.actcd,
        items: this.state.openChk,
      });
    if (data?.items) {
      this.setState({
        popupData: data?.items || [],
        // poup trail
        longmis3_tot: data?.longmis3_tot || '0',
        p_misamt_tot: data?.misamt_tot || '0',
        p_resuamt_tot: data?.resuamt_tot || '0',
        shotmis_tot: data?.shotmis_tot || '0',
        iamt_tot: data?.iamt_tot || '0',
      });
    } else {
      this.setState({
        popupData: [],
      });
    }
    if (data?.items2) {
      this.setState({
        popupData2: data?.items2 || [],
        longmis3_tot2: data?.longmis3_tot2 || '0',
        misamt_tot2: data?.misamt_tot2 || '0',
        rcvamt_tot2: data?.rcvamt_tot2 || '0',
        resuamt_tot2: data?.resuamt_tot2 || '0',
        shotmis_tot2: data?.shotmis_tot2 || '0',
      });
    } else {
      this.setState({
        popupData2: [],
      });
    }
  }

  @action
  async modalPrintEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    if (this.state.popupData.length < 1) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `${ReceivableTabFunctionNames[i]}_print`,
      {
        sub: 'w_popup_da023w',
        spjangcd: this.state.popupFocused?.spjangcd,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.popupFocused?.cltcd,
        actcd: this.state.popupFocused?.actcd,
        items: this.state.openChk,
      },
    );
  }

  @action
  async modalExcelEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    if (this.state.popupData.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `${ReceivableTabFunctionNames[i]}_excel`,
      {
        sub: 'w_popup_da023w',
        spjangcd: this.state.popupFocused?.spjangcd,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.popupFocused?.cltcd,
        actcd: this.state.popupFocused?.actcd,
        items: this.state.openChk,
      },
    );
  }

  @computed
  get tabIndex(): ReceivableTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  render() {
    return (
      <ReceivableTemplate
        scope={this}
        update={(state, callback) => this.setState(state, callback)}
      />
    );
  }
}
