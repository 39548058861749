import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import { GiLift } from 'react-icons/gi';
import {
  FaCalendarCheck,
  FaCcAmazonPay,
  FaFileSignature,
  FaSignature,
} from 'react-icons/fa';
import {
  BsFileText,
  BsPersonLinesFill,
} from 'react-icons/bs';
import { AiFillDollarCircle } from 'react-icons/ai';
import { RefObject } from 'react';
import {
  AskType,
  Category,
  ConfirmType,
  GridLayoutHeader,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
  TableLayoutHeader,
} from '../../../../constants';
import { EnrollmentTemplate } from './Enrollment.template';
import {
  EnrollmentAccountModel,
  EnrollmentActModel,
  EnrollmentChangePersonModel,
  EnrollmentCheckModel,
  EnrollmentContractInformationModel,
  EnrollmentContractLongModel,
  EnrollmentDivisionModel,
  EnrollmentHistoryModel,
  EnrollmentInchargeModel,
  EnrollmentReceivableModel,
  EnrollmentUnitModel,
  PersonPopupModel,
} from './models';
import {
  InfinityRetrieve,
  TabModel,
} from '../../../../models';
import {
  DragAndDropLayout,
  GridLayout,
  TableLayout,
} from '../../../../components';
import {
  Confirm,
  ConfirmDelete,
  ConfirmFail,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  TabHeaderAccount,
  TabHeaderCheck,
  TabHeaderCheckDetail,
  TabHeaderHistory,
  TabHeaderIncharge,
  TabHeaderReceivable,
  TabHeaderUnit,
} from './tabs';
import {
  Date6Calculator,
  Date8,
  Today,
} from '../../../../utils/time';
import { PageComponent } from '../../../../utils';
import { HistoryPopupModel } from '../MonthContract/HistoryPopup.model';
import { RoutineModel } from '../../inspect/Routine/Routine.model';
import { Update } from '../../../../utils/array';
import { FileSelector } from '../../../../utils/file';


// 현장 리스트 항목
export interface EnrollmentActListItem {
  actcd: string;
  actnm: string;
  pernm: string;
}

// 호기 리스트 항목
export interface EnrollmentEqupListItem {
  equpcd: string;
  equpnm: string;
  contflag: string;
}

export enum EnrollmentTabId {
  UNIT,
  CONTRACKINFORMATION,
  HISTORY,
  INCHARGE,
  ACCOUNT,
  DIVISION,
  CHECK,
  RECEIVABLE,
}

export enum EnrollmentItemChangeTypes {
  bcontg,
  bstdate,
  bildyd,
  billgubun,
  addyn,
  evercd,
  evernight,
  contg,
  tax_combine,
  boryu,
}

export const EnrollmentItemChangeTypeNames = [
  'rnumflag',
  'rnum',
  'is_sex',
  'entdate',
  'payiden',
  'spjangcd',
  'faminum',
  'licence',
];

export const EnrollmentTabTitles = [
  '호기등록',
  '계약정보',
  '계약이력정보',
  '담당자이력정보',
  '거래처이력정보',
  '분할납부정보',
  '검사이력정보',
  '미수내역',
];

export const EnrollmentTabModels = [
  EnrollmentUnitModel,
  EnrollmentContractInformationModel,
  EnrollmentHistoryModel,
  EnrollmentInchargeModel,
  EnrollmentAccountModel,
  EnrollmentDivisionModel,
  EnrollmentCheckModel,
  EnrollmentReceivableModel,
];

export const EnrollmentTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
  'tab_4',
  'tab_5',
  'tab_6',
  'tab_7',
  'tab_8',
];

export const EnrollmentTabDataStateNames = [
  'units',
  'contractinformations',
  'histories',
  'incharges',
  'accounts',
  'divisions',
  'checks',
  'receivables',
];

export const EnrollmentTabUpdatesStateNames = [
  'unitUpdates',
  'contractinformationUpdates',
  'historyUpdates',
  'inchargeUpdates',
  'accountUpdates',
  'divisionUpdates',
  'checkUpdates',
  'receivableUpdates',
];

export const EnrollmentTabFocusedStateNames = [
  'unitsFocused',
  'contractinformationsFocused',
  'historiesFocused',
  'inchargesFocused',
  'accountsFocused',
  'divisionsFocused',
  'checksFocused',
  'receivablesFocused',
];

export interface BankItem {
  bankcd: string;
  banknm: string;
}

export interface staffListItem{
  divicd?: string;
  pernm?: string;
}

export enum BtnGubun {
  b_taxall,
  b_jiroall,
  b_remarkall,
  b_pic_ok,
  b_perid2all,
}


export interface EnrollmentState {
  // 기본정보
  // 탭: 호기등록
  units?: Array<EnrollmentUnitModel>;
  unitUpdates?: Array<EnrollmentUnitModel>;
  unitFocused?: EnrollmentUnitModel;

  // 탭: 계약정보
  contractinformations?: Array<EnrollmentContractInformationModel>;
  contractinformationUpdates?: Array<EnrollmentContractInformationModel>;
  contractinformationsFocused?: EnrollmentContractInformationModel;
  contractinformationsFocusedNew?: any;

  // 탭: 계약이력정보
  histories?: Array<EnrollmentHistoryModel>;
  historyUpdates?: Array<EnrollmentHistoryModel>;
  histroiesFocused?: EnrollmentHistoryModel;

  // 탭: 담당자이력정보
  incharges?: Array<EnrollmentInchargeModel>;
  inchargeUpdates?: Array<EnrollmentInchargeModel>;
  inchargesFocused?: EnrollmentInchargeModel;

  // 탭: 거래처이력정보
  accounts?: Array<EnrollmentAccountModel>;
  accountUpdates?: Array<EnrollmentAccountModel>;
  accountsFocused?: EnrollmentAccountModel;

  // 탭: 분할납부정보
  divisions?: Array<EnrollmentDivisionModel>;
  divisionUpdates?: Array<EnrollmentDivisionModel>;
  divisionsFocused?: EnrollmentDivisionModel;

  // 탭: 검사이력정보
  checks?: Array<EnrollmentCheckModel>;
  checkUpdates?: Array<EnrollmentCheckModel>;
  checksFocused?: EnrollmentCheckModel;

  // 탭: 미수내역
  receivables?: Array<EnrollmentReceivableModel>;
  receivableUpdates?: Array<EnrollmentReceivableModel>;
  receivablesFocused?: EnrollmentReceivableModel;

  focusedTab?: TabModel;

  focuseddata?: any;
  focusedDetail?: any;
  spjangcd?: string;
  bankcd?: string;
  rtclafi?: string;
  bildyds?: Array<any>;
  contbilds?: Array<any>;
  areanms?: Array<any>;
  evercds?: Array<any>;
  gubuns?: Array<any>;
  contgs?: Array<any>;
  contgubuns?: Array<any>;
  deljugis?: Array<any>;
  taxgubuns?: Array<any>;
  gubuns2?: Array<any>;
  kinds?: Array<any>
  forms?: Array<any>
  invers?: Array<any>;
  kcgubuns?: Array<any>;
  kcresults?: Array<any>;
  speeds?: Array<any>;
  evcds?: Array<any>;
  gpsURL: string;
  placeList?: Array<EnrollmentActModel>;
  img_pic?: string;
  img_sign?: string;
  percentImagePic?: number;
  percentImageSign?: number;
  basicAddressToggle?: boolean;
  basicAddressToggle2?: boolean;
  basicAddressToggle3?: boolean;
  fileDetailModal?: boolean;

  // 호기등록 정보센터연동 모달
  unitRemoteModal?: boolean;

  // 계약연장 모달
  selectModal?: boolean;
  onlyModal?: boolean;
  allModal?: boolean;
  stmon?: string;

  // 계약기록 모달
  historyFocusedData?: HistoryPopupModel;
  historyData?: Array<HistoryPopupModel>;
  historyModal?: boolean;
  remark?: string;

  // 담당자변경 모달
  changeModal?: boolean;
  modalDate: string;

  // 전체 체크
  pushTotalCheck: boolean;

  // 계약정보 탭 비지블 체크
  contractVisible?: boolean;

  // 분할납부정보 탭 비지블 체크
  divisionVisible?: boolean;

  popupData?: any;
  contractLongData: Array<EnrollmentContractLongModel>;
  contractData: EnrollmentContractLongModel;
  faminum?: any;
  contractflag?: string;

  // 담당자 변경 팝업창
  searchQuery: string;
  contflag: string;
  perid: string;
  pernm: string;
  actcd: string;
  actcd2: string;
  actcd3: string;
  actnm2: string;
  actnm3: string;
  wkactcd: string;
  wkactcd2: string;
  wkactcd3: string;
  wkactnm: string;
  wkactnm2: string;
  taxweek: string;
  wkactnm3: string;
  gubun: string;
  beperid: string; // 전담당자 코드
  bepernm: string; // 전담당자명
  changeGubun: string;
  perid2: string; // 후담당자 코드
  pernm2: string; // 후담당자명
  history: string;

  // 현장 리스트
  acts?: Array<EnrollmentActListItem>;
  focusedAct?: EnrollmentActListItem;

  // 기본 정보
  actInfo?: EnrollmentActModel;
  data?: EnrollmentActModel;

  // 점검자 모달뷰
  checkPersonDetailModal: boolean;
  checkPersonFocused?: Array<any>;

  // 세금계산처 모달뷰
  taxDetailModal: boolean;
  taxFocused?: Array<any>;

  // 담당자메일 모달뷰
  personEmailDetailModal: boolean;
  personEmailFocused?: Array<any>;

  // 검사이력정보 테이블
  checkDetail: Array<EnrollmentCheckModel>;
  checkDetailFocused: EnrollmentCheckModel;

  // 계약연장 팝업
  actnm: string;
  mgubun: string;
  amt: string;
  addyn: string;
  contg: string;
  stdate: string;
  enddate: string;
  bcontg: string;
  bamt: string;
  baddyn: string;
  bstdate: string;
  benddate: string;
  bcontdate: string;
  chk: string;

  // 계약서 첨부 팝업
  isVisibleAttachment: boolean;
  isVisibleAttachment2: boolean;

  tabContdate: string;

  // 담당자변경 팝업
  changePopUpFocused: EnrollmentChangePersonModel;
  changePopUpData: Array<EnrollmentChangePersonModel>;

  // 담당자변경 팝업 2
  PersonPopUpFocused: PersonPopupModel;
  PersonPopUpData: Array<PersonPopupModel>;

  // 도면, PLC
  isDPModalVisible?: boolean;
  isPLCModalVisible?: boolean;

  // 청구주기
  isRecipeIntervalVisible?: boolean
  delmonAll?: boolean;
  delmon1?: boolean;
  delmon2?: boolean;
  delmon3?: boolean;
  delmon4?: boolean;
  delmon5?: boolean;
  delmon6?: boolean;
  delmon7?: boolean;
  delmon8?: boolean;
  delmon9?: boolean;
  delmon10?: boolean;
  delmon11?: boolean;
  delmon12?: boolean;

  // 분할납부 청구주기
  isDivideIntervalVisible?: boolean
  divideDelmonAll: boolean;
  divideDelmon1: boolean;
  divideDelmon2: boolean;
  divideDelmon3: boolean;
  divideDelmon4: boolean;
  divideDelmon5: boolean;
  divideDelmon6: boolean;
  divideDelmon7: boolean;
  divideDelmon8: boolean;
  divideDelmon9: boolean;
  divideDelmon10: boolean;
  divideDelmon11: boolean;
  divideDelmon12: boolean;

  checkPageMove?: boolean;

  // 히스토리 저장데이터
  saveData?: Array<EnrollmentUnitModel>;

  // 검사계획 상세 모달
  isInspectDetailModalVisible?: boolean;
  inspectDetailData?: Array<any>;
  inspectDetailItem?: RoutineModel;

  // 화면 넘겨왔을때 신규값
  actinfoNew: any;
  pageSaveData: any;

  // 조회 보수팀 계약정보, 계약이력정보탭 막기위한 변수
  rekukcd: string;
  recontflag: string;

  // 호기 코드변경
  changeUnitModal: boolean;
  currentUnitData: Array<any>;
  changeUnitData: Array<any>;
  testData: Array<any>;
}


/**
 * 컨트롤러
 * @window w_tb_e601_new
 * @category 보수현장등록
 */
export class Enrollment extends PageComponent<PageProps, EnrollmentState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  tabHeaders2: Array<Array<GridLayoutHeader>>;

  table: React.RefObject<TableLayout> = React.createRef();

  tableUnit: React.RefObject<TableLayout> = React.createRef();

  tableCheck: React.RefObject<TableLayout> = React.createRef();

  tableCheckDetail: React.RefObject<GridLayout> = React.createRef();

  tableContractOnly: React.RefObject<TableLayout> = React.createRef();

  tableContractAll: React.RefObject<TableLayout> = React.createRef();

  tableHistory: React.RefObject<TableLayout> = React.createRef();

  tableChange: React.RefObject<TableLayout> = React.createRef();

  tableCheckPerson: React.RefObject<TableLayout> = React.createRef();

  tableChangeUnit: RefObject<DragAndDropLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  gridReceivable: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinityModal?: InfinityRetrieve;

  infinityModal2?: InfinityRetrieve;

  infinityHistory?: InfinityRetrieve;

  updatedRows?: Array<EnrollmentActModel>;

  updatedRows2?: Array<EnrollmentUnitModel>;

  updatedRows3?: Array<HistoryPopupModel>;

  updatedRows4?: Array<EnrollmentContractLongModel>;

  updatedRows5?: Array<EnrollmentChangePersonModel>;

  // 선택된 호기 인덱스
  focusedUnitIndex: number = 0;

  // 정보센터 연동시 일괄연동 여부
  isWholeGosiSync: boolean = false;

  // 정보센터 연동 전에 승강기 번호 변동시 저장 후 재시도 유도
  isChangedElno: boolean = false;

  // 다른 메뉴에서 보수현장등록 넘어왔을 때 이전 메뉴 메뉴명
  preMenu: string = '';

  // 계약기록 인덱스
  focusedHistoryRow: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = EnrollmentTabId;
    const titles = EnrollmentTabTitles;

    const params = this.props.publicStore?.getPageParams();
    this.preMenu = params?.preMenu || '';

    const { user } = this.props.publicStore;
    let bosuPerid = '';
    let bosuPernm = '';

    this.tabs = [
      new TabModel(id.UNIT.toString(), GiLift, titles[id.UNIT]),
      new TabModel(id.CONTRACKINFORMATION.toString(), FaSignature, titles[id.CONTRACKINFORMATION]),
      new TabModel(id.HISTORY.toString(), FaFileSignature, titles[id.HISTORY]),
      new TabModel(id.INCHARGE.toString(), BsPersonLinesFill, titles[id.INCHARGE]),
      new TabModel(id.ACCOUNT.toString(), BsFileText, titles[id.ACCOUNT]),
      new TabModel(id.DIVISION.toString(), FaCcAmazonPay, titles[id.DIVISION]),
      new TabModel(id.CHECK.toString(), FaCalendarCheck, titles[id.CHECK]),
      new TabModel(id.RECEIVABLE.toString(), AiFillDollarCircle, titles[id.RECEIVABLE]),
    ];

    // w_tb_e601 (보수현장현황) 화면에서 더블 클릭으로 화면이동시 담당자 비어있음.
    if (user.kukcd !== '01' && params.menu !== 'w_tb_e601w') {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
    }

    // state 기본값 정의
    this.state = props.state || {
      placeList: [],
      contractLongData: [],
      searchQuery: params?.searchQuery || '',
      pageSaveData: params?.pageSaveData || '',
      checkPageMove: !!params?.pageSaveData,
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      actcd: '',
      actcd2: '',
      actcd3: '',
      wkactcd: '',
      wkactcd2: '',
      wkactcd3: '',
      wkactnm: '',
      contflag: '%',
      history: '%',
      bankcd: '',
      beperid: '',
      bepernm: '',
      changeGubun: '1',
      perid2: '',
      pernm2: '',
      data: [],
      focusedTab: this.tabs[EnrollmentTabId.UNIT],
      basicAddressToggle: true,
      basicAddressToggle2: true,
      basicAddressToggle3: true,
      contractflag: '0',
      pushTotalCheck: false,
      contractVisible: false,
      divisionVisible: false,
      isVisibleAttachment: false,
      isVisibleAttachment2: false,
      tabContdate: '',
      remark: '',
      modalDate: Today.date(),
      divideDelmonAll: true,
      divideDelmon1: true,
      divideDelmon2: true,
      divideDelmon3: true,
      divideDelmon4: true,
      divideDelmon5: true,
      divideDelmon6: true,
      divideDelmon7: true,
      divideDelmon8: true,
      divideDelmon9: true,
      divideDelmon10: true,
      divideDelmon11: true,
      divideDelmon12: true,

      rekukcd: '',
      recontflag: '',

      // 호기 코드변경
      changeUnitModal: false,
      currentUnitData: [],
      changeUnitData: [],
      testData: [],
    };

    this.tabHeaders = [
      // 호기등록
      TabHeaderUnit,
      // 계약정보
      [
      ],

      // 계약이력정보
      TabHeaderHistory,

      // 담당자이력정보
      TabHeaderIncharge,

      // 거래처이력정보
      TabHeaderAccount,

      // 분할납부정보
      [
      ],

      // 검사이력정보
      TabHeaderCheck,

      // 미수내역
      [
      ],
    ];

    this.tabHeaders2 = [
      // 검사이력정보
      TabHeaderCheckDetail,
      // 미수내역
      TabHeaderReceivable,
    ];
  }

  @action
  async componentDidRecover() {
    const params = await this.props.publicStore?.getPageParams();
    this.preMenu = params?.preMenu || '';

    if (params && params?.searchQuery) {
      await this.SS({
        searchQuery: params?.searchQuery || this.state.searchQuery,
      });
      await this.onRetrieveEvent();
    }

    if (params?.pageSaveData) {
      await this.SS({
        checkPageMove: true,
        pageSaveData: params.pageSaveData,
        actinfoNew: params.pageSaveData,
      });
      await this.onNewEvent();
    }
  }

  dateToYYYYMMDD(date: Date) {
    function pad(num: string | number | any[]) {
      // eslint-disable-next-line no-param-reassign
      num += '';
      return typeof num !== 'number' && num?.length < 2 ? `0${num}` : num;
    }
    return `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}`;
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    [
      async () => {
        // 건물용도 리스트 가져오기
        const data = await api.dropdown('wf_dd_e029');
        this.setState({ bildyds: data?.items || [] });
      },
      async () => {
        // 유지관리비별 건물구분 리스트 가져오기
        const data = await api.dropdown('wf_dd_ca510_028_01');
        this.setState({ contbilds: data?.items || [] });
      },
      async () => {
        // 지역 리스트 가져오기
        const data = await api.dropdown('wf_dd_e028_new');
        this.setState({ areanms: data?.items || [] });
      },
      async () => {
        // 매출구분 리스트 가져오기
        const data = await api.dropdown('wf_dd_da020_notjgubun');
        this.setState({ gubuns: data?.items || [] });
      },
      async () => {
        // 건물: 리스트
        const data = await api.dropdown('wf_dd_e101_aver', { contbild: this.state.actInfo?.contbild || '03' });
        this.setState({ evercds: data?.items || [] });
      },
      async () => {
        // 팝업 계약연장 리스트 (계약분류)
        const data = await api.dropdown('wf_dd_ca510_001_01');
        this.setState({ contgs: data?.items || [] });
      },
      async () => {
        // 계약 구분 리스트
        const data = await api.dropdown('wf_dd_ca510_008_01');
        this.setState({ contgubuns: data?.items || [] });
      },
      async () => {
        // 청구주기
        const data = await api.dropdown('wf_dd_ca510_007_01');
        this.setState({ deljugis: data?.items || [] });
      },
      async () => {
        // 세금구분 리스트
        const data = await api.dropdown('wf_dd_ca510_006_01');
        this.setState({ taxgubuns: data?.items || [] });
      },
      async () => {
        // 구분
        const data = await api.dropdown('wf_dd_e002_01');
        this.setState({ gubuns2: data?.items || [] });
      },
      async () => {
        // 종류
        const data = await api.dropdown('wf_dd_e004_01');
        this.setState({ kinds: data?.items || [] });
      },
      async () => {
        // 형식
        const data = await api.dropdown('wf_dd_e005_01');
        this.setState({ forms: data?.items || [] });
      },
      async () => {
        // 도어방식
        const data = await api.dropdown('wf_dd_e007_01');
        this.setState({ invers: data?.items || [] });
      },
      async () => {
        // 정격속도
        const data = await api.dropdown('wf_dd_e008_01');
        this.setState({ speeds: data?.items || [] });
      },
      async () => {
        // 검사구분
        const data = await api.dropdown('wf_dd_ca510_012_01');
        this.setState({ kcgubuns: data?.items || [] });
      },
      async () => {
        // 검사결과
        const data = await api.dropdown('wf_dd_ca510_018_01');
        this.setState({ kcresults: data?.items || [] });
      },
      async () => {
        // 점검기종
        const data = await api.dropdown('wf_dd_e030_new');
        this.setState({ evcds: data?.items || [] });
      },
    ].forEach((x) => x());

    await this.onResisterMoveEvent();
  }

  @action
  async onResisterMoveEvent() {
    if (this.state.checkPageMove) {
      await this.SS({
        actinfoNew: this.state.pageSaveData,
      });
      await this.onNewEvent();
    } else {
      await this.onRetrieveEvent();
    }
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 다른메뉴 화면에서 온건지 확인하기위한 변수
    this.preMenu = '';

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        wkactcd: this.state.wkactcd,
        gubun: this.state.contflag,
        perid: this.state.perid,
        history: this.state.history,
      },
      (params) => api.fxExec(
        `${EnrollmentTabFunctionNames[this.tabIndex]}_retrieve`,
        params,
      ),
      (items, next) => {
        this.setState({
          placeList: [...this.state.placeList || [], ...items.map((x) => new EnrollmentActModel(x))],
        }, next);
      },
      async () => {
        await this.SS({
          placeList: [],
        });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    const last = this.state.actInfo;
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    // @ts-ignore
    this.setState({
      placeList: [],
      actInfo: new EnrollmentActModel(),
      data: new EnrollmentActModel([], false),
      [EnrollmentTabUpdatesStateNames[this.tabIndex]]: [],
      [EnrollmentTabFocusedStateNames[this.tabIndex]]: undefined,
      [EnrollmentTabDataStateNames[this.tabIndex]]: [],
      unitFocused: new EnrollmentUnitModel(),
      contractinformationsFocused: new EnrollmentContractInformationModel(),
      contractinformationsFocusedNew: new EnrollmentContractInformationModel(),
      histroiesFocused: new EnrollmentHistoryModel(),
      inchargesFocused: new EnrollmentInchargeModel(),
      accountsFocused: new EnrollmentAccountModel(),
      divisionsFocused: new EnrollmentDivisionModel(),
      checksFocused: new EnrollmentCheckModel(),
      receivablesFocused: new EnrollmentReceivableModel(),
      focuseddata: [],
      focusedDetail: [],
    }, async () => {
      this.table.current?.update();
      const index = await this.infinity?.retrieveTo(['actcd'], [last?.actcd], type, true) || 0;

      this.setState({
        rekukcd: this.infinity?.box?.kukcd,
        recontflag: this.infinity?.box?.contflag,
      });
      if (this.state.placeList && this.state.placeList?.length > index) {
        this.grid.current?.setFocus(index);
      }
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    // 계산서발행 말일 입력 시 말일체크로 하도록 함
    if (this.state.contractinformationsFocused?.dedate === '30' || this.state.contractinformationsFocused?.dedate === '31') {
      if (this.state.contractinformationsFocused?.delchk === '0') {
        ConfirmWarning.show('확인', '전자계산서발행 날짜가 말일로 입력되있습니다. 말일체크를 해주세요!');
        return;
      }
    }

    await this.SS({ checkPageMove: false });
    // items -> 배열로
    // const paramsUnit = {
    //   ...this.state.actInfo,
    //   items1: this.state.unitUpdates,
    // };

    const paramsUnit = {
      ...this.state.actInfo,
      items1: {
        ...this.state.unitFocused,
        // @ts-ignore
        contyn: this.state.unitUpdates[0]?.contyn || '1',
      },
      // items11: this.state.unitUpdates?.map((x) => ({
      //   custcd: x.custcd,
      //   spjangcd: x.spjangcd,
      //   actcd: x.actcd,
      //   equpcd: x.equpcd,
      //   contyn: x.contyn,
      // })),
    };

    const paramsContraction = {
      ...this.state.actInfo,
      items2: {
        ...this.state.contractinformationsFocused,
        perid: this.state.contractinformationsFocused?.perid || this.state.contractinformationsFocusedNew?.perid,
        pernm: this.state.contractinformationsFocused?.pernm || this.state.contractinformationsFocusedNew?.pernm,
        mgubun: this.state.contractinformationsFocused?.mgubun || this.state.contractinformationsFocusedNew?.mgubun,
        taxflag: this.state.contractinformationsFocused?.taxflag || this.state.contractinformationsFocusedNew?.taxflag,
        jirogubun: this.state.contractinformationsFocused?.jirogubun || this.state.contractinformationsFocusedNew?.jirogubun,
        contgubun: this.state.contractinformationsFocused?.contgubun || this.state.contractinformationsFocusedNew?.contgubun,
        contdate: this.state.contractinformationsFocused?.contdate || this.state.contractinformationsFocusedNew?.contdate,
        stdate: this.state.contractinformationsFocused?.stdate || this.state.contractinformationsFocusedNew?.stdate,
        enddate: this.state.contractinformationsFocused?.enddate || this.state.contractinformationsFocusedNew?.enddate,
        recedate: this.state.contractinformationsFocused?.recedate || this.state.contractinformationsFocusedNew?.recedate,
      },
    };
    const paramsDivision = {
      ...this.state.actInfo,
      items6: this.state.divisionsFocused,
    };

    let isSuccess: boolean;

    if (this.tabIndex === EnrollmentTabId.UNIT) {
      const contynCheck = this?.state.units?.filter((y) => y.contyn === '1');

      // 거래처등록 화면에서 넘어왔을 때
      if (this.preMenu !== 'w_tb_xclient') {
        if (!ConfirmWarning.assert(contynCheck?.length.toString() === this.state.actInfo?.qty, '오류', '관리수와 호기대수가 맞지않습니다.')) {
          return;
        }
      }
      isSuccess = await api.fxSave(
        'tab_1_save',
        paramsUnit,
        this.state.unitFocused?.new === '1',
      );
    } else if (this.tabIndex === EnrollmentTabId.CONTRACKINFORMATION) {
      // 신규 등록 시 tab2에서 계약정보 내용도 저장되서 tab1에서 저장하게끔 막음
      if (this.state.contractinformationsFocusedNew === undefined && this.state.data?.isNew === true) {
        ConfirmWarning.show('경고', '신규 등록 시 호기등록 탭에서 저장 후 이용해주세요');
        return;
      }

      if (this.state.contractinformationsFocused?.mupernm === '' && this.state.contractinformationsFocused?.muperid.trim() !== '') {
        ConfirmWarning.show('경고', '무상계약진행자 이름 또는 사원번호가 누락되었습니다.\n 무상계약진행자 입력란으로 가서 더블클릭하여 선택 또는 엔터키를 눌러주십시오.');
        return;
      }

      if (this.state.contractinformationsFocused?.pernm === '' && this.state.contractinformationsFocused?.perid.trim() !== '') {
        ConfirmWarning.show('경고', '계약자 이름 또는 사원번호가 누락되었습니다.\n 계약자 입력란으로 가서 더블클릭하여 선택 또는 엔터키를 눌러주십시오.');
        return;
      }

      isSuccess = await api.fxSave(
        'tab_2_save',
        paramsContraction,
        false,
      );
    } else if (this.tabIndex === EnrollmentTabId.DIVISION) {
      isSuccess = await api.fxSave(
        'tab_6_save',
        paramsDivision,
        false,
      );
    } else {
      isSuccess = await api.fxSave(
        `${EnrollmentTabFunctionNames[i]}_save`,
        {
          ...this.state.actInfo,
          // @ts-ignore
          items3: this.state[EnrollmentTabUpdatesStateNames[i]],
        },
        true,
      );
    }

    if (!isSuccess) {
      return;
    }

    await this.SS({
      unitFocused: new EnrollmentUnitModel(this.state.unitFocused),
      contractinformationsFocusedNew: undefined,
    });

    isSuccess && await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
    await this.tableUnit.current?.update();
    this.tableUnit.current?.setFocus(0, 1);
    await this.tableCheck.current?.update();
    this.tableCheck.current?.setFocus(0, 1);
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;

    if (!this.state.checkPageMove && (this.state.searchQuery !== '' || this.state.contflag !== '%'
      || this.state.perid !== '' || this.state.pernm !== '' || this.state.wkactcd !== '' || this.state.wkactnm !== '')) {
      await this.SS({
        searchQuery: '',
        contflag: '%',
        perid: '',
        pernm: '',
        wkactcd: '',
        wkactnm: '',
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }

    if (this.state.data?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    await this.infinity2?.retrieveAll();

    if (this.state.checkPageMove) {
      const { user } = this.props.publicStore;
      // 신규로 actcd값 가져오기 위해 new 해줌
      const data = await api.fxExec(
        `${EnrollmentTabFunctionNames[i]}_new`,
        {
          perid: this.props.publicStore?.user?.userid,
          spjangcd: this.props.publicStore?.user?.spjangcd,
        },
      );
      this.isChangedElno = false;
      await this.SS({
        checkPageMove: false,
        placeList: [this.state.actinfoNew, ...this.state.placeList || []],
        data: new EnrollmentActModel(this.state.actinfoNew, true),
        units: [],
        unitFocused: new EnrollmentUnitModel(),
        unitUpdates: [new EnrollmentUnitModel()],
        actInfo: new EnrollmentActModel({
          ...this.state.actinfoNew,
          actcd: data?.actcd,
          qty: '1',
          wkactcd: '001',
          wkactnm: '자사',
          perid: user.perid,
        }, true),
      });
      await this.onSaveEvent();
      // await this.unitCreate();
    } else {
      const data = await api.fxExec(
        `${EnrollmentTabFunctionNames[i]}_new`,
        {
          perid: this.state.focuseddata?.perid,
          spjangcd: this.state.focuseddata?.spjangcd,
        },
      );

      // 그린엘리베이터(평택) 자체적으로 현장코드 이용하여 이상해서 따로뻄
      if (data && this.props.publicStore.user.custcd === 'green_p') {
        this.setState({
          placeList: [new EnrollmentActModel({
            ...data,
            actcd: '',
            actnm: '',
            actid: '',
            wkactcd: '',
            wkactnm: '',
          }, true), ...this.state.placeList || []],
          actInfo: new EnrollmentActModel({
            ...data,
            actcd: '',
            actnm: '',
            actid: '',
            wkactcd: '',
            wkactnm: '',
          }, true),
          data: new EnrollmentActModel({
            ...data,
            actcd: '',
            actnm: '',
            actid: '',
            wkactcd: '',
            wkactnm: '',
          }, true),
        });
      } else {
        this.setState({
          placeList: [new EnrollmentActModel(data, true), ...this.state.placeList || []],
          actInfo: new EnrollmentActModel(data, true),
          data: new EnrollmentActModel(data, true),
        });
      }
      data && this.setState({
        units: [],
        unitFocused: new EnrollmentUnitModel(),
      });
      this.grid.current?.setFocus(0);
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `현장코드: ${this.state.focuseddata?.actcd}, 현장명: ${this.state.focuseddata?.actnm}`;
    if (await ConfirmDelete.show(text)) {
      const text2 = '현장을 삭제하시는게 맞습니까?';
      await api.delete(text2, this.state.focuseddata) && await this.onRetrieveEvent();
    }
  }

  @action
  async billgubunChange(name: string, item: any) {
    // 매출분류 비매출로 변경시 전자계산서발행 미발행으로
    const { actionStore: api } = this.props;
    let data : any = [];

    if (name === 'billgubun') {
      data = await api.fxExec(
        'dw_4_itemchanged',
        {
          itemname: name,
          data: item.billgubun,
        },
      );
    }

    data && this.setState({
      contractinformationsFocused: new EnrollmentContractInformationModel({
        ...this.state.contractinformationsFocused,
        ...data,
      }, this.state.contractinformationsFocused?.isNew),
    });
  }

  @action
  async dw_3_itemChanged(value: boolean, item: any, rowUpdate: RowUpdate, itemname: string) {
    const { actionStore: api } = this.props;

    // 호기등록 계약 체크 변동 시
    if (itemname === 'contyn') {
      rowUpdate({
        ...item,
        contyn: value === true ? '1' : '0',
      });

      const data = await api.fxExec('dw_3_itemchanged',
        {
          itemname: 'contyn',
          data: value === true ? '1' : '0',
          actcd: item.actcd,
          equpcd: item.equpcd,
        });

      // 호기등록 계약 체크 변동 시 actInfo의 관리수 qty를 reRender 하기 위함.
      this.setState({
        actInfo: new EnrollmentActModel({
          ...this.state.actInfo,
          qty: data.qty,
        }),
      });
    }
  }

  @action
  onRowFocusEvent(item: any) {
    // 정확하진 않을수도있음
    // unitFocused: 호기등록 focus Data
    // actInfo: List Focus Data
    // units: 호기등록 items

    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.setState({
      contractVisible: false,
      divisionVisible: false,
    });

    // if (this.state.actInfo?.isNew && this.state.checkPageMove) {
    //   return;
    // }

    if (this.state.focuseddata?.isNew && this.state.checkPageMove) {
      return;
    }

    if (item?.equpcd) {
      this.onDetailRowFocusEvent(new EnrollmentUnitModel({
        custcd: item.custcd,
        spjangcd: item.spjangcd,
        actcd: item.actcd,
        equpcd: item.equpcd,
      }), 0);
    }

    this.isChangedElno = false;

    // focuseddata랑 actInfo는 뭐가 다른 데이터일까
    if (item?.isNew && this.props.publicStore.user.custcd === 'green_p') {
      this.setState({
        focuseddata: new EnrollmentActModel(item, item?.isNew),
        actInfo: new EnrollmentActModel(item, item?.isNew),
        contractinformations: [],
        incharges: [],
        accounts: [],
        receivables: [],
      });
      this.table.current?.update(true);
      return;
    }

    this.setState({
      focuseddata: new EnrollmentActModel(item, item?.isNew),
    }, async () => {
      const data = await api.exec(
        Category.MAINTENANCE,
        `${EnrollmentTabFunctionNames[this.tabIndex]}_dw_1_RowFocuschanged`,
        {
          ...this.state.focuseddata,
          as_nm: this.state.searchQuery,
        },
      );

      const i = this.tabIndex + 1;
      // eslint-disable-next-line no-prototype-builtins
      const itemsNumber = data.hasOwnProperty(`items${i}`) && data[`items${i}`];
      if (itemsNumber) {
        // @ts-ignore
        data && this.setState({
          // 신규일 떄 탭이동시 rowfocus타는현상으로 인해 입력했던 actInfo값들이 사라지는현상 으로
          // 삼항조건 넣었음.
          actInfo: item?.isNew ? this.state.actInfo : new EnrollmentActModel(data, item?.isNew),
          units: itemsNumber.map((x: any) => new EnrollmentUnitModel(x)),
          [EnrollmentTabDataStateNames[this.tabIndex]]:
            itemsNumber.map(
              (x: any) => new EnrollmentTabModels[this.tabIndex](x),
            ),
          contractinformationsFocused:
            new EnrollmentContractInformationModel(data?.items2 && data?.items2.length ? data?.items2[0] : {}),
          divisionsFocused:
            new EnrollmentDivisionModel(data?.items6 && data?.items6.length ? data?.items6[0] : {}),
          checks: itemsNumber.map((x: any) => new EnrollmentCheckModel(x)),
          histroiesFocused: data?.items3?.length ? new EnrollmentHistoryModel(data.items3[0]) : undefined,
        }, () => {
          this.table.current?.update();
        });
      } else {
        // @ts-ignore
        this.setState({
          [EnrollmentTabDataStateNames[this.tabIndex]]: [],
        }, () => this.table.current?.update());
      }

      if (data?.items6) {
        this.setState({ divisionVisible: true });
      } else {
        // @ts-ignore
        data && this.setState({
          // 신규일 떄 탭이동시 rowfocus타는현상으로 인해 입력했던 actInfo값들이 사라지는현상 으로
          // 삼항조건 넣었음.
          actInfo: item?.isNew ? this.state.actInfo : new EnrollmentActModel(data, item?.isNew),
        }, async () => {
          await this.table.current?.update();
          await this.tableUnit.current?.update();
          await this.tableCheck.current?.update();
          if (this.state.units && this.state.units.length > 0) {
            this.tableUnit.current?.setFocus(0);
          }
          if (this.state.checks && this.state.checks.length > 0) {
            this.tableCheck.current?.setFocus(0);
          }
          // 미수내역탭
          if (i === 8 && this.state.receivables && this.state.receivables.length > 0) {
            // focusdata -> 미수내역탭  data
            // @ts-ignore
            await this.SS({
              [EnrollmentTabFocusedStateNames[this.tabIndex]]: this.state.receivables[0],
            });
          }
          if (this.state.histories && this.state.histories.length > 0) {
            this.tableHistory.current?.setFocus(0);
          }
          if (!data?.items3) {
            this.setState({
              contractinformations: [],
            });
            await this.table.current?.update();
          }
          if (!data?.items4) {
            this.setState({
              incharges: [],
            });
            await this.table.current?.update();
          }
          if (!data?.items5) {
            this.setState({
              accounts: [],
            });
            await this.table.current?.update();
          }
          if (!data?.items8) {
            this.setState({
              receivables: [],
            });
          }
        });
      }

      if (i === 1) {
        if (!data?.items1) {
          this.setState({
            units: [],
            unitFocused: new EnrollmentUnitModel(),
          }, () => {
            this.tableUnit.current?.update();
          });
          return;
        }
        await this.tableUnit.current?.update();
        this.state.units?.length && this.tableUnit.current?.setFocus(0);
      }
    });
  }

  @action
  async onDetailRowFocusEvent(item: EnrollmentUnitModel, i: number) {
    const { actionStore: api } = this.props;

    if (!item) {
      return;
    }

    if (this.state.unitFocused?.isNew && this.tabIndex === EnrollmentTabId.UNIT) {
      return;
    }

    // if (item.new === '1') {
    //   return;
    // }

    this.updatedRows = [];
    this.focusedUnitIndex = i;
    this.isChangedElno = false;

    if (this.state.data?.isNew) {
      await this.SS({
        units: [],
        unitFocused: new EnrollmentUnitModel(),
      });
      return;
    }

    await this.SS({ focusedDetail: item });

    const data = await api.exec(Category.MAINTENANCE,
      `${EnrollmentTabFunctionNames[this.tabIndex]}_dw_3_RowFocuschanged`, this.state.focusedDetail);
    data && await this.SS({
      unitFocused: data,
    });
  }

  @action
  async onDetailCheckRowFocusEvent(item: any) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    await this.SS({ focusedDetail: item });
    const data = await api.exec(Category.MAINTENANCE,
      `${EnrollmentTabFunctionNames[this.tabIndex]}_dw_10_RowFocuschanged`, this.state.focusedDetail);

    data && await this.SS({
      checkDetail: data.items1,
      checksFocused: data,
    });

    if (this.state.checks && this.state.checks.length > 0) {
      await this.tableCheckDetail.current?.forceRepaint(true);
      this.tableCheckDetail.current?.setFocus(0);
    }
  }

  @action
  async onCheckDetailRowFocusEvent(item: any) {
    this.setState({ checkDetailFocused: item });
  }

  @action
  async onTabChange(focusedTab: TabModel) {
    const i = this.tabIndex;
    const { user } = this.props.publicStore;

    // 보수팀 계약정보탭, 계약이력정보탭 권한 막음
    if (focusedTab.id === '1' || focusedTab.id === '2') {
      if (this.state.recontflag === '0' && this.state.rekukcd !== '01') {
        ConfirmWarning.show('경고', '권한이 없습니다');
        return;
      }

      if (user.contflag === '0' && user.kukcd !== '01') {
        ConfirmWarning.show('경고', '권한이 없습니다');
        return;
      }
    }

    if (this.state.focusedTab
      // @ts-ignore
      && (this.state[EnrollmentTabUpdatesStateNames[i]]?.length || 0) > 0) {
      const text = '저장하지 않은 데이터가 있습니다.\n탭을 넘어가면 저장하지 않은 데이터는 사라집니다.\n넘어가시겠습니까?';
      if (await Confirm.show('경고', text, ConfirmType.WARNING)) {
        // @ts-ignore
        this.setState({
          [EnrollmentTabUpdatesStateNames[i]]: [],
          [EnrollmentTabFocusedStateNames[i]]: undefined,
        });
      } else {
        return;
      }
    }
    // @ts-ignore
    this.setState({
      focusedTab,
      [EnrollmentTabUpdatesStateNames[i]]: [],
      [EnrollmentTabFocusedStateNames[i]]: undefined,
    },
    () => {
      this.onRowFocusEvent(this.state.focuseddata);
    });
  }

  @action
  async checkModal(isOpen: boolean) {
    if (this.state.actInfo?.isNew) {
      ConfirmWarning.show('경고', '현장 신규 등록시에는 저장 후 담당자를 지정하실 수 있습니다');
      return;
    }

    const { user } = this.props.publicStore;

    if (user.custcd === 'dmyong_g' && user.perid !== '00039' && user.divicd.substr(0, 2) !== 'AA') {
      const text2 = '담당자변경 권한이 없습니다';
      ConfirmWarning.show('확인', text2);
    } else {
      await this.SS({
        checkPersonDetailModal: isOpen,
        PersonPopUpFocused: new PersonPopupModel(),
      });
      await this.checkPersonRetrieve();

      if (!isOpen && !this.state.data?.isNew) {
        this.onRowFocusEvent(this.state.focuseddata);
      }
    }
  }

  @action
  async personEmailModal(isOpen: boolean) {
    await this.SS({ personEmailDetailModal: isOpen });
    await this.personEmailRetrieve();

    if (!isOpen && !this.state.data?.isNew) {
      this.onRowFocusEvent(this.state.focuseddata);
    }
  }

  @action
  async taxModal(isOpen: boolean) {
    // 거래처 수정시 onSave를하면서 현장명 및 회사구분을 입력안하면 다시 조회되는현상으로 먼저 입력하도록 수정
    if (this.state.actInfo?.actnm === '' || this.state.actInfo?.wkactnm === '') {
      ConfirmWarning.show('경고', '현장명과 회사구분을 먼저 입력해주세요');
      return;
    }

    // 오픈 시
    if (isOpen) {
      this.updatedRows2 = [];
      await this.taxRetrieve();
    }

    // 변경된 내역 없으면 그냥 닫기
    if (!isOpen && !this.updatedRows2?.length) {
      await this.SS({
        taxDetailModal: isOpen,
      });
      return;
    }

    // 닫기 시 변경된내역이있으면 저장하겠냐고 물어보기
    if (!isOpen) {
      // 변경된 저장 안하면 그냥닫고 저장 시에만 save타도록 함.
      if (this.updatedRows2?.length && await Confirm.ask('경고', '수정된 내역이 있습니다. 저장 후 닫으시겠습니까?', '예', '아니오') === AskType.YES) {
        await this.onTaxSaveEvent();

        if (!this.state.data?.isNew) {
          this.onRowFocusEvent(this.state.focuseddata);
        }
      }
    }
    await this.SS({ taxDetailModal: isOpen });
  }

  @action
  async personEmailRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      PersonPopUpData: [],
    });

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_tb_e601_actmail',
        actcd: this.state.focuseddata?.actcd,
      });
    if (data?.items) {
      this.setState({
        PersonPopUpData: data?.items,
      }, async () => {
        await this.tableCheckPerson.current?.update(true);
        this.tableCheckPerson.current?.setFocus(0, 1);
      });
    }
  }

  @action
  async taxRetrieve() {
    const { actionStore: api } = this.props;

    // 초기화
    await this.SS({
      PersonPopUpData: [],
    });

    // 조회
    const data = await api.fxExec('retrieve',
      {
        sub: 'w_tb_e601_cltcd',
        actcd: this.state.focuseddata?.actcd,
      });

    // items있는경우 Data set
    if (data?.items) {
      this.setState({
        PersonPopUpData: data?.items,
      });
    }

    this.state.PersonPopUpData.length && this.tableCheckPerson.current?.setFocus(0);
    await this.tableCheckPerson.current?.update(true);
  }

  @action
  async checkPersonRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_tb_e601_perid',
        actcd: this.state.focuseddata?.actcd,
      });

    if (data?.items) {
      await this.SS({
        PersonPopUpData: data?.items.map((x: any) => new PersonPopupModel(x)),
      });
    } else {
      await this.SS({
        PersonPopUpData: [],
      });
    }

    await this.tableCheckPerson.current?.update(true);
    this.state.PersonPopUpData.length && this.tableCheckPerson.current?.setFocus(0, 1);
  }

  @action
  async onNewCheckPersonEvent() {
    const { actionStore: api } = this.props;

    // 담당(점검자) 히스토리 팝업창 신규 추가 시 한 행만 추가되도록 제한
    if (this.state.PersonPopUpData.length > 0) {
      if (this.state.PersonPopUpData[this.state.PersonPopUpData.length - 1]?.isNew) {
        ConfirmWarning.show('확인', '한번에 한 행만 추가할 수 있습니다.');
        return;
      }
    }

    const data = await api.new({
      sub: 'w_tb_e601_perid',
      actcd: this.state.focuseddata?.actcd,
    });

    const newModel = new PersonPopupModel(data, true);

    if (data) {
      if (data.be_enddate) {
        this.state.PersonPopUpData[this.state.PersonPopUpData.length - 1].enddate = data.be_enddate;
      }
      this.setState({
        PersonPopUpData: [...this.state.PersonPopUpData || [], newModel],
        PersonPopUpFocused: newModel,
      }, () => this.tableCheckPerson.current?.update(true));
      this.tableCheckPerson.current?.setFocus(this.state.PersonPopUpData.length - 1, 1);
    }
  }

  @action
  async onNewTaxEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_tb_e601_cltcd',
      actcd: this.state.focuseddata?.actcd,
    });

    if (this.state.PersonPopUpData.length === 0) {
      data.seq = '01';
    } else {
      data.seq = `0${Number(this.state.PersonPopUpData[this.state.PersonPopUpData.length - 1].seq) + 1}`;
    }
    const newModel = new PersonPopupModel(data, true);

    if (data) {
      this.setState({
        PersonPopUpData: [...this.state.PersonPopUpData || [], newModel],
        PersonPopUpFocused: newModel,
      }, () => this.tableCheckPerson.current?.update(true));
      this.tableCheckPerson.current?.setFocus(this.state.PersonPopUpData.length - 1, 1);
    }
  }

  @action
  async onNewPersonEmailEvent() {
    const { actionStore: api } = this.props;

    const data = await api.new({
      sub: 'w_tb_e601_actmail',
      actcd: this.state.focuseddata?.actcd,
    });

    const newModel = new PersonPopupModel(data, true);

    if (data) {
      this.setState({
        PersonPopUpData: [...this.state.PersonPopUpData || [], newModel],
        PersonPopUpFocused: newModel,
      }, () => this.tableCheckPerson.current?.update(true));
      this.tableCheckPerson.current?.setFocus(this.state.PersonPopUpData.length - 1, 1);
    }
  }

  @action
  async onCheckPersonSaveEvent() {
    const { actionStore: api } = this.props;

    let personNull = false;

    // 담당자명 비어있을시 입력하도록 경고창
    if (this.state.PersonPopUpData[this.state.PersonPopUpData.length - 1]?.isNew && this.state.PersonPopUpData[this.state.PersonPopUpData.length - 1]?.pernm === '') {
      personNull = true;
    }

    if (personNull) {
      ConfirmWarning.show('경고', '담당자명이 비어있습니다. \n담당자코드를 더블클릭하여 선택해주시거나 \n담당자코드를 입력 후 엔터를 눌러주시길 바랍니다.');
      return;
    }

    // const params = [{
    //   new: this.state.PersonPopUpFocused.new,
    //   actcd: this.state.PersonPopUpFocused.actcd,
    //   seq: this.state.PersonPopUpFocused.seq,
    //   perid: this.state.PersonPopUpFocused.perid,
    //   pernm: this.state.PersonPopUpFocused.pernm,
    //   stdate: this.state.PersonPopUpFocused.stdate,
    //   enddate: this.state.PersonPopUpFocused.enddate,
    //   remark: this.state.PersonPopUpFocused.remark,
    // }];

    const data = await api.save({
      sub: 'w_tb_e601_perid',
      items: this.updatedRows2,
    }, true);
    if (data) {
      this.setState({
        saveData: this.updatedRows2,
      }, async () => this.checkModal(false));
    }

    const changeData = {
      // @ts-ignore
      divinm: this.state.saveData[0].divinm || this.state.saveData[0].uv_arg3,
      // @ts-ignore
      perid: this.state.saveData[0].perid,
      // @ts-ignore
      pernm: this.state.saveData[0].pernm,
    };

    this.setState({
      actInfo: new EnrollmentActModel({
        ...this.state.actInfo,
        ...changeData,
      }, this.state.focuseddata?.isNew), // this.state.actInfo?.isNew
    });
  }

  @action
  async onTaxSaveEvent() {
    const { actionStore: api } = this.props;

    // 거래처명 비어있을시 입력하도록 경고창
    const cltnmEmpty = this.state.PersonPopUpData?.some((x) => x.isNew && (!x.cltcd || !x.cltnm));

    if (cltnmEmpty) {
      ConfirmWarning.show('경고', '거래처명이 비어있습니다. \n거래처코드를 더블클릭하여 선택해주시거나 \n거래처코드를 입력 후 엔터를 눌러주시길 바랍니다.');
      return;
    }

    // 변경 없을 시 updatedRows가 없으므로 변경저장내용없다고 띄어줌
    if (!this.updatedRows2?.length) {
      ConfirmWarning.show('경고', '변경된 내용이 없습니다.');
      return;
    }

    // 한개 이상 거래처가 있어야함.
    // 기존에 delete시 거래처가 모두 삭제 가능하도록 되있어서 delete시에 한개가 남으면 삭제 안되도록 수정하긴함 그래서 필요없을지도.
    if (!this.state.PersonPopUpData?.length) {
      ConfirmWarning.show('경고', '최소 한개 이상의 거래처가 등록되어야 합니다.');
      return;
    }

    // 마지막 거래처로 셋팅.
    const currentCltInfo = this.state.PersonPopUpData[this.state.PersonPopUpData.length - 1];

    const data = await api.save({
      sub: 'w_tb_e601_cltcd',
      contflag: this.state.actInfo?.contflag,
      contdate: this.state.actInfo?.contdate,
      items: this.updatedRows2,
    }, this.state.actInfo?.new === '1');

    // 얘는 왜 있는지 모르겠음
    if (data) {
      await this.SS({
        saveData: this.updatedRows2,
      });
    }

    // 저장 시 화면단 거래처 변경
    await this.SS({
      actInfo: new EnrollmentActModel({
        ...this.state.actInfo,
        cltcd: currentCltInfo.cltcd,
        cltnm: currentCltInfo.cltnm,
      }, this.state.focuseddata?.isNew),
    });

    // 신규등록시 호기등록탭에서 저장시켜야해서 tab2_save안타도록함
    if (this.state.data?.isNew) {
      return;
    }

    // 저장 시 계약정보탭 유지보수거래처 변경
    if (!(!this.state.contractinformationsFocused
      || this.state.contractinformationsFocused?.isNew
      || (this.state.contractinformationsFocusedNew && this.state.contractinformationsFocusedNew?.isNew)
    )) {
      const paramsContraction = {
        ...this.state.actInfo,
        contdate: this.state.contractinformationsFocused?.contdate || this.state.contractinformationsFocusedNew?.contdate,
        cltcd: currentCltInfo.cltcd,
        cltnm: currentCltInfo.cltnm,
        items2: {
          ...this.state.contractinformationsFocused,
          perid: this.state.contractinformationsFocused?.perid || this.state.contractinformationsFocusedNew?.perid,
          pernm: this.state.contractinformationsFocused?.pernm || this.state.contractinformationsFocusedNew?.pernm,
          mgubun: this.state.contractinformationsFocused?.mgubun || this.state.contractinformationsFocusedNew?.mgubun,
          taxflag: this.state.contractinformationsFocused?.taxflag || this.state.contractinformationsFocusedNew?.taxflag,
          jirogubun: this.state.contractinformationsFocused?.jirogubun || this.state.contractinformationsFocusedNew?.jirogubun,
          contgubun: this.state.contractinformationsFocused?.contgubun || this.state.contractinformationsFocusedNew?.contgubun,
          contdate: this.state.contractinformationsFocused?.contdate || this.state.contractinformationsFocusedNew?.contdate,
          stdate: this.state.contractinformationsFocused?.stdate || this.state.contractinformationsFocusedNew?.stdate,
          enddate: this.state.contractinformationsFocused?.enddate || this.state.contractinformationsFocusedNew?.enddate,
          recedate: this.state.contractinformationsFocused?.recedate || this.state.contractinformationsFocusedNew?.recedate,
          cltcd: currentCltInfo.cltcd,
          cltnm: currentCltInfo.cltnm,
        },
      };

      await api.fxExec(
        'tab_2_save',
        paramsContraction,
        this.state.focuseddata?.isNew,
      );
    }
    await this.taxRetrieve();
    await this.onRetrieveEvent(); // tab2의 유지보수거래처도 바껴야하므로 retrieve시킴
    this.updatedRows2 = [];
  }

  @action
  async onPersonEmailSaveEvent() {
    const { actionStore: api } = this.props;

    const data = await api.save({
      sub: 'w_tb_e601_actmail',
      items: this.updatedRows2,
    }, true);
    if (data) {
      this.setState({
        saveData: this.updatedRows2,
      }, () => this.checkPersonRetrieve());
    }

    const changeData = {
      // @ts-ignore
      actmail: this.state.saveData[0].actmail,
    };

    this.setState({
      actInfo: new EnrollmentActModel({
        ...this.state.actInfo,
        ...changeData,
      }, true),
    });
  }

  @action
  async onCheckPersonDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e601_perid',
      actcd: this.state.PersonPopUpFocused.actcd,
      seq: this.state.PersonPopUpFocused.seq,

    }) && await this.checkPersonRetrieve();
    await this.tableCheckPerson.current?.update(true);

    this.updatedRows2 = [];
  }

  @action
  async onTaxDelete() {
    const { actionStore: api } = this.props;

    // 거래처 1개이상 남아있어야함.
    if (this.state.PersonPopUpData.length < 2) {
      ConfirmWarning.show('경고', '마지막 거래처는 삭제가 불가능합니다.');
      return;
    }

    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e601_cltcd',
      actcd: this.state.PersonPopUpFocused.actcd,
      contflag: this.state.PersonPopUpFocused.contflag,
      contdate: this.state.PersonPopUpFocused.contdate,
      seq: this.state.PersonPopUpFocused.seq,

    }) && await this.taxRetrieve();

    this.updatedRows2 = [];

    // 삭제 시 마지막 거래처 셋팅.
    const currentCltInfo = this.state.PersonPopUpData[this.state.PersonPopUpData.length - 1];

    // 기존화면 거래처, 거래처명변경
    await this.SS({
      actInfo: new EnrollmentActModel({
        ...this.state.actInfo,
        cltcd: currentCltInfo.cltcd,
        cltnm: currentCltInfo.cltnm,
      }, this.state.focuseddata?.isNew),
    });

    // 신규등록시 호기등록탭에서 저장시켜야해서 tab2_save안타도록함
    if (this.state.data?.isNew) {
      return;
    }

    // 저장 시 계약정보탭 유지보수거래처 변경
    if (!(!this.state.contractinformationsFocused
      || this.state.contractinformationsFocused?.isNew
      || (this.state.contractinformationsFocusedNew && this.state.contractinformationsFocusedNew?.isNew)
    )) {
      const paramsContraction = {
        ...this.state.actInfo,
        contdate: this.state.contractinformationsFocused?.contdate || this.state.contractinformationsFocusedNew?.contdate,
        cltcd: currentCltInfo.cltcd,
        cltnm: currentCltInfo.cltnm,
        items2: {
          ...this.state.contractinformationsFocused,
          perid: this.state.contractinformationsFocused?.perid || this.state.contractinformationsFocusedNew?.perid,
          pernm: this.state.contractinformationsFocused?.pernm || this.state.contractinformationsFocusedNew?.pernm,
          mgubun: this.state.contractinformationsFocused?.mgubun || this.state.contractinformationsFocusedNew?.mgubun,
          taxflag: this.state.contractinformationsFocused?.taxflag || this.state.contractinformationsFocusedNew?.taxflag,
          jirogubun: this.state.contractinformationsFocused?.jirogubun || this.state.contractinformationsFocusedNew?.jirogubun,
          contgubun: this.state.contractinformationsFocused?.contgubun || this.state.contractinformationsFocusedNew?.contgubun,
          contdate: this.state.contractinformationsFocused?.contdate || this.state.contractinformationsFocusedNew?.contdate,
          stdate: this.state.contractinformationsFocused?.stdate || this.state.contractinformationsFocusedNew?.stdate,
          enddate: this.state.contractinformationsFocused?.enddate || this.state.contractinformationsFocusedNew?.enddate,
          recedate: this.state.contractinformationsFocused?.recedate || this.state.contractinformationsFocusedNew?.recedate,
          cltcd: currentCltInfo.cltcd,
          cltnm: currentCltInfo.cltnm,
        },
      };

      await api.fxExec(
        'tab_2_save',
        paramsContraction,
        false,
      );
    }

    await this.taxRetrieve();
    await this.onRetrieveEvent(); // tab2의 유지보수거래처도 바껴야하므로 retrieve시킴
    this.updatedRows2 = [];
  }

  @action
  async onPersonEmailDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e601_actmail',
      actcd: this.state.PersonPopUpFocused.actcd,
      seq: this.state.PersonPopUpFocused.seq,

    }) && await this.checkPersonRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async onMailRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_tb_e601_actmail',
        actcd: this.state.focuseddata?.actcd,
      });
    if (data?.items) {
      this.setState({
        PersonPopUpData: data?.items,
      });
    }
  }

  @action
  async contractSelectModal(isOpen: boolean) {
    if (this.state.contractflag === '1') {
      this.setState({ contractflag: '0' });
    }
    this.setState({ selectModal: isOpen });
  }

  @action
  async openUnitRemoteControl() {
    const item = this.state.unitFocused;
    const { publicStore } = this.props;

    if (this.isChangedElno) {
      ConfirmWarning.show('오류', '승강기 번호 변경 후 우선 저장해주세요');
      return;
    }

    if (!item) {
      ConfirmWarning.show('오류', '호기를 먼저 선택해주세요');
      return;
    }

    if (item?.new === '1') {
      ConfirmWarning.show('오류', '한 번 저장 후 시도해주세요');
      return;
    }

    if (!item.elno) {
      ConfirmWarning.show('오류', '승강기 번호를 입력 후 재시도 해주세요');
      return;
    }

    if (item.elno.length !== 7) {
      ConfirmWarning.show('오류', '승강기 번호가 이상합니다');
      return;
    }

    await publicStore.withManager(() => {
      if (!this.state.units?.length) {
        ConfirmWarning.show('오류', '호기를 먼저 선택해주세요');
      } else if (this.state.units?.length > 1) {
        this.SS({ unitRemoteModal: true });
      } else {
        this.isWholeGosiSync = false;
        this.syncEqupFromGosi();
      }
    });
  }

  @action
  async closeRemoteControlModal(success: boolean, isMulti: boolean) {
    await this.SS({ unitRemoteModal: false });

    if (success) {
      this.isWholeGosiSync = isMulti;
      await this.syncEqupFromGosi();
    }
  }

  @action
  async syncEqupFromGosi() {
    const { publicStore, actionStore: api } = this.props;
    const item = this.state.unitFocused;

    if (!item) {
      ConfirmWarning.show('오류', '호기를 먼저 선택해주세요');
      return;
    }

    if (item?.new === '1') {
      ConfirmWarning.show('오류', '한 번 저장 후 시도해주세요');
      return;
    }

    if (!item.elno) {
      ConfirmWarning.show('오류', '승강기 번호를 입력 후 재시도 해주세요');
      return;
    }

    if (item.elno.length !== 7) {
      ConfirmWarning.show('오류', '승강기 번호가 이상합니다');
      return;
    }

    publicStore.doLoading();

    const defaultRawXml = await publicStore.runManagerScript('007', item?.elno, true);

    if (defaultRawXml.indexOf('<status>404</status>') === -1) {
      const gizongRawJson = await publicStore.runManagerScript('008', item?.elno);

      // if (status4 !== 200) {
      //   ConfirmWarning.show('오류', '정보센터에서 점검 기종을 찾을 수 없었습니다\n직접 입력해주세요');
      //   publicStore.stopLoading();
      //   return;
      // }

      if (!gizongRawJson?.elvtrMgtNo1 || !gizongRawJson?.elvtrMgtNo2) {
        ConfirmWarning.show('오류', '건물관리번호가 없습니다\n잠시 후 재시도 부탁드립니다');
        publicStore.stopLoading();
        return;
      }

      const { elvtrMgtNo1, elvtrMgtNo2 } = gizongRawJson;

      const specRawJson = await publicStore.runManagerScript('005', `${elvtrMgtNo1},${elvtrMgtNo2}`);

      // if (status2 !== 200) {
      //   ConfirmWarning.show('오류', '정보센터에서 제원정보를 찾을 수 없었습니다\n승강기 번호를 확인해주세요');
      //   publicStore.stopLoading();
      //   return;
      // }

      const insuranceRawJson = await publicStore.runManagerScript('004', `${elvtrMgtNo1},${elvtrMgtNo2}`);

      // if (status3 !== 200) {
      //   ConfirmWarning.show('오류', '정보센터에서 보험이 가입된 이력을 찾을 수 없었습니다');
      //   publicStore.stopLoading();
      //   return;
      // }

      const beplanmonRawJson = await publicStore.runManagerScript('009', `${item?.elno},${new Date6Calculator().add(-1).toString()}`);

      await api.fxExec('wb_e611_gosiload', {
        actcd: item.actcd,
        equpcd: item.equpcd,
        elno: item.elno,
        elvtrMgtNo1,
        elvtrMgtNo2,
        items1: defaultRawXml,
        items2: JSON.stringify({
          ds_list: specRawJson.ds_list.map((x: any) => ({ COLUMN_NAME: x.COLUMN_NAME || '', SPEC_ITEM_NAME: x.SPEC_ITEM_NAME || '' })),
          ErrorMsg: specRawJson.ErrorMsg,
          ErrorCode: specRawJson.ErrorCode,
        }),
        items3: JSON.stringify({
          ds_list: insuranceRawJson.ds_list.map((x: any) => ({
            COMPANY_NM: x.COMPANY_NM || '',
            CONT_EN_DE: x.CONT_EN_DE || '',
            CONT_ST_DE: x.CONT_ST_DE || '',
            INSURANCE_KIND: x.INSURANCE_KIND || '',
            INSURANCE_STOCK_NO: x.INSURANCE_STOCK_NO || '',
          })),
          ErrorMsg: insuranceRawJson.ErrorMsg,
          ErrorCode: insuranceRawJson.ErrorCode,
        }),
        items4: JSON.stringify({
          chkcode: `${gizongRawJson.chkcode}`,
          chknum: `${gizongRawJson.chknum}`,
          elvtrMgtNo1: `${gizongRawJson.elvtrMgtNo1}`,
          elvtrMgtNo2: `${gizongRawJson.elvtrMgtNo2}`,
          status: `${gizongRawJson.status}`,
        }),
        items5: JSON.stringify(beplanmonRawJson.response),
      });
    }

    await this.onDetailRowFocusEvent(this.state.focusedDetail, this.focusedUnitIndex);

    publicStore.stopLoading();

    // 일괄 연동시
    if (this.isWholeGosiSync) {
      if ((this.state.units?.length || 0) > this.focusedUnitIndex + 1) {
        // 다음
        this.focusedUnitIndex += 1;
        this.tableUnit.current?.setFocus(this.focusedUnitIndex);
        await this.waitNextEqup(item.elno);
        await this.syncEqupFromGosi();
      } else if (this.state.units?.length) {
        // 기본 연동이 끝나면 검사 연동
        this.focusedUnitIndex = 0;
        this.tableUnit.current?.setFocus(this.focusedUnitIndex);
        await this.waitNextEqup(item.elno);
        await this.askSyncAdChk();
      }
    } else {
      await this.syncAdChk();
    }
  }

  async waitNextEqup(elno: string, resolve: (() => void) | undefined = undefined) {
    return new Promise<void>((res) => {
      if (this.state.unitFocused?.elno === elno) {
        setTimeout(() => this.waitNextEqup(elno, resolve || res), 500);
      } else if (resolve) {
        resolve();
      } else {
        res();
      }
    });
  }

  @action
  async contractOnlyModal(isOpen: boolean) {
    await this.contractRetrieve();
    this.setState({ onlyModal: isOpen });
  }

  @action
  async contractAllModal(isOpen: boolean) {
    await this.contractRetrieve2();
    this.setState({ allModal: isOpen });
  }

  @action
  async askSyncAdChk() {
    const item = this.state.unitFocused;

    if (!item) {
      ConfirmWarning.show('오류', '호기를 먼저 선택해주세요');
      return;
    }

    if (item?.new === '1') {
      ConfirmWarning.show('오류', '한 번 저장 후 시도해주세요');
      return;
    }

    if (AskType.YES === await Confirm.ask(
      '검사 연동',
      '호기 연동은 완료되었습니다\n추가로 정보센터에서 검사 내역을 불러오시겠습니까?\n(검사 연동은 오래걸립니다)',
      '예',
      '아니오',
    )) {
      await this.syncAdChk();
    } else {
      await ConfirmSuccess.show('완료', '호기 연동이 완료되었습니다');
    }
  }

  @action
  async syncAdChk() {
    const { publicStore, actionStore: api } = this.props;

    let item = this.state.unitFocused;

    if (this.tabIndex === EnrollmentTabId.CHECK) {
      item = this.state.focusedDetail;
    }

    if (!item) {
      ConfirmWarning.show('오류', '호기를 먼저 선택해주세요');
      return;
    }

    if (!item?.elvtrmgtno1 || !item?.elvtrmgtno2) {
      ConfirmWarning.show('오류', '건물관리번호가 없습니다\n호기 연동 후 이용 부탁드립니다');
      return;
    }

    await publicStore.withManager(async () => {
      const adchkJson = await publicStore.runManagerScript('002', `${item?.elvtrmgtno1},${item?.elvtrmgtno2}`);

      // if (status !== 200) {
      //   ConfirmWarning.show('오류', '검사 이력을 불러오지 못했습니다\n잠시 후 다시 시도해주세요');
      //   return;
      // }

      const items = {
        ds_list: adchkJson.ds_list.map((x: any) => ({
          ADDRESS: `${x.ADDRESS || ''}`,
          APPLC_BE_DT: `${x.APPLC_BE_DT || ''}`,
          APPLC_EN_DT: `${x.APPLC_EN_DT || ''}`,
          BULD_NM: `${x.BULD_NM || ''}`,
          CNDL_RESULT_DIV: `${x.CNDL_RESULT_DIV || ''}`,
          CRFT_NO: `${x.CRFT_NO || ''}`,
          ELEVATOR_NO: `${x.ELEVATOR_NO || ''}`,
          ELVTR_ASIGN_NM: `${x.ELVTR_ASIGN_NM || ''}`,
          ELVTR_KIND_NM: `${x.ELVTR_KIND_NM || ''}`,
          ELVTR_MGT_NO1: `${x.ELVTR_MGT_NO1 || ''}`,
          ELVTR_MGT_NO2: `${x.ELVTR_MGT_NO2 || ''}`,
          INSPCT_DT: `${x.INSPCT_DT || ''}`,
          INSPCT_INSTT: `${x.INSPCT_INSTT || ''}`,
          INSPCT_INSTT_CD: `${x.INSPCT_INSTT_CD || ''}`,
          INSPCT_INSTT_CD_TY: `${x.INSPCT_INSTT_CD_TY || ''}`,
          INSPCT_KIND: `${x.INSPCT_KIND || ''}`,
          INSPCT_KIND_CD: `${x.INSPCT_KIND_CD || ''}`,
          INSPCT_NAME: `${x.INSPCT_NAME || ''}`,
          INSPCT_ODR: `${x.INSPCT_ODR || ''}`,
          INSPCT_RESULT_CD: `${x.INSPCT_RESULT_CD || ''}`,
          MAIN_INSPCT_NAME: `${x.MAIN_INSPCT_NAME || ''}`,
          PSEXAM_DIV: `${x.PSEXAM_DIV || ''}`,
          PSEXAM_YN: `${x.PSEXAM_YN || ''}`,
          RECPTN_DE: `${x.RECPTN_DE || ''}`,
          RECPTN_NO: `${x.RECPTN_NO || ''}`,
          items: [],
        })),
        ErrorMsg: adchkJson.ErrorMsg,
        ErrorCode: adchkJson.ErrorCode,
      };

      publicStore.doLoading();
      // eslint-disable-next-line no-restricted-syntax
      for (const x of items.ds_list) {
        if (x.INSPCT_RESULT_CD !== '10') {
          // eslint-disable-next-line no-await-in-loop
          const adchkJson = await publicStore.runManagerScript(
            '003',
            `${x.INSPCT_INSTT_CD},${item?.elvtrmgtno1},${item?.elvtrmgtno2},${x.RECPTN_DE},${x.RECPTN_NO}`,
          );
          x.items = adchkJson.ds_list;
        }
      }
      publicStore.stopLoading();

      await api.fxExec('wb_e073', {
        actcd: item?.actcd,
        equpcd: item?.equpcd,
        elno: item?.elno,
        elvtrMgtNo1: item?.elvtrmgtno1,
        elvtrMgtNo2: item?.elvtrmgtno2,
        items: JSON.stringify(items).replace(/\|/ig, '_'),
      });

      await this.onDetailCheckRowFocusEvent(this.state.focusedDetail);

      if (this.isWholeGosiSync) {
        if ((this.state.units?.length || 0) > this.focusedUnitIndex + 1 && item?.elno) {
          // 다음
          this.focusedUnitIndex += 1;
          this.tableUnit.current?.setFocus(this.focusedUnitIndex);
          await this.waitNextEqup(item?.elno);
          await this.syncAdChk();
        } else {
          await ConfirmSuccess.show('완료', '호기, 검사 일괄 연동이 완료되었습니다');
        }
      } else {
        ConfirmSuccess.show('완료', '연동이 완료되었습니다');
      }
    });
  }

  @action
  async contractRetrieve() {
    const { actionStore: api } = this.props;

    this.setState({
      stmon: this.state.contractinformationsFocused?.enddate.slice(0, 6),
      actcd2: this.state.focuseddata?.actcd,
      actnm2: this.state.focuseddata?.actnm,
      wkactcd2: this.state.focuseddata?.wkactcd,
      wkactnm2: this.state.focuseddata?.wkactnm,
    });

    // 무한 스크롤바 헬퍼 초기화
    this.infinityModal = new InfinityRetrieve(
      {
        sub: 'w_popup_e101',
        stmon: this.state.contractinformationsFocused?.enddate.slice(0, 6),
        actcd: this.state.actcd2,
        wkactcd: this.state.wkactcd2,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          contractLongData: [...this.state.contractLongData, ...items],
        });
      },
      async () => {
        await this.SS({ contractLongData: [] });
        await this.infinityModal?.retrieveAll();
        await this.tableContractOnly.current?.update(false);
        this.tableContractOnly.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      contractLongData: [],
    }, async () => {
      await this.infinityModal?.retrieveAll();
      await this.tableContractOnly.current?.update(false);
      this.tableContractOnly.current?.setFocus(0, 1);
    });
  }

  @action
  async contractRetrieveClicked() {
    const { actionStore: api } = this.props;

    this.setState({
      stmon: this.state.stmon,
      actcd2: this.state.actcd2,
      actnm2: this.state.actnm2,
      wkactcd2: this.state.wkactcd2,
      wkactnm2: this.state.wkactnm2,
      taxweek: this.state.taxweek,
    });

    // 무한 스크롤바 헬퍼 초기화
    this.infinityModal = new InfinityRetrieve(
      {
        sub: 'w_popup_e101',
        stmon: this.state.contractinformationsFocused?.enddate.slice(0, 6),
        actcd: this.state.actcd2,
        wkactcd: this.state.wkactcd2,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          contractLongData: [...this.state.contractLongData, ...items],
        });
      },
      async () => {
        await this.SS({ contractLongData: [] });
        await this.infinityModal?.retrieveAll();
        await this.tableContractOnly.current?.update(false);
        this.tableContractOnly.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      contractLongData: [],
    }, async () => {
      await this.infinityModal?.retrieveAll();
      await this.tableContractOnly.current?.update(false);
      this.tableContractOnly.current?.setFocus(0, 1);
    });
  }

  @action
  async contractRetrieve2() {
    const { actionStore: api } = this.props;

    this.setState({
      stmon: Today.yearMon(),
      actcd3: '',
      actnm3: '',
      wkactcd3: '',
      wkactnm3: '',
    });

    // 무한 스크롤바 헬퍼 초기화
    this.infinityModal = new InfinityRetrieve(
      {
        sub: 'w_popup_e101',
        stmon: Today.yearMon(),
        actcd: this.state.actcd3,
        wkactcd: this.state.wkactcd3,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          contractLongData: [...this.state.contractLongData, ...items],
        });
      },
      async () => {
        await this.SS({ contractLongData: [] });
        await this.infinityModal?.retrieveAll();
        await this.tableContractAll.current?.update(false);
        this.tableContractAll.current?.setFocus(0, 1);
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      contractLongData: [],
    }, async () => {
      await this.infinityModal?.retrieveAll();
      await this.tableContractAll.current?.update(false);
      this.tableContractAll.current?.setFocus(0, 1);
    });
  }

  @action
  async contractRetrieve2Clicked() {
    const { actionStore: api } = this.props;

    this.setState({
      stmon: this.state.stmon,
      actcd3: this.state.actcd3,
      actnm3: this.state.actnm3,
      wkactcd3: this.state.wkactcd3,
      wkactnm3: this.state.wkactnm3,
    });

    // 무한 스크롤바 헬퍼 초기화
    this.infinityModal = new InfinityRetrieve(
      {
        sub: 'w_popup_e101',
        stmon: Today.yearMon(),
        actcd: this.state.actcd3,
        wkactcd: this.state.wkactcd3,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          contractLongData: [...this.state.contractLongData, ...items],
        });
      },
      async () => {
        await this.SS({ contractLongData: [] });
        await this.infinityModal?.retrieveAll();
        await this.tableContractAll.current?.update(false);
        this.tableContractAll.current?.setFocus(0, 1);
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      contractLongData: [],
    }, async () => {
      await this.infinityModal?.retrieveAll();
      await this.tableContractAll.current?.update(false);
      this.tableContractAll.current?.setFocus(0, 1);
    });
  }

  @action
  async contractSave() {
    const { actionStore: api } = this.props;

    if (await api.save({
      new: '0',
      sub: 'w_popup_e101',
      items: this.state.contractLongData,
    }, true)) {
      this.tableContractOnly.current?.resetUpdates();
    }
    await this.contractOnlyModal(false);
    await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
  }

  @action
  async contractSave2() {
    const { actionStore: api } = this.props;

    if (await api.save({
      new: '0',
      sub: 'w_popup_e101',
      items: this.state.contractLongData,
    }, true)) {
      this.tableContractAll.current?.resetUpdates();
    }
    await this.contractAllModal(false);
    await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
  }

  @action
  async contractDelete() {
    const { actionStore: api } = this.props;
    const i = this.tabIndex;
    const warning = await Confirm.show('확인', '계약이력은 복구할수없습니다. 삭제하시겠습니까?', ConfirmType.QUESTION);
    if (warning === false) return;

    if (await api.fxExec(
      'wb_e101_history_delete',
      // @ts-ignore
      this.state[EnrollmentTabFocusedStateNames[i]],
    )) await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
  }

  @action
  async getGPS() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_gps',
      {

      });
    this.setState({
      gpsURL: data.url,
    });
    window.open(this.state.gpsURL);
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async photoModal(isOpen: boolean) {
    isOpen && ConfirmWarning.show('경고', '아직준비중입니다.');
  }

  @action
  async onAddressButton(value: any) {
    const message = '현장명으로 주소를 검색하시겠습니까?';
    if (AskType.YES !== await Confirm.ask('확인', message, 'Yes', 'No')) return;

    const data = await this.props.modalStore.openSearchAddress(value, value);
    await this.SS({
      actInfo: new EnrollmentActModel({
        ...this.state.actInfo,
        zipcode: data.zipcode,
        address: data.roadAddress,
        address2: data.address,
        pubzipcd: data.zipcode,
        pubaddress: data.roadAddress,
        anzipcd: data.zipcode,
        anaddress: data.roadAddress,
        anaddress2: data.address,
        publat: data.y,
        publng: data.x,
      }, this.state.focuseddata?.isNew), // this.state.actInfo?.isNew
    });
  }

  @action
  async changePersonModal(isOpen: boolean) {
    this.setState({
      beperid: '',
      bepernm: '',
      perid2: '',
      pernm2: '',
    });
    await this.changeRetrieve();
    this.setState({ changeModal: isOpen });
  }

  @action
  async changePersonModalClose() {
    await this.changeRetrieve();
    this.setState({
      changeModal: false,
      beperid: '',
      bepernm: '',
      perid2: '',
      pernm2: '',
    });
  }

  @action
  async changeRetrieve() {
    const { actionStore: api } = this.props;

    this.setState({
      beperid: this.state.beperid,
      gubun: this.state.changeGubun,
    });

    // 무한 스크롤바 헬퍼 초기화
    this.infinityModal = new InfinityRetrieve(
      {
        sub: 'w_popup_e601',
        beperid: this.state.beperid,
        gubun: this.state.changeGubun,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          changePopUpData: [...this.state.changePopUpData, ...items],
        });
      },
      async () => {
        await this.SS({ changePopUpData: [] });
        await this.infinityModal?.retrieveAll();
        await this.tableChange.current?.update(false);
        this.tableChange.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      changePopUpData: [],
    }, async () => {
      await this.infinityModal?.retrieveAll();
      await this.tableChange.current?.update(true);
      this.tableChange.current?.setFocus(0, 1);
    });
  }

  @action
  async changeSave() {
    const { actionStore: api } = this.props;

    if (this.state.beperid === '' || this.state.bepernm === '') {
      ConfirmWarning.show('경고', '전담당자명이 비어있습니다. \n담당자코드를 더블클릭하여 선택해주시거나 \n담당자명을 입력 후 엔터를 눌러주시길 바랍니다.');
      return;
    }

    if (this.state.perid2 === '' || this.state.pernm2 === '') {
      ConfirmWarning.show('경고', '후담당자명이 비어있습니다. \n담당자코드를 더블클릭하여 선택해주시거나 \n담당자명을 입력 후 엔터를 눌러주시길 바랍니다.');
      return;
    }

    if (await api.save({
      sub: 'w_popup_e601',
      beperid: this.state.beperid,
      perid: this.state.perid2,
      items: this.state.changePopUpData,
      stdate: this.state.modalDate,
    }, true)) {
      this.tableChange.current?.resetUpdates();
      this.setState({
        changeModal: false,
      });
    }
  }

  @action
  async itemChanged(type: number, item: any) {
    const { actionStore: api } = this.props;
    let data : any = [];

    const focusedDetailIndex = this.state.contractLongData.findIndex((x) => x.actnm === item.actnm);

    if (type === EnrollmentItemChangeTypes.bcontg) {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          sub: 'w_popup_e101',
          itemname: EnrollmentItemChangeTypes[type],
          addyn: item.addyn,
          amt: item.amt,
          enddate: item.enddate,
          data: item.bcontg,
        },
      );
      // 변경계약 변경 시 chk값 1로 하기 위함
      if (data !== undefined) {
        data.chk = '1';
      }
    }

    if (type === EnrollmentItemChangeTypes.bildyd) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: EnrollmentItemChangeTypes[type],
          data: item.bildyd,
        },
      );
    }

    data && this.setState({
      actInfo: new EnrollmentActModel({
        ...this.state.actInfo,
        ...data,
      }, this.state.data?.isNew),
    });

    if (type === EnrollmentItemChangeTypes.bstdate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          itemname: EnrollmentItemChangeTypes[type],
          stdate: this.state.stdate,
          enddate: this.state.enddate,
        },
      );
    }

    data && this.setState({
      contractData: new EnrollmentContractLongModel({
        ...this.state.contractData,
      }),
      // eslint-disable-next-line max-len
      contractLongData: this.state.contractLongData.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new EnrollmentContractLongModel({ ...x, ...data, bcontg: item.bcontg }, x.isNew)
          : new EnrollmentContractLongModel(x, x.isNew)
      )),
    }, () => {
      if (this.state.onlyModal === true) {
        this.tableContractOnly.current?.update(false);
      } else if (this.state.allModal === true) {
        this.tableContractAll.current?.update(false);
      }
    });

    data && this.setState({
      contractinformationsFocusedNew: new EnrollmentContractInformationModel({
        ...this.state.contractinformationsFocused,
        ...data,
      }, this.state.contractinformationsFocusedNew?.isNew),
    });

    data && this.setState({
      contractinformationsFocused: new EnrollmentContractInformationModel({
        ...this.state.contractinformationsFocused,
        ...data,
      }, this.state.contractinformationsFocused?.isNew),
    });
  }

  @action
  async HistoryTabItemChanged(item: any, name: string, value: string, rowUpdate: RowUpdate) {
    const { actionStore: api } = this.props;

    const newItem = {
      ...item,
      [name]: value,
    };

    rowUpdate(newItem);

    await api.fxExec(
      'dw_8_itemchanged',
      {
        itemname: name,
        data: value,
        actcd: newItem.actcd,
        contdate: newItem.contdate,
        contg: newItem.contg,
        amt: newItem.amt,
        deljugi: newItem.deljugi,
        contgubun: newItem.contgubun,
        qty: newItem.qty,
      },
    );
  }

  @action
  async itemChangedActcd(cd: string, cdnm: string) {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_2_itemchanged',
      {
        itemname: 'cltcd',
        data: cd,
        pubcltnm: cdnm,
        actcd: this.state.actInfo?.actcd,
      },
    );

    await this.SS({
      actInfo: new EnrollmentActModel({
        ...this.state.actInfo,
        pubpernm: data?.pubpernm || '',
        publat: data?.anlat || '',
        publng: data?.anlng || '',
        pubzipcd: data?.pubzipcd || '',
        pubaddress: data?.pubaddress || '',
        oldaddress: data?.oldpubaddress || '',
        anzipcd: data?.pubzipcd || '',
        anaddress: data?.anaddress || '',
        anaddress2: data?.anaddress2 || '',
        oldanaddress: data?.oldanaddress || '',
        ancltnm: data?.ancltnm || '',
      }, this.state.focuseddata?.isNew), // this.state.actInfo?.isNew
    });

    if (AskType.YES === await Confirm.ask('확인', '승강기 소재지를 계산서발행처(거래처) 주소로 반영하시겠습니까?', '예', '아니요')) {
      await this.SS({
        actInfo: new EnrollmentActModel({
          ...this.state.actInfo, zipcode: data?.pubzipcd || '', address: data?.anaddress || '', address2: data?.anaddress2 || '',
        }, this.state.focuseddata?.isNew), // this.state.actInfo?.isNew
      });
    }
  }

  @action
  async onLinkEvent() {
    const { actionStore: api } = this.props;

    await api.fxExec(
      'wb_e073',
      {
        elno: this.state.checksFocused?.elno,
        elvtrmgtno1: this.state.checksFocused?.elvtrmgtno1,
        elvtrmgtno2: this.state.checksFocused?.elvtrmgtno2,
        actcd: this.state.checksFocused?.actcd,
        equpcd: this.state.checksFocused?.equpcd,
      },
    );
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    if (this.state.onlyModal === true || this.state.allModal === true) {
      this.setState({
        pushTotalCheck: checked,
        contractLongData: this.state.contractLongData?.map((x: any) => ({
          ...x,
          chk: checked ? '1' : '0',
        })),
      });
    } else if (this.state.changeModal === true) {
      this.setState({
        pushTotalCheck: checked,
        changePopUpData: this.state.changePopUpData?.map((x: any) => ({
          ...x,
          chk: checked ? '1' : '0',
        })),
      });
    }
    if (this.state.onlyModal === true) {
      this.tableContractOnly.current?.update(false);
    } else if (this.state.allModal === true) {
      this.tableContractAll.current?.update(false);
    } else if (this.state.changeModal === true) {
      this.tableChange.current?.update(false);
    }
  }

  @action
  async unitCreate() {
    const { actionStore: api } = this.props;

    let data = [];

    if (this.state.units?.length) {
      const text = '이미 저장된 호기가 있습니다! 삭제하고 다시생성하시겠습니까??';
      if (!await Confirm.show('주의', text, ConfirmType.QUESTION)) {
        return;
      }
    }

    if (this.state.actInfo?.qty === '0') {
      ConfirmWarning.show('경고', '관리대수를 1대 이상 입력해주세요');
      return;
    }

    // @ts-ignore
    let isSuccess: boolean;

    const paramsUnit = {
      ...this.state.actInfo,
      items1: {
        ...this.state.unitFocused,
        // @ts-ignore
        contyn: this.state.unitUpdates[0]?.contyn || '1',
      },
    };

    // 신규시 필수입력 확인
    // eslint-disable-next-line prefer-const
    isSuccess = await api.fxSave(
      'tab_1_save',
      paramsUnit,
      this.state.unitFocused?.new === '1',
    );

    // 필수입력 넣을시 호기생성
    if (!isSuccess) return;
    data = await api.fxExec('wb_e611_create_all',
      {
        actcd: this.state.actInfo?.actcd,
        qty: this.state.actInfo?.qty,
      });
    if (!data) return;

    await this.tableUnit.current?.update(false);
    data && await this.onRetrieveEvent(RetrieveFocusType.DEFAULT);
  }

  @action
  async unitAdd() {
    if ((this.state.units?.filter((x) => x.isNew).length || 0) > 0) {
      ConfirmWarning.show('오류', '한번에 한 호기만 등록이 가능합니다');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec('wb_e611_new',
      {
        actcd: this.state.actInfo?.actcd,
        cltcd: this.state.actInfo?.cltcd,
      });
    const newModel = new EnrollmentUnitModel(data, true);
    data && this.setState({
      units: [...this.state.units || [], newModel],
      unitFocused: newModel,
      actInfo: new EnrollmentActModel({
        ...this.state.actInfo,
        qty: String((this.state.units?.length || 0) + 1),
      }, this.state.focuseddata?.isNew), // 기존 값 this.state.actInfo?.isNew
    }, async () => {
      await this.tableUnit.current?.update(true);
      this.state.units?.length && this.tableUnit.current?.setFocus(this.state.units?.length - 1);
    });
  }

  @action
  async unitDelete() {
    const { actionStore: api } = this.props;

    const text = `코드 : ${this.state.unitFocused?.equpcd} 호기명 : ${this.state.unitFocused?.equpnm}`;
    await api.fxDelete(
      'wb_e611_delete',
      text,
      {
        actcd: this.state.unitFocused?.actcd,
        equpcd: this.state.unitFocused?.equpcd,
      },
    ) && await this.onRetrieveEvent();
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async informationCenterSend() {
    const text = '해당기능은 정보센터 고도화사업으로 인해 중단되었습니다. 정보센터에서 계약내용을 기입해주시기 바랍니다';
    ConfirmWarning.show('경고', text);
  }

  @action
  async contractionResister() {
    const { actionStore: api } = this.props;

    if (!this.state.actInfo?.custcd || this.state.focuseddata?.isNew) {
      ConfirmWarning.show('오류', '계약 등록시 호기등록탭 정보를 먼저 저장 후 이용해주세요');
      return;
    }

    let data = [];

    if (this.state.contractinformationsFocused?.contdate) {
      const text = '이미 계약을 등록했습니다!';
      ConfirmWarning.show('경고', text);
      return;
    }

    data = await api.fxExec('wb_e101_new',
      {
        actcd: this.state.actInfo?.actcd,
        cltcd: this.state.actInfo?.cltcd,
        cltnm: this.state.unitFocused?.cltnm,
        contbild: this.state.actInfo?.contbild,
        evercd: '',
        dw_3cnt: this.state.history.length,
        state: this.state.actInfo?.state,
        enddate: '',
        // @ts-ignore
        perid: this.state.actInfo.perid || '',
        pernm: this.state.actInfo?.pernm,
      });
    data && this.setState({
      contractVisible: true,
      contractinformationsFocusedNew: new EnrollmentContractInformationModel(data, true),
      contractinformationsFocused: new EnrollmentContractInformationModel(data, true),
    });

    if (!this.state.historyData && this.state.actInfo?.state === '2') {
      ConfirmWarning.show('확인', '설치현장에서 넘어온 현장입니다. 무상 계약내역이 자동 셋팅됩니다!');
      const freshData: any[] = [];
      const day = new Date(Date8.withDash(this.state.actInfo?.enddate));
      const month = new Date(day.setMonth(day.getMonth() + 3)); // 월
      const monthDate = this.dateToYYYYMMDD(month);
      freshData.push(({
        perid: this.state.actInfo?.perid,
        pernm: this.state.actInfo?.pernm,
        mgubun: '0',
        taxflag: '0',
        jirogubun: '0',
        contgubun: '03',
        seq: '001',
        contdate: this.state.actInfo?.enddate,
        stdate: this.state.actInfo?.enddate,
        enddate: monthDate,
        deljugi: '01',
        delmon1: '01',
        delmon2: '02',
        delmon3: '03',
        delmon4: '04',
        delmon5: '05',
        delmon6: '06',
        delmon7: '07',
        delmon8: '08',
        delmon9: '09',
        delmon10: '10',
        delmon11: '11',
        delmon12: '12',
      }));
      this.setState({
        contractinformationsFocused:
          new EnrollmentContractInformationModel(freshData, false),
      });
    }
  }

  @action
  async contractionDelete() {
    const { actionStore: api } = this.props;

    const text = `현장명 : ${this.state.actInfo?.actcd} 계약일자: ${this.state.contractinformationsFocused?.contdate}`;
    await api.fxDelete(
      'wb_e101_delete',
      text,
      {
        actcd: this.state.actInfo?.actcd,
        seq: this.state.contractinformationsFocused?.seq,
      },
    ) && await this.onRetrieveEvent();
  }

  @action
  async divisionResister() {
    const { actionStore: api } = this.props;

    let data = [];

    if (this.state.divisionsFocused?.recedate) {
      const text = '이미 분할정보를 등록했습니다!';
      ConfirmWarning.show('경고', text);
      return;
    }

    data = await api.fxExec('wb_e110_new',
      {
        actcd: this.state.actInfo?.actcd,
        cltcd: this.state.actInfo?.cltcd,
        cltnm: this.state.unitFocused?.cltnm,
        contbild: this.state.actInfo?.contbild,
        evercd: '',
        state: this.state.actInfo?.state,
        enddate: '',
        // @ts-ignore
        perid: this.state.actInfo.perid || '',
        pernm: this.state.actInfo?.pernm,
      });
    data && this.setState({
      divisionVisible: true,
      divisionsFocused:
        new EnrollmentDivisionModel(data, false),
    });
  }

  @action
  async divisionDelete() {
    const { actionStore: api } = this.props;

    const text = `현장명 : ${this.state.actInfo?.actcd} 순번: 001`;
    await api.fxDelete(
      'wb_e110_delete',
      text,
      {
        actcd: this.state.actInfo?.actcd,
        seq: '001',
      },
    ) && await this.onRetrieveEvent();
  }

  @action
  async onButtonclick(datas: any, name: any) {
    const { actionStore: api } = this.props;

    let itemData = {};

    const text = '전체 현장의 전자계산서발행 내용을 같은 내용으로 적용하시겠습니까?';
    const text2 = '전체 현장의 지로발행 내용을 같은 내용으로 적용하시겠습니까?';
    const text3 = '전체 현장의 적요를 같은 내용으로 적용하시겠습니까?';

    switch (BtnGubun[name]) {
      case 'b_taxall':
        if (!await Confirm.show('주의', text, ConfirmType.QUESTION)) {
          return;
        }
        itemData = {
          taxflag: this.state.contractinformationsFocused?.taxflag,
          taxchk: this.state.contractinformationsFocused?.taxchk,
          dedate: this.state.contractinformationsFocused?.dedate,
          delchk: this.state.contractinformationsFocused?.delchk,
        };
        break;

      case 'b_jiroall':
        if (!await Confirm.show('주의', text2, ConfirmType.QUESTION)) {
          return;
        }
        itemData = {
          jirogubun: this.state.contractinformationsFocused?.jirogubun,
          ungijun: this.state.contractinformationsFocused?.ungijun,
          jirodate: this.state.contractinformationsFocused?.jirodate,
          jirochk: this.state.contractinformationsFocused?.jirochk,
        };
        break;

      case 'b_remarkall':
        if (!await Confirm.show('주의', text3, ConfirmType.QUESTION)) {
          return;
        }
        itemData = {
          remark: this.state.contractinformationsFocused?.remark,
        };
        break;

      case 'b_pic_ok':
        itemData = {
          perid: this.state.contractinformationsFocused?.perid,
          stdate: this.state.contractinformationsFocused?.stdate,
          enddate: this.state.contractinformationsFocused?.enddate,
          actcd: this.state.contractinformationsFocused?.actcd,
          combine: this.state.contractinformationsFocused?.combine,
        };
        break;

      default:
        break;
    }

    const data = await api.fxExec('dw_1_buttonclicked',
      {
        new: this.state.contractinformationsFocused?.isNew === true ? '1' : '0',
        cltcd: this.state.contractinformationsFocused?.cltcd,
        actcd: this.state.actInfo?.actcd,
        itemname: BtnGubun[name],
        data: datas,
        ...itemData,
      });

    data && this.setState({
      contractinformationsFocusedNew: new EnrollmentContractInformationModel({
        ...this.state.contractinformationsFocusedNew,
        ...data,
      }, this.state.contractinformationsFocusedNew?.isNew),
    });
  }

  @action
  async onButtonclick2(datas: any, name: any) {
    const { actionStore: api } = this.props;

    let itemData = {};
    const text = `현재${this.state.actInfo?.pernm} 담당자님의 현장으로 ${this.state.actInfo?.pernm2}님을 보조점검자로
     일괄셋팅 하시겠습니까?`;
    // 필요없는 소스 같음 (onButtononclick 복사해서 쓴 것 같음)
    // const text2 = '전체 현장의 지로발행 내용을 같은 내용으로 적용하시겠습니까?';
    // const text3 = '전체 현장의 적요를 같은 내용으로 적용하시겠습니까?';

    // 담당자일괄상동
    switch (BtnGubun[name]) {
      case 'b_perid2all':
        if (this.state.actInfo?.perid2 === ''
          || this.state.actInfo?.perid2 === null || this.state.actInfo?.pernm2 === null) {
          ConfirmWarning.show('경고', '보조자가 없습니다!');
          return;
        }
        if (!await Confirm.show('선택', text, ConfirmType.QUESTION)) {
          return;
        }
        itemData = {
          perid: this.state.actInfo?.perid,
          perid2: this.state.actInfo?.perid2,
        };
        break;

        // 필요없는 소스 같음 (onButtononclick 복사해서 쓴 것 같음)
        // case 'b_jiroall':
        //   if (!await Confirm.show('주의', text2, ConfirmType.QUESTION)) {
        //     return;
        //   }
        //   itemData = {
        //     jirogubun: datas,
        //     ungijun: datas,
        //     jirodate: datas,
        //     jirochk: datas,
        //   };
        //   break;
        //
        // case 'b_remarkall':
        //   if (!await Confirm.show('주의', text3, ConfirmType.QUESTION)) {
        //     return;
        //   }
        //   itemData = {
        //     remark: datas,
        //   };
        //   break;
        //
        // case 'b_pic_ok':
        //   itemData = {
        //     perid: datas,
        //     stdate: datas,
        //     enddate: datas,
        //     actcd: datas,
        //     combine: datas,
        //   };
        //   break;

      default:
        break;
    }

    const data = await api.fxExec('dw_2_buttonclicked',
      {
        // new: this.state.actInfo?.isNew === true ? '1' : '0',
        new: this.state.focuseddata?.isNew === true ? '1' : '0',
        cltcd: this.state.actInfo?.cltcd,
        actcd: this.state.actInfo?.actcd,
        itemname: BtnGubun[name],
        data: datas,
        ...itemData,
      });

    data && this.setState({
      actInfo: data,
    }, () => this.onRetrieveEvent());
  }

  @action
  onRowFocusEvent2(item: PersonPopupModel) {
    this.setState({
      PersonPopUpFocused: new PersonPopupModel(item, this.state.PersonPopUpFocused?.isNew),
    });
  }

  @computed
  get tabIndex(): EnrollmentTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  goChkPreset() {
    this.props.publicStore.go('/w_tb_e611_035_03', {
      searchQuery: this.state.actInfo?.actnm,
    });
  }


  // Links
  // 전화번호등록
  async openTelnum() {
    if (this.state.actInfo?.tel === '' || this.state.actInfo?.tel === null) {
      ConfirmWarning.show('오류', '전화번호가 없습니다.');
      return;
    }
    const { publicStore } = this.props;
    publicStore.go('/w_tb_e911', {
      searchQuery: this.state.actInfo?.actnm,
    });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows(rows: any, updatedRows: any) {
    // @ts-ignore
    this.setState({
      [EnrollmentTabUpdatesStateNames[this.tabIndex]]: updatedRows,
      [EnrollmentTabDataStateNames[this.tabIndex]]: rows,
    });
  }

  @action
  onUpdatedRows2(rows2: any, updatedRows2: any) {
    this.updatedRows2 = updatedRows2;
    this.setState({ PersonPopUpData: rows2 });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows3(rows3: any, updatedRows3: any) {
    this.updatedRows3 = updatedRows3;
    this.setState({ historyData: rows3 });
  }

  /**
   * 행 변경 이벤트
   * @param rows        전체 행 (변경 행 반영된 상태)
   * @param updatedRows 변경 행들만
   */
  @action
  onUpdatedRows4(rows4: any, updatedRows4: any) {
    this.updatedRows4 = updatedRows4;
    this.setState({ contractLongData: rows4 });
  }

  @action
  onUpdatedRows5(rows5: any, updatedRows5: any) {
    this.updatedRows5 = updatedRows5;
    this.setState({ changePopUpData: rows5 });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  async onHistoryRowFocusEvent(item: HistoryPopupModel, index: number) {
    this.focusedHistoryRow = index;
    this.setState({ historyFocusedData: item, remark: item.remark });
  }

  @action
  async updateHistoryRemark(remark: string) {
    // 계약내용 item안에 remark값이 아닌 state로 remark값을 따로 만들어줘서 변경해줌.
    await Update.byIndex(this, 'historyData', this.focusedHistoryRow, 'remark', remark);
  }


  // 도면, PLC 팝업
  @action
  async copyDP() {
    if (!this.state.unitFocused?.dpattcnt || parseInt(this.state.unitFocused?.dpattcnt, 10) < 1) {
      ConfirmWarning.show('오류', '선택한 호기가 도면이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    await api.fxExec('wb_dpcopy', {
      actcd: this.state.actInfo?.actcd,
      equpcd: this.state.unitFocused?.equpcd,
    });

    this.onRowFocusEvent(this.state.focuseddata);
  }

  @action
  async copyCCTV() {
    const { actionStore: api } = this.props;
    const result = await Confirm.ask('선택', `현재 선택된 호기 ${this.state.unitFocused?.equpcd}의 셋팅으로 다른 호기들도 전부 셋팅(반영) 하시겠습니까?`, '예', '아니오');

    if (result) {
      return;
    }

    await api.fxExec('wb_cctv', {
      actcd: this.state.actInfo?.actcd,
      cctv: this.state.unitFocused?.cctv,
    });

    this.onRowFocusEvent(this.state.focuseddata);
  }

  @action
  openPLC() {
    ConfirmWarning.show('...', '현재 이 기능은 개발중입니다');
  }

  @action
  async closeDPModal() {
    await this.SS({ isDPModalVisible: false });
    this.onRowFocusEvent(this.state.focuseddata);
  }

  @action
  async closePLCModal() {
    await this.SS({ isPLCModalVisible: false });
    this.onRowFocusEvent(this.state.focuseddata);
  }

  @action
  async historyRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinityHistory = new InfinityRetrieve(
      {
        sub: 'w_tb_e101_history',
        actcd: this.state.focuseddata?.actcd,
      },
      (params) => api.fxExec('retrieve', params),
      (items) => {
        this.setState({
          historyData: [...items],
        });
      },
      async () => {
        await this.SS({ historyData: [] });
        await this.infinityHistory?.retrieveAll();
        await this.tableHistory.current?.update(true);
        this.tableHistory.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      historyData: [],
    }, async () => {
      await this.infinityHistory?.retrieveAll();
      await this.tableHistory.current?.update(true);
      this.tableHistory.current?.setFocus(0, 1);
    });
  }

  @action
  async onNewHistoryEvent() {
    const { actionStore: api } = this.props;

    if (this.state.historyFocusedData?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다.');
      return;
    }

    const data = await api.new({
      sub: 'w_tb_e101_history',
      actcd: this.state.focuseddata?.actcd,
    });

    const newModel = new HistoryPopupModel(data, true);

    if (data) {
      this.setState({
        historyData: [newModel, ...this.state.historyData || []],
        historyFocusedData: newModel,
      }, () => this.tableHistory.current?.update(true));
      // @ts-ignore
      this.tableHistory.current?.setFocus(0, 1);
    }
  }

  @action
  async onHistorySaveEvent() {
    const { actionStore: api } = this.props;
    // const params = [{
    //   new: this.state.historyFocusedData?.new,
    //   actcd: this.state.historyFocusedData?.actcd,
    //   actnm: this.state.historyFocusedData?.actnm,
    //   seq: this.state.historyFocusedData?.seq,
    //   perid: this.state.historyFocusedData?.perid,
    //   pernm: this.state.historyFocusedData?.pernm,
    //   hdate: this.state.historyFocusedData?.hdate,
    //   title: this.state.historyFocusedData?.title,
    //   remark: this.state.historyFocusedData?.remark,
    //   wakeflag: '',
    //   wakedate: this.state.historyFocusedData?.wakedate,
    //   okflag: this.state.historyFocusedData?.okflag,
    // }];

    if (this.state.remark?.trim() === '') {
      ConfirmWarning.show('경고', '계약내용이 없습니다. 계약내용을 입력해주세요');
      return;
    }

    if (!this.state.historyFocusedData) return;
    const data = await api.save({
      sub: 'w_tb_e101_history',
      items: this.state.historyData,
    }, this.state.historyFocusedData.new === '1');
    if (data) {
      await this.historyRetrieve();
    }
  }

  @action
  async historyDelete() {
    const { actionStore: api } = this.props;
    // eslint-disable-next-line radix
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_tb_e101_history',
      actcd: this.state.focuseddata?.actcd,
      seq: this.state.historyFocusedData?.seq,
    }) && await this.historyRetrieve();

    this.updatedRows3 = [];
  }

  // 계약기록
  @action
  async historyPopupModal(isOpen: boolean) {
    setTimeout(() => this.historyRetrieve(), 50);
    this.setState({ historyModal: isOpen });
  }

  // 계약정보 > 청구주기
  @action
  async openRecipeIntervalModal() {
    const { actionStore: api } = this.props;

    if (!this.state.contractinformationsFocused
      || this.state.contractinformationsFocused?.isNew
      || (this.state.contractinformationsFocusedNew && this.state.contractinformationsFocusedNew?.isNew)
    ) {
      ConfirmWarning.show('오류', '먼저 저장 후 사용해주세요');
      return;
    }

    await this.SS({
      isRecipeIntervalVisible: true,
      delmonAll: false,
    });

    const data = await api.retrieve({
      sub: 'w_popup_e101_pub',
      actcd: this.state.actInfo?.actcd,
      seq: this.state.contractinformationsFocused?.seq,
      deljugi: this.state.contractinformationsFocused?.deljugi,
    });

    await this.SS({
      delmon1: data?.delmon1 === '01' || false,
      delmon2: data?.delmon2 === '02' || false,
      delmon3: data?.delmon3 === '03' || false,
      delmon4: data?.delmon4 === '04' || false,
      delmon5: data?.delmon5 === '05' || false,
      delmon6: data?.delmon6 === '06' || false,
      delmon7: data?.delmon7 === '07' || false,
      delmon8: data?.delmon8 === '08' || false,
      delmon9: data?.delmon9 === '09' || false,
      delmon10: data?.delmon10 === '10' || false,
      delmon11: data?.delmon11 === '11' || false,
      delmon12: data?.delmon12 === '12' || false,
    });

    await this.SS({
      delmonAll: this.state.delmon1 && this.state.delmon2 && this.state.delmon3 && this.state.delmon4 && this.state.delmon5
        && this.state.delmon6 && this.state.delmon7 && this.state.delmon8 && this.state.delmon9 && this.state.delmon10
        && this.state.delmon11 && this.state.delmon12,
    });
  }

  @action
  async openInspectDetailModal(item: RoutineModel) {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        sub: 'w_popup_e073_fail',
        ...item,
      },
    );

    if (data?.items) {
      this.setState({
        isInspectDetailModalVisible: true,
        inspectDetailData: data?.items,
        inspectDetailItem: item,
      });
    }
  }

  @action
  async closeRecipeIntervalModal() {
    await this.SS({ isRecipeIntervalVisible: false });
  }

  // 계약정보 청구주기
  @action
  async saveRecipeIntervalModal() {
    const { actionStore: api } = this.props;

    if (await api.save({
      sub: 'w_popup_e101_pub',
      actcd: this.state.actInfo?.actcd,
      seq: this.state.contractinformationsFocused?.seq,
      deljugi: this.state.contractinformationsFocused?.deljugi,
      delmon1: this.state.delmon1 ? '01' : '00',
      delmon2: this.state.delmon2 ? '02' : '00',
      delmon3: this.state.delmon3 ? '03' : '00',
      delmon4: this.state.delmon4 ? '04' : '00',
      delmon5: this.state.delmon5 ? '05' : '00',
      delmon6: this.state.delmon6 ? '06' : '00',
      delmon7: this.state.delmon7 ? '07' : '00',
      delmon8: this.state.delmon8 ? '08' : '00',
      delmon9: this.state.delmon9 ? '09' : '00',
      delmon10: this.state.delmon10 ? '10' : '00',
      delmon11: this.state.delmon11 ? '11' : '00',
      delmon12: this.state.delmon12 ? '12' : '00',
    }, false)) {
      this.onRowFocusEvent(this.state.focuseddata);
    }

    await this.SS({
      isRecipeIntervalVisible: false,
    });
  }

  @action
  async onToggleRecipeDelmon(value: boolean) {
    this.SS({
      delmonAll: value,
      delmon1: value,
      delmon2: value,
      delmon3: value,
      delmon4: value,
      delmon5: value,
      delmon6: value,
      delmon7: value,
      delmon8: value,
      delmon9: value,
      delmon10: value,
      delmon11: value,
      delmon12: value,
    });
  }

  @action
  async openDivideIntervalModal() {
    await this.SS({
      isDivideIntervalVisible: true,
      divideDelmonAll: this.state.divisionsFocused?.delflag === '1',
      divideDelmon1: this.state.divisionsFocused?.delmon1 !== '00',
      divideDelmon2: this.state.divisionsFocused?.delmon2 !== '00',
      divideDelmon3: this.state.divisionsFocused?.delmon3 !== '00',
      divideDelmon4: this.state.divisionsFocused?.delmon4 !== '00',
      divideDelmon5: this.state.divisionsFocused?.delmon5 !== '00',
      divideDelmon6: this.state.divisionsFocused?.delmon6 !== '00',
      divideDelmon7: this.state.divisionsFocused?.delmon7 !== '00',
      divideDelmon8: this.state.divisionsFocused?.delmon8 !== '00',
      divideDelmon9: this.state.divisionsFocused?.delmon9 !== '00',
      divideDelmon10: this.state.divisionsFocused?.delmon10 !== '00',
      divideDelmon11: this.state.divisionsFocused?.delmon11 !== '00',
      divideDelmon12: this.state.divisionsFocused?.delmon12 !== '00',
    });
  }

  @action
  async onToggleDivideDelmon(value: boolean) {
    await this.SS({
      divideDelmonAll: value,
      divideDelmon1: value,
      divideDelmon2: value,
      divideDelmon3: value,
      divideDelmon4: value,
      divideDelmon5: value,
      divideDelmon6: value,
      divideDelmon7: value,
      divideDelmon8: value,
      divideDelmon9: value,
      divideDelmon10: value,
      divideDelmon11: value,
      divideDelmon12: value,
    });
  }

  @action
  async saveDivideIntervalModal() {
    await this.SS({
      isDivideIntervalVisible: false,
      divisionsFocused: new EnrollmentDivisionModel({
        ...this.state.divisionsFocused,
        delflag: this.state.divideDelmonAll,
        delmon01: this.state.divideDelmon1 ? '01' : '00',
        delmon02: this.state.divideDelmon2 ? '02' : '00',
        delmon03: this.state.divideDelmon3 ? '03' : '00',
        delmon04: this.state.divideDelmon4 ? '04' : '00',
        delmon05: this.state.divideDelmon5 ? '05' : '00',
        delmon06: this.state.divideDelmon6 ? '06' : '00',
        delmon07: this.state.divideDelmon7 ? '07' : '00',
        delmon08: this.state.divideDelmon8 ? '08' : '00',
        delmon09: this.state.divideDelmon9 ? '09' : '00',
        delmon10: this.state.divideDelmon10 ? '10' : '00',
        delmon11: this.state.divideDelmon11 ? '11' : '00',
        delmon12: this.state.divideDelmon12 ? '12' : '00',
      }),
    });
  }

  @action
  async closeDivideIntervalModal() {
    await this.SS({
      isDivideIntervalVisible: false,
      divisionsFocused: new EnrollmentDivisionModel({
        ...this.state.divisionsFocused,
        delflag: this.state.divideDelmonAll,
        delmon01: this.state.divideDelmon1 ? '01' : '00',
        delmon02: this.state.divideDelmon2 ? '02' : '00',
        delmon03: this.state.divideDelmon3 ? '03' : '00',
        delmon04: this.state.divideDelmon4 ? '04' : '00',
        delmon05: this.state.divideDelmon5 ? '05' : '00',
        delmon06: this.state.divideDelmon6 ? '06' : '00',
        delmon07: this.state.divideDelmon7 ? '07' : '00',
        delmon08: this.state.divideDelmon8 ? '08' : '00',
        delmon09: this.state.divideDelmon9 ? '09' : '00',
        delmon10: this.state.divideDelmon10 ? '10' : '00',
        delmon11: this.state.divideDelmon11 ? '11' : '00',
        delmon12: this.state.divideDelmon12 ? '12' : '00',
      }),
    });
  }

  @action
  async dw_6_itemChanged(value: any, name: any) {
    const { actionStore: api, publicStore } = this.props;

    let data: any = {};

    if (name === 'remflag') {
      data = await api.fxExec('dw_6_itemchanged', {
        itemname: name,
        data: value ? '1' : '0',
        actcd: this.state.actInfo?.actcd,
        equpcd: this.state.unitFocused?.equpcd,
      });
    } else if (name === 'kcflag') {
      data = await api.fxExec('dw_6_itemchanged', {
        itemname: name,
        data: value ? '1' : '0',
        actcd: this.state.actInfo?.actcd,
        equpcd: this.state.unitFocused?.equpcd,
      });
    } else if (name === 'evcd_new') {
      if (this.state.unitFocused?.elno.trim() === '' || this.state.unitFocused?.elno === undefined) {
        ConfirmWarning.show('경고', '승강기 번호를 입력해주세요');
        return;
      }

      const beplanmonRawJson = await publicStore.runManagerScript(
        '009',
        `${this.state.unitFocused?.elno},${new Date6Calculator().add(-1).toString()}`,
      );
      data = await api.fxExec('dw_6_itemchanged', {
        itemname: name,
        data: value ? '1' : '0',
        actcd: this.state.actInfo?.actcd,
        equpcd: this.state.unitFocused?.equpcd,
        elno: this.state.unitFocused?.elno,
        items5: JSON.stringify(beplanmonRawJson.response),
      });
    } else if (name === 'equpnm') {
      // 호기명 - 하이픈표시 있을 때 백엔드쪽에 dong과 ho를 보내줌
      // dong: - 하이픈표시 앞에 있는 문자열
      // ho: - 하이픈표시 뒤에 있는 문자열
      // 하이픈표시가 2개이상 있으면 dong: 첫번째 하이픈 앞에 문자열, ho: 두번째 하이픈 앞에 문자열부터 마지막까지
      // ex) 1-1 -> dong: 1, ho: 1
      // ex) 1-1동-1 -> dong:1, ho: 동-1
      let dong = '';
      let ho = '';
      const hyphenCount = this.state.unitFocused?.equpnm.match(/-/g)?.length;

      if (this.state.unitFocused?.equpnm.includes('-')) {
        if (hyphenCount === 1) {
          const hyphenIndex = this.state.unitFocused?.equpnm.indexOf('-');
          dong = this.state.unitFocused?.equpnm.substring(0, hyphenIndex);
          ho = this.state.unitFocused?.equpnm.substring(hyphenIndex + 1);
        }

        // @ts-ignore
        if (hyphenCount > 1) {
          const hyphenIndex = this.state.unitFocused?.equpnm.indexOf('-');
          const secondHyphenIndex = this.state.unitFocused?.equpnm.substr(hyphenIndex + 1).indexOf('-');
          dong = this.state.unitFocused?.equpnm.substring(0, hyphenIndex);
          ho = this.state.unitFocused?.equpnm.substring(secondHyphenIndex + 1);
        }

        data = await api.fxExec('dw_6_itemchanged', {
          itemname: name,
          data: value,
          actcd: this.state.actInfo?.actcd,
          equpcd: this.state.unitFocused?.equpcd,
          dong,
          ho,
        });
      } else {
        data = await api.fxExec('dw_6_itemchanged', {
          itemname: name,
          data: value,
          actcd: this.state.actInfo?.actcd,
          equpcd: this.state.unitFocused?.equpcd,
        });
      }
    } else if (name === 'plc') {
      data = await api.fxExec('dw_6_itemchanged', {
        itemname: name,
        data: value ? '1' : '0',
        actcd: this.state.actInfo?.actcd,
        equpcd: this.state.unitFocused?.equpcd,
      });
    } else if (name === 'class2') {
      data = await api.fxExec('dw_6_itemchanged', {
        itemname: name,
        data: value,
        actcd: this.state.actInfo?.actcd,
        equpcd: this.state.unitFocused?.equpcd,
        inver: this.state.unitFocused?.inver,
        speed: this.state.unitFocused?.speed,
        number: this.state.unitFocused?.number,
        nperson: this.state.unitFocused?.nperson,
      });
    } else if (name === 'incentive') {
      data = await api.fxExec('dw_6_itemchanged', {
        itemname: name,
        data: value,
        actcd: this.state.actInfo?.actcd,
        equpcd: this.state.unitFocused?.equpcd,
      });
    } else if (name === 'nperson' || name === 'number' || name === 'inver' || name === 'speed') {
      data = await api.fxExec('dw_6_itemchanged', {
        itemname: name,
        data: value,
        actcd: this.state.actInfo?.actcd,
        equpcd: this.state.unitFocused?.equpcd,
        inver: this.state.unitFocused?.inver,
        spped: this.state.unitFocused?.speed,
        number: this.state.unitFocused?.number,
        nperson: this.state.unitFocused?.nperson,
        class2: this.state.unitFocused?.class2,
      });
    }

    data && this.setState({
      unitFocused: new EnrollmentUnitModel({
        ...this.state.unitFocused,
        ...data,
      }, this.state.unitFocused?.isNew),
    });
  }

  changedElno() {
    this.isChangedElno = true;
  }

  async openChangeUnit() {
    await this.SS({
      changeUnitModal: true,
      currentUnitData: [],
      changeUnitData: [],
    });
    await this.onRetrieveChangeUnit();
  }

  closeChangeUnit() {
    this.SS({
      changeUnitModal: false,
      currentUnitData: [],
      changeUnitData: [],
    });
  }

  async onRetrieveChangeUnit() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve', {
      sub: 'w_popup_e611_codechange',
      actcd: this.state.focuseddata.actcd,
    });

    // currentUnitData: 변경전 데이터
    // changeUnitData: 변경후 데이터
    await this.SS({
      currentUnitData: data?.items,
      changeUnitData: data?.items,
    });

    this.tableChangeUnit.current?.update(true);
  }

  async onSaveChangeUnit() {
    const { actionStore: api } = this.props;

    const result = await Confirm.ask('확인', '코드변경은 진행도중에 절대 강제종료하시면 안됩니다. 완료될때까지 기달려주세요 진행하시겠습니까?', '예', '아니오');

    const changeItems = this.state.changeUnitData.map((x) => ({
      equpcd: x.equpcd,
      equpcd_old: x.equpcd_old,
      addrow: x.equpcd === x.equpcd_old ? '0' : '1',
    }));

    // const { waitQueueStore, modalStore } = this.props;
    //
    // waitQueueStore.append('호기코드변경', 'LAZYTASK-w_popup_e611_codechange', async (response) => {
    // }, () => this.onSaveChangeUnit);
    // modalStore?.openWaitQueue();

    if (!result) {
      await api.save({
        sub: 'w_popup_e611_codechange',
        actcd: this.state.focuseddata.actcd,
        items: changeItems,
      }, false) && await this.onRetrieveEvent();
    }
  }

  /* EXCEL 호기일괄등록 */
  async unitOnExcelEvent() {
    // 호기가 있을 시 덮어씌운다는 팝업창
    if (this.state.units!.length > 0) {
      const warning = await Confirm.ask('경고', '이미 저장된호기는 삭제되고 엑셀에 있는 정보로 덮어쓰기 됩니다\n\n 진행하시겠습니까?', '예', '아니오');
      if (warning) return;
    }
    const result = await Confirm.ask('선택', '승강기정보센터에 있는 호기내용[EXCEL]으로\n 일괄등록 합니다.\n *정보센터파일위치: 업체관리 -> 유지관리현장 -> 엑셀로보내기\n\n 정보센터에서 받은 해당 파일[EXCEL]을 선택해주세요\n 진행하시겠습니까?', '예', '아니오');

    if (!result) {
      try {
        const files = await FileSelector.single(false);
        await this.unitExcelUpload(files[0].name, files[0]);
      } catch {
        ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
      }
    }
  }

  async unitExcelUpload(filename: string, file: Blob) {
    const {
      actionStore: api, waitQueueStore, modalStore, publicStore,
    } = this.props;

    const tempInfo = await api.tempUpload(file, filename);

    waitQueueStore.append('호기일괄등록', 'LAZYTASK-wb_excel', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
      await this.onRetrieveEvent();
    }, () => this.unitExcelUpload(filename, file));
    modalStore?.openWaitQueue();

    if (await api.exec(
      Category.GENERAL,
      'wb_e611_excel',
      {
        actcd: this.state.actInfo!.actcd,
        cltcd: this.state.actInfo!.cltcd,
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    )) {
      await this.onRetrieveEvent();
    }
  }

  render() {
    return (
      <EnrollmentTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
        dataUpdate={(change, callback) => {
          this.setState({
            actInfo: {
              ...this.state.actInfo,
              ...change,
            },
            inchargesFocused: {
              // @ts-ignore
              ...this.state[[EnrollmentTabDataStateNames[this.tabIndex]]],
              ...change,
            },
          }, () => callback && callback());
        }}
      />
    );
  }
}
