export default function ToFit(
  cb: Function,
  { dismissCondition = () => false, triggerCondition = () => true } = {},
) {
  if (!cb) {
    throw Error('Invalid required arguments');
  }

  let tick = false;

  return () => {
    if (tick) {
      return undefined;
    }

    tick = true;
    return requestAnimationFrame(() => {
      if (dismissCondition()) {
        tick = false;
        return undefined;
      }

      if (triggerCondition()) {
        tick = false;
        return cb();
      }

      return undefined;
    });
  };
}
