// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../../../assets/images/card-excel.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BuyRegister_modalCardImage__2fKK9 {\n  width: 100%;\n  height: 100%;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n  background-size: contain; }\n", "",{"version":3,"sources":["webpack://src/app/pages/account/withdraw/BuyRegister/BuyRegister.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,2EAAoF;EACpF,wBAAwB,EAAA","sourcesContent":[".modalCardImage {\n  width: 100%;\n  height: 100%;\n  background: url(../../../../../assets/images/card-excel.png) no-repeat center center;\n  background-size: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalCardImage": "BuyRegister_modalCardImage__2fKK9"
};
export default ___CSS_LOADER_EXPORT___;
