import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import {
  Button,
  CheckBox,
  DateTextBox,
  FlexLayout,
  GridLayout, LayoutTitle,
  ModalView,
  SearchLayout, SubHeader,
  TableLayout,
  TabView,
  TextBox,
} from '../../../../components';
import {
  LongReceivable,
  LongReceivableTabDataStateNames,
} from './LongReceivable';
import { Global, TemplateProps } from '../../../../constants';
import { Date8 } from '../../../../utils/time';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';

const recivstatuss = [
  { value: 'A ', remark: 'KTNET접수' },
  { value: 'B ', remark: '발송전' },
  { value: 'D ', remark: '거부(화주)' },
  { value: 'E ', remark: 'KTNET오류' },
  { value: 'F ', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L ', remark: '승인(화주)' },
  { value: 'N ', remark: '이메일전송실패' },
  { value: 'R ', remark: '열람' },
  { value: 'T ', remark: '국세청1차접수' },
  { value: 'X ', remark: '국세청오류' },
];

/**
 * 화면
 * @window w_input_da023w_03_view
 * @category 장기미수현황
 */
export const LongReceivableTemplate: React.FC<TemplateProps<LongReceivable>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout
            size={200}
            style={{ marginLeft: 25 }}
          >
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ stdate: value })}
              onEnter={() => scope.onDataChange()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onDataChange());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onDataChange());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ enddate: value })}
              onEnter={() => scope.onDataChange()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onDataChange());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onDataChange());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={500}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder="거래처, 현장으로 검색하세요"
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onDataChange()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={382}>
            <span>미수기간(월)</span>
            <TextBox
              textAlign='center'
              value={scope.state.month3}
              isDisabledTrackingStateChange={true}
              onChange={(value) => {
                scope.setState({ month3: value });
              }}
              onEnter={() => scope.onDataChange()}
            />
          </FlexLayout>

          <FlexLayout
            size={250}
            style={{ marginLeft: 22 }}
          >
            <span style={{ color: 'var(--color-red)' }}>담당자</span>
            <TextBox
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => setData({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onDataChange();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.pernm}
              readonly={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              value={scope.state.divicd}
              textAlign="center"
              onChange={(value) => setData({ divicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onDataChange();
                },
              )}
            />
            <TextBox
              weight={1.5}
              value={scope.state.divinm}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.table}
            header={[
              {
                id: 'cnam',
                text: '매출구분',
                width: 80,

              },
              {
                id: 'chk',
                text: (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={scope.state.pushTotalCheck}
                      onChange={async (value) => scope
                        .updateCheckAllToggle(value)}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
                width: 20,
                render: (x, rowUpdate) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    <CheckBox
                      noMargin
                      value={x.chk === '1'}
                      onChange={(value) => {
                        rowUpdate({ chk: value ? '1' : '0' });
                      }}
                      isDisabledTrackingStateChange={true}
                    />
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.openChk}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.tableInfinity}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
          />
        </FlexLayout>

        <FlexLayout
          isVertical={true}
          weight={5}
        >
          <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />
          </FlexLayout>
          <FlexLayout weight={1.5}>
            <GridLayout
              ref={scope.grid}
              scope={scope}
              header={scope.tabHeaders[scope.tabIndex]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              infinityHandler={scope.infinity}
              onRowClick={(item) => scope.onRowFocusEvent(item)}
              onRowDoubleClick={() => (scope.tabIndex === 0 ? scope.textModal(true) : {})}
              // @ts-ignore
              data={scope.state[LongReceivableTabDataStateNames[scope.tabIndex]] || [] }
            />
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        isVisible={scope.state.textDetailModal}
        onClose={() => scope.textModal(false)}
        width={1200}
        zIndex={-2}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout
            weight={14}
            isVertical={true}
          >
            <LayoutTitle>상세현황</LayoutTitle>
            <GridLayout
              ref={scope.taxDetailGrid}
              onRowDoubleClick={() => scope.salesDetailModalOpen(true)}
              onRowClick={(item) => scope.taxDetailModalRowFocused(item)}
              header={[
                {
                  id: 'actnm',
                  text: scope.state.focused?.actcd === '' ? '거래처명' : '현장명',
                  width: 15,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      단기미수:{scope.state.shotmis_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {scope.state.focused?.actcd === '' ? x.cltnm : x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misdate',
                  text: '발생일자',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.misdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misnum',
                  text: '번호',
                  width: 6,
                },
                {
                  id: 'remark',
                  text: '비고',
                  width: 15,
                  trail: () => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      장기미수:{scope.state.longmis3_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recivstatus',
                  text: '전자세금계산서',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      합 계
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '청구금액',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.mijamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'iamt',
                  text: '입금액',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.iamt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.iamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'resuamt',
                  text: '미수잔액',
                  width: 10,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.resuamt_tot2}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.resuamt)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'longmisnm',
                  text: '장기미수구분',
                  width: 14,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        color: x.longmisnm === '단기미수 1개월'
                          ? '' : x.longmisnm === '단기미수 2개월'
                            ? '' : 'var(--color-red)',
                      }}
                    >
                      {x.longmisnm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupData}
              infinityHandler={scope.infinity2}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.modalPrint()}>
                출력
              </Button>
              <Button onClick={() => scope.textModal(false)}>
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.salesDetailModal}
        onClose={() => scope.SS({ salesDetailModal: false, salesDetailModalData: [] })}
        width={1000}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>매출상세</LayoutTitle>

          <FlexLayout>
            <GridLayout
              ref={scope.saleDetatilGrid}
              header={[
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 260,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: ' misdate',
                  text: '발생일자',
                  width: 100,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Date8.withDash(x.misdate)}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misnum',
                  text: '번호',
                  width: 80,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.misnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'seq',
                  text: '순번',
                  width: 60,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.seq}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'remark',
                  text: '매출상세',
                  width: 260,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'recivstatus',
                  text: '전자세금계산서',
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{
                        // eslint-disable-next-line no-nested-ternary
                        color: x.recivstatus.trim() === 'F'
                          // eslint-disable-next-line no-nested-ternary
                          ? 'var(--color-blue)' : x.recivstatus === 'FF'
                            ? '#7A297B' : x.recivstatus === 'B'
                              ? 'var(--color-red)' : '',
                      }}
                    >
                      {recivstatuss.filter((y) => y.value === x.recivstatus.trim())[0]?.remark}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'misamt',
                  text: '청구금액',
                  render: (x) => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.misamt)}
                    </FlexLayout>
                  ),
                },
              ]}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              data={scope.state.salesDetailModalData}
            >
            </GridLayout>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.SS({ salesDetailModal: false, salesDetailModalData: [] })}>
                확인
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
