// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GridLayoutAdditionalHeader_container__LRhpG {\n  position: relative;\n  display: flex;\n  width: 100%;\n  overflow: hidden;\n  border-radius: var(--radius-default); }\n  .GridLayoutAdditionalHeader_container__LRhpG > div {\n    position: absolute;\n    display: block;\n    width: 100%; }\n    .GridLayoutAdditionalHeader_container__LRhpG > div > div > div {\n      padding: 0;\n      margin: 0;\n      box-sizing: border-box;\n      background-color: var(--color-primary);\n      color: var(--color-secondary); }\n      .GridLayoutAdditionalHeader_container__LRhpG > div > div > div:not(:first-child) {\n        margin-left: 2px; }\n", "",{"version":3,"sources":["webpack://src/app/components/layout/GridLayoutAdditionalHeader/GridLayoutAdditionalHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,oCAAoC,EAAA;EALtC;IAQI,kBAAkB;IAClB,cAAc;IACd,WAAW,EAAA;IAVf;MAaM,UAAU;MACV,SAAS;MACT,sBAAsB;MACtB,sCAAsC;MACtC,6BAA6B,EAAA;MAjBnC;QAoBQ,gBAAgB,EAAA","sourcesContent":[".container {\n  position: relative;\n  display: flex;\n  width: 100%;\n  overflow: hidden;\n  border-radius: var(--radius-default);\n\n  & > div {\n    position: absolute;\n    display: block;\n    width: 100%;\n\n    & > div > div {\n      padding: 0;\n      margin: 0;\n      box-sizing: border-box;\n      background-color: var(--color-primary);\n      color: var(--color-secondary);\n\n      &:not(:first-child) {\n        margin-left: 2px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GridLayoutAdditionalHeader_container__LRhpG"
};
export default ___CSS_LOADER_EXPORT___;
