/**
 * 모델
 * @window w_s004
 * @category 계좌번호등록 연동하기 팝업
 */
export class AccountModalModel {
  /*
  *
  *
  * */
  acccd: string;

  /*
  *
  *
  * */
  accname: string;

  /*
  *
  *
  * */
  accnum: string;

  /*
  *
  *
  * */
  alert: string;

  /*
  *
  *
  * */
  bank: string;

  /*
  *
  *
  * */
  bankcd: string;

  /*
  *
  *
  * */
  bankcd5: string;

  /*
  *
  *
  * */
  bankda_cd: string;

  /*
  *
  *
  * */
  bankda_date: string;

  /*
  *
  *
  * */
  bankda_message: string;

  /*
  *
  *
  * */
  bankflag: string;

  /*
  *
  *
  * */
  banknm: string;

  /*
  *
  *
  * */
  basic: string;

  /*
  *
  *
  * */
  clsdate: string;

  /*
  *
  *
  * */
  clsflag: string;

  /*
  *
  *
  * */
  contdate: string;

  /*
  *
  *
  * */
  custcd: string;

  /*
  *
  *
  * */
  expedate: string;

  /*
  *
  *
  * */
  intrate: string;

  /*
  *
  *
  * */
  lastdate: string;

  /*
  *
  *
  * */
  lasttime: string;

  /*
  *
  *
  * */
  mijamt: string;

  /*
  *
  *
  * */
  saupnum: string;

  /*
  *
  *
  * */
  signchk: string;

  /*
  *
  *
  * */
  signflag: string;

  /*
  *
  *
  * */
  signkey: string;

  /*
  *
  *
  * */
  signkey2: string;

  /*
  *
  *
  * */
  signpass: string;

  /*
  *
  *
  * */
  signpass2: string;

  /*
  *
  *
  * */
  spjangcd: string;

  /*
  *
  *
  * */
  spjangnm: string;

  /*
  *
  *
  * */
  sub: string;

  /*
  *
  *
  * */
  useyn: string;

  constructor(data: any = {}) {
    this.acccd = data.acccd || '';
    this.accname = data.accname || '';
    this.accnum = data.accnum || '';
    this.alert = data.alert || '';
    this.bank = data.bank || '';
    this.bankcd = data.bankcd || '';
    this.bankcd5 = data.bankcd5 || '';
    this.bankda_cd = data.bankda_cd || '';
    this.bankda_date = data.bankda_date || '';
    this.bankda_message = data.bankda_message || '';
    this.bankflag = data.bankflag || '';
    this.banknm = data.banknm || '';
    this.basic = data.basic || '';
    this.clsdate = data.clsdate || '';
    this.clsflag = data.clsflag || '';
    this.contdate = data.contdate || '';
    this.custcd = data.custcd || '';
    this.expedate = data.expedate || '';
    this.intrate = data.intrate || '';
    this.lastdate = data.lastdate || '';
    this.lasttime = data.lasttime || '';
    this.mijamt = data.mijamt || '';
    this.saupnum = data.saupnum || '';
    this.signchk = data.signchk || '';
    this.signflag = data.signflag || '';
    this.signkey = data.signkey || '';
    this.signkey2 = data.signkey2 || '';
    this.signpass = data.signpass || '';
    this.signpass2 = data.signpass2 || '';
    this.spjangcd = data.spjangcd || '';
    this.spjangnm = data.spjangnm || '';
    this.sub = data.sub || '';
    this.useyn = data.useyn || '';
  }
}
