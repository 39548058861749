// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./h0GsssGg9FxgDgCjLeAd7hjWx-6tPUUvkdU.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./h0GsssGg9FxgDgCjLeAd7hjYx-6tPUUv.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* latin-ext */\n@font-face {\n    font-family: 'Oxygen Mono';\n    font-style: normal;\n    font-weight: 400;\n    src: local('Oxygen Mono'), local('OxygenMono-Regular'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2');\n  unicode-range: U+30-39;\n  }\n  /* latin */\n  @font-face {\n    font-family: 'Oxygen Mono';\n    font-style: normal;\n    font-weight: 400;\n    src: local('Oxygen Mono'), local('OxygenMono-Regular'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n    unicode-range: U+30-39;\n  }", "",{"version":3,"sources":["webpack://src/app/containers/fonts/oxygenmono/font.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;IAChB,+GAAwH;EAC1H,sBAAsB;EACtB;EACA,UAAU;EACV;IACE,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;IAChB,+GAAqH;IACrH,sBAAsB;EACxB","sourcesContent":["/* latin-ext */\n@font-face {\n    font-family: 'Oxygen Mono';\n    font-style: normal;\n    font-weight: 400;\n    src: local('Oxygen Mono'), local('OxygenMono-Regular'), url(./h0GsssGg9FxgDgCjLeAd7hjWx-6tPUUvkdU.woff2) format('woff2');\n  unicode-range: U+30-39;\n  }\n  /* latin */\n  @font-face {\n    font-family: 'Oxygen Mono';\n    font-style: normal;\n    font-weight: 400;\n    src: local('Oxygen Mono'), local('OxygenMono-Regular'), url(./h0GsssGg9FxgDgCjLeAd7hjYx-6tPUUv.woff2) format('woff2');\n    unicode-range: U+30-39;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
