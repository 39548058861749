import * as React from 'react';
import { action } from 'mobx';
import { PageProps, PageToolEvents } from '../../../../constants';
import { OldSpaceTemplate } from './OldSpace.template';
import { OldSpaceModel } from './OldSpace.model';
import { InfinityRetrieve } from '../../../../models';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';
import { OldSpaceRemarkModalModel } from './OldSpaceRemarkModal.model';
import { TableLayout } from '../../../../components';

interface OldSpaceState {
  gigan: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  gubun: string;
  contg: string;
  ordflag: string;
  contgubun: string;
  searchQuery: string;
  clttype: string;
  data: Array<OldSpaceModel>;
  focusedData?: OldSpaceModel;
  contgs: Array<any>;
  contgubuns: Array<any>;

  // trail
  total: string;
  ordcnt_tot: string;

  // 진행현황 팝업창
  remarkModal: boolean;
  remarkModalData: Array<OldSpaceRemarkModalModel>;
  remarkModalFocusedData: OldSpaceRemarkModalModel;
}

/**
 * 컨트롤러
 * @window w_tb_e601w_02
 * @category 설치된지오래된현장
 */
export class OldSpace extends PageComponent<PageProps, OldSpaceState>
  implements PageToolEvents {
  updatedRows?: Array<OldSpaceRemarkModalModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  remarkModalTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // const { user } = this.props.publicStore;
    // let bosuPerid = '';
    // let bosuPernm = '';
    // let bosuDivicd = '';
    // let bosuDivinm = '';
    //
    // if (user.kukcd !== '01') {
    //   bosuPerid = user.perid;
    //   bosuPernm = user.pernm;
    //   bosuDivicd = user.divicd;
    //   bosuDivinm = user.divinm;
    // }

    // state 기본값 정의
    this.state = props.state || {
      gigan: '15',
      searchQuery: '',
      gubun: '%',
      contg: '%',
      contgubun: '%',
      ordflag: '%',
      divicd: '',
      divinm: '',
      perid: '',
      pernm: '',
      clttype: '%',
      data: [],
      total: '0',
      ordcnt_tot: '0',

      // 진행현황 팝업창
      remarkModal: false,
      remarkModalData: [],
      remarkModalFocusedData: new OldSpaceRemarkModalModel(),
    };
  }

  /**
   * 화면이 새로 열린 경우 자동 조회
   * @brief 기준 데이터를 순차적으로 조회하는 경우에 대한 예시 코드
   */
  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    let data = [];

    // 리스트 (계약분류)
    data = await api.dropdown('wf_dd_ca510_001_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgs: data.items });

    // 계약 구분 리스트
    data = await api.dropdown('wf_dd_ca510_008_02');

    if (!data) return;

    // 성공시 상태 반영
    this.setState({ contgubuns: data.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        gigan: this.state.gigan,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
        contgubun: this.state.contgubun,
        ordflag: this.state.ordflag,
        divicd: this.state.divicd,
        perid: this.state.perid,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          data: [...this.state.data, ...items.map((x: any) => new OldSpaceModel(x))],
        });
      },
      async () => {
        await this.SS({ data: [] });
        await this.infinity?.retrieveAll();
        this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
    }, async () => {
      await this.infinity?.retrieveAll();
      const data = this.infinity?.box;
      this.setState({
        total: data?.total || '0',
      });
      if (this.state.data.length) {
        this.grid.current?.setFocus(0);
      }
    });
  }

  @action
  async onRemarkModal(isOpen: boolean) {
    await this.SS({
      remarkModal: isOpen,
    });

    await this.onRemarkModalRetrieve();
  }

  @action
  async onCloseRemarkModal() {
    await this.SS({
      remarkModal: false,
      remarkModalData: [],
    });
  }

  @action
  async onRemarkModalRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      remarkModalData: [],
    });

    const data = await api.fxExec('retrieve', {
      sub: 'w_tb_e601_remodering',
      actcd: this.state.focusedData?.actcd,
    });

    if (data) {
      await this.SS({
        remarkModalData: data?.items,
      });
    }

    await this.remarkModalTable.current?.update(true);
    this.remarkModalTable.current?.setFocus(0);
  }

  @action
  async remarkModalFocused(item: OldSpaceRemarkModalModel) {
    await this.SS({
      remarkModalFocusedData: new OldSpaceRemarkModalModel(item, item?.isNew),
    });
  }

  @action
  async onRemarkModalNewEvent() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('new', {
      sub: 'w_tb_e601_remodering',
      actcd: this.state.focusedData?.actcd,
      seq: this.state.remarkModalFocusedData?.seq,
    });

    if (data) {
      await this.SS({
        remarkModalData: [...this.state.remarkModalData, new OldSpaceRemarkModalModel(data, true)],
      });

      await this.remarkModalTable.current?.update(true);
      this.remarkModalTable.current?.setFocus(this.state.remarkModalData.length - 1);
    }
  }

  async onRemarkModalSaveEvent() {
    const { actionStore: api } = this.props;

    const changeItems: any = [];

    this.state.remarkModalData.forEach((x) => {
      changeItems.push({
        new: x.new,
        actcd: x.actcd,
        seq: x.seq,
        perid: x.perid,
        hdate: x.hdate,
        remark: x.remark,
      });
    });

    await api.fxExec('save', {
      sub: 'w_tb_e601_remodering',
      actcd: this.state.focusedData?.actcd,
      items: changeItems,
    });
    ConfirmWarning.show('확인', '저장되었습니다.');

    await this.onRemarkModalRetrieve();
  }

  async onRemarkModalDeleteEvente() {
    const { actionStore: api } = this.props;

    const text = `순서: ${this.state.remarkModalFocusedData?.seq}`;

    await api.fxDelete(
      'delete', text,
      {
        sub: 'w_tb_e601_remodering',
        actcd: this.state.focusedData?.actcd,
        seq: this.state.remarkModalFocusedData?.seq,
      },
    );

    ConfirmWarning.show('확인', '삭제되었습니다.');

    await this.onRemarkModalRetrieve();
  }

  @action
  async onUpdatedRows(rows: Array<OldSpaceRemarkModalModel>, updatedRows: Array<OldSpaceRemarkModalModel>) {
    this.updatedRows = updatedRows;
    await this.SS({
      remarkModalData: rows,
    });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }
    await api.printWithElmanManager({
      gigan: this.state.gigan,
      as_nm: this.state.searchQuery,
      gubun: this.state.gubun,
      contgubun: this.state.contgubun,
      ordflag: this.state.ordflag,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '엑셀변환할 내역이 없습니다.')) {
      return;
    }
    await api.excel({
      gigan: this.state.gigan,
      as_nm: this.state.searchQuery,
      gubun: this.state.gubun,
      contgubun: this.state.contgubun,
      ordflag: this.state.ordflag,
      divicd: this.state.divicd,
      perid: this.state.perid,
    });
  }

  /**
   * 행 선택 이벤트
   * @param item
   */
  @action
  onRowFocusEvent(item: OldSpaceModel) {
    this.setState({ focusedData: item });
  }

  render() {
    return <OldSpaceTemplate
      scope={this}
      update={(change, callback) => {
        this.setState({
          ...this.state,
          ...change,
        }, () => callback && callback());
      }}
    />;
  }
}
