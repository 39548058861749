export * from './RegistTabAttachment.template';
export * from './RegistTabBal.template';
export * from './RegistTabConference.template';
export * from './RegistTabProcess.template';
export * from './RegistTabScAmt.template';
export * from './RegistTabSuju.template';
export * from './RegistTabTel.template';
export * from './RegistTabEstimate.template';
export * from './RegistTabSales.template';

