
/**
 * 모델
 * @window w_tb_ae200
 * @category 지출결의서
 */

export class JournalDetailPopupModel {
  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly rptnum: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly lat: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly lng: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly carcd: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly wkcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly wktext: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly frtime: string;

  /**
   *
   *
   * **data type** varchar(5)
   */
  readonly totime: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly prekm: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly km: string;

  /**
   *
   *
   * **data type** varchar(32764)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly plandate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly edudate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly edunum: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly lkdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly lknum: string;


  constructor(data: any = {}) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.rptdate = data.rptdate || '';
    this.perid = data.perid || '';
    this.rptnum = data.rptnum || '';
    this.cltcd = data.cltcd || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.lat = data.lat || '';
    this.lng = data.lng || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.carcd = data.carcd || '';
    this.wkcd = data.wkcd || '';
    this.wktext = data.wktext || '';
    this.frtime = data.frtime || '';
    this.totime = data.totime || '';
    this.prekm = data.prekm || '';
    this.km = data.km || '';
    this.remark = data.remark || '';
    this.plandate = data.plandate || '';
    this.edudate = data.edudate || '';
    this.edunum = data.edunum || '';
    this.lkdate = data.lkdate || '';
    this.lknum = data.lknum || '';
  }
}
