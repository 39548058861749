// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Badge_container__XdjNy {\n  position: absolute;\n  right: 1px;\n  top: -3px; }\n", "",{"version":3,"sources":["webpack://src/app/components/layout/Badge/Badge.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS,EAAA","sourcesContent":[".container {\n  position: absolute;\n  right: 1px;\n  top: -3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Badge_container__XdjNy"
};
export default ___CSS_LOADER_EXPORT___;
