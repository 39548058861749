import { computed } from 'mobx';

/**
 * 부서별 모델
 * @window w_input_da023w_03_view
 * @category 장기미수현황
 */
export class DepartmentModel {
  /**
   * 문자
   *
   * **data type** char(1)
   */
   smsflag: string;

  /**
   * 회사코드
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 거래처명
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 부서
   *
   * **data type** string
   */
  divicd: string;

  /**
   * 부서
   *
   * **data type** string
   */
  divinm: string;

  /**
   * 인사코드
   *
   * **data type** string
   */
  perid: string;

  /**
   * 담당자
   *
   * **data type** string
   */
  pernm: string;

  /**
   * 매출액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 매출액
   *
   * **data type** number
   */
  misdate: string;

  /**
   * 입금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 미수잔액
   *
   * **data type** number
   */
  resuamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.smsflag = data.smsflag || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.divicd = data.divicd || '';
    this.divinm = data.divinm || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.misamt = data.misamt || '';
    this.misdate = data.misdate || '';
    this.rcvamt = data.rcvamt || '';
    this.resuamt = data.resuamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
