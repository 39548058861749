export class CltModalModel {
  actflag: string;

  bizitemnm: string;

  biztypenm: string;

  bmar: string;

  cltcd: string;

  cltnm: string;

  custcd: string;

  foreyn: string;

  indate: string;

  inperid: string;

  inputdate: string;

  liamt: string;

  prtcltnm: string;

  relyn: string;

  rnumchk: string;

  saupnum: string;

  taxmail: string;

  constructor(data: any = {}) {
    this.actflag = data.actflag || '';
    this.bizitemnm = data.bizitemnm || '';
    this.biztypenm = data.biztypenm || '';
    this.bmar = data.bmar || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.custcd = data.custcd || '';
    this.foreyn = data.foreyn || '';
    this.indate = data.indate || '';
    this.inperid = data.inperid || '';
    this.inputdate = data.inputdate || '';
    this.liamt = data.liamt || '';
    this.prtcltnm = data.prtcltnm || '';
    this.relyn = data.relyn || '';
    this.rnumchk = data.rnumchk || '';
    this.saupnum = data.saupnum || '';
    this.taxmail = data.taxmail || '';
  }
}
