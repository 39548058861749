import * as React from 'react';
import { RefObject } from 'react';
import { action } from 'mobx';
import update from 'react-addons-update';
import {
  AskType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import { InfinityRetrieve } from '../../../../models';
import EnrollmentModel from './models/EnrollmentModel';
import { EnrollmentTemplate } from './Enrollment.template';
import { PageComponent } from '../../../../utils';
import {
  DragAndDropLayout,
  GridLayout,
  TableLayout,
} from '../../../../components';
import {
  Confirm,
  ConfirmSuccess,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Date6,
  Date8,
  DateStabilizer,
  Today,
} from '../../../../utils/time';
import EnrollmentDetailModel from './models/EnrollmentDetailModel';
import EnrollmentMaterialsModel from './models/EnrollmentMaterialsModel';
import {
  ApiService,
  Format,
} from '../../../../utils/string';
import EnrollmentPullModel from './models/EnrollmentPullModel';
import { ProductCodeModel } from '../../../general/materials/ProductCode/ProductCode.model';


export enum EstimateImage {
  KS1,
  KS2,
  KS3,
  S1,
  S2,
  S3,
}

// 화면에 있는 state 이름
const imgStateNames = [
  'img_ks1',
  'img_ks2',
  'img_ks3',
  'img_s1',
  'img_s2',
  'img_s3',
];

// PB 백엔드와 약속한 함수 이름(호출명)
const imgFunctionNames = [
  'pic_ks1',
  'pic_ks2',
  'pic_ks3',
  'pic_s1',
  'pic_s2',
  'pic_s3',
];


interface EnrollmentState {
  searchQuery: string;
  stdate: string;
  enddate: string;
  gubun: string;
  compyn: string;

  data: Array<EnrollmentModel>;
  content: EnrollmentModel;

  detail: Array<EnrollmentDetailModel>;
  materials: Array<EnrollmentMaterialsModel>;

  taxGubuns: Array<any>;
  Gubuns: Array<any>;
  compGubuns: Array<any>;

  addamt_tot?: string;
  addamt_tot2?: string;
  compamt_tot?: string;
  compamt_tot2?: string;
  qty_tot2?: string;
  samt_tot?: string;
  samt_tot2?: string;

  isVisibleEstimate: boolean;
  dataModalEstimate: Array<any>;
  modalDate: string;
  modalSearchQuery: string;
  img_ks1?: ArrayBuffer;
  img_ks2?: ArrayBuffer;
  img_ks3?: ArrayBuffer;
  img_s1?: ArrayBuffer;
  img_s2?: ArrayBuffer;
  img_s3?: ArrayBuffer;

  isVisibleAttachment: boolean;

  isVisiblePull: boolean;
  dataPull: Array<EnrollmentPullModel>;

  saveFlag: boolean;
  compdate_stamp: string;

  useCalc: string;
  e451_ok: number;
}


/**
 * 컨트롤러
 * @window w_tb_e471
 * @category 공사등록
 */
export class Enrollment extends PageComponent<PageProps, EnrollmentState>
  implements PageToolEvents {
  infinity?: InfinityRetrieve;

  infinityDetail?: InfinityRetrieve

  infinityMaterials?: InfinityRetrieve;

  infinityPull?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  tableDetail: RefObject<TableLayout> = React.createRef();

  tableMaterials: RefObject<DragAndDropLayout> = React.createRef();

  tableEsimate: RefObject<TableLayout> = React.createRef();

  gridFocus?: EnrollmentModel;

  gridFocusIndex: number = 0;

  tableDetailFocus?: EnrollmentDetailModel;

  tableDetailFocusIndex: number = 0;

  tableMaterialsFocus?: EnrollmentMaterialsModel;

  tableEstimateFocus?: any;

  tableEstimateFocusIndex: number = 0;

  tabIndex: number = 0;

  gridPullFocusIndex: number = 0;


  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    this.state = props.state || {
      searchQuery: '',
      stdate: `${Date6.make()}01`,
      enddate: Today.date(),
      gubun: '%',
      compyn: '%',
      compdate_stamp: '0',
      useCalc: '0',

      data: [], // 조회 시 data
      content: new EnrollmentModel(), // rowfocus data

      taxGubuns: [],
      Gubuns: [],
      compGubuns: [],

      detail: [], // 견적항목
      materials: [], // 부품내역등록

      isVisibleEstimate: false,
      dataModalEstimate: [],
      modalDate: Today.date(),
      modalSearchQuery: '',

      isVisibleAttachment: false,

      isVisiblePull: false,
      dataPull: [],
      saveFlag: false,
      e451_ok: 0,
    };
  }


  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 과세구분
    const data = await api.dropdown('wf_dd_ca510_006_01');
    await this.SS({ taxGubuns: data?.items || [] });

    const data2 = await api.dropdown('wf_dd_da020_all');
    await this.SS({ Gubuns: data2?.items || [] });

    const data3 = await api.dropdown('wf_dd_da020_notjgubun');
    await this.SS({ compGubuns: data3?.items || [] });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
        gubun: this.state.gubun,
        compyn: this.state.compyn,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new EnrollmentModel(item)),
          ],
        });
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length > this.gridFocusIndex) {
          await this.onRowFocusEvent(this.state.data[this.gridFocusIndex], this.gridFocusIndex);
        } else if (this.state.data.length > 0) {
          await this.onRowFocusEvent(this.state.data[0], 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.content;
    await this.SS({
      data: [],
      detail: [],
      content: new EnrollmentModel(),
      materials: [],
    });

    const index = await this.infinity?.retrieveTo(['compdate', 'compnum'],
      [lastSelected?.compdate, lastSelected?.compnum], type, true) || 0;
    if (this.state.data && this.state.data.length > index) {
      this.grid.current?.setFocus(index);
    }
    await this.SS({
      e451_ok: this.infinity?.box?.e451_ok || 0,
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;

    if (this.state.data[0]?.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가할 수 있습니다.');
      return;
    }

    // if (this.state.data.filter((x) => x.isNew).length) {
    //   ConfirmWarning.show('경고', '한번에 한 행만 추가할 수 있습니다.');
    //   return;
    // }

    const data = await api.new();

    if (data) {
      const one = new EnrollmentModel(data, true);

      await this.SS({
        content: one,
        data: [one, ...this.state.data],
        detail: [],
        materials: [],
        addamt_tot: data?.addamt_tot || '0',
        addamt_tot2: data?.addamt_tot2 || '0',
        compamt_tot: data?.compamt_tot || '0',
        compamt_tot2: data?.compamt_tot2 || '0',
        qty_tot2: data?.qty_tot2 || '0',
        samt_tot: data?.samt_tot || '0',
        samt_tot2: data?.samt_tot2 || '0',
      });

      this.grid.current?.setFocus(0);
      this.setState({ saveFlag: false });
    }
  }

  @action
  async onSaveEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...this.state.content,
      compflag: this.state.content.compflag,
      itemname: 'compflag',
      data: this.state.content.compflag,
    }, this.state.content.isNew);

    if (result === undefined) {
      return;
    }

    const response = await api.save(
      {
        ...this.state.content,
        items: this.state.detail,
        items2: this.state.materials,
      },
      this.state.content.isNew,
    );
    if (response) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.content.compdate);
      this.setState({
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
        content: new EnrollmentModel({ ...response, compnum: response.compnum }),
      });
      await this.onRetrieveEvent();
      this.setState({ saveFlag: true });
    }
  }

  @action
  async onDeleteEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${Date8.withDash(this.state.content?.compdate)} ${this.state.content?.constnm}`;

    if (await api.delete(text, {
      misdate: this.state.content?.misdate,
      compdate: this.state.content?.compdate,
      compnum: this.state.content?.compnum,
      costdate: this.state.content?.costdate,
      costnum: this.state.content?.costnum,
      contdate: this.state.content?.contdate,
      contnum: this.state.content?.contnum,
      compflag: this.state.content?.compflag,
    })) {
      await this.SS({
        data: update(this.state.data, {
          $splice: [[this.gridFocusIndex, 1]],
        }),
      });

      await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }
    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state.content.gubun !== '11', '오류', '출력할수 없는 내역입니다.')) {
      return;
    }

    if (this.state.content.gubun !== '12') {
      const confirm = await Confirm.ask('확인', '공사완료일을 표기하시겠습니까?', '예', '아니오');

      switch (confirm) {
        case AskType.YES:
          await this.SS({ compdate_stamp: '1' });
          break;

        case AskType.NO:
          await this.SS({ compdate_stamp: '0' });
          break;

        case AskType.CANCEL:
          return;
      }
    }


    const { actionStore: api } = this.props;

    await api.printWithElmanManager({
      costdate: this.state.content?.costdate,
      costnum: this.state.content?.costnum,
      gubun: this.state.content?.gubun,
      prow: (this.state.detail.length + this.state.materials.length).toString(),
      compdate_stamp: this.state.compdate_stamp,
    });
  }

  @action
  async onMessageEvent(topic: string, message: string) {
    const { publicStore } = this.props;
    if (topic === `/elman/web/${publicStore.user.custcd}/${publicStore.user.perid}`) {
      const json = JSON.parse(JSON.parse(message));
      if (json.key === 'ALERT-ANSWER'
        && AskType.YES === await Confirm.ask(json.title, json.message, '예', '아니요')
      ) {
        await this.openPull();
      }
    }
  }


  @action
  async onRowFocusEvent(item: EnrollmentModel | undefined, index: number) {
    this.tableMaterialsFocus = undefined;
    this.tableDetailFocus = undefined;

    if (!item) {
      await this.SS({ content: new EnrollmentModel() });
      this.gridFocus = item;
      this.gridFocusIndex = 0;
      return;
    }

    await this.SS({ content: item });
    this.gridFocus = item;
    this.gridFocusIndex = index;

    if (this.state.content.isNew) {
      await this.SS({
        detail: [],
        materials: [],
        saveFlag: true,
      });
      await this.tableDetail.current?.update(false);
      await this.tableMaterials.current?.update(false);
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_1_RowFocuschanged', {
      compdate: this.gridFocus.compdate,
      compnum: this.gridFocus.compnum,
      page: '0',
    });

    if (data) {
      await this.SS({
        content: new EnrollmentModel(data),
        detail: data.items?.map((x: any) => new EnrollmentDetailModel(x)) || [],
        materials: data.items2?.map((x: any) => new EnrollmentMaterialsModel(x)) || [],
        addamt_tot: data?.addamt_tot || '0',
        addamt_tot2: data?.addamt_tot2 || '0',
        compamt_tot: data?.compamt_tot || '0',
        compamt_tot2: data?.compamt_tot2 || '0',
        qty_tot2: data?.qty_tot2 || '0',
        samt_tot: data?.samt_tot || '0',
        samt_tot2: data?.samt_tot2 || '0',
      });

      await this.tableDetail.current?.update(true);
      await this.tableMaterials.current?.update(true);

      if (this.state.detail.length > 0 && this.tabIndex === 0) {
        this.onTableDetailRowFocusEvent(this.state.detail[0], 0);
        this.tableDetail.current?.setFocus(0);
      }

      if (this.state.materials.length > 0 && this.tabIndex === 1) {
        this.onTableMaterialsRowFocusEvent(this.state.materials[0]);
        this.tableMaterials.current?.setFocus(0);
      }
    }
  }

  @action
  async onChangeTableMaterials(rows: Array<EnrollmentMaterialsModel>) {
    await this.SS({
      materials: rows.map((x, i) => new EnrollmentMaterialsModel({
        ...x,
        seq: `000${i + 1}`.substr(-3),
      }, x.isNew)),
    });
  }

  @action
  onTabChange(index: number) {
    if (this.state.content.isNew) {
      return;
    }
    this.tabIndex = index;
    this.onRowFocusEvent(this?.gridFocus, this.gridFocusIndex);
  }

  @action
  async headerItemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...this.state.content,
      [key]: value,
      itemname: key,
      data: value,
    }, this.state.content.isNew);
    if (result === undefined) {
      return;
    }
    await this.SS({
      content: new EnrollmentModel({
        ...this.state.content,
        ...result,
        saleflag: key === 'saleamt' ? '1' : this.state.content.saleflag,
      }, this.state.content.isNew),
    });
    await this.tableMaterials.current?.update(true);
  }


  onTableDetailRowFocusEvent(item: EnrollmentDetailModel, index: number) {
    this.tableDetailFocus = item;
    this.tableDetailFocusIndex = index;
  }

  @action
  async tableDetailItemChanged(rowUpdate: RowUpdate, key: string, value: string) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_3_itemchanged', {
      ...this.state.detail[this.tableDetailFocusIndex],
      [key]: value,
      itemname: key,
      data: value,
    }, this.state.detail[this.tableDetailFocusIndex].isNew);

    if (result) {
      rowUpdate(result);
    }
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, isSamt: boolean = false) {
    if (this.state.useCalc === '1') {
      return;
    }
    const isTax = this.state.content.taxcls === '1';
    const qty = Format.toNumber(item.qty);
    let uamt = Format.toNumber(item.uamt);

    let samt = Format.toNumber(item.samt);

    if (!isSamt) {
      samt = Math.round((qty * uamt) / (isTax ? 1.1 : 1));
    } else {
      samt = isTax ? Math.round(samt / 1.1) : samt;
    }

    if (item.myn === '1' && !isSamt) {
      samt = 0;
      uamt = 0;
    }

    const addamt = Math.round(samt * 0.1);

    rowUpdate({
      ...item,
      samt: samt.toString(),
      addamt: addamt.toString(),
      compamt: (samt + addamt).toString(),
    });
  }

  @action
  async tabCompAmtCalc(item: any, rowUpdate: RowUpdate) {
    // 견적금액
    const compamt = Format.toNumber(item.compamt);

    // 금액 -> 견적금액 / 1.1 ( 반올림 )
    let samt = Format.toNumber(item.samt);
    samt = Math.round(compamt / 1.1);

    // 부가세 -> 견적금액 - 금액
    let addamt = Format.toNumber(item.addamt);
    addamt = compamt - samt;

    rowUpdate({
      ...item,
      compamt: compamt.toString(),
      samt: samt.toString(),
      addamt: addamt.toString(),
    });
  }

  @action
  async onClickDetailNew() {
    const { actionStore: api } = this.props;

    if (!this.state.content.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    if (this.tabIndex === 0) {
      const data = await api.fxExec('tab_1_dw_3_new', {
        compdate: this.state.content.compdate,
        compnum: this.state.content.compnum,
        actcd: this.state.content.actcd,
        gubun: this.state.content.gubun,
        seq: this.state.detail.length < 1
          ? '001'
          : `000${this.state.detail.length + 1}`.substr(-3),
      });
      if (data) {
        await this.setState({
          detail: [
            ...this.state.detail,
            {
              ...data,
              new: '1',
            },
          ],
        }, async () => {
          await this.tableDetail.current?.update(true);
          await this.tableDetail.current?.setFocus(this.state.detail.length - 1, 1);
        });
      }
    } else if (this.tabIndex === 1) {
      const data = await api.fxExec('tab_2_dw_3_new', {
        compdate: this.state.content.compdate,
        compnum: this.state.content.compnum,
        actcd: this.state.content.actcd,
        gubun: this.state.content.gubun,
        seq: this.state.materials.length < 1
          ? '001'
          : `000${this.state.materials.length + 1}`.substr(-3),
      });
      if (data) {
        await this.setState({
          materials: [
            ...this.state.materials, new EnrollmentMaterialsModel(data, true),
          ],
        }, async () => {
          await this.tableMaterials.current?.update(true);
          await this.tableMaterials.current?.setFocus(this.state.materials.length - 1);
        });
      }
    }
  }

  @action
  async onClickDetailDelete() {
    if (!this.tableDetailFocus?.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.fxDelete('tab_1_dw_3_delete', `설치비 지급내역 ${this.tableDetailFocus?.seq}번`, {
      compdate: this.tableDetailFocus?.compdate,
      compnum: this.tableDetailFocus?.compnum,
      seq: this.tableDetailFocus?.seq,
    })) {
      await this.onRowFocusEvent(this.gridFocus, this.gridFocusIndex);
    }
  }


  onTableMaterialsRowFocusEvent(item: EnrollmentMaterialsModel) {
    this.tableMaterialsFocus = item;
  }

  @action
  async tableMaterialsItemChanged(rowUpdate: RowUpdate, key: string, value: string, code: ProductCodeModel, item: EnrollmentMaterialsModel) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_4_itemchanged', {
      ...item,
      [key]: value,
      itemname: key,
      data: value,
    }, item.isNew);

    if (result) {
      rowUpdate({
        pcode: code.phm_pcod,
        pname: code.phm_pnam,
        psize: code.phm_size,
        punit: code.phm_unit,
        uamt: code.phm_uamt || code.phm_samt,
      });
    }
  }

  @action
  async onClickMaterialsNew() {
    const { actionStore: api } = this.props;

    if (!this.state.content.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    const data = await api.fxExec('tab_2_dw_3_new', {
      compdate: this.state.content.compdate,
      compnum: this.state.content.compnum,
      actcd: this.state.content.actcd,
      gubun: this.state.content.gubun,
      seq: this.state.materials.length < 1
        ? '001'
        : `000${this.state.materials.length + 1}`.substr(-3),
    });

    if (data) {
      this.setState({
        materials: [
          ...this.state.materials,
          new EnrollmentMaterialsModel(data, true),
        ],
      }, async () => {
        await this.tableMaterials.current?.update(false);
        this.tableMaterials.current?.setFocus(this.state.materials.length - 1, 1);
      });
    }
  }

  @action
  async onClickMaterialsDelete() {
    if (!this.tableMaterialsFocus?.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.fxDelete('tab_2_dw_3_delete', `부품 ${this.tableMaterialsFocus?.pcode}`, {
      compdate: this.tableMaterialsFocus?.compdate,
      compnum: this.tableMaterialsFocus?.compnum,
      seq: this.tableMaterialsFocus?.seq,
    })) {
      await this.onRowFocusEvent(this.gridFocus, this.gridFocusIndex);
    }
  }


  // Modal estimate
  @action
  async openEstimateModal() {
    await this.SS({ isVisibleEstimate: true });
    this.retreiveEstimateModal();
  }

  @action
  async retreiveEstimateModal() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve({
      sub: 'w_popup_e451_01',
      actcd: this.state.modalSearchQuery || '%',
    });

    if (data?.items) {
      await this.SS({
        dataModalEstimate: data?.items?.map((x: any) => ApiService.notNull(x)) || [],
      });

      await this.tableEsimate.current?.update(true);

      this.tableEsimate.current?.setFocus(0);
    }
  }

  @action
  async removeModalEstimateItem() {
    const items = this.state.dataModalEstimate.filter((x) => x.chk === '1');

    if (!items.length) {
      ConfirmWarning.show('오류', '삭제할 견적서를 먼저 선택해주세요');
      return;
    }

    const text = '선택한 내용을 목록에서 삭제하시겠습니까?';
    const { actionStore: api } = this.props;
    await api.delete(
      text, {
        sub: 'w_popup_e451_01',
        items,
      },
    );
    await this.retreiveEstimateModal();
    // await api.retrieve({
    //   sub: 'w_popup_e451_01',
    //   items,
    // });
  }

  @action
  async saveModalEstimate() {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('wb_e451_ok', {
      ...this.tableEstimateFocus,
      compdate: this.state.modalDate,
      compgun: this.state.compyn,
      offinm: this.tableEstimateFocus.constnm,
      saleflag: this.state.content.saleflag,
    }, true);

    if (result) {
      await this.SS({
        isVisibleEstimate: false,
        content: new EnrollmentModel(result),
      });
      ConfirmSuccess.showPbMessage(result);
      await this.onRetrieveEvent();
    }
  }

  onEstimateRowFocusEvent(item: any, index: number) {
    this.tableEstimateFocus = item;
    this.tableEstimateFocusIndex = index;

    this.imageRequest(EstimateImage.KS1);
    this.imageRequest(EstimateImage.S1);
    this.imageRequest(EstimateImage.KS2);
    this.imageRequest(EstimateImage.S2);
    this.imageRequest(EstimateImage.KS3);
    this.imageRequest(EstimateImage.S3);
  }

  @action
  async imageRequest(i: number) {
    const { actionStore: api } = this.props;
    const blob = await api.fxBinary(
      imgFunctionNames[i],
      {
        sub: 'w_popup_e451_01',
        ...this.tableEstimateFocus,
      },
    );


    // @ts-ignore
    this.setState({
      [imgStateNames[i]]: blob,
    });
  }


  // Attachment
  @action
  async openAttachment() {
    if (!this.state.content.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    await this.SS({ isVisibleAttachment: true });
  }


  // Pull
  @action
  async openPull() {
    const { actionStore: api } = this.props;
    this.gridPullFocusIndex = 0;
    this.infinityPull = new InfinityRetrieve(
      {
        sub: 'w_popup_e451',
        actcd: this.state.content?.actcd,
        saleflag: this.state.content?.saleflag,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.SS({
          dataPull: [
            ...this.state.dataPull,
            ...items.map((item) => new EnrollmentPullModel(item)),
          ],
        });
      },
      async () => {
        await this.SS({
          dataPull: [],
        });
        await this.infinityPull?.retrieve();
      },
    );

    await this.SS({
      dataPull: [],
      isVisiblePull: true,
    });
    this.infinityPull?.retrieve();
  }

  @action
  async donePull() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_2_itemchanged', {
      ...this.state.dataPull[this.gridPullFocusIndex],
      offinm: this.state.dataPull[this.gridPullFocusIndex].constnm,
      actcd_after: this.state.content?.actcd,
      itemname: 'actcd_after',
      data: 'actcd_after',
      compdate: this.state.content?.compdate,
      compnum: this.state.content?.compnum,
      compyn: this.state.content?.compyn,
    }, this.state.content.isNew);

    ConfirmSuccess.showPbMessage(data);

    const { stdate, enddate } = DateStabilizer.get(this.state.stdate, this.state.enddate, data.compdate);

    await this.SS({
      stdate,
      enddate,
      isVisiblePull: false,
      content: new EnrollmentModel(data),
    });

    this.gridFocusIndex = 0;
    await this.onRetrieveEvent();
  }

  onPullRowFocusEvent(index: number) {
    this.gridPullFocusIndex = index;
  }


  render() {
    return <EnrollmentTemplate
      scope={this}
    />;
  }
}
