
import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { FloorModel } from '../models';
import { BreakAnalysisOne } from '../BreakAnalysisOne';
import { TextBox } from '../../../../../components';

// 층수별
const Floor: GridLayoutHeader<FloorModel, BreakAnalysisOne>[] = [
  {
    id: 'number',
    text: '층수별',
    width: 100,
    trail: () => (
      <FlexLayout
        justify="left"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.number}
      </FlexLayout>
    ),
  },
  {
    id: 'cnt',
    text: '고장건수',
    width: 200,
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.cnt}
      />
    ),
  },
];

export default Floor;
