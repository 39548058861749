import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  GridLayout,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { ManageDeposit } from './ManageDeposit';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';

const recivstatuss = [
  { value: 'A ', remark: 'KTNET접수' },
  { value: 'B ', remark: '발송전' },
  { value: 'D ', remark: '거부(화주)' },
  { value: 'E ', remark: 'KTNET오류' },
  { value: 'F ', remark: '국세청최종접수' },
  { value: 'FF', remark: '국세청직접발행' },
  { value: 'L ', remark: '승인(화주)' },
  { value: 'N ', remark: '이메일전송실패' },
  { value: 'R ', remark: '열람' },
  { value: 'T ', remark: '국세청1차접수' },
  { value: 'X ', remark: '국세청오류' },
];

const gubuns = [
  { value: '11', remark: '유지보수' },
  { value: '12', remark: '수리공사' },
  { value: '13', remark: '부품교체' },
  { value: '14', remark: '설치공사' },
  { value: '15', remark: '리모델링' },
  { value: '16', remark: '현대엘리베이터(공)' },
  { value: '17', remark: '전기공사' },
  { value: '18', remark: '통장거래' },
  { value: '19', remark: '기타수입' },
  { value: '20', remark: '기타환불' },
  { value: '21', remark: '가지급정산' },
  { value: '22', remark: '개발매출' },
];

/**
 * 화면
 * @window w_tb_ca642_ats
 * @category 예금관리
 */
export const ManageDepositTemplate: React.FC<TemplateProps<ManageDeposit>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={10}>
        <label style={{ color: '#7F7A2C' }}>
          *입출금처리가 되지 않은건은 녹색으로 표시됩니다.(은행내역만 입력된경우)
        </label>
        <label style={{ marginLeft: 240 }}>*입출금처리, 지로, CMS, 제외처리 된것은 검은색으로 표기됩니다.</label>
      </FlexLayout>

      <FlexLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
        style={{ marginTop: 10 }}
      >
        <SearchLayout>
          <FlexLayout size={200}>
            <span>기간</span>
            <DateTextBox
              value={scope.state.stdate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ stdate: value })}
              onEnter={() => scope.onSearchEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onSearchEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onSearchEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={180}>
            <span style={{ marginLeft: 8 }}>~</span>
            <DateTextBox
              value={scope.state.enddate}
              textAlign="center"
              format="####-##-##"
              onChange={(value) => setData({ enddate: value })}
              onEnter={() => scope.onSearchEvent()}
              head={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onSearchEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24}/>
              </button>)}
              trail={(<button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onSearchEvent());
                }}
              >
                <MdKeyboardArrowRight size={24}/>
              </button>)}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={150}>
            <span>처리</span>
            <ComboBox
              value={scope.state.compflag}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '처리' },
                { value: '0', remark: '미처리' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ compflag: option.value },
                () => scope.onSearchEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={150}>
            <span>입/출금</span>
            <ComboBox
              value={scope.state.gubun}
              data={[
                { value: '%', remark: '전체' },
                { value: '1', remark: '입금' },
                { value: '2', remark: '출금' },
              ].map((x) => new ComboBoxModel(x.value, x.remark))}
              onSelect={(option) => setData({ gubun: option.value },
                () => scope.onSearchEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span>검색어</span>
            <TextBox
              placeholderColor="#CACACA"
              placeholderAlign="left"
              placeholder={scope.state.new === '0' ? '적요로 검색하세요' : ''}
              value={scope.state.searchQuery}
              onChange={(value) => setData({ searchQuery: value })}
              onEnter={() => scope.onSearchEvent()}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={176}>
            <span>사업장</span>
            <ComboBox
              value={scope.state.spjangcd}
              textAlign="center"
              style={{ marginLeft: 12 }}
              data={scope.props.publicStore.spjangs.map((x) => new ComboBoxModel(x.spjangcd, x.spjangnm))}
              onSelect={(option) => setData({ spjangcd: option.value },
                () => scope.onSearchEvent())}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={400}>
            <span style={{ color: 'var(--color-red)' }}>계좌번호</span>
            <TextBox
              textAlign="center"
              value={scope.state.bankcd}
              onChange={(value) => setData({ bankcd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AA040',
                {},
                true,
                (item) => {
                  setData({
                    bankcd: item.cd,
                    banknm: item.cdnm,
                    accnum: item.cdnm,
                  });
                  scope.onSearchEvent();
                },
              )}
            />
            <TextBox
              weight={2}
              value={scope.state.banknm}
              readonly={true}
              onChange={(value) => setData({ cltnm: value })}
            />
            <TextBox
              weight={2}
              value={scope.state.accnum}
              readonly={true}
              onChange={(value) => setData({ accnum: value })}
            />
          </FlexLayout>

          {/* <Button onClick={() => scope.onButtonEvent()}> */}
          {/*  빠른연동 */}
          {/* </Button> */}
        </SearchLayout>
      </FlexLayout>

      <FlexLayout>
        <TableLayout
          ref={scope.table}
          isPaging={true}
          header={[
            {
              id: 'input',
              text: '구분',
              width: 70,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.input}
                  style={{ color: x.input === 'h' ? '#7F7A2C' : '' }}
                  data={[
                    { value: 'b', remark: '예금' },
                    { value: 'h', remark: '현금' },
                    { value: 'j', remark: '지로' },
                    { value: 'c', remark: 'CMS' },
                    { value: 'x', remark: '제외' },
                    { value: 'd', remark: '대출' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ input: option.value })}
                />
              ),
            },
            {
              id: 'snddate',
              text: '거래일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.compflag !== '1' ? '#7F7A2C' : '' }}
                >
                  {Date8.withDash(x.snddate)}
                </FlexLayout>
              ),
            },
            {
              id: 'sndnum',
              text: '번호',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.compflag !== '1' ? '#7F7A2C' : '' }}
                >
                  {x.sndnum}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 285,
              render: (x, rowUpdate, ref) => (
                <FlexLayout style={{ overflow: 'hidden' }}>
                  <TextBox
                    ref={ref}
                    value={x.remark}
                    textAlign="left"
                    isMultiline={true}
                    color={x.compflag !== '1' ? '#7F7A2C' : '' }
                    onChange={(value) => rowUpdate({ remark: value })}
                  />
                </FlexLayout>
              ),
            },
            {
              id: 'gubun',
              text: '입출금',
              width: 70,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.gubun}
                  style={{
                    color: x.gubun === '1' ? 'var(--color-blue)'
                      : x.gubun === '2' ? 'var(--color-red)'
                        : '',
                  }}
                  data={[
                    { value: '1', remark: '입금' },
                    { value: '2', remark: '출금' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ gubun: option.value })}
                />
              ),
            },
            {
              id: 'bamt',
              text: '예금금액',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  color={
                    x.gubun === '1' ? 'var(--color-blue)'
                      : x.gubun === '2' ? 'var(--color-red)'
                        : ''
                  }
                  textAlign="right"
                  ref={ref}
                  value={x.bamt}
                  onChange={(value) => rowUpdate({ bamt: value })}
                />
              ),
            },
            {
              id: 'hamt',
              text: '현금금액',
              width: 100,
              render: (x, rowUpdate, ref) => (
                x.input === 'h'
                  ? <FormatNumberTextBox
                    color={
                      x.gubun === '1' ? 'var(--color-blue)'
                        : x.gubun === '2' ? 'var(--color-red)'
                          : ''
                    }
                    ref={ref}
                    char={','}
                    charAt={3}
                    textAlign="right"
                    value={x.hamt}
                    onChange={(value) => rowUpdate({ hamt: value })}
                  />
                  : <FlexLayout
                    justify="right"
                    align="center"
                    style={{ color: x.gubun === '1' ? 'var(--color-blue)' : 'var(--color-red)' }}
                  >
                    {Format.number(x.hamt)}
                  </FlexLayout>
              ),
            },
            {
              id: 'bankcd',
              text: '은행코드',
              width: 65,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  textAlign="center"
                  color={x.compflag !== '1' ? '#7F7A2C' : '' }
                  value={x.bankcd}
                  onChange={(value) => rowUpdate({ bankcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_AA040',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        bankcd: item.cd,
                        banknm: item.uv_arg3,
                        accnum: item.uv_arg1,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'banknm',
              text: '은행명',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.compflag !== '1' ? '#7F7A2C' : '' }}
                >
                  {x.banknm}
                </FlexLayout>
              ),
            },
            {
              id: 'accnum',
              text: '계좌번호',
              width: 150,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: x.compflag !== '1' ? '#7F7A2C' : '' }}
                >
                  {x.accnum}
                </FlexLayout>
              ),
            },
            {
              id: 'spjangcd',
              text: '사업장',
              width: 150,
              render: (x, rowUpdate) => (
                <ComboBox
                  style={{ color: x.compflag !== '1' ? '#7F7A2C' : '' }}
                  value={x.spjangcd}
                  data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
                  onSelect={(option) => rowUpdate({ spjangcd: option.value })}
                />
              ),
            },
            {
              id: 'cltcd',
              text: '거래처코드',
              color: 'var(--color-red)',
              width: 80,
              render: (x, rowUpdate, refs) => (
                <TextBox
                  refs={refs}
                  value={x.cltcd}
                  textAlign="center"
                  color={x.compflag !== '1' ? '#7F7A2C' : '' }
                  onChange={(value) => {
                    rowUpdate({ cltcd: value });
                    scope.SS({
                      popupSearchQuery: value,
                    });
                  }}
                  onDoubleClick={() => scope.onCltcdPopupOpen(true)}
                  onEnter={() => scope.onCltcdPopupOpen(true)}
                />
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                  style={{ color: x.compflag !== '1' ? '#7F7A2C' : '' }}
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'mismijchk',
              text: '입출금처리',
              width: 70,
              render: (x, rowUpdate) => (
                x.mismijchk === '1'
                  ? <FlexLayout
                    justify="center"
                    align="center"
                    isVertical={true}
                    style={{ color: x.compflag === '0' ? 'var(--color-red)' : 'var(--color-blue)' }}
                  >
                    <CheckBox
                      value={x.mismijchk === '1'}
                      noMargin={true}
                      onChange={(v) => scope.onMismijchkClicked(rowUpdate, x, v ? '1' : '0')}
                    />
                    <span>{x.compflag === '0' ? '미처리' : '처리'}</span>
                  </FlexLayout>
                  : <CheckBox
                    value={x.mismijchk === '1'}
                    onChange={(v) => scope.onMismijchkClicked(rowUpdate, x, v ? '1' : '0')}
                  />
              ),
            },
            {
              id: 'interlock',
              text: '은행연동',
              width: 70,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                  style={{ color: x.interlock === '1' ? 'var(--color-blue)' : '' }}
                >
                  {x.interlock === '1' ? '연동' : '미연동'}
                </FlexLayout>
              ),
            },
            {
              id: 'artcd',
              text: '항목코드',
              color: 'var(--color-red)',
              width: 80,
              render: (x, rowUpdate, refs) => (
                <TextBox
                  refs={refs}
                  value={x.artcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ artcd: value })}
                  onDoubleClick={() => scope.artcdOpenModal(x, x.artcd, rowUpdate)}
                  onEnter={(value) => scope.artcdOpenModal(x, value, rowUpdate)}
                />
              ),
            },
            {
              id: 'artnm',
              text: '항목명',
              width: 150,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.artnm}
                </FlexLayout>
              ),
            },
            {
              id: 'acccd',
              text: '계정코드',
              color: 'var(--color-red)',
              width: 80,
              render: (x, rowUpdate, refs) => (
                <TextBox
                  refs={refs}
                  value={x.acccd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ acccd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_AC001',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        acccd: item.cd,
                        accnm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'accnm',
              text: '항목명',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.accnm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.ManageDepositList}
          infinityHandler={scope.infinity}
          rowHeight={45}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onVerticalScroll={(posY) => scope.onMainTableScroll(posY)}
          onRowFocusEvent={(item, index) => scope.onRowFocusEvent(item, index)}
        />
      </FlexLayout>

      <ModalView
        width={1300}
        zIndex={1}
        isVisible={scope.state.depositVisible}
        onClose={() => {
          scope.setState({ depositVisible: false });
          scope.onRetrieveEvent();
        }}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>매출/매입내역 확인</LayoutTitle>

          <TableLayout
            ref={scope.table2}
            header={[
              {
                id: 'chk',
                text: '선택',
                width: 50,
                render: (x, rowUpdate) => (
                  <CheckBox
                    value={x.chk === '1'}
                    onChange={(v) => {
                      if (scope?.state.janamt === '0') {
                        ConfirmWarning.show('경고', '남은 잔액이 0원입니다. 더 이상 선택할 수 없습니다.');
                        return;
                      }
                      rowUpdate({ chk: v ? '1' : '0' });
                    }}
                  />
                ),
              },
              {
                id: 'misdate',
                text: '미수일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.misdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'misnum',
                text: '번호',
                width: 70,
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'recivstatus',
                text: '상태',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {recivstatuss.filter((y) => y.value === x.recivstatus)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'misamt',
                text: '매출액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.misamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.misamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'janamt',
                text: '미수잔액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.janamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.janamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'inamt',
                text: '기입금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.inamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.inamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'wbamt',
                text: '예금',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.wbamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.wbamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'whamt',
                text: '현금',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.whamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.whamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'wgamt',
                text: '기타',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.wgamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.wgamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'billkind',
                text: '증빙구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.billkind === '1' ? '발행' : '미발행'}
                  </FlexLayout>
                ),
              },
              {
                id: 'gubun',
                text: '매출구분',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {gubuns.filter((y) => y.value === x.gubun)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_spdate',
                text: '게산서일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.tax_spdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_spnum',
                text: '번호',
                width: 70,
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity2}
            onChange={(rows) => {
              setTimeout(() => scope.itemchanged(rows), 100);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
            data={scope.state.popupList}
          />

          <SubHeader>
            <FlexLayout
              justify="start"
              align="center"
            >
              <FlexLayout>
                예금금액: {Format.number(scope.state.data?.st_totamt)}
              </FlexLayout>

              <FlexLayout style={{ color: 'var(--color-blue)' }}>
                정산금액: {Format.number(scope.state.resuamt)}
              </FlexLayout>

              <FlexLayout style={{ color: 'var(--color-red)' }}>
                잔액: {Format.number(scope.state.janamt) < '0' ? '0' : Format.number(scope.state.janamt)}
              </FlexLayout>
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onModalSaveEvent()}>확인</Button>
              <Button
                onClick={() => {
                  scope.setState({ depositVisible: false }, () => scope.onRetrieveEvent());
                }}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={1300}
        isVisible={scope.state.withdrawVisible}
        onClose={() => {
          scope.setState({ withdrawVisible: false });
          scope.onRetrieveEvent();
        }}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>매출/매입내역 확인</LayoutTitle>

          <TableLayout
            ref={scope.table2}
            header={[
              {
                id: 'chk',
                text: '선택',
                width: 50,
                render: (x, rowUpdate) => (
                  <CheckBox
                    value={x.chk === '1'}
                    onChange={(v) => {
                      if (scope?.state.janamt === '0') {
                        ConfirmWarning.show('경고', '남은 잔액이 0원입니다. 더 이상 선택할 수 없습니다.');
                        return;
                      }
                      rowUpdate({ chk: v ? '1' : '0' });
                    }}
                    isDisabledTrackingStateChange={true}
                  />
                ),
              },
              {
                id: 'mijdate',
                text: '미지급일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.mijdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijnum',
                text: '번호',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.mijnum}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijcltnm',
                text: '발생거래처',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.mijcltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'remark',
                text: '적요',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijamt',
                text: '발생금액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.mijamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.mijamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'janamt',
                text: '미지급잔액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.janamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.janamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'inamt',
                text: '기지급액',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.inamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.inamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'wbamt',
                text: '예금',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.wbamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.wbamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'whamt',
                text: '현금',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.whamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.whamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'wgamt',
                text: '기타',
                width: 100,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.wgamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.wgamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'schdate',
                text: '지급예정일',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Date8.withDash(x.schdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_spdate',
                text: '게산서일자',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.tax_spdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'tax_spnum',
                text: '번호',
                width: 70,
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity2}
            onChange={(rows) => {
              scope.itemchanged(rows);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
            data={scope.state.popupList}
          />

          <SubHeader>
            <FlexLayout
              justify="start"
              align="center"
            >
              <FlexLayout>
                예금금액: {Format.number(scope.state.data?.st_totamt)}
              </FlexLayout>

              <FlexLayout style={{ color: 'var(--color-blue)' }}>
                정산금액: {Format.number(scope.state.resuamt)}
              </FlexLayout>

              <FlexLayout style={{ color: 'var(--color-red)' }}>
                잔액: {Format.number(scope.state.janamt) < '0' ? '0' : Format.number(scope.state.janamt)}
              </FlexLayout>
            </FlexLayout>

            <FlexLayout
              justify="start"
              align="center"
              size={250}
            >
              <ComboBox
                value={scope.state.bmarflag}
                data={[
                  { value: '0', remark: '없음' },
                  { value: '1', remark: '있음' },
                ].map((y) => new ComboBoxModel(y.value, y.remark))}
                onSelect={(option) => {
                  setData({ bmarflag: option.value });
                  scope.onValueChangeEvent(option.value);
                }}
              />

              <TextBox
                size={120}
                value={scope.state.bmar}
                onChange={(value) => setData({ bmar: value })}
                readonly={true}
              />
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.onModalSaveEvent()}>확인</Button>
              <Button
                onClick={() => {
                  scope.setState({ withdrawVisible: false }, () => scope.onRetrieveEvent());
                }}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={800}
        isVisible={scope.state.cltcdPopupIsvisible}
        onClose={() => {
          scope.onCltcdPopupClose();
        }}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>매입거래처선택</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.popupSearchQuery}
                onChange={(value) => scope.SS({ popupSearchQuery: value })}
                onEnter={() => scope.onCltcdPopupRetrieve()}
              >
              </TextBox>
              <FlexLayout
                size={100}
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin={true}
                  value={scope.state.rtclafiChk === '1'}
                  onChange={(value) => scope.setState({ rtclafiChk: value ? '1' : '0' }, () => scope.onCltcdPopupRetrieve())}
                >
                </CheckBox>
                <span>퇴사자포함</span>
              </FlexLayout>
            </FlexLayout>
          </SearchLayout>

          <TableLayout
            ref={scope.cltcdPopupTable}
            header={[
              {
                id: 'gubun',
                text: '구분',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {
                      x.gubun === '1' ? '거래처'
                        : x.gubun === '2' ? '사원'
                          : x.gubun === '3' ? '부서'
                            : x.gubun === '4' ? '은행'
                              : x.gubun === '5' ? '카드'
                                : x.gubun === '6' ? '현장'
                                  : x.gubun === '%' ? '전체' : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'cltcd',
                text: '코드',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.cltcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 150,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'saupnum',
                text: '사업자번호',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.saupnum.substr(0, 3)}-{x.saupnum.substr(3, 2)}-{x.saupnum.substr(5, 5)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '대표자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.prenm}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.cltcdPopupData}
            onRowFocusEvent={(item) => scope.onCltcdPopupRowfocusEvent(item)}
            onRowDoubleClick={() => scope.onCltcdUpdated()}
          >
          </TableLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onCltcdUpdated()}
              >
                확인
              </Button>
              <Button
                onClick={() => {
                  scope.onCltcdPopupClose();
                }}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.isArtModal}
        width={600}
        height={600}
        onClose={() => scope.SS({ isArtModal: false, artModalData: [] })}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.artSearchQuery}
                onChange={(value) => scope.SS({ artSearchQuery: value })}
                onEnter={() => scope.artModalOpen()}
              >
              </TextBox>
            </FlexLayout>
          </SearchLayout>

          <GridLayout
            ref={scope.artModalGrid}
            header={[
              {
                id: 'artcd',
                text: '코드',
                width: 200,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.artcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'artnm',
                text: '코드명',
                width: 380,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.artnm}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.artModalData}
            onRowClick={(item) => scope.onArtModalRowFocus(item)}
            onRowDoubleClick={(item) => scope.onChangeArt(item)}
          >
          </GridLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onChangeArt()}>확인</Button>
              <Button
                onClick={() => scope.SS({ isArtModal: false, artModalData: [] })}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
