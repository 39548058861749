// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LayoutTitle_container__q_kan {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: var(--radius-default);\n  background-color: var(--color-primary); }\n  .LayoutTitle_container__q_kan > span {\n    color: var(--color-secondary);\n    font-size: 12px;\n    font-weight: 800; }\n  .LayoutTitle_container__q_kan.LayoutTitle_highlight__3VTM_ {\n    background-color: var(--color-secondary); }\n    .LayoutTitle_container__q_kan.LayoutTitle_highlight__3VTM_ > span {\n      color: var(--color-primary); }\n", "",{"version":3,"sources":["webpack://src/app/components/forms/LayoutTitle/LayoutTitle.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,sCAAsC,EAAA;EALxC;IAQI,6BAA6B;IAC7B,eAAe;IACf,gBAAgB,EAAA;EAVpB;IAcI,wCAAwC,EAAA;IAd5C;MAiBM,2BAA2B,EAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: var(--radius-default);\n  background-color: var(--color-primary);\n\n  & > span {\n    color: var(--color-secondary);\n    font-size: 12px;\n    font-weight: 800;\n  }\n\n  &.highlight {\n    background-color: var(--color-secondary);\n\n    & > span {\n      color: var(--color-primary);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LayoutTitle_container__q_kan",
	"highlight": "LayoutTitle_highlight__3VTM_"
};
export default ___CSS_LOADER_EXPORT___;
