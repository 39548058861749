// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HwpView_container__48SiT {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  overflow-y: scroll; }\n", "",{"version":3,"sources":["webpack://src/app/components/forms/HwpView/HwpView.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kBAAkB,EAAA","sourcesContent":[".container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HwpView_container__48SiT"
};
export default ___CSS_LOADER_EXPORT___;
