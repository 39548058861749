import { computed } from 'mobx';

/**
 * 팝업모델
 * @window w_tb_ca611_01
 * @category 입고등록
 */

export class StockPopupModel {
  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly chk: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly baldate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly balnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly balseq: string;

  /**
   *
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly cltnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly store: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly bal_remark: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty: number;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly tamt: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly deldate: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly pumnum: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly compflag: string;

  /**
   *
   *
   * **data type** varchar(3276)
   */
  readonly bigo: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly projno: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly myn: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.chk = data.chk || '0';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.baldate = data.baldate || '';
    this.balnum = data.balnum || '';
    this.balseq = data.balseq || '';
    this.cltcd = data.cltcd || '';
    this.cltnm = data.cltnm || '';
    this.store = data.store || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.perid = data.perid || '';
    this.divicd = data.divicd || '';
    this.bal_remark = data.bal_remark || '';
    this.qty = data.qty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.tamt = data.tamt || '';
    this.deldate = data.deldate || '';
    this.remark = data.remark || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.compflag = data.compflag || '';
    this.bigo = data.bigo || '';
    this.projno = data.projno || '';
    this.myn = data.myn || '';

    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
