import * as React from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  CalendarLayout,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  FormatTextBox,
  LayoutTitle,
  ModalView,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { PlanEnroll, PlanEnrollChangeTypes } from './PlanEnroll';
import { CalendarLayoutDataModel } from '../../../../models/component';
import { PlanEnrollModel } from './PlanEnroll.model';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { SearchBinding } from '../../../../models/common';
import { Date6Calculator } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e511
 * @category 당직계획등록
 */
export const PlanEnrollTemplate: React.FC<TemplateProps<PlanEnroll>> = ({
  scope,
}) => (
  <FlexLayout isVertical={true}>
    <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <FlexLayout size={200}>
        <span>월</span>
        <DateTextBox
          value={scope.state.stmon}
          textAlign="center"
          format="####년 ##월"
          onChange={(value) => {
            scope.setState({ stmon: value },
              () => scope.onRetrieveEvent());
          }}
          onEnter={() => scope.onRetrieveEvent()}
          head={(<button
            onClick={() => {
              scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add(-1).toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowLeft size={24}/>
          </button>)}
          trail={(<button
            onClick={() => {
              scope.setState({ stmon: new Date6Calculator(scope.state.stmon).add().toString() },
                () => scope.onRetrieveEvent());
            }}
          >
            <MdKeyboardArrowRight size={24}/>
          </button>)}
          isDisabledTrackingStateChange={true}
        />
      </FlexLayout>
    </SearchLayout>

    <FlexLayout>
      <CalendarLayout
        month={scope.state.stmon}
        data={scope.state.data}
        render={(c, items: CalendarLayoutDataModel<PlanEnrollModel>[]) => (
          <DefaultLayout style={{ cursor: 'pointer' }}>
            <div>
              <span style={{ color: '#47C83E' }}>완료 {scope.dayRemarks[c.data]?.done || 0} </span>
              <span style={{ color: 'var(--color-black)' }}>계획 {scope.dayRemarks[c.data]?.plan || 0} </span>
            </div>
            {items.slice(0, 6).map((x, i) => (
              <div key={i}>
                <span style={{ color: scope.getItemColor(x.data) }}>
                  [{scope.getItemPrefix(x.data)}]
                </span>
                {`${x.data.pernm} - `}
                {scope.state.gubuns?.filter((y) => y.com_code === x.data.flag)[0]?.com_cnam}
              </div>
            ))}

          </DefaultLayout>
        )}
        onDateClick={(date) => {
          // CalendarItem의 data는 여유 공간인데 CalendarLayout에서는 8자리 날짜가 들어있습니다
          scope.openWorkerModal(date.data);
        }}
      />
    </FlexLayout>

    <ModalView
      isVisible={scope.state.isWorkerModalVisible}
      onClose={() => scope.closeWorkerModal()}
      width={500}
      height={600}
      zIndex={-1}
    >
      <FlexLayout isVertical={true} style={{ padding: 8 }}>
        <LayoutTitle>당직 등록</LayoutTitle>

        <FlexLayout
          size={Global.LAYOUT_BUTTON_HEIGHT_1}
          justify='right'
        >
          <FlexLayout
            style={{ color: 'var(--color-blue)' }}
            align='center'
            justify='left'
          >
            <span>*담당팀장을 입력하면 팀원이 들어갑니다.</span>
          </FlexLayout>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.modalNew()}
          >
            추 가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.modalDelete()}
          >
            삭 제
          </Button>
        </FlexLayout>
        <FlexLayout>
          <TableLayout
            ref={scope.popupTable}
            header={[
              {
                id: 'plandate',
                text: '일자',
                width: 100,
                render: (x) => (
                  <FormatTextBox
                    textAlign="center"
                    format="####-##-##"
                    value={x.plandate}
                  />
                ),
              },
              {
                id: 'perid',
                text: '사원코드',
                width: 100,
                color: 'var(--color-red)',
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.perid}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ perid: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JA001',
                      {},
                      true,
                      (item) => {
                        rowUpdate({
                          perid: item.cd,
                          pernm: item.cdnm,
                        });
                        scope.itemChanged(PlanEnrollChangeTypes.PERID, {
                          ...x,
                          perid: item.cd,
                        });
                      },
                    )}
                  />
                ),
              },
              {
                id: 'pernm',
                text: '사원명',
                width: 100,
                render: (x, rowUpdate, ref) => (
                  <TextBox
                    ref={ref}
                    value={x.pernm}
                    textAlign="center"
                    onChange={(value) => rowUpdate({ pernm: value })}
                  />
                ),
              },
              {
                id: 'gubun',
                text: '구분',
                width: 100,
                render: (x, rowUpdate, _) => (
                  <ComboBox
                    textAlign="center"
                    value={x.gubun}
                    data={scope.state.gubuns?.map((y) => new ComboBoxModel(y.com_code, y.com_cnam))}
                    onSelect={(option) => { rowUpdate({ gubun: option.value }); }}
                  />
                ),
              },
            ]}
            data={scope.state.workerPopupList}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onChange={(rows, updatedRows) => {
              scope.onUpdatedRows(rows, updatedRows);
            }}
            onRowFocusEvent={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>

        <SubHeader>
          <FlexLayout justify="end">
            <Button onClick={() => scope.modalSave()}>저장</Button>
            <Button onClick={() => scope.closeWorkerModal()}>닫기</Button>
          </FlexLayout>
        </SubHeader>
      </FlexLayout>
    </ModalView>
  </FlexLayout>
);
