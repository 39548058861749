import * as React from 'react';
import { action } from 'mobx';
import { WorkReportTemplate } from './WorkReport.template';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { DateStabilizer, Today } from '../../../../utils/time';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
} from '../../../../constants';
import {
  WorkReportDetailModel,
  WorkReportModel,
  WorkReportPopupModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils';

export enum WorkReportItemChangeTypes {
  RPTDATE,
  TELPERID,
  CONTCD,
  RESUCD,
  RECETIME,
  COMTIME,
  ACTCD,
  EQUPCD,
  PERID,
  PERID_OK,
}

export enum WorkReportItemChangeTypeNames {
  rptdate,
  telperid,
  contcd,
  resucd,
  recetime,
  comptime,
  actcd,
  equpcd,
  perid,
  perid_ok,
}

interface WorkReportState {

  // DropDown
  contcds?: Array<any>; // 고장내용
  resucds?: Array<any>; // 처리내용
  gregicds?: Array<any>; // 고장부위
  regicds?: Array<any>; // 고장부위상세
  remocds?: Array<any>; // 고장요인
  faccds?: Array<any>; // 고장원인
  resultcds?: Array<any>; // 결과

  // 검색 조건
  stmon: string;
  stdate: string;
  enddate: string;

  // 데이터 객체
  focusedWorkReport?: WorkReportModel;
  focusedWorkReportDetail?: WorkReportDetailModel;
  focusedWorkReportPopup?: WorkReportPopupModel;
  workerPopupDate: string;
  data: WorkReportModel;
  lastNewData: WorkReportModel;
  workReportList: Array<WorkReportModel>;
  workReportDetailList: Array<WorkReportDetailModel>;
  workReportPopupList: Array<WorkReportPopupModel>;

  isReported: boolean; // 결재상신 여부
  workerModal: boolean;

  focusIndex: number;
  chk: string;

  // 당직 구분
  flags: Array<any>;
}

/**
 * 컨트롤러
 * @window w_tb_e512
 * @category 당직근무보고서
 */
export class WorkReport extends PageComponent<PageProps, WorkReportState>
  implements PageToolEvents {
  updatedRows?: Array<WorkReportDetailModel>;

  updatedRows2?: Array<WorkReportPopupModel>;

  grid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month: string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date: string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      stdate: `${year}${month}01`,
      enddate: `${year}${month}${date}`,
      stmon: Today.yearMon(),
      data: new WorkReportModel(),
      workReportList: [],
      workReportDetailList: [],
      isReported: false,

      contcds: [],
      resucds: [],
      gregicds: [],
      regicds: [],
      remocds: [],
      faccds: [],
      resultcds: [],

      workerModal: false,

      focusIndex: 0,
      chk: '0',

      // 당직 구분
      flags: [],
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }
      this.modalItemChanged(WorkReportItemChangeTypes.PERID_OK);
    }
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const data:any = {
      contcds: await api.dropdown('wf_dd_e010'),
      resucds: await api.dropdown('wf_dd_e012'),
      gregicds: await api.dropdown('wf_dd_e013'),
      regicds: await api.dropdown('wf_dd_e014'),
      remocds: await api.dropdown('wf_dd_e011'),
      faccds: await api.dropdown('wf_dd_e019'),
      resultcds: await api.dropdown('wf_dd_e015'),
      flags: await api.dropdown('wf_dd_ca510_025_01'),
    };

    this.setState({
      contcds: data.contcds.items,
      resucds: data.resucds.items,
      gregicds: data.gregicds.items,
      regicds: data.regicds.items,
      remocds: data.remocds.items,
      faccds: data.faccds.items,
      resultcds: data.resultcds.items,
      flags: data.flags.items,
    });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stmon: this.state.stmon,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          workReportList: [...this.state.workReportList, ...items],
        }, next);
      },
      async () => {
        await this.SS({
          workReportList: [],
        });

        await this.infinity?.retrieveAll();

        this.state.workReportList.length > 0
          && this.grid.current?.setFocus(0);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.data;
    this.setState({
      workReportList: [],
      workReportDetailList: [],
      data: new WorkReportModel(),
    }, async () => {
      const index = await this.infinity?.retrieveTo(['rptdate', 'telpernm'],
        [lastSelected?.rptdate, lastSelected?.telpernm], type, true) || 0;
      // this.state.workReportList.length > 0
      // && this.onRowFocusEvent(this.state.workReportList[this.state.focusIndex], this.state.focusIndex);
      // if (this.state.chk === '1') {
      //   this.grid.current?.setFocus(this.state.focusIndex);
      //   this.setState({ chk: '0' });
      // } else {
      //   this.grid.current?.setFocus(0);
      // }
      this.state.workReportList.length > 0 && this.grid.current?.setFocus(index);
    });
  }

  @action
  onRowFocusEvent(item: WorkReportModel, index: number) {
    if (item?.new === '1') {
      const { lastNewData } = this.state;
      this.setState({
        focusedWorkReportDetail: undefined,
        workReportDetailList: [],
        isReported: false,
        data: lastNewData,
      });
      this.table.current?.update(false);
      return;
    }

    const { actionStore: api } = this.props;
    this.updatedRows = [];

    this.setState(
      {
        focusedWorkReport: item,
        focusIndex: index,
      },
      async () => {
        const data = await api.exec(Category.EMAIL, 'dw_1_RowFocuschanged', this.state.focusedWorkReport);
        data && this.setState({ data: new WorkReportModel(data) });

        this.infinity2 = new InfinityRetrieve({
          ...item,
        },
        (params) => api.fxExec('dw_1_RowFocuschanged', params),
        (items) => {
          this.setState({
            workReportDetailList: items.map((x: any) => (
              // 순번 생성
              new WorkReportDetailModel({
                ...x,
                // eslint-disable-next-line no-nested-ternary
                // seq: index < 10 ? `00${index + 1}` : index < 100 ? `0${index + 1}` : (index + 1).toString(),
              })
            )),
          });
        },
        async () => {
          await this.SS({ workReportDetailList: [] });
          await this.infinity2?.retrieveAll();
          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        });

        // 결재상신 여부확인
        if (this.state.data.appgubun === null
          || this.state.data.appgubun === ''
          || this.state.data.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }
        this.table.current?.update(false);

        if (!this.infinity2.count) {
          this.setState({ workReportDetailList: [] }, () => this.table.current?.update());
        }

        await this.infinity2?.retrieveAll();
        await this.table.current?.update();
        this.table.current?.setFocus(0, 0);
      },
    );
  }

  @action
  onRowFocusEvent2(item:WorkReportDetailModel) {
    this.setState({ focusedWorkReportDetail: item });
  }

  @action
  onRowFocusEvent3(item:WorkReportPopupModel) {
    this.setState({ focusedWorkReportPopup: item });
  }

  @action
  async itemChanged(type: number, date?: any) {
    const { actionStore: api } = this.props;
    let data;
    const params = {
      itemname: WorkReportItemChangeTypeNames[type],
      new: this.state.data.new,
    };
    if (type === WorkReportItemChangeTypeNames.rptdate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: date,
          telperid: this.state.data.telperid,
          telpernm: this.state.data.telpernm,
          saferemark: this.state.data.saferemark,
        },
      );

      if (data?.items) {
        this.setState({
          workReportDetailList: data?.items.map((x: any, index: number) => (
            // 순번 생성
            new WorkReportDetailModel({
              ...x,
              // eslint-disable-next-line no-nested-ternary
              seq: index < 9 ? `00${index + 1}` : index < 99 ? `0${index + 1}` : (index + 1).toString(),
            })
          )),
        }, () => this.table.current?.update(false));
      } else {
        this.setState({
          workReportDetailList: [],
        }, () => this.table.current?.update(false));
      }
    }

    if (type === WorkReportItemChangeTypeNames.telperid) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: this.state.data.telperid,
        },
      );
    }

    data && this.setState({
      data: new WorkReportModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  async detailItemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.workReportDetailList.findIndex((x) => x.seq === item.seq);
    const params = {
      itemname: WorkReportItemChangeTypeNames[type],
      rptdate: this.state.data.rptdate,
      data: item,
      new: this.state.focusedWorkReportDetail?.new,
    };
    let data = { new: undefined };

    data = await api.fxExec(
      'dw_3_itemchanged',
      params,
    );

    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
    data && this.setState({
      focusedWorkReportDetail: new WorkReportDetailModel({
        ...this.state.focusedWorkReportDetail,
      }, false),
      // eslint-disable-next-line max-len
      workReportDetailList: this.state.workReportDetailList.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new WorkReportDetailModel({ ...x, ...data }, data.new === '1') : new WorkReportDetailModel(x)
      )),
    }, () => this.table.current?.update(false));
  }


  @action
  async modalItemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;
    if (item) this.setState({ focusedWorkReportPopup: item });
    // eslint-disable-next-line radix
    const day:number = parseInt(this.state.data.rptdate.substring(6, 8));
    let data = { items: [] };
    const params = {
      itemname: WorkReportItemChangeTypeNames[type],
      new: this.state.focusedWorkReportPopup?.new,
    };

    if (type === WorkReportItemChangeTypeNames.perid) {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          sub: 'w_popup_e514',
          ...params,
          data: item.perid,
        },
      );
    }

    if (type === WorkReportItemChangeTypeNames.perid_ok) {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          sub: 'w_popup_e514',
          ...params,
          perid: this.state.focusedWorkReportPopup?.perid,
          stmon: this.state.stmon,
          date: day.toString(),
          data: '',
        },
      );

      // 팀원 가져오기
      data?.items && this.setState({
        // eslint-disable-next-line max-len
        workReportPopupList: data?.items.map((x: any) => new WorkReportPopupModel({ ...x, rptdate: this.state.data.rptdate })),
      }, () => this.popupTable.current?.update(false));
    }
  }

  // 결재상신
  @action
  async onReport() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;
    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.WORKNIGHT);

    // 결재상신
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.WORKNIGHT.toString(),
      ...this.state.data,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    // 결재 상신된 청구서
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    if (this.state.data?.rptdate === '') {
      ConfirmWarning.show('경고', '작성일자를 선택해주십시오');
      return;
    }

    if (await api.save({
      ...this.state.data,
      rptdate: this.state.data.rptdate || Today.date(),
      items: this.state.workReportDetailList,
    }, this.state.data.new === '1')) {
      this.updatedRows = [];
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, this.state.data.rptdate);
      this.setState({
        chk: '1',
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();
    if (data) {
      const newModel = new WorkReportModel(data, true);
      this.setState({
        data: newModel,
        lastNewData: newModel,
        workReportList: [
          newModel,
          ...this.state.workReportList,
        ],
        workReportDetailList: [],
        focusedWorkReport: newModel,
        focusIndex: 0,
      }, async () => {
        await this.table.current?.update(false);
        await this.grid.current?.setFocus(0);
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;

    const text = `청구일자: ${this.state.focusedWorkReport?.rptdate}`;
    await api.delete(text, this.state.data);
    (this.state.focusedWorkReport?.appgubun === '001' || this.state.focusedWorkReport?.appgubun === '121'
    || this.state.focusedWorkReport?.appgubun === '131' || this.state.focusedWorkReport?.appgubun === '')
      ? await this.onRetrieveEvent(RetrieveFocusType.FIRST) : await this.onDelete2Event();
  }

  @action
  async onDelete2Event() {
    const { actionStore: api } = this.props;
    let alert: boolean;
    // eslint-disable-next-line max-len,prefer-const
    alert = await Confirm.show('확인', '이미 결재가 상신되어있는 문서입니다. 다른이(상급자)가 이문서에 대해 승인이 있습니다. 그래도 상신취소 하시겠습니까?', ConfirmType.QUESTION);
    if (!alert) return;

    const data = await api.fxExec(
      'delete_appok',
      {
        rptdate: this.state.data?.rptdate,
        appnum: this.state.data?.appnum,
        appgubun: this.state.data?.appgubun,
      },
    );
    await data && this.onRetrieveEvent();
  }

  @action
  async onNewEvent2() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        rptdate: this.state.data.rptdate || Today.date(),
        seq: this.state.focusedWorkReportDetail?.seq,
        stdate: this.state.focusedWorkReportDetail?.recedate,
        enddate: this.state.focusedWorkReportDetail?.compdate,
        title: this.state.focusedWorkReportDetail?.title,
      },
    );

    const seq = this.state.workReportDetailList.length;
    data && this.setState({
      workReportDetailList: [...this.state.workReportDetailList,
        new WorkReportDetailModel({
          // eslint-disable-next-line no-nested-ternary
          ...data, seq: seq < 9 ? `00${seq + 1}` : seq < 99 ? `0${seq + 1}` : (seq + 1).toString(),
        }, true)],
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.workReportDetailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.workReportDetailList.length === 0) {
      ConfirmWarning.show('경고', '삭제할 내용이 없습니다.');
      return;
    }
    const text = `${this.state.focusedWorkReportDetail?.seq}`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        new: this.state.focusedWorkReportDetail?.new,
        rptdate: this.state.data?.rptdate,
        recedate: this.state.focusedWorkReportDetail?.recedate,
        recenum: this.state.focusedWorkReportDetail?.recenum,
        seq: this.state.focusedWorkReportDetail?.seq,
        appgubun: this.state.data?.appgubun,

      },
    ) && this.onRowFocusEvent(this.state.focusedWorkReport!, this.state.focusIndex);
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.focusedWorkReportDetail?.recenum, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      rptdate: this.state.data?.rptdate,
      seq: this.state.focusedWorkReportDetail?.seq,
    });
  }

  // /**
  //  * 행 변경 이벤트
  //  * @param rows      전체 행 (변경 행 반영된 상태)
  //  * @param updatedRows 변경 행들만
  //  */
  @action
  onUpdatedRows(rows: Array<WorkReportDetailModel>, updatedRows: Array<WorkReportDetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ workReportDetailList: rows });
  }

  @action
  onUpdatedRows2(rows2: Array<WorkReportPopupModel>, updatedRows2: Array<WorkReportPopupModel>) {
    this.updatedRows2 = updatedRows2;
    this.setState({ workReportPopupList: rows2 });
  }

  @action
  async openWorkerModal(isOpen: boolean) {
    if (!this.state.data.rptdate) {
      ConfirmWarning.show('경고', '먼저 작성일자를 입력하세요.');
      return;
    }

    // 해당 문서 저장 후 retrieve할 수 있게 경고창.
    // outnum 가져오기 위해
    if (this.state.focusedWorkReport?.isNew) {
      ConfirmWarning.show('경고', '먼저 저장 후 선택해주세요');
      return;
    }

    this.setState({
      workerModal: isOpen,
    });
    isOpen && await this.modalRetrieve();
  }

  @action
  async modalRetrieve() {
    const { actionStore: api } = this.props;

    this.infinity3 = new InfinityRetrieve(
      {
        sub: 'w_popup_e514',
        outdate: this.state.data.rptdate,
        rptdate: this.state.data.rptdate,
        appnum: this.state.data.appnum,
      },
      (params) => api.retrieve(params),
      (items) => {
        this.setState({
          workReportPopupList: [...this.state.workReportPopupList, ...items],
        }, () => this.popupTable.current?.update(true));
      },
      async () => {
        await this.SS({ workReportPopupList: [] });
        await this.infinity3?.retrieveAll();
        this.popupTable.current?.update();
        this.popupTable.current?.setFocus(0, 1);
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      workReportPopupList: [],
    }, async () => {
      await this.infinity3?.retrieveAll();
      this.popupTable.current?.update();
      this.popupTable.current?.setFocus(0, 1);
    });
  }


  @action
  async modalDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_e514',
      seq: this.state.focusedWorkReportPopup?.seq,
      rptdate: this.state.focusedWorkReportPopup?.rptdate,
    }) && await this.modalRetrieve();

    this.updatedRows2 = [];
  }

  @action
  async modalSave() {
    const { actionStore: api } = this.props;

    if (this.state.workReportPopupList.length < 1) {
      await this.openWorkerModal(false);
      return;
    }

    const data = await api.fxExec('save',
      {
        sub: 'w_popup_e514',
        items: this.state.workReportPopupList,
        rptdate: this.state.data.rptdate,
      });

    if (data) {
      await this.openWorkerModal(false);
      await this.onRowFocusEvent(this.state.focusedWorkReport!, this.state.focusIndex);
      this.updatedRows2 = [];
    }
  }


  @action
  async modalNew() {
    const { actionStore: api } = this.props;
    const seq = this.state.workReportPopupList.length + 1;
    const data = await api.fxExec('new',
      {
        sub: 'w_popup_e514',
        rptdate: this.state.data.rptdate,
        appnum: this.state.data.appnum,
        outdate: this.state.data.rptdate,
        outnum: this.state.focusedWorkReport?.appnum,
      });

    if (data) {
      // eslint-disable-next-line max-len
      const newModel = new WorkReportPopupModel({
        ...data,
        seq: seq.toString(),
      }, true);

      this.setState({
        workReportPopupList: [
          newModel,
          ...this.state.workReportPopupList,
        ],
        focusedWorkReportPopup: newModel,
      }, async () => {
        await this.popupTable.current?.update(false);
        this.popupTable.current?.setFocus(0, 1);
      });
    }
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  render() {
    return (
      <WorkReportTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new WorkReportModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
