// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CircleBadge_container__19I1F {\n  overflow: hidden;\n  display: flex;\n  position: absolute;\n  left: calc(50% + 14px);\n  height: 19px;\n  border-radius: 32px;\n  color: #FFFFFF;\n  font-size: 11px;\n  -webkit-transform: translateY(-6px);\n          transform: translateY(-6px);\n  opacity: 0.85; }\n  .CircleBadge_container__19I1F > span {\n    flex: 1 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-width: 19px;\n    background-color: var(--color-secondary); }\n", "",{"version":3,"sources":["webpack://src/app/components/layout/CircleBadge/CircleBadge.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,mCAA2B;UAA3B,2BAA2B;EAC3B,aAAa,EAAA;EAVf;IAaI,SAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,wCAAwC,EAAA","sourcesContent":[".container {\n  overflow: hidden;\n  display: flex;\n  position: absolute;\n  left: calc(50% + 14px);\n  height: 19px;\n  border-radius: 32px;\n  color: #FFFFFF;\n  font-size: 11px;\n  transform: translateY(-6px);\n  opacity: 0.85;\n\n  & > span {\n    flex: 1 0 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-width: 19px;\n    background-color: var(--color-secondary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CircleBadge_container__19I1F"
};
export default ___CSS_LOADER_EXPORT___;
