import { computed } from 'mobx';

/**
 * 모델
 * @window w_s004
 * @category 계좌번호등록
 */
export class AccountModel {
  /**
   * 회사코드
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   * 사업장코드
   *
   * **data type** char(2)
   */
  readonly spjangcd: string;

  /**
   * 금융기관
   *
   * **data type** varchar(2)
   */
  readonly bank: string;

  /**
   * 은행코드
   *
   * **data type** varchar(6)
   */
  readonly bankcd: string;

  /**
   * 계좌번호
   *
   * **data type** varchar(20)
   */
  readonly accnum: string;

  /**
   * 금융기관명
   *
   * **data type** varchar(20)
   */
  readonly banknm: string;

  /**
   * 시작일
   *
   * **data type** varchar(8)
   */
  readonly contdate: string;

  /**
   * 만기일
   *
   * **data type** varchar(8)
   */
  readonly expedate: string;

  /**
   * X
   *
   * **data type** float
   */
  readonly intrate: string;

  /**
   * 관리코드
   *
   * **data type** varchar(5)
   */
  readonly bankcd5: string;

  /**
   * 계정
   *
   * **data type** varchar(4)
   */
  readonly acccd: string;

  /**
   * 계정명
   *
   * **data type** varchar(20)
   */
  readonly accnm: string;

  /**
   * X
   *
   * **data type** varchar(60)
   */
  readonly deposit: string;

  /**
   * 계좌명칭
   *
   * **data type** varchar(50)
   */
  readonly ACCNAME: string;

  /**
   * 구분
   *
   * **data type** varchar(50)
   */
  readonly bankflag: string;

  /**
   * 기본
   *
   * **data type** char(1)
   */
  readonly basic: string;

  /**
   * 사용유무
   *
   * **data type** char(1)
   */
  readonly useyn: string;

  /**
   * 수수료
   *
   * **data type** float
   */
  readonly mijamt: string;

  /**
   * 계좌연동채크
   *
   * **data type** varchar(1)
   */
  readonly signflag: string;

  /**
   * 계좌연동번호
   *
   * **data type** varchar(100)
   */
  readonly signkey: string;

  /**
   * 계좌연동비밀번호
   *
   * **data type** varchar(100)
   */
  readonly signpass: string;

  /**
   * 사용마지막일
   *
   * **data type** varchar(8)
   */
  readonly lastdate: string;

  /**
   * 사용마지막시간
   *
   * **data type** varchar(8)
   */
  readonly lasttime: string;

  /**
   * 계좌연동아이디
   *
   * **data type** varchar(100)
   */
  readonly signkey2: string;

  /**
   * 계좌연동아이디비번
   *
   * **data type** varchar(100)
   */
  readonly signpass2: string;

  /**
   * 연동사유(결과)
   *
   * **data type** varchar(255)
   */
  readonly bankda_message: string;

  /**
   * 연동일
   *
   * **data type** datetime
   */
  readonly bankda_date: string;

  /**
   * 계좌연동해지구분
   *
   * **data type** varchar(1)
   */
  readonly clsflag: string;

  // /**
  //  * 계좌연동해지일
  //  *
  //  * **data type** varchar(8)
  //  */
  readonly clsdate: string;

  /**
   * 지로
   *
   * **data type** varchar(1)
   */
  readonly giro: string;

  /**
   * CMS
   *
   * **data type** varchar(1)
   */
  readonly cms: string;

  /**
   * ??
   *
   * **data type** varchar(1)
   */
  readonly signchk: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.bank = data.bank || '';
    this.bankcd = data.bankcd || '';
    this.accnum = data.accnum || '';
    this.banknm = data.banknm || '';
    this.contdate = data.contdate || '';
    this.expedate = data.expedate || '';
    this.intrate = data.intrate || '';
    this.bankcd5 = data.bankcd5 || '';
    this.acccd = data.acccd || '';
    this.accnm = data.accnm || '';
    this.deposit = data.deposit || '';
    this.ACCNAME = data.ACCNAME || '';
    this.bankflag = data.bankflag || '';
    this.basic = data.basic || '';
    this.useyn = data.useyn || '';
    this.mijamt = data.mijamt || '';
    this.signflag = data.signflag || '';
    this.signkey = data.signkey || '';
    this.signpass = data.signpass || '';
    this.lastdate = data.lastdate || '';
    this.lasttime = data.lasttime || '';
    this.signkey2 = data.signkey2 || '';
    this.signpass2 = data.signpass2 || '';
    this.bankda_message = data.bankda_message || '';
    this.bankda_date = data.bankda_date || '';
    this.clsflag = data.clsflag || '';
    this.clsdate = data.clsdate || '';
    this.giro = data.giro || '';
    this.cms = data.cms || '';
    this.signchk = data.signchk || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
