import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  Category,
  RetrieveFocusType,
} from '../../../../constants';
import {
  DepositInputModel,
  DepositInputDetail2Model,
  DepositInputDetailModel,
} from './models';
import { GridLayout, TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models/common';

import { ConfirmWarning } from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import { DepositInputTemplate } from './DepositInput.template';
import { Format } from '../../../../utils/string';
import { Date8, DateStabilizer } from '../../../../utils/time';

export enum ItemChange {
  rcvdate,
  bamt,
  jamt,
  jmar,
  dcamt,
  eamt,
  samt,
  damt,
  hchk,
  bchk,
  jchk,
  echk,
  schk,
  dchk,
  gchk,
  dcchk,
}

export enum BtnGubun {
  b_bankcd,
  b_jbankcd,
}

interface ExpenseState {
  // 검색조건
  stdate: string;
  enddate: string;
  cltcd: string,
  cltnm: string,

  // data
  depositList: Array<DepositInputModel>;
  depositDetailList: Array<DepositInputDetailModel>;
  depositDetailList2: Array<DepositInputDetail2Model>;
  focusedDeposit?: DepositInputDetailModel;
  focused?: DepositInputModel;
  focusIndex: number;
  data: DepositInputModel;
  gubun?: Array<any>;
  gubuns: Array<any>;
  searchGubun?: string;
  openChk: boolean;

  // trail
  misamt_tot: string;
  janamt_tot: string;
  inamt_tot: string;
  winamt_tot: string;
  wbamt_tot: string;
  iamt_tot: string;
  bamt_tot: string;
  jamt_tot: string;
  jmar_tot: string;
  hamt_tot: string;
  wjamt_tot: string;
  iamt_tot2: string;
}

/**
 * 컨트롤러
 * @window w_input_da026_all
 * @category 입금입력
 */
export class DepositInput extends PageComponent<PageProps, ExpenseState>
  implements PageToolEvents {
  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  grid2: React.RefObject<GridLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      // search
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      cltcd: '',

      // data
      focusIndex: 0,
      depositList: [],
      depositDetailList: [],
      depositDetailList2: [],
      data: new DepositInputModel(),
      searchGubun: '1',
      openChk: false,

      gubuns: [],

      // trail
      misamt_tot: '0',
      janamt_tot: '0',
      inamt_tot: '0',
      winamt_tot: '0',
      wbamt_tot: '0',
      iamt_tot: '0',
      bamt_tot: '0',
      jamt_tot: '0',
      jmar_tot: '0',
      hamt_tot: '0',
      wjamt_tot: '0',
      iamt_tot2: '0',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 기타구분
    const data = await api.dropdown('wf_dd_ca510_201_01');
    if (!data) return;
    this.setState({ gubun: data.items });

    // 매출구분
    const data2 = await api.dropdown('wf_da020_code');
    if (!data2) return;
    this.setState({ gubuns: data2?.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    if (!this.state.cltcd && this.state.openChk) {
      ConfirmWarning.show('확인', '거래처를 선택하세요');
      return;
    }

    // 위 아래 동시 호출
    // setTimeout(() => this.onRetrieveEvent2());

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.cltcd,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          depositList: [
            ...this.state.depositList,
            ...items.map((x: any) => new DepositInputModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          depositList: [],
          depositDetailList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.depositList && this.state.depositList?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 3);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      focusIndex: 0,
      depositList: [],
      depositDetailList: [],
      depositDetailList2: [],
      openChk: true,
      data: new DepositInputModel(),
    }, async () => {
      await this.table?.current?.update(true);
      this.grid?.current?.forceUpdate();
      this.grid2?.current?.forceUpdate();

      const index = await this.infinity?.retrieveTo(['misdate', 'misnum'],
        [this.state.focused?.misdate, this.state.focused?.misnum], type, true) || 0;

      if (this.state.depositList && this.state.depositList.length > index) {
        await this.table.current?.update();
        this.table.current?.setFocus(index, 3);
        await this.onMainRowFocusEvent(this.state.depositList[0], 0);
      }

      await this.SS({
        misamt_tot: this.infinity?.box?.misamt_tot || '0',
        janamt_tot: this.infinity?.box?.janamt_tot || '0',
        inamt_tot: this.infinity?.box?.inamt_tot || '0',
        winamt_tot: this.infinity?.box?.winamt_tot || '0',
        wbamt_tot: this.infinity?.box?.wbamt_tot || '0',
      });
    });

    await this.onRetrieveEvent2();
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.cltcd,
      },
      (params) => api.fxExec('retrieve2', params),
      (items) => {
        this.setState({
          depositDetailList: [
            ...this.state.depositDetailList,
            ...items.map((x: any) => new DepositInputDetailModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          depositDetailList: [],
          depositDetailList2: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.depositDetailList && this.state.depositDetailList?.length > 0) {
          await this.grid.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      depositDetailList: [],
      depositDetailList2: [],
    });

    await this.infinity2?.retrieveAll();
    if (this.state.depositDetailList && this.state.depositDetailList?.length > 0) {
      await this.grid.current?.setFocus(0);
    } else {
      await this.onNewEvent();
    }

    await this.SS({
      iamt_tot: this.infinity2?.box?.iamt_tot || '0',
    });
  }

  @action
  async onMainRowFocusEvent(item: any, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  async onRowFocusEvent(item: any) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity3 = new InfinityRetrieve(
      {
        cltcd: item?.cltcd,
        rcvdate: item?.rcvdate,
        rcvnum: item?.rcvnum,
      },
      (params) => api.fxExec('dw_3_RowFocuschanged', params),
      (items) => {
        this.setState({
          depositDetailList2: [
            ...this.state.depositDetailList2,
            ...items.map((x: any) => new DepositInputDetail2Model(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          depositDetailList2: [],
        });

        await this.infinity3?.retrieveAll();
        if (this.state.depositDetailList2 && this.state.depositDetailList2?.length > 0) {
          await this.grid2.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      depositDetailList2: [],
      focusedDeposit: item,
    }, async () => {
      await this.infinity3?.retrieveAll();
      if (this.state.depositDetailList2 && this.state.depositDetailList2?.length > 0) {
        await this.grid2.current?.setFocus(0);
      }

      this.setState({
        data: new DepositInputModel({
          ...this.state.data,
          ...this.infinity3?.box,
        }, true),
        bamt_tot: this.infinity3?.box.bamt_tot,
        jamt_tot: this.infinity3?.box.jamt_tot,
        jmar_tot: this.infinity3?.box.jmar_tot,
        hamt_tot: this.infinity3?.box.hamt_tot,
        wjamt_tot: this.infinity3?.box.wjamt_tot,
        iamt_tot2: this.infinity3?.box.iamt_tot,
      });
    });
  }

  @action
  async onItemChanged(datas: any, name: any) {
    const { actionStore: api } = this.props;

    let itemData = {};

    switch (ItemChange[name]) {
      case 'jmar':
        itemData = {
          jamt: this.state.data.tjamt || '0',
          jmar: datas,
        };
        break;

      case 'dcamt':
        itemData = {
          damt: this.state.cltcd,
        };
        break;

      default:
        itemData = {
          cltcd: this.state.cltcd,
        };
    }

    const data = await api.fxExec(
      'dw_1_itemchanged',
      {
        new: this.state.data?.isNew ? '1' : '0',
        itemname: ItemChange[name],
        data: datas,
        ...itemData,
      },
      false,
      true,
    );

    data && this.setState({
      data: new DepositInputModel({
        ...this.state.data,
        ...data,
      }, true),
    });
  }

  @action
  updateTotal() {
    const ld_hamt = Format.toNumber(this.state.data?.hamt);
    const ld_bamt = Format.toNumber(this.state.data?.bamt);
    const ld_jamt = Format.toNumber(this.state.data?.jamt);
    const ld_jmar = Format.toNumber(this.state.data?.jmar);
    const ld_eamt = Format.toNumber(this.state.data?.eamt);
    const ld_samt = Format.toNumber(this.state.data?.samt);
    const ld_damt = Format.toNumber(this.state.data?.damt);
    const ld_gamt = Format.toNumber(this.state.data?.gamt);
    const ld_dcamt = Format.toNumber(this.state.data?.dcamt);
    const ld_total_amt = ld_hamt + ld_bamt + ld_jamt + ld_jmar + ld_eamt + ld_samt + ld_damt + ld_gamt + ld_dcamt;

    this.setState({
      data: new DepositInputModel({
        ...this.state.data,
        total_amt: ld_total_amt.toString(10),
      }, true),
    });
  }

  @action
  async onButtonclick(datas: any, name: any) {
    const { actionStore: api } = this.props;

    let itemData = {};

    switch (BtnGubun[name]) {
      case 'b_bankcd':
        itemData = {
          bankcd: datas,
        };
        break;

      default:
        itemData = {
          jbankcd: datas,
        };
        break;
    }

    const data = await api.fxExec('dw_1_buttonclicked',
      {
        new: this.state.data?.isNew === true ? '1' : '0',
        cltcd: this.state.cltcd,
        rcvnum: this.state.data.rcvnum,
        rcvdate: this.state.data.rcvdate,
        itemname: BtnGubun[name],
        data: datas,
        ...itemData,
      });

    data && this.setState({
      data: new DepositInputModel({
        ...this.state.data,
        ...data,
      }, true),
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      cltcd: this.state.focusedDeposit?.cltcd,
      rcvdate: this.state.focusedDeposit?.rcvdate,
      rcvnum: this.state.focusedDeposit?.rcvnum,
    });

    data && this.setState({
      data: new DepositInputModel({
        ...data,
      }, true),
      depositDetailList: [],
      depositDetailList2: [],
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    if (!this.state.data.jamt && this.state.data.jchk === '1') {
      ConfirmWarning.show('확인', '지로은행을 입력해주세요.');
      return;
    }

    if (!this.state.data.cardcd && this.state.data.dchk === '1') {
      ConfirmWarning.show('확인', '카드은행을 입력해주세요.');
      return;
    }

    const dataChk = this.state.data;
    dataChk.bamt = this.state.data.bamt.replace(',', '');
    dataChk.bmar = this.state.data.bmar.replace(',', '');
    dataChk.total_amt = this.state.data.total_amt.replace(',', '');
    dataChk.hamt = this.state.data.hamt.replace(',', '');
    dataChk.gamt = this.state.data.gamt.replace(',', '');
    dataChk.cltcd = this.state.cltcd;
    dataChk.cltnm = this.state.cltnm;

    if (await api.save({
      ...dataChk,
      cltcd: this.state.cltcd,
      cltnm: this.state.cltnm,
      items: this.state.depositList,
    }, true)) {
      await this.table.current?.resetUpdates();

      const { stdate, enddate } = DateStabilizer.get(this.state.stdate, this.state.enddate, dataChk.rcvdate);
      await this.SS({ stdate, enddate });
    }
    this.onRetrieveEvent();
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `입금일자: ${Date8.withDash(this.state.focusedDeposit?.rcvdate)}\n입금번호: ${this.state.focusedDeposit?.rcvnum}`;
    await api.delete(text, this.state.focusedDeposit) && this.onRetrieveEvent(RetrieveFocusType.END);
  }

  @action
  async onUpdatedRows(rows: Array<DepositInputModel>) {
    if (!this.state.data.perid) {
      ConfirmWarning.show('오류', '입금담당자를 우선 선택해주세요');
      this.table.current?.update(true);
      return;
    }

    if (!this.state.data.accnum && this.state.data.bchk === '1') {
      ConfirmWarning.show('오류', '입금은행을 우선 선택해주세요');
      this.table.current?.update(true);
      return;
    }

    await this.setState({ depositList: rows }, () => {
      this.itemchanged(this.state.depositList);
    });
  }

  @action
  async itemchanged(dw: Array<DepositInputModel>) {
    let items = dw.sort((a, b) => (a.winamt < b.winamt ? 1 : a.winamt > b.winamt ? -1 : 0));

    items = items.map((x) => new DepositInputModel({
      ...x,
      winamt: x.chk === '1' ? x.winamt : '0',
      whamt: x.chk === '1' ? x.whamt : '0',
      wbamt: x.chk === '1' ? x.wbamt : '0',
      wjamt: x.chk === '1' ? x.wjamt : '0',
      wjmar: x.chk === '1' ? x.wjmar : '0',
      weamt: x.chk === '1' ? x.weamt : '0',
      wsamt: x.chk === '1' ? x.wsamt : '0',
      wdamt: x.chk === '1' ? x.wdamt : '0',
      wgamt: x.chk === '1' ? x.wgamt : '0',
      wdcamt: x.chk === '1' ? x.wdcamt : '0',
    }, x.isNew));

    let ld_hamt = Format.toNumber(this.state.data?.hamt);
    let ld_bamt = Format.toNumber(this.state.data?.bamt);
    let ld_jamt = Format.toNumber(this.state.data?.jamt);
    let ld_jmar = Format.toNumber(this.state.data?.jmar);
    const ld_eamt = Format.toNumber(this.state.data?.eamt);
    const ld_samt = Format.toNumber(this.state.data?.samt);
    let ld_damt = Format.toNumber(this.state.data?.damt);
    let ld_gamt = Format.toNumber(this.state.data?.gamt);
    let ld_dcamt = Format.toNumber(this.state.data?.dcamt);
    let ld_total_amt = ld_hamt + ld_bamt + ld_jamt + ld_jmar + ld_eamt + ld_samt + ld_damt + ld_gamt + ld_dcamt;

    items = items.map((x) => {
      if (x.chk === '1') {
        if (ld_total_amt === 0) return x;

        const winamt = Format.toNumber(x.winamt);
        if (winamt > 0) {
          ld_hamt -= Format.toNumber(x.whamt);
          ld_bamt -= Format.toNumber(x.wbamt);
          ld_jamt -= Format.toNumber(x.wjamt);
          ld_damt -= Format.toNumber(x.wdamt);
          ld_gamt -= Format.toNumber(x.wgamt);
          ld_dcamt -= Format.toNumber(x.wdcamt);
          ld_total_amt -= winamt;
          return x;
        }

        const n = new DepositInputModel(x, x.isNew);

        let ld_janamt = Format.toNumber(n.janamt);
        if (ld_hamt !== 0) {
          if (ld_janamt < ld_hamt) { // 현금
            n.winamt = (ld_janamt).toString(10);
            n.whamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_hamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (ld_hamt).toString(10);
            n.whamt = ld_hamt.toString(10);
            ld_total_amt -= ld_hamt;
            ld_janamt -= ld_hamt;
            ld_hamt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_bamt !== 0) {
          if (ld_janamt < ld_bamt) { // 예금
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wbamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_bamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_bamt).toString(10);
            n.wbamt = ld_bamt.toString(10);
            ld_total_amt -= ld_bamt;
            ld_janamt -= ld_bamt;
            ld_bamt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_jamt !== 0) {
          if (ld_janamt < ld_jamt) { // 지로
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wjamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_jamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_jamt).toString(10);
            n.wjamt = ld_jamt.toString(10);
            ld_total_amt -= ld_jamt;
            ld_janamt -= ld_jamt;
            ld_jamt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_jmar !== 0) {
          if (ld_janamt < ld_jmar) { // 지로 수수료
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wjmar = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_jmar -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_jmar).toString(10);
            n.wjmar = ld_jmar.toString(10);
            ld_total_amt -= ld_jmar;
            ld_janamt -= ld_jmar;
            ld_jmar = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_damt !== 0) {
          if (ld_janamt < ld_damt) { // 카드대금
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wdamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_damt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_damt).toString(10);
            n.wdamt = ld_damt.toString(10);
            ld_total_amt -= ld_damt;
            ld_janamt -= ld_damt;
            ld_damt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_gamt !== 0) {
          if (ld_janamt < ld_gamt) { // 기타
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wgamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_gamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_gamt).toString(10);
            n.wgamt = ld_gamt.toString(10);
            ld_total_amt -= ld_gamt;
            ld_janamt -= ld_gamt;
            ld_gamt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_dcamt !== 0) {
          if (ld_janamt < ld_dcamt) { // DC
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wdcamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_dcamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_dcamt).toString(10);
            n.wdcamt = ld_dcamt.toString(10);
            ld_total_amt -= ld_dcamt;
            ld_janamt -= ld_dcamt;
            ld_dcamt = 0;
          }
        }
        return n;
      }
      return x;
    });

    const dataValue = this.state.data;
    const chks = items.filter((x) => x.chk === '1');
    if (chks.length && !dataValue.remark) {
      dataValue.remark = chks[0].remark;
    }

    // 오름차순정렬
    const sequenceData = items.sort((a, b) => (a.misdate < b.misdate ? -1 : a.misdate > b.misdate ? 1 : 0));

    this.setState({
      depositList: sequenceData,
      data: dataValue,
    });
    await this.table.current?.update(false);
  }

  @action
  async smsEvent() {
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;
    const { user } = this.props.publicStore;
    if (!ConfirmWarning.assert(this.state?.depositList.length, '오류', '문자전송할 내역이 없습니다')) {
      return;
    }

    const content = `분의 입금이 확인되었습니다. 더나은 서비스로 보답하겠습니다 -${user.spjangcd}`;
    const data = await api.exec(Category.MAINTENANCE, 'wb_sms', {
      remark: this.state.data.remark,
      contents: content,
    });
    const hpData = [{ tel: data.hp }];
    modalStore.openSMS(hpData, data?.text);
    await api.smsSend(
      {
        callcc: data.hp,
        pernm: data.pernm,
      },
    );
  }

  @action
  async onJbankcdChange(v: boolean) {
    const { actionStore: api } = this.props;

    if (v) {
      const data = await api.fxExec('dw_2_itemchanged', {
        cltcd: this.state.cltcd,
      });
      this.state.data.jbankcd = data;
      this.state.data.jbanknm = data;
    } else {
      this.state.data.jbankcd = '';
      this.state.data.jbanknm = '';
    }
  }

  render() {
    return (
      <DepositInputTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new DepositInputModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
