// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttachmentBox_dropzone__3h-ki {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  border-radius: var(--radius-default);\n  background-color: rgba(56, 132, 187, 0.5);\n  color: #FFFFFF;\n  font-size: 16px;\n  z-index: 5; }\n", "",{"version":3,"sources":["webpack://src/app/components/forms/AttachmentBox/AttachmentBox.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,OAAO;EACP,SAAS;EACT,oCAAoC;EACpC,yCAAyC;EACzC,cAAc;EACd,eAAe;EACf,UAAU,EAAA","sourcesContent":[".dropzone {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  border-radius: var(--radius-default);\n  background-color: rgba(56, 132, 187, 0.5);\n  color: #FFFFFF;\n  font-size: 16px;\n  z-index: 5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropzone": "AttachmentBox_dropzone__3h-ki"
};
export default ___CSS_LOADER_EXPORT___;
