import * as React from 'react';
import {
  FlexLayout,
  TextBox,
  ComboBoxModel,
  ComboBox,
  GridLayout,
  SearchLayout,
  FormatTextBox,
  FormatNumberTextBox,
  RowCover,
  Button,
  ModalView,
  LayoutTitle,
  TableLayout,
  DateTextBox,
  SubHeader,
} from '../../../../components';
import { Global, TemplateProps } from '../../../../constants';
import { OldSpace } from './OldSpace';
import { SearchBinding } from '../../../../models';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Date8, Today } from '../../../../utils/time';

/**
 * 화면
 * @window w_tb_e601w_02
 * @category 설치된지오래된현장
 */
export const OldSpaceTemplate: React.FC<TemplateProps<OldSpace>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout>
          <span>설치기간</span>
          <FormatTextBox
            format="##년 이상"
            value={scope.state.gigan}
            onChange={(value) => scope.setState({ gigan: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>현장명</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 호기로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>계약구분</span>
          <ComboBox
            value={scope.state.gubun}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '계약중' },
              { value: '2', remark: '해지' },
              { value: '3', remark: '미계약' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ gubun: option.value }, () => {
              scope.onRetrieveEvent();
            })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>수주여부</span>
          <ComboBox
            value={scope.state.ordflag}
            data={[
              { value: '%', remark: '전체' },
              { value: '1', remark: '있음' },
              { value: '0', remark: '없음' },
            ].map((x) => new ComboBoxModel(x.value, x.remark))}
            onSelect={(option) => setData({ ordflag: option.value }, () => {
              scope.onRetrieveEvent();
            })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>계약분류</span>
          <ComboBox
            value={scope.state.contg}
            data={scope.state.contgs?.map((y) => new ComboBoxModel(y.code, y.cnam))}
            onSelect={(option) => setData({ contg: option.value }, () => {
              scope.onRetrieveEvent();
            })}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>부서</span>
          <TextBox
            weight={1.4}
            textAlign="center"
            value={scope.state.divicd}
            onChange={(value) => setData({ divicd: value })}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JC002',
              {},
              true,
              (item) => {
                setData({
                  divicd: item.cd,
                  divinm: item.cdnm,
                });
                scope.onRetrieveEvent();
              },
            )}
          />
          <TextBox
            weight={1.6}
            value={scope.state.divinm}
            readonly={true}
            onChange={(value) => setData({ divinm: value })}
          />
        </FlexLayout>
        <FlexLayout>
          <span style={{ color: 'var(--color-red)' }}>담당자</span>
          <TextBox
            weight={1.4}
            value={scope.state.pernm}
            textAlign="center"
            onChange={(value) => setData({ pernm: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_JA001',
              {},
              true,
              (item) => {
                setData({ perid: item.cd, pernm: item.cdnm });
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            weight={1.6}
            value={scope.state.perid}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <GridLayout
        ref={scope.grid}
        header={[
          {
            id: 'no',
            text: 'No',
            width: 50,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                총계
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.no}
              </FlexLayout>
            ),
          },
          {
            id: 'pernm',
            text: '담당자',
            width: 70,
            trail: () => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.total} 대
              </FlexLayout>
            ),
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.pernm}
              </FlexLayout>
            ),
          },
          {
            id: 'divinm',
            text: '부서명',
            width: 70,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.divinm}
              </FlexLayout>
            ),
          },
          {
            id: 'wkactnm',
            text: '회사구분',
            width: 80,
            render: (x) => (
              <FlexLayout
                align="center"
                justify="center"
                style={x.wkactcd !== '001' ? { color: 'var(--color-violet)' } : ''}
              >
                {x.wkactnm}
              </FlexLayout>
            ),
          },
          {
            id: 'ordflag',
            text: '수주여부',
            width: 50,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
                style={{
                  color: x.ordflag === '0' ? 'var(--color-red)'
                    : x.ordflag === '1' ? 'var(--color-blue)' : 'var(--color-violet)',
                }}
              >
                {x.ordflag === '0' ? '미처리'
                  : x.ordflag === '1' ? '수주' : '제외' }
              </FlexLayout>
            ),
          },
          {
            id: 'actcd',
            text: '현장코드',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {x.actcd}
              </FlexLayout>
            ),
          },
          {
            id: 'actnm',
            text: '현장명',
            width: 170,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.actnm}
              </FlexLayout>
            ),
          },
          {
            id: 'equpcd',
            text: '호기코드',
            width: 90,
          },
          {
            id: 'equpnm',
            text: '호기명',
            width: 90,
          },
          {
            id: 'elno',
            text: '승강기번호',
            width: 90,
            render: (x) => (
              <FormatTextBox
                format="####-###"
                textAlign="center"
                value={x.elno}
              />
            ),
          },
          {
            id: 'yyyymm',
            text: '최초설치년도',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.yyyymm}
              />
            ),
          },
          {
            id: 'gigan',
            text: '년수',
            width: 60,
          },
          {
            id: 'yyyymm2',
            text: '설치년도',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.yyyymm2}
              />
            ),
          },
          {
            id: 'gigan2',
            text: '년수',
            width: 60,
          },
          {
            id: 'gubun002',
            text: '정밀검사(회)',
            width: 90,
          },
          {
            id: 'kcenddate',
            text: '운행종료일',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.kcenddate}
              />
            ),
          },
          {
            id: 'totkcdate',
            text: '최종검사일',
            width: 100,
            render: (x) => (
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={x.totkcdate}
              />
            ),
          },
          {
            id: 'contg',
            text: '계약구분',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.contgs?.filter((y) => y.code === x.contg)[0]?.cnam}
              </FlexLayout>
            ),
          },
          {
            id: 'contgubun',
            text: '계약분류',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {scope.state.contgubuns?.filter((y) => y.code === x.contgubun)[0]?.cnam}
              </FlexLayout>
            ),
          },
          {
            id: 'daeamt',
            text: '대당금액(월)',
            width: 100,
            render: (x) => (
              <FormatNumberTextBox
                char={','}
                charAt={3}
                textAlign="right"
                value={x.daeamt}
              />
            ),
          },
          {
            id: 'number',
            text: '운행층수',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.number}
              </FlexLayout>
            ),
          },
          {
            id: 'bosunm',
            text: '보수업체',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.bosunm}
              </FlexLayout>
            ),
          },
          {
            id: 'actpernm',
            text: '현장담당자',
            width: 100,
          },
          {
            id: 'tel',
            text: '전화번호',
            width: 100,
          },
          {
            id: 'address',
            text: '주소',
            width: 200,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.address}
              </FlexLayout>
            ),
          },
          {
            id: 'address2',
            text: '상세주소',
            width: 300,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.address2}
              </FlexLayout>
            ),
          },
          {
            id: 'jejak',
            text: '제작',
            width: 130,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.jejak}
              </FlexLayout>
            ),
          },
          {
            id: 'mdnm',
            text: '모델',
            width: 100,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.mdnm}
              </FlexLayout>
            ),
          },
          {
            id: 'spped',
            text: '속도',
            width: 50,
          },
          {
            id: 'evnm',
            text: '점검기종',
            width: 130,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.evnm}
              </FlexLayout>
            ),
          },
          {
            id: 'classnm',
            text: '분류',
            width: 100,
          },
          {
            id: 'class2',
            text: '인화물구분',
            width: 80,
            render: (x) => (
              <FlexLayout
                justify="left"
                align="center"
              >
                {x.class2 === 'F' ? '화물용' : '인승용'}
              </FlexLayout>
            ),
          },
          {
            id: 'remark',
            text: '진행현황',
            width: 300,
            render: (x) => (
              <React.Fragment>
                <FlexLayout>
                  <RowCover color="#FAFA80" />
                  <Button
                    isMini={true}
                    theme={BUTTON_THEMES.BLUE}
                    onClick={() => scope.onRemarkModal(true)}
                  >
                    조회
                  </Button>
                </FlexLayout>

                <FlexLayout weight={5}>
                  <RowCover color="#FAFA80" />
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                </FlexLayout>
              </React.Fragment>
            ),
          },
          {
            id: 'hdate',
            text: '답변작성일',
            width: 130,
            render: (x) => (
              <FlexLayout
                justify="center"
                align="center"
              >
                {Date8.withDash(x.hdate)}
              </FlexLayout>
            ),
          },
        ]}
        rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
        infinityHandler={scope.infinity}
        onRowClick={(item) => scope.onRowFocusEvent(item)}
        data={scope.state.data}
      />

      <ModalView
        isVisible={scope.state.remarkModal}
        onClose={() => scope.onCloseRemarkModal()}
        width={1000}
        zIndex={-1}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>{scope.state.focusedData?.actnm} 진행현황 내역</LayoutTitle>

          <FlexLayout weight={14}>
            <TableLayout
              ref={scope.remarkModalTable}
              header={[
                {
                  id: 'seq',
                  text: '순서',
                  width: 10,
                },
                {
                  id: 'inperid',
                  text: '담당자',
                  width: 10,
                  color: 'var(--color-red)',
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      textAlign="center"
                      value={x.perid}
                      onChange={(value) => rowUpdate({ perid: value })}
                      bindSearchModal={new SearchBinding(
                        scope.props.modalStore,
                        'TB_JA001',
                        {},
                        true,
                        (item) => {
                          rowUpdate({
                            perid: item.cd,
                            pernm: item.cdnm,
                          });
                        },
                      )}
                    />
                  ),
                },
                {
                  id: 'pernm',
                  text: '담당자명',
                  width: 10,
                },
                {
                  id: 'indate',
                  text: '기록일자',
                  width: 10,
                  render: (x, rowUpdate, ref) => (
                    <DateTextBox
                      ref={ref}
                      format="####-##-##"
                      value={x.indate || Today.date()}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ indate: value })}
                    />
                  ),
                },
                {
                  id: 'remark',
                  text: '진행현황',
                  width: 40,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.remark}
                      textAlign="left"
                      onChange={(value) => rowUpdate({ remark: value })}
                    />
                  ),
                },
              ]}
              data={scope.state.remarkModalData}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onRowFocusEvent={(item) => scope.remarkModalFocused(item)}
              onChange={(rows, updatedRows) => {
                scope.onUpdatedRows(rows, updatedRows);
              }}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onRemarkModalNewEvent()}>신규</Button>
              <Button onClick={() => scope.onRemarkModalSaveEvent()}>저장</Button>
              <Button onClick={() => scope.onRemarkModalDeleteEvente()}>삭제</Button>
              <Button onClick={() => scope.onCloseRemarkModal()}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
