// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TabLayout_footer__A3Gxq {\n  display: inline-flex;\n  justify-content: center;\n  position: absolute;\n  right: 0;\n  padding: 4px 8px;\n  height: 100%; }\n  .TabLayout_footer__A3Gxq > div:not(:last-child) {\n    margin-right: 8px; }\n\n.TabLayout_screen__1_aJ_ {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 0; }\n", "",{"version":3,"sources":["webpack://src/app/components/layout/TabLayout/TabLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,kBAAkB;EAClB,QAAQ;EACR,gBAAgB;EAChB,YAAY,EAAA;EANd;IASI,iBAAiB,EAAA;;AAIrB;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,UAAU,EAAA","sourcesContent":[".footer {\n  display: inline-flex;\n  justify-content: center;\n  position: absolute;\n  right: 0;\n  padding: 4px 8px;\n  height: 100%;\n\n  & > div:not(:last-child) {\n    margin-right: 8px;\n  }\n}\n\n.screen {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "TabLayout_footer__A3Gxq",
	"screen": "TabLayout_screen__1_aJ_"
};
export default ___CSS_LOADER_EXPORT___;
