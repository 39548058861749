import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { GiveModel } from './models/Give.model';
import { GridLayout, TableLayout } from '../../../../components';
import { GiveRegistrationTemplate } from './GiveRegistration.template';
import { InfinityRetrieve } from '../../../../models/common';
import {
  GiveDetail2Model,
  GiveDetailModel,
  GivePopupModel,
} from './models';
import { PageComponent } from '../../../../utils/layout';
import { ConfirmWarning } from '../../../../utils/confirm';
import { Format } from '../../../../utils/string';

export enum ItemChange {
  snddate,
  bmarflag,
}

interface GiveRegistrationState {
  // 검색조건
  stdate: string;
  enddate: string;
  cltcd: string,
  cltnm: string,

  // data
  giveList: Array<GiveModel>;
  giveDetailList: Array<GiveDetailModel>;
  giveDetailList2: Array<GiveDetail2Model>;
  focused?: GiveModel;
  focusedGive?: GiveModel;
  focusIndex: number;
  data: GiveModel;
  gubun?: Array<any>;
  searchGubun?: string;

  // trail
  mijamt_tot: string;
  janamt_tot: string;
  inamt_tot: string;
  winamt_tot: string;
  wbamt_tot: string;
  resuamt_tot: string;
  tamt_tot: string;
  whamt_tot: string;
  iamt_tot: string;
  bamt_tot: string;
  hamt_tot: string;
  gamt_tot: string;

  givePopupData: Array<GivePopupModel>;
  rtclafiChk: string;
  rtclafi: string;
  popupSearchQuery: string;
  givePopupIsvisible: boolean;
  givePopupFocused: GivePopupModel;
}

/**
 * 컨트롤러
 * @window w_tb_ca642_01_all
 * @category 지급등록
 */
export class GiveRegistration extends PageComponent<PageProps, GiveRegistrationState>
  implements PageToolEvents {
  table: React.RefObject<TableLayout> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  detail2Grid: React.RefObject<GridLayout> = React.createRef();

  cltcdPopupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    this.state = props.state || {
      // search
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      cltcd: '',

      // data
      focusIndex: 0,
      giveList: [],
      giveDetailList: [],
      giveDetailList2: [],
      data: new GiveModel(),
      searchGubun: '1',

      // trail
      mijamt_tot: '0',
      janamt_tot: '0',
      inamt_tot: '0',
      winamt_tot: '0',
      wbamt_tot: '0',
      resuamt_tot: '0',
      tamt_tot: '0',
      whamt_tot: '0',
      iamt_tot: '0',
      bamt_tot: '0',
      hamt_tot: '0',
      gamt_tot: '0',

      rtclafiChk: '0',
      popupSearchQuery: '',
    };
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 기타구분
    const data = await api.dropdown('wf_dd_ca510_201_01');
    if (!data) return;
    this.setState({ gubun: data.items });

    // data 값 미리 지정
    const datas = this.state.data;
    datas.bchk = '1';
    datas.bmarflag = '0';
    datas.rcvdate = this.state.enddate;

    this.setState({ data: datas });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    if (!this.state.cltcd) {
      ConfirmWarning.show('확인', '거래처를 선택하세요');
      return;
    }

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.cltcd,
        cltnm: this.state.cltnm,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          giveList: [
            ...this.state.giveList,
            ...items.map((x: any) => new GiveModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          giveList: [],
          giveDetailList: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.giveList && this.state.giveList?.length > 0) {
          await this.table.current?.update(true);
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      giveList: [],
      giveDetailList: [],
      data: new GiveModel(),
    }, async () => {
      const index = await this.infinity?.retrieveTo(['cltcd', 'mijnum'],
        [this.state.focused?.cltcd, this.state.focused?.mijnum], type, true) || 0;
      if (this.state.giveList && this.state.giveList.length > index) {
        await this.table.current?.update(true);
        this.table.current?.setFocus(index, 0);
        // this.onRetrieveEvent2();
        await this.onMainRowFocusEvent(this.state.giveList[0], 0);
      }

      this.SS({
        mijamt_tot: this.infinity?.box?.mijamt_tot || '0',
        janamt_tot: this.infinity?.box?.janamt_tot || '0',
        inamt_tot: this.infinity?.box?.inamt_tot || '0',
        winamt_tot: this.infinity?.box?.winamt_tot || '0',
        wbamt_tot: this.infinity?.box?.wbamt_tot || '0',
        resuamt_tot: this.infinity?.box?.resuamt_tot || '0',
        tamt_tot: this.infinity?.box?.tamt_tot || '0',
      });
      await this.table.current?.update(true);
    });
    await this.onRetrieveEvent2();
  }

  @action
  async onRetrieveEvent2() {
    const { actionStore: api } = this.props;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity2 = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        cltcd: this.state.cltcd,
        cltnm: this.state.cltnm,
      },
      (params) => api.fxExec('retrieve2', params),
      (items) => {
        this.setState({
          giveDetailList: [
            ...this.state.giveDetailList,
            ...items.map((x: any) => new GiveDetailModel(x)),
          ],
        });
      },
      async () => {
        await this.SS({
          giveDetailList: [],
          giveDetailList2: [],
        });

        await this.infinity2?.retrieveAll();
        if (this.state.giveDetailList && this.state.giveDetailList?.length > 0) {
          this.grid.current?.setFocus(0);
        }
      },
    );
    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      giveDetailList: [],
      giveDetailList2: [],
    }, async () => {
      const dataDate = await this.infinity2?.retrieveAll();

      this.SS({
        iamt_tot: dataDate?.iamt_tot || '0',
      });

      if (this.state.giveDetailList.length > 0) {
        await this.onRowFocusEvent(this.state.giveDetailList[0]);
      } else {
        await this.onNewEvent();
      }
    });
    await this.grid.current?.forceRepaint(true);
  }

  @action
  async onMainRowFocusEvent(item: any, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  async onRowFocusEvent(item: any) {
    const { actionStore: api } = this.props;

    await this.SS({
      focusedGive: item,
    });

    const datas = await api.fxExec(
      'dw_3_RowFocuschanged',
      {
        cltcd: item?.cltcd,
        snddate: item?.snddate,
        sndnum: item?.sndnum,
      },
    );

    if (datas?.items) {
      await this.SS({
        giveDetailList2: datas.items,
        data: new GiveModel({
          ...this.state.data,
          ...datas,
        }, true),
      });
    } else {
      await this.SS({ giveDetailList2: [] });
    }
    await this.detail2Grid.current?.forceRepaint(true);
  }

  @action
  async onitemChange(datas: any, name: any) {
    const { actionStore: api } = this.props;

    let itemData = {};

    switch (ItemChange[name]) {
      case 'snddate':
        itemData = {
          sndnum: this.state.data.sndnum,
        };
        break;

      case 'bmarflag':
        itemData = {
          bamt: this.state.data.bamt,
          bmar: this.state.data.bmar,
        };
        break;

      default:
        itemData = {
          cltcd: this.state.cltcd,
        };
        break;
    }

    const data = await api.fxExec('dw_1_itemchanged',
      {
        new: this.state.data?.isNew === true ? '1' : '0',
        itemname: ItemChange[name],
        data: datas,
        ...itemData,
      });

    data && this.setState({
      data: new GiveModel({
        ...this.state.data,
        ...data,
      }, true),
    });
  }

  @action
  async onButtonclick(datas: any) {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('dw_1_buttonclicked',
      {
        new: this.state.data?.isNew === true ? '1' : '0',
        itemname: 'b_bankcd',
        data: datas,
        cltcd: this.state.cltcd,
        snddate: this.state.data.snddate,
        sndnum: this.state.data.sndnum,
      });

    data && this.setState({
      data: new GiveModel({
        ...this.state.data,
        ...data,
      }, true),
    });
  }

  @action
  onUpdatedRows(rows: any) {
    this.setState({ giveList: rows }, () => {
      this.itemchanged(this.state.giveList);
    });
  }

  @action
  async itemchanged(dw: Array<GiveModel>) {
    let items = dw.sort((a, b) => (a.winamt < b.winamt ? 1 : a.winamt > b.winamt ? -1 : 0));

    items = items.map((x) => new GiveModel({
      ...x,
      winamt: x.chk === '1' ? x.winamt : '0',
      whamt: x.chk === '1' ? x.whamt : '0',
      wbamt: x.chk === '1' ? x.wbamt : '0',
      weamt: x.chk === '1' ? x.weamt : '0',
      wsamt: x.chk === '1' ? x.wsamt : '0',
      wdamt: x.chk === '1' ? x.wdamt : '0',
      wgamt: x.chk === '1' ? x.wgamt : '0',
    }, x.isNew));

    let ld_hamt = Format.toNumber(this.state.data?.hamt);
    let ld_bamt = Format.toNumber(this.state.data?.bamt);
    const ld_eamt = Format.toNumber(this.state.data?.eamt);
    const ld_samt = Format.toNumber(this.state.data?.samt);
    let ld_damt = Format.toNumber(this.state.data?.damt);
    let ld_gamt = Format.toNumber(this.state.data?.gamt);
    let ld_total_amt = ld_hamt + ld_bamt + ld_eamt + ld_samt + ld_damt + ld_gamt;

    items = items.map((x) => {
      if (x.chk === '1') {
        if (ld_total_amt === 0) return x;

        const winamt = Format.toNumber(x.winamt);
        if (winamt > 0) {
          ld_hamt -= Format.toNumber(x.whamt);
          ld_bamt -= Format.toNumber(x.wbamt);
          ld_damt -= Format.toNumber(x.wdamt);
          ld_gamt -= Format.toNumber(x.wgamt);
          ld_total_amt -= winamt;
          return x;
        }

        const n = new GiveModel(x, x.isNew);

        let ld_janamt = Format.toNumber(n.janamt);
        if (ld_hamt !== 0) {
          if (ld_janamt < ld_hamt) { // 현금
            n.winamt = (ld_janamt).toString(10);
            n.whamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_hamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (ld_hamt).toString(10);
            n.whamt = ld_hamt.toString(10);
            ld_total_amt -= ld_hamt;
            ld_janamt -= ld_hamt;
            ld_hamt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_bamt !== 0) {
          if (ld_janamt < ld_bamt) { // 예금
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wbamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_bamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_bamt).toString(10);
            n.wbamt = ld_bamt.toString(10);
            ld_total_amt -= ld_bamt;
            ld_janamt -= ld_bamt;
            ld_bamt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_damt !== 0) {
          if (ld_janamt < ld_damt) { // 카드대금
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wdamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_damt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_damt).toString(10);
            n.wdamt = ld_damt.toString(10);
            ld_total_amt -= ld_damt;
            ld_janamt -= ld_damt;
            ld_damt = 0;
          }
        }

        if (ld_janamt === 0) return n;
        if (ld_total_amt === 0) return n;

        if (ld_gamt !== 0) {
          if (ld_janamt < ld_gamt) { // 기타
            n.winamt = (Format.toNumber(n.winamt) + ld_janamt).toString(10);
            n.wgamt = ld_janamt.toString(10);
            ld_total_amt -= ld_janamt;
            ld_gamt -= ld_janamt;
            ld_janamt -= ld_janamt;
          } else {
            n.winamt = (Format.toNumber(n.winamt) + ld_gamt).toString(10);
            n.wgamt = ld_gamt.toString(10);
            ld_total_amt -= ld_gamt;
            ld_janamt -= ld_gamt;
            ld_gamt = 0;
          }
        }
        return n;
      }
      return x;
    });

    const dataValue = this.state.data;
    const chks = items.filter((x) => x.chk === '1');
    if (chks.length && !dataValue.remark) {
      dataValue.remark = chks[0].remark;
    }

    // 오름차순정렬
    const sequenceData = items.sort((a, b) => (a.mijdate < b.mijdate ? -1 : a.mijdate > b.mijdate ? 1 : 0));

    this.setState({
      giveList: sequenceData,
      data: dataValue,
    });
    await this.table.current?.update(false);
  }

  @action
  updateTotal() {
    const ld_hamt = Format.toNumber(this.state.data?.hamt);
    const ld_bamt = Format.toNumber(this.state.data?.bamt);
    const ld_eamt = Format.toNumber(this.state.data?.eamt);
    const ld_samt = Format.toNumber(this.state.data?.samt);
    const ld_damt = Format.toNumber(this.state.data?.damt);
    const ld_gamt = Format.toNumber(this.state.data?.gamt);
    const ld_total_amt = ld_hamt + ld_bamt + ld_eamt + ld_samt + ld_damt + ld_gamt;

    this.setState({
      data: new GiveModel({
        ...this.state.data,
        total_amt: ld_total_amt.toString(10),
      }, true),
    });
  }

  @action
  async onNewEvent() {
    const { actionStore: api } = this.props;
    const data = await api.new({
      cltcd: this.state.cltcd,
      cltnm: this.state.cltnm,
    });

    data && this.setState({
      data: new GiveModel(data, true),
      giveDetailList: [],
      giveDetailList2: [],
    }, async () => {
      this.table.current?.update(false);
    });
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;

    const dataChk = this.state.data;
    // eslint-disable-next-line no-param-reassign
    dataChk.bamt = this.state.data.bamt.replace(',', '');
    dataChk.bmar = this.state.data.bmar.replace(',', '');
    dataChk.total_amt = this.state.data.total_amt.replace(',', '');
    dataChk.hamt = this.state.data.hamt.replace(',', '');
    dataChk.gamt = this.state.data.gamt.replace(',', '');

    if (await api.save({
      ...dataChk,
      cltcd: this.state.cltcd,
      cltnm: this.state.cltnm,
      items: this.state.giveList,
    }, true)) {
      this.table.current?.resetUpdates();
      this.onRetrieveEvent();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = `지급번호: ${this.state.focusedGive?.sndnum} 지급일자: ${this.state.focusedGive?.snddate}`;
    await api.delete(text, this.state.focusedGive) && this.onRetrieveEvent(RetrieveFocusType.END);
  }


  @action
  async onGivePopupOpen(isOpen: boolean) {
    await this.SS({
      givePopupIsvisible: isOpen,
    });

    await this.onGivePopupRetrieve();
  }

  @action
  async onGivePopupRetrieve() {
    const { actionStore: api } = this.props;

    await this.SS({
      givePopupData: [],
    });

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_ca642_ats_cltcd',
        as_nm: this.state.popupSearchQuery,
        rtclafi: this.state.rtclafiChk === '0' ? '001' : '%',
      });

    await this.SS({
      givePopupData: data?.items,
    });

    await this.cltcdPopupTable.current?.update(true);
    await this.cltcdPopupTable.current?.setFocus(0);
  }


  @action
  async onGivePopupRowfocusEvent(item: GivePopupModel) {
    await this.SS({
      givePopupFocused: item,
    });

    // 팝업창 조회결과 하나일 때 cltcd, cltnm 자동입력 후 팝업창 닫아줌
    if (this.state.givePopupData?.length < 2) {
      await this.onGivePopupConfirm();
    }
  }

  @action
  async onGivePopupClose() {
    await this.SS({
      givePopupFocused: new GivePopupModel(),
      popupSearchQuery: '',
      givePopupIsvisible: false,
      rtclafi: '001',
    });
  }

  @action
  async onGivePopupConfirm() {
    await this.SS({
      givePopupFocused: new GivePopupModel(),
      popupSearchQuery: '',
      givePopupIsvisible: false,
      rtclafi: '001',
      cltcd: this.state.givePopupFocused?.cltcd,
      cltnm: this.state.givePopupFocused?.cltnm,
    });
    await this.onRetrieveEvent();
  }

  render() {
    return (
      <GiveRegistrationTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new GiveModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
