/**
 * 팝업 모델
 * @window w_tb_ca608_02
 * @category 자재청구서 불러오기
 */

export class BillPopupModel {
  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly chk: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly custcd: string;

  /**
   *
   *
   * **data type** varchar(2)
   */
  readonly spjangcd: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly pumdate: string;

  /**
   *
   *
   * **data type** varchar(4)
   */
  readonly pumnum: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly pumseq: string;

  /**
   *
   *
   * **data type** varchar(15)
   */
  readonly actcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly actnm: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly equpcd: string;

  /**
   *
   *
   * **data type** varchar(100)
   */
  readonly equpnm: string;

  /**
   *
   *
   * **data type** varchar(20)
   */
  readonly pcode: string;

  /**
   *
   *
   * **data type** varchar(50)
   */
  readonly pname: string;

  /**
   *
   *
   * **data type** varchar(200)
   */
  readonly psize: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly punit: string;

  /**
   *
   *
   * **data type** varchar(10)
   */
  readonly perid: string;

  /**
   *
   *
   * **data type** varchar(30)
   */
  readonly pernm: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly divicd: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly qty: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly samt: string;

  /**
   *
   *
   * **data type** number(0)
   */
  readonly tsamt: string;

  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly compflag: string;

  /**
   * 남은수량
   *
   * **data type** varchar(1)
   */
  readonly overqty: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly title: string;

  /**
   *
   *
   * **data type** varchar(255)
   */
  readonly remark: string;

  constructor(data: any = {}) {
    this.chk = data.chk || '';
    this.custcd = data.custcd || '';
    this.spjangcd = data.spjangcd || '';
    this.pumdate = data.pumdate || '';
    this.pumnum = data.pumnum || '';
    this.pumseq = data.pumseq || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.pcode = data.pcode || '';
    this.pname = data.pname || '';
    this.psize = data.psize || '';
    this.punit = data.punit || '';
    this.perid = data.perid || '';
    this.pernm = data.pernm || '';
    this.divicd = data.divicd || '';
    this.qty = data.qty || '';
    this.overqty = data.overqty || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
    this.tsamt = data.tsamt || '';
    this.compflag = data.compflag || '';
    this.title = data.title || '';
    this.remark = data.remark || '';
  }
}
