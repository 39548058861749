import * as React from 'react';
import {
  action,
  computed,
} from 'mobx';
import {
  MdReceipt,
  MdReorder,
  MdToday,
} from 'react-icons/md';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { InfinityRetrieve } from '../../../../models/common';
import { JournalTemplate } from './Journal.template';
import { TableLayout } from '../../../../components/layout/TableLayout';
import {
  Date8,
  Today,
} from '../../../../utils/time';
import { TabModel } from '../../../../models/component';
import {
  ChargePopupModel,
  DetailModel,
  EducationPopupModel,
  JournalModel,
  RecordPopupModel,
  RoutineModel,
  SelfModel,
} from './models';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
  TableLayoutHeader,
} from '../../../../constants';
import {
  TabHeaderDetail,
  TabHeaderRoutine,
  TabHeaderSelf,
} from './tabs';
import { GridLayoutAdditionalHeader } from '../../../../components/layout/GridLayoutAdditionalHeader';
import { EqupPopupModel } from './models/EqupPopup.model';
import { SchedulePopupModel } from './models/SchedulePopup.model';
import { PageComponent } from '../../../../utils';

export enum JournalTabId {
  Detail,
  Self,
  Routine,
}

export enum JournalItemChangeTypes {
  RPTDATE,
  ST_ACTNM,
  END_ACTNM,
  WKCD,
}

export enum JournalItemChangeTypeNames {
  rptdate,
  st_actnm,
  end_actnm,
  wkcd,
}

const upflags = [
  { value: '001', color: '#000000' },
  { value: '002', color: '#5B1A86' },
  { value: '003', color: '#7F7A2C' },
  { value: '004', color: '#ED709B' },
  { value: '005', color: '#A77450' },
  { value: '006', color: '#881B20' },
  { value: '007', color: '#FAC96C' },
];

export const JournalTabTitles = [
  '상세내용',
  '자체점검현황',
  '정기검사현황',
];

export const JournalTabModels = [
  DetailModel,
  SelfModel,
  RoutineModel,
];

export const JournalTabFunctionNames = [
  'tab_1',
  'tab_2',
  'tab_3',
];

export const JournalTabDataStateNames = [
  'detailList',
  'selfList',
  'routineList',
];

export enum JournalButtonClickedTypes {
  ADD,
  MINUS,
}

export enum JournalButtonClickedTypeNames{
  b_add,
  b_minus,
}

interface JournalState {

  // 검색조건
  stmon: string; // 기간
  peridState: string; // 사원명조건
  perid: string; // 사원명
  pernm: string; // 사원명
  kukcd: string;

  popupStmon: string; // 기간

  // 데이터 객체

  // 테이블 포커싱 데이터
  focusedJournal?: JournalModel;
  focusedDetail?: DetailModel;
  focusedEducation?: DetailModel;
  focusedCharge?: ChargePopupModel;
  focusedRecord?: RecordPopupModel;

  // 레이블 박스 데이터
  data: JournalModel;

  // 테이블 데이터 리스트
  journalList: Array<JournalModel>;
  detailList: Array<DetailModel>;
  selfList: Array<SelfModel>;
  routineList: Array<RoutineModel>;

  // 교육 모달
  isEducationModalVisible: boolean;
  popupEducationList: Array<EducationPopupModel>;

  // 담당자 합계 모달
  isChargeModalVisible: boolean;
  popupChargeList: Array<ChargePopupModel>;

  // 호기생성 모달
  popupWkcd: string;
  popupRemark: string;
  isEqupModalVisible: boolean;
  popupEqupList: Array<EqupPopupModel>;

  // 계약기록 모달
  isRecordModalVisible: boolean;
  popupRecordList: Array<RecordPopupModel>;

  focusedTab?: TabModel;

  actcd: string;
  actnm: string;

  tab2_state: string;
  tab3_state: string;

  wkcds: Array<any>;

  // 모달
  fileDetailModal: boolean; // 첨부파일

  isReported: boolean; // 결재상신 여부

  // 스케쥴 등록
  isScheduleMaintenanceModalVisible?: boolean;
  isScheduleGreetingModalVisible?: boolean;
  popupScheduleList?: Array<SchedulePopupModel>;
  schedulePopupDate: string;
  focusedSchedule?: SchedulePopupModel,
  popupPerid: string;
  popupPernm: string;

  isGraphVisible: boolean;

  // 통계
  total_tot: string;
  prekm_tot: string;
  km_tot: string;

  tabRoutineCnt_tot: string;
  tabRoutineCompcnt_tot: string;
  tabRoutineResult01_tot: string;
  tabRoutineResult03_tot: string;
  tabRoutineResult02_tot: string;
  tabRoutineMiscnt_tot: string;
  tabRoutineNotcnt_tot: string;

  tabSelfPlancnt_tot: string;
  tabSelfSuccess_tot: string;
  tabSelfCompcnt_tot: string;
  tabSelfJcomcnt_rece_tot: string;
  tabSelfCompcnt_not_tot: string;
  tabSelfJcomcnt_tot: string;
  tabSelfTotcompcnt_tot: string;

  focusIndex: number;
  chk: string;
}

/*
* 컨트롤러
* @window w_tb_e037_type2
* @category 개별업무일지작성
*/

export class Journal extends PageComponent<PageProps, JournalState>
  implements PageToolEvents {
  tabs: Array<TabModel>;

  tabHeaders: Array<Array<TableLayoutHeader>>;

  tapChk?: number;

  updatedRows?: Array<DetailModel>;

  updatedRecordRows?: Array<RecordPopupModel>;

  updatedScheduleRows?: Array<SchedulePopupModel>;

  updatedEqupRows?: Array<EqupPopupModel>;

  additionalTitle: React.RefObject<GridLayoutAdditionalHeader> = React.createRef();

  grid: React.RefObject<GridLayout> = React.createRef();

  detailTable: React.RefObject<TableLayout> = React.createRef();

  popupScheduleTable: React.RefObject<TableLayout> = React.createRef();

  popupEducationGrid: React.RefObject<GridLayout> = React.createRef();

  popupChargeGrid: React.RefObject<GridLayout> = React.createRef();

  popupRecordTable: React.RefObject<TableLayout> = React.createRef();

  popupEqupTable: React.RefObject<TableLayout> = React.createRef();

  popupTable: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  infinity2?: InfinityRetrieve;

  infinity3?: InfinityRetrieve;

  infinity4?: InfinityRetrieve;

  graph?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const id = JournalTabId;
    const titles = JournalTabTitles;

    this.tabs = [
      new TabModel(id.Detail.toString(), MdReorder, titles[id.Detail]),
      new TabModel(id.Self.toString(), MdReceipt, titles[id.Self]),
      new TabModel(id.Routine.toString(), MdToday, titles[id.Routine]),
    ];

    this.tabHeaders = [
      TabHeaderDetail, // 상세내용
      TabHeaderSelf, // 자체점검현황
      TabHeaderRoutine, // 정기검사현황
    ];

    // state 기본값 정의
    this.state = props.state || {
      stmon: Today.yearMon(),
      perid: '',
      peridState: '0',

      popupStmon: Today.yearMon(),

      data: new JournalModel(),

      actcd: '',
      actnm: '',

      tab2_state: '%',
      tab3_state: '%',

      journalList: [],
      detailList: [],
      selfList: [],
      routineList: [],
      popupEducationList: [],
      popupChargeList: [],
      popupRecordList: [],
      popupScheduleList: [],
      popupEqupList: [],

      popupWkcd: '001',
      popupRemark: '',

      wkcds: [],

      isChargeModalVisible: false,
      isEqupModalVisible: false,
      isEducationModalVisible: false,
      isReported: false,

      popupPerid: '',
      popupPernm: '',

      isGraphVisible: false,

      total_tot: '',
      prekm_tot: '',
      km_tot: '',

      tabRoutineCnt_tot: '',
      tabRoutineCompcnt_tot: '',
      tabRoutineResult01_tot: '',
      tabRoutineResult03_tot: '',
      tabRoutineResult02_tot: '',
      tabRoutineMiscnt_tot: '',
      tabRoutineNotcnt_tot: '',

      tabSelfPlancnt_tot: '',
      tabSelfSuccess_tot: '',
      tabSelfCompcnt_tot: '',
      tabSelfJcomcnt_rece_tot: '',
      tabSelfCompcnt_not_tot: '',
      tabSelfJcomcnt_tot: '',
      tabSelfTotcompcnt_tot: '',

      focusIndex: 0,
      chk: '0',
    };
  }

  @action
  async onMessageEvent(_: string, message: string) {
    const { actionStore: api } = this.props;
    let data;

    const json = JSON.parse(JSON.parse(message));
    if (json?.key === 'ALERT-ANSWER') {
      if (!await Confirm.show(json?.message, '', ConfirmType.QUESTION)) {
        return;
      }
      data = await api.fxExec(
        'delete_appok',
        {
          sndflag: this.state.focusedJournal?.sndflag,
          rptdate: this.state.focusedJournal?.rptdate,
          perid: this.state.focusedJournal?.perid,
          appnum: this.state.focusedJournal?.appnum,
          appgubun: this.state.focusedJournal?.appgubun,
        }, false,
      );

      data && this.setState({
        data: new JournalModel({
          ...this.state.data,
          ...data,
        }, data.new),
      }, () => this.onRetrieveEvent());
    }
  }

  @action
  async itemChanged(type: number, _?: any) {
    const { actionStore: api } = this.props;
    let data = { new: undefined };
    const params = {
      itemname: JournalItemChangeTypeNames[type],
      data: '',
      new: this.state.data.new,
    };
    if (type === JournalItemChangeTypeNames.rptdate) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          data: this.state.data.stdate,
        },
      );
    }

    if (type === JournalItemChangeTypeNames.st_actnm) {
      data = await api.fxExec(
        'dw_2_itemchanged',
        {
          ...params,
          st_lat: this.state.data.st_lat,
          st_lng: this.state.data.st_lng,
          cnt: this.state.detailList.length.toString(),
          lat: this.state.detailList[0]?.lat,
          lng: this.state.detailList[0]?.lng,
        },
      );
    }

    data && this.setState({
      data: new JournalModel({
        ...this.state.data, // new = 0
        ...data, // new = 1
      }, data.new === '1'),
    });
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    // 조건에 따른 사원명
    this.setState({
      perid: user.perid,
      pernm: user.pernm,
      peridState: '1',
    });

    const data = await api.dropdown('wf_dd_e021');
    if (!data) return;

    this.setState({ wkcds: data?.items });

    await this.onRetrieveEvent();
    this.onTabChange(this.tabs[JournalTabId.Detail]);
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;

    const param = {
      stmon: this.state.stmon,
      perid: this.state.perid,
      tab2_state: this.state.tab2_state,
      tab3_state: this.state.tab3_state,
    };

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      param,
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          journalList: [...this.state.journalList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ journalList: [] });
        this.infinity?.retrieve();
        this.grid.current?.setFocus(0);
      },
    );

    this.infinity3 = new InfinityRetrieve(
      param,
      (params) => api.fxExec('retrieve2', params),
      (items, next) => {
        this.setState({
          selfList: [...this.state.selfList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ selfList: [] });
        await this.infinity3?.retrieve();
      },
    );

    this.infinity4 = new InfinityRetrieve(
      param,
      (params) => api.fxExec('retrieve3', params),
      (items, next) => {
        this.setState({
          routineList: [...this.state.routineList, ...items],
        }, next);
      },
      async () => {
        await this.SS({ routineList: [] });
        await this.infinity4?.retrieveAll();
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      journalList: [],
      detailList: [],
      selfList: [],
      routineList: [],
      data: new JournalModel(),
    }, async () => {
      const index = await this.infinity?.retrieveTo(['perid', 'rptdate'],
        [this.state.focusedJournal?.perid, this.state.focusedJournal?.rptdate], type, true) || 0;

      const dataSelf = await this.infinity3?.retrieve();
      const dataRoutine = await this.infinity4?.retrieve();

      if (this.state.journalList && this.state.journalList.length > index) {
        await this.grid.current?.setFocus(index);
      }

      this.setState({
        tabRoutineCnt_tot: dataRoutine?.cnt_tot || '0',
        tabRoutineCompcnt_tot: dataRoutine?.compcnt_tot || '0',
        tabRoutineResult01_tot: dataRoutine?.result01_tot || '0',
        tabRoutineResult03_tot: dataRoutine?.result03_tot || '0',
        tabRoutineResult02_tot: dataRoutine?.result02_tot || '0',
        tabRoutineMiscnt_tot: dataRoutine?.miscnt_tot || '0',
        tabRoutineNotcnt_tot: dataRoutine?.notcnt_tot || '0',

        tabSelfPlancnt_tot: dataSelf?.plancnt_tot || '0',
        tabSelfSuccess_tot: dataSelf?.success_tot || '0',
        tabSelfCompcnt_tot: dataSelf?.compcnt_tot || '0',
        tabSelfJcomcnt_rece_tot: dataSelf?.jcomcnt_rece_tot || '0',
        tabSelfCompcnt_not_tot: dataSelf?.compcnt_not_tot || '0',
        tabSelfJcomcnt_tot: dataSelf?.jcomcnt_tot || '0',
        tabSelfTotcompcnt_tot: dataSelf?.totcompcnt_tot || '0',
      });
    });
  }

  @action
  async popupItemChanged(type: number, item?: any) {
    const { actionStore: api } = this.props;

    // eslint-disable-next-line max-len
    const focusedDetailIndex = this.state.popupScheduleList?.findIndex((x) => x.plannum === item.plannum);
    let data = { new: undefined };
    const params = {
      itemname: JournalItemChangeTypeNames[type],
      data: '',
      new: this.state.focusedSchedule?.new,
      sub: 'w_popup_e501',
    };
    if (type === JournalItemChangeTypeNames.wkcd) {
      data = await api.fxExec(
        'dw_list_itemchanged',
        {
          ...params,
          data: item.wkcd,
        },
      );
    }

    // 디테일 테이블에서 수정되어야하는 로우를 찾아 값 변경
    data && this.setState({
      focusedSchedule: new SchedulePopupModel({
        ...this.state.focusedSchedule,
      }, false),
      // eslint-disable-next-line max-len
      popupScheduleList: this.state.popupScheduleList?.map((x: any, index: number) => (
        index === focusedDetailIndex
          ? new SchedulePopupModel({ ...x, ...data }, data.new === '1') : new SchedulePopupModel(x)
      )),
    }, () => this.popupScheduleTable.current?.update(false));
  }

  // eslint-disable-next-line class-methods-use-this
  getItemColor(item: string): string {
    return upflags.filter((y) => y.value === item)[0]?.color;
  }

  @action
  // 부서계획 불러오기
  // eslint-disable-next-line class-methods-use-this
  async roadSchedule() {
    // 서버 측 기능 구현 후 테스트
    ConfirmWarning.show('경고', '해당 기능 준비중입니다.');
    //
    // const { actionStore: api } = this.props;
    //
    // const data = await api.fxExec('wb_diviplan',
    //   {
    //     sub: 'w_popup_e501',
    //     plandate: this.state.schedulePopupDate,
    //     perid: this.state.popupPerid,
    //     pernm: this.state.popupPernm,
    //   });
    //
    // if (data && data.items) {
    //   this.setState({
    //     popupScheduleList: [
    //       ...this.state.popupScheduleList!,
    //       ...data.items,
    //     ],
    //   }, () => this.popupTable.current?.update());
    // }
  }

  @action
  onRowFocusEvent(item: JournalModel, index: number) {
    const { actionStore: api } = this.props;

    if (item?.new === '1') {
      this.setState({ isReported: false });
      return;
    }

    this.setState(
      {
        focusedJournal: item,
        focusIndex: index,
      },
      async () => {
        this.infinity2 = new InfinityRetrieve({
          rptdate: this.state.focusedJournal?.rptdate,
          perid: this.state.focusedJournal?.perid,
        },
        (params) => api.fxExec('dw_1_RowFocuschanged', {
          rptdate: this.state.focusedJournal?.rptdate,
          perid: this.state.focusedJournal?.perid,
          ...params,
        }),
        (items) => {
          this.setState({
            detailList: items.map((x, i) => new DetailModel({
              ...x,
              rptnum: `00${i + 1}`.substr(-2),
              rptdate: this.state.data.rptdate,
            })),
          });
        },
        async () => {
          await this.SS({ detailList: [] });
          await this.infinity2?.retrieve();
          await this.detailTable.current?.update();
          this.detailTable.current?.setFocus(0, 0);
        });

        // 결재상신 여부확인
        if (this.state.focusedJournal?.appgubun === null
          || this.state.focusedJournal?.appgubun === ''
          || this.state.focusedJournal?.appgubun === '131') {
          this.setState({ isReported: false });
        } else {
          this.setState({ isReported: true });
        }

        if (!this.infinity2.count) {
          this.setState({ detailList: [] }, () => this.detailTable.current?.update());
        }

        const data = await this.infinity2?.retrieve();
        await this.detailTable.current?.update();

        const datas = await api.fxExec(
          'dw_1_RowFocuschanged',
          {
            rptdate: this.state.focusedJournal?.rptdate,
            perid: this.state.focusedJournal?.perid,
          }, false,
        );
        this.setState({
          data: new JournalModel(datas),
        });

        data && this.setState({
          total_tot: this.infinity2?.box?.total || '0',
          prekm_tot: this.infinity2?.box?.prekm_tot || '0',
          km_tot: this.infinity2?.box?.km_tot || '0',
        });

        this.detailTable.current?.setFocus(0, 0);
      },
    );
  }

  @action
  onTabChange(focusedTab: TabModel) {
    this.setState({ focusedTab },
      async () => {
        await this.detailTable.current?.update();
        // @ts-ignore
        if (this.state[JournalTabDataStateNames[this.tabIndex]].length > 0) {
          this.detailTable.current?.setFocus(0, 0);
        }
      });
  }

  @computed
  get tabIndex(): JournalTabId {
    return parseInt(this.state.focusedTab?.id || '0', 10);
  }

  @action
  fileModal(isOpen: boolean) {
    if (this.state.data.new === '1') {
      ConfirmWarning.show('확인', '먼저 문서를 저장하세요.');
      return;
    }
    this.setState({ fileDetailModal: isOpen });
  }

  @action
  async openReferSelector() {
    if (!this.state.data?.appgubun || !this.state.data?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.JOURNAL, this.state.data?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    this.onRowFocusEvent(this.state.focusedJournal!, this.state.focusIndex);
  }

  // 결재상신
  @action
  async onReport() {
    const { modalStore } = this.props;
    const { actionStore: api } = this.props;

    let text = '';
    let appflag = '';

    if (this.state.isReported) {
      appflag = 'cancel';
      text = '결재상신을 취소하시겠습니까?';
    } else {
      appflag = 'ok';
      text = '결재를 상신하시겠습니까?';
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    // 결재라인 모달
    appflag === 'ok' && await modalStore.openApprovalLine(PAPERCD.WORKNIGHT);
    // 결재상신
    const data = await api.exec(Category.EMAIL, 'wb_appreport', {
      papercd: PAPERCD.JOURNAL.toString(),
      appnum: this.state.data.appnum,
      appgubun: this.state.data.appgubun,
      rptdate: this.state.data.rptdate,
      perid: this.state.data.perid,
      title: '',
      refer: this.state.data.refer,
      appflag,
    });
    data && this.onRetrieveEvent();
  }

  @action
  async onSaveEvent() {
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재가 상신되어 있습니다.');
      return;
    }

    const { actionStore: api } = this.props;
    if (await api.save({
      new: this.state.data.new,
      custcd: this.state.data.custcd,
      spjangcd: this.state.data.spjangcd,
      rptdate: this.state.data.rptdate || Today.date(),
      perid: this.state.data.perid,
      pernm: this.state.data.pernm,
      divicd: this.state.data.divicd,
      divinm: this.state.data.divinm,
      rspcd: this.state.data.rspcd,
      rspnm: this.state.data.rspnm,
      rptremark: this.state.data.rptremark,
      overwk: this.state.data.overwk,
      upmuremark: this.state.data.upmuremark,
      endperid: this.state.data.endperid,
      endpernm: this.state.data.endpernm,
      st_actcd: this.state.data.st_actcd,
      st_actnm: this.state.data.st_actnm,
      st_lat: this.state.data.st_lat,
      st_lng: this.state.data.st_lng,
      end_actcd: this.state.data.end_actcd,
      end_actnm: this.state.data.end_actnm,
      end_lat: this.state.data.end_lat,
      end_lng: this.state.data.end_lng,
      km: this.state.data.km,
      endflag: this.state.data.endflag,
      endtext: this.state.data.endtext,
      chk: this.state.data.chk,
      stdate: this.state.data.stdate,
      sttime: this.state.data.sttime,
      enddate: this.state.data.enddate,
      endtime: this.state.data.endtime,
      carcd1: this.state.data.carcd1,
      carnum1: this.state.data.carnum1,
      soyou1: this.state.data.soyou1,
      model1: this.state.data.model1,
      gubun1: this.state.data.gubun1,
      carcd2: this.state.data.carcd2,
      carnum2: this.state.data.carnum2,
      soyou2: this.state.data.soyou2,
      model2: this.state.data.model2,
      gubun2: this.state.data.gubun2,
      sjik: this.state.data.sjik,
      djik: this.state.data.djik,
      syn: this.state.data.syn,
      gubun: this.state.data.gubun,
      appdate: this.state.data.appdate,
      apptime: this.state.data.apptime,
      appgubun: this.state.data.appgubun,
      appnum: this.state.data.appnum,
      appperid: this.state.data.appperid,
      appremark: this.state.data.appremark,
      picflag: this.state.data.picflag,
      refer: this.state.data.refer,
      samt: this.state.data.samt,
      attcnt: this.state.data.attcnt,
      items: this.state.detailList,
    }, this.state.data.new === '1')) {
      await this.setState({ chk: '1' });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onNewEvent() {
    if (this.state.data.isNew) {
      ConfirmWarning.show('경고', '한번에 한 행만 추가하실 수 있습니다. 저장 후 다음 행을 등록해주세요.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const newModel = new JournalModel(data, true);

      this.setState({
        data: newModel,
        journalList: [
          newModel,
          ...this.state.journalList,
        ],
        focusedJournal: newModel,
        detailList: [],
        focusIndex: 0,
      }, async () => {
        await this.grid.current?.setFocus(0);
        await this.detailTable.current?.update();
      });
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    if (this.state.isReported && this.state.data.appgubun !== '001') {
      await api.fxExec('delete',
        {
          sndflag: this.state.focusedJournal?.sndflag,
          rptdate: this.state.focusedJournal?.rptdate,
          perid: this.state.focusedJournal?.perid,
          appnum: this.state.focusedJournal?.appnum,
          appgubun: this.state.focusedJournal?.appgubun,
        }, false);
    } else {
      // eslint-disable-next-line max-len
      const text = `작성일자: ${this.state.focusedJournal?.rptdate}를 모두 삭제하시겠습니까?`;
      await api.delete(text, {
        sndflag: this.state.focusedJournal?.sndflag,
        rptdate: this.state.focusedJournal?.rptdate,
        perid: this.state.focusedJournal?.perid,
        appnum: this.state.focusedJournal?.appnum,
        appgubun: this.state.focusedJournal?.appgubun,
      }) && this.onRetrieveEvent(RetrieveFocusType.END);
    }
  }


  @action
  async onNewEvent2() {
    if (this.state.isReported) {
      ConfirmWarning.show('경고', '이미 결재된 문서입니다.');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'dw_3_new',
      {
        rptdate: this.state.data.rptdate || Today.date(),
        rptnum: this.state.data?.rptnum,
        wkcd: this.state.data?.wkcd,
      },
    );

    const seq = this.state.detailList.length;
    data && this.setState({
      detailList: [...this.state.detailList,
        // eslint-disable-next-line no-nested-ternary,max-len
        new DetailModel({ ...data, rptnum: seq < 9 ? `0${seq + 1}` : (seq + 1).toString() }, true)],
    }, async () => {
      await this.detailTable.current?.update(true);
      this.detailTable.current?.setFocus(this.state.detailList.length - 1, 0);
    });
  }

  @action
  async onDeleteEvent2() {
    const { actionStore: api } = this.props;
    if (this.state.detailList.length === 0) {
      ConfirmWarning.show('경고', '청구할 항목이 없습니다.');
      return;
    }
    const text = `순번 : ${this.state.focusedDetail?.rptnum} 삭제하시겠습니까?`;
    await api.fxDelete(
      'dw_3_delete',
      text,
      {
        rptdate: this.state.data?.rptdate,
        perid: this.state.data?.perid,
        rptnum: this.state.focusedDetail?.rptnum,
        appgubun: this.state.data?.appgubun,
        wkcd: this.state.focusedDetail?.wkcd,
        plandate: '',
        actcd: this.state.focusedDetail?.actcd,
        equpcd: this.state.focusedDetail?.equpcd,
        lkdate: '',
        lknum: '',
      },
    ) && this.onRowFocusEvent(this.state.focusedJournal!, this.state.focusIndex);
  }

  @action
  async onPrintEvent() {
    if (!ConfirmWarning.assert(this.state.data?.rptdate, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    const { actionStore: api } = this.props;
    await api.printWithElmanManager({
      rptdate: this.state.data.rptdate,
      perid: this.state.perid,
    });
  }


  @action
  async onLoadToday() {
    const { actionStore: api } = this.props;

    if (!this.state.data?.rptdate) {
      ConfirmWarning.show('오류', '업무일지 일자를 먼저 선택해주세요');
      return;
    }

    if (!await Confirm.show('선택', '스케쥴(계획) 등록한 내용을 불러옵니다. \n불러오시겠습니까?', ConfirmType.QUESTION)) {
      return;
    }

    const data = await api.fxExec('wb_today',
      {
        rptdate: this.state.data.rptdate,
        perid: this.state.data.perid,
        st_lat: this.state.data.st_lat,
        st_lng: this.state.data.st_lng,
        end_actnm: this.state.data.end_actnm,
        end_lat: this.state.data.end_lat,
        end_lng: this.state.data.end_lng,
      });

    const initialRptnum = this.state.detailList?.length ? Number(this.state.detailList[this.state.detailList.length - 1].rptnum) : 0;

    if (data.items) {
      this.setState({
        detailList: [
          ...this.state.detailList,
          ...data.items?.map((x: any, i: number) => new DetailModel({
            ...x,
            rptdate: this.state.data.rptdate,
            rptnum: `00${i + 1 + initialRptnum}`.substr(-2),
          }, true)),
        ],
      }, () => this.detailTable.current?.update());
    }
  }

  @action
  async openEducationModal() {
    this.setState({
      isEducationModalVisible: true,
    }, () => this.modalEducationRetrieve());
  }

  @action
  async closeEducationModal() {
    this.setState({
      popupEducationList: [],
      isEducationModalVisible: false,
    });
  }

  @action
  async modalEducationRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('wb_education',
      {
        rptdate: this.state.data.rptdate,
        perid: this.state.data.perid,
        edudate: this.state.data.edudate,
        edunum: this.state.data.edunum,
        title: '',
      }, false);

    if (data?.items) {
      this.setState({
        popupEducationList: data.items?.map((x: any) => new EducationPopupModel(x)),
      }, async () => {
        await this.popupEducationGrid.current?.setFocus(0);
      });
    } else {
      this.setState({
        popupEducationList: [],
      }, () => this.popupTable.current?.update());
    }
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async modalEducationSave() {
    if (!await Confirm.show('확인', '선택한 내역으로 교육받음 처리 하시겠습니까?', ConfirmType.QUESTION)) {
      return;
    }

    // 교육 불러오기 버튼 임시 보류
    ConfirmWarning.show('확인', '해당 기능 준비중입니다.');
    // return;

    // const { actionStore: api } = this.props;
    // if (this.state.popupEducationList?.length! < 1) {
    //   await this.setState({ isEducationModalVisible: false });
    //   return;
    // }
    //
    // const data = await api.fxExec('save',
    //   {
    //     sub: 'w_popup_e037_pb404_perid',
    //     items: this.state.popupEducationList,
    //   });
    //
    // if (data) {
    //   this.closeEducationModal();
    // }
  }

  @action
  async openChargeModal() {
    this.setState({
      isChargeModalVisible: true,
    }, () => this.modalChargeRetrieve());
  }

  @action
  async closeChargeModal() {
    this.setState({
      popupChargeList: [],
      isGraphVisible: false,
      isChargeModalVisible: false,
    });
  }

  @action
  async modalChargeRetrieve() {
    const { actionStore: api } = this.props;
    const { user } = this.props.publicStore;

    this.graph = `https://api.elmansoft.com/chart/stick2.php?database=weberp&window=w_popup_e033w&type=0&as_custcd=${user.custcd}&as_spjangcd=${user.spjangcd}&as_mon=${this.state.popupStmon}`;

    const data = await api.retrieve(
      {
        sub: 'w_popup_e033w',
        stmon: this.state.popupStmon,
      },
    );

    if (data?.items) {
      this.setState({
        isGraphVisible: true,
        popupChargeList: data.items?.map((x: any) => new ChargePopupModel({ open: '0', ...x })),
      }, async () => {
        await this.popupChargeGrid.current?.setFocus(0);
      });
    } else {
      this.setState({
        isGraphVisible: true,
        popupChargeList: [],
      });
    }
  }


  @action
  async modalChargePrint() {
    const { actionStore: api } = this.props;

    await api.printWithElmanManager(
      {
        sub: 'w_popup_e033w',
        stmon: this.state.popupStmon,
      },
    );
  }

  @action
  async openRecordModal() {
    this.setState({
      isRecordModalVisible: true,
    }, () => this.modalRecordRetrieve());
  }

  @action
  async closeRecordModal() {
    this.setState({
      popupRecordList: [],
      isRecordModalVisible: false,
    });
  }

  @action
  async modalRecordRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve(
      {
        sub: 'w_tb_e101_history',
        stmon: this.state.stmon,
        perid: this.state.perid,
      },
    );

    if (data?.items) {
      this.setState({
        popupRecordList: data.items?.map((x: any) => new RecordPopupModel(x)),
      }, () => this.popupRecordTable.current?.update());
    } else {
      this.setState({
        popupRecordList: [],
      }, () => this.popupRecordTable.current?.update());
    }
  }

  @action
  async modalRecordSave() {
    const { actionStore: api } = this.props;
    if (this.state.popupRecordList?.length! < 1) {
      await this.setState({ isEducationModalVisible: false });
      return;
    }

    const data = await api.save(
      {
        sub: 'w_tb_e101_history',
        items: this.state.popupEducationList,
      }, false,
    );

    if (data) {
      this.closeEducationModal();
    }
  }

  @action
  async modalRecordNew() {
    const { actionStore: api } = this.props;
    const data = await api.new(
      {
        sub: 'w_tb_e101_history',
        // 입력필요
      },
    );

    data && this.setState({
      popupRecordList: [...this.state.popupRecordList, new RecordPopupModel(data, true)],
    }, async () => {
      await this.popupRecordTable.current?.update(false);
      this.popupRecordTable.current?.setFocus(this.state.popupRecordList.length - 1, 0);
    });
  }

  @action
  async modalRecordDelete() {
    const { actionStore: api } = this.props;
    if (this.state.popupRecordList.length === 0) {
      ConfirmWarning.show('경고', '삭제할 항목이 없습니다.');
      return;
    }

    const text = `순번 : ${this.state.focusedRecord?.seq}\n 기록일자 : ${this.state.focusedRecord?.hdate}`;

    const data = await api.delete(
      text,
      {
        sub: 'w_tb_e101_history',
        ...this.state.focusedRecord,
      },
    );

    if (data) {
      this.modalRecordRetrieve();
    }
  }

  @action
  async openScheduleModal() {
    // 익일 계획
    const date = new Date(Date8.withDash(this.state.data.rptdate));
    date.setDate(date.getDate() + 1);

    if (this.state.data.kukcd === '01') {
      // 총무팀
      this.setState({
        isScheduleGreetingModalVisible: true,
        schedulePopupDate: Date8.make(date),
      }, () => this.modalSchduleRetrieve());
    } else {
      // 그 외
      this.setState({
        isScheduleMaintenanceModalVisible: true,
        schedulePopupDate: Date8.make(date),
      }, () => this.modalSchduleRetrieve());
    }
  }

  @action
  async closeScheduleModal() {
    this.setState({
      schedulePopupDate: '',
      popupScheduleList: [],
      isScheduleGreetingModalVisible: false,
      isScheduleMaintenanceModalVisible: false,
    });
    this.updatedRows = [];
    this.onRetrieveEvent();
  }

  @action
  async modalSchduleRetrieve() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve(
      {
        sub: 'w_popup_e501',
        plandate: this.state.schedulePopupDate,
        perid: this.state.data.perid,
      },
    );

    if (data?.items) {
      this.setState({
        popupScheduleList: data?.items,
      }, () => this.popupScheduleTable.current?.update());
    } else {
      this.setState({
        popupScheduleList: [],
      }, () => this.popupScheduleTable.current?.update());
    }
  }

  @action
  async modalSchduleDelete() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 목록에서 삭제하시겠습니까?';

    await api.delete(text, {
      sub: 'w_popup_e501',
      plandate: this.state.focusedSchedule?.plandate,
      plannum: this.state.focusedSchedule?.plannum,
      upflag: this.state.focusedSchedule?.upflag,
    }) && await this.modalSchduleRetrieve();

    this.updatedRows = [];
  }

  @action
  async modalSchduleSave() {
    const { actionStore: api } = this.props;
    if (this.state.popupScheduleList?.length) {
      await api.save(
        {
          sub: 'w_popup_e501',
          items: this.state.popupScheduleList,
        }, this.state.data.new === '1',
      );
    }

    this.closeScheduleModal();
  }

  @action
  async modalSchduleNew() {
    const { actionStore: api, publicStore } = this.props;
    const seq:number | undefined = this.state.popupScheduleList?.length;

    const data = await api.fxExec('new',
      {
        sub: 'w_popup_e501',
        planmon: this.state.schedulePopupDate.substring(0, 6),
      });

    if (data) {
      const newModel = new SchedulePopupModel({
        ...data,
        perid: data.perid || publicStore.user.perid,
        pernm: data.pernm || publicStore.user.pernm,
        plandate: this.state.schedulePopupDate,
        // eslint-disable-next-line no-nested-ternary
        plannum: seq! < 10 ? `00${seq! + 1}` : seq! < 100 ? `0${seq! + 1}` : (seq! + 1).toString(),
      }, true);

      this.setState({
        popupScheduleList: [...this.state.popupScheduleList!, newModel],
        focusedSchedule: newModel,
      }, async () => {
        await this.popupScheduleTable.current?.update(false);
        this.popupScheduleTable.current?.setFocus(this.state.popupScheduleList?.length! - 1);
      });
    }
  }

  @action
  async openEqupModal() {
    if (this.state.actcd === '') {
      ConfirmWarning.show('확인', '먼저 현장을 선택하세요!');
      return;
    }
    this.setState({
      isEqupModalVisible: true,
    }, () => this.modalEqupRetrieve());
  }

  @action
  async closeEqupModal() {
    this.setState(
      {
        popupWkcd: '001',
        popupRemark: '',
        popupEqupList: [],
        isEqupModalVisible: false,
      },
    );
  }

  @action
  async modalEqupRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve(
      {
        sub: 'w_popup_e037_equpcd',
        actcd: this.state.actcd,
      },
    );

    if (data?.items) {
      this.setState({
        popupEqupList: data.items?.map((x: any) => new EqupPopupModel(x)),
      }, () => this.popupEqupTable.current?.update());
    } else {
      this.setState({
        popupEqupList: [],
      }, () => this.popupEqupTable.current?.update());
    }
  }

  @action
  async modalEqupConfirm() {
    if (this.state.popupWkcd === '') {
      ConfirmWarning.show('확인', '구분을 선택하세요!');
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.fxExec('wb_equp_create',
      {
        rptdate: this.state.data.rptdate,
        perid: this.state.data.perid,
        actcd: this.state.actcd,
        actnm: this.state.actnm,
        wkcd: this.state.popupWkcd,
        remark: this.state.popupRemark,
        items: this.state.popupEqupList.filter((x) => x.chk === '1'),
      }, true);

    // 기존 detailList에 호기생성한 data추가.
    data?.items && this.setState({
      detailList: [
        ...this.state.detailList,
        ...data?.items.map((x: any) => new DetailModel({
          ...x,
          rptdate: this.state.data.rptdate,
        }, true)),
      ],
    }, () => {
      // rptnum이 필요하여 강제로 넣어줌.
      // 기존에 detailList가 있을수있어 추가 후 rptnum갱신해줬음.
      const equpData = this.state.detailList.map((x: any, i: number) => new DetailModel({
        ...x,
        rptnum: i < 9 ? `0${i + 1}` : (i + 1).toString(),
      }, true));

      this.setState({
        detailList: equpData,
      });

      this.closeEqupModal();
      this.detailTable.current?.update();
    });
  }

  @action
  onDetailRowFocusEvent(item: DetailModel) {
    this.setState({ focusedDetail: item });
  }

  @action
  onChargeRowFocusEvent(item: ChargePopupModel) {
    this.setState({ focusedCharge: item });
  }

  @action
  onScheduleRowFocusEvent(item: SchedulePopupModel) {
    this.setState({ focusedSchedule: item });
  }

  @action
  onRecordRowFocusEvent(item: RecordPopupModel) {
    this.setState({ focusedRecord: item });
  }

  @action
  async onButtonClicked(type:any, item:any) {
    const { actionStore: api } = this.props;
    let data = {
      new: undefined,
      items: [],
    };
    const params = {
      sub: 'w_popup_e033w',
      itemname: JournalButtonClickedTypeNames[type],
      data: '',
    };

    if (type === JournalButtonClickedTypes.ADD) {
      data = await api.fxExec(
        'dw_list_buttonclikced',
        {
          ...params,
          divicd: item.divicd,
          stmon: this.state.popupStmon,
        },
      );

      if (data?.items) {
        const focusedIndex = this.state.popupChargeList.findIndex((x) => x.perid === item.perid);

        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, true),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, true),
            ...data?.items.map((x: any) => new ChargePopupModel(x)),
            ...this.state.popupChargeList.slice(focusedIndex + 1),
          ],
        });
      }
    }

    if (type === JournalButtonClickedTypes.MINUS) {
      // 버튼을 누른 행의 인덱스 추출
      const focusedIndex:number = this.state.popupChargeList.findIndex((x) => x.perid === item.perid);

      // 버튼을 누른 행 다음에 있는 첫번째 부서장 모델 추출
      const model = this.state.popupChargeList.slice(focusedIndex + 1).find((x) => x.chk === '0');

      // 버튼을 누른 행의 다음 부서장 인덱스 추출
      const deleteIndex = this.state.popupChargeList.findIndex((x) => x.divicd === model?.divicd);
      if (deleteIndex > -1) {
        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, false),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, false),
            ...this.state.popupChargeList.slice(deleteIndex),
          ],
        });
      } else {
        this.setState({
          focusedCharge: new ChargePopupModel(this.state.focusedCharge, false),
          popupChargeList: [
            ...this.state.popupChargeList.slice(0, focusedIndex),
            new ChargePopupModel(this.state.focusedCharge, false),
          ],
        });
      }
    }
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalReferenceRemark(this.state.data?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    modalStore.openApprovalRemark(this.state.data?.appnum);
  }

  @action
  onUpdatedRows(rows: Array<DetailModel>, updatedRows: Array<DetailModel>) {
    this.updatedRows = updatedRows;
    this.setState({ detailList: rows });
  }

  @action
  onScheduleUpdateRows(rows: Array<SchedulePopupModel>, updatedRows: Array<SchedulePopupModel>) {
    this.updatedScheduleRows = updatedRows;
    this.setState({ popupScheduleList: rows });
  }

  @action
  onRecordUpdateRows(rows: Array<RecordPopupModel>, updatedRows: Array<RecordPopupModel>) {
    this.updatedRecordRows = updatedRows;
    this.setState({ popupRecordList: rows });
  }

  @action
  onEqupUpdateRows(rows: Array<EqupPopupModel>, updatedRows: Array<EqupPopupModel>) {
    this.updatedEqupRows = updatedRows;
    this.setState({ popupEqupList: rows });
  }

  render() {
    return (
      <JournalTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            data: new JournalModel({
              ...this.state.data,
              ...change,
            }, this.state.data.isNew),
          }, () => callback && callback());
        }}
      />
    );
  }
}
