import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Button,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { Plan } from './Plan';
import { Format } from '../../../../utils/string';
import { SearchBinding } from '../../../../models/common';
import { Sum } from '../../../../utils/array';
import { Date6Calculator } from '../../../../utils/time';

const compyns = [
  { value: '0', remark: '견적' },
  { value: '1', remark: '공사' },
  { value: '2', remark: '매출' },
  { value: '3', remark: '폐기' },
  { value: '5', remark: '수금' },
  { value: '11', remark: '계약전' },
  { value: '12', remark: '계약완료' },
  { value: '', remark: '견적전' },
];

/**
 * 화면
 * @window w_tb_da052w_09
 * @category 계획대비추진리스트
 */
export const PlanTemplate: React.FC<TemplateProps<Plan>> = ({
  scope,
  update,
}) => {
  const setData = update!;

  return (
    <FlexLayout isVertical={true}>
      <FlexLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <SearchLayout>
          <FlexLayout size={200}>
            <span>년도</span>
            <DateTextBox
              value={scope.state.year}
              textAlign="center"
              format="####"
              onChange={(value) => setData({ year: value })}
              onEnter={(value) => setData({ year: value }, () => scope.onRetrieveEvent())}
              head={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() - 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>
              )}
              trail={(
                <button
                  onClick={() => {
                    const date = new Date(`${scope.state.year}-01-01`);
                    date.setFullYear(date.getFullYear() + 1);
                    setData({ year: date.getFullYear().toString() },
                      () => scope.onRetrieveEvent());
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>
              )}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={300}>
            <span>분기</span>
            <div>
              <OptionBox
                value={scope.state.bungi}
                data={[
                  { value: '1', remark: '1분기' },
                  { value: '2', remark: '2분기' },
                  { value: '3', remark: '3분기' },
                  { value: '4', remark: '4분기' },
                ]}
                onChange={(item) => setData({ bungi: item.value },
                  () => scope.onRetrieveEvent())}
                isDisabledTrackingStateChange={true}
              />
            </div>
          </FlexLayout>

          <FlexLayout size={250}>
            <span>구분</span>
            <ComboBox
              value={scope.state.gubun}
              style={{
                padding: '0 0 0 6px',
                fontSize: 12,
              }}
              data={[
                { value: '11', remark: '유지보수' },
                { value: '12', remark: '수리공사' },
                { value: '13', remark: '부품교체' },
              ].map((y) => new ComboBoxModel(y.value, y.remark))}
              onSelect={(option) => setData({ gubun: option.value })}
              isDisabledTrackingStateChange={true}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>부서</span>
            <TextBox
              textAlign="center"
              value={scope.state.divicd}
              onChange={(value) => setData({ divicd: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JC002',
                {},
                true,
                (item) => {
                  setData({
                    divicd: item.cd,
                    divinm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              weight={2}
              value={scope.state.divinm}
              readonly={true}
              onChange={(value) => setData({ divinm: value })}
            />
          </FlexLayout>

          <FlexLayout size={250}>
            <span style={{ color: 'var(--color-red)' }}>사용자</span>
            <TextBox
              weight={1.5}
              value={scope.state.perid}
              textAlign="center"
              onChange={(value) => scope.setState({ perid: value })}
              isDisabledTrackingStateChange={true}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_JA001',
                {},
                true,
                (item) => {
                  setData({
                    perid: item.cd,
                    pernm: item.cdnm,
                  });
                  scope.onRetrieveEvent();
                },
              )}
            />
            <TextBox
              value={scope.state.pernm}
              weight={1.5}
              readonly={true}
            />
          </FlexLayout>
        </SearchLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <FlexLayout
          weight={0.02}
          style={{ color: 'var(--color-blue)' }}
        >
          *계획내용, 진행내용, 월에서 더블클릭시 내용을 계획내용을 변경할 수 있습니다.
        </FlexLayout>

        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'divinm',
                text: '부서',
                width: 5,
                sum: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자',
                width: 4,
                sum: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '담당자별그룹',
                isHidden: true,
                group: true,
                value: (x) => x.pernm,
              },
              {
                id: 'actcd',
                text: '현장코드',
                width: 4,
                sum: (x, data) => {
                  const sumActcd = Sum.groupLength(data, 'actcd', x, ['pernm']);
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {sumActcd} 건
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {scope.state.total} 건
                  </FlexLayout>
                ),
              },
              {
                id: 'actnm',
                text: '현장명',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.actnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'equpnm',
                text: '호기명',
                width: 5,
              },
              {
                id: 'remark',
                text: '계획내용',
                width: 10,
                sum: (_) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      계획
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      실행
                    </FlexLayout>
                  </FlexLayout>
                ),
                trail: (_) => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      계획
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      실행
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    onDoubleClick={() => scope.onDetailModal(true)}
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'samt01',
                text: '1월',
                width: 6,
                sum: (x, data) => {
                  const sumSamt01 = Sum.byGroup(data, 'samt01', x, ['pernm']);
                  const sumCostamt01 = Sum.byGroup(data, 'costamt01', x, ['pernm']);
                  return (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumSamt01)}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(sumCostamt01)}
                      </FlexLayout>
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.samt01_tot}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {scope.state.costamt01_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    onDoubleClick={() => (x.samt01 > 0 || x.costamt01 > 0 ? scope.onMonthModal(true) : {})}
                  >
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt01)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.costamt01)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'samt02',
                text: '2월',
                width: 6,
                sum: (x, data) => {
                  const sumSamt02 = Sum.byGroup(data, 'samt02', x, ['pernm']);
                  const sumCostamt02 = Sum.byGroup(data, 'costamt02', x, ['pernm']);
                  return (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumSamt02)}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(sumCostamt02)}
                      </FlexLayout>
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.samt02_tot}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {scope.state.costamt02_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    onDoubleClick={() => (x.samt02 > 0 || x.costamt02 > 0 ? scope.onMonthModal(true) : {})}
                  >
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt02)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.costamt02)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'samt03',
                text: '3월',
                width: 6,
                sum: (x, data) => {
                  const sumSamt03 = Sum.byGroup(data, 'samt03', x, ['pernm']);
                  const sumCostamt03 = Sum.byGroup(data, 'costamt03', x, ['pernm']);
                  return (
                    <FlexLayout isVertical={true}>
                      <FlexLayout
                        justify="right"
                        align="center"
                      >
                        {Format.number(sumSamt03)}
                      </FlexLayout>

                      <FlexLayout
                        justify="right"
                        align="center"
                        style={{ color: 'var(--color-blue)' }}
                      >
                        {Format.number(sumCostamt03)}
                      </FlexLayout>
                    </FlexLayout>
                  );
                },
                trail: () => (
                  <FlexLayout isVertical={true}>
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.samt03_tot}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {scope.state.costamt03_tot}
                    </FlexLayout>
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    isVertical={true}
                    onDoubleClick={() => (x.samt03 > 0 || x.costamt03 > 0 ? scope.onMonthModal(true) : {})}
                  >
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {Format.number(x.samt03)}
                    </FlexLayout>

                    <FlexLayout
                      justify="right"
                      align="center"
                      style={{ color: 'var(--color-blue)' }}
                    >
                      {Format.number(x.costamt03)}
                    </FlexLayout>
                  </FlexLayout>
                ),
              },
              {
                id: 'compyn',
                text: '진행상태',
                width: 6,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{
                      color: x.compyn === '2'
                        ? 'var(--color-blue)' : x.compyn === '5'
                          ? '#7F7A2C' : x.compyn === ''
                            ? 'var(--color-red)' : x.compyn === '11'
                              ? 'var(--color-red)' : x.compyn === '12'
                                ? 'var(--color-blue)' : '',
                    }}
                  >
                    {compyns.filter((y) => y.value === x.compyn)[0]?.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'state_remark',
                text: '진행내용',
                width: 10,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                    onDoubleClick={() => scope.onProgressModalModal(true)}
                  >
                    {x.state_remark}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.data}
            infinityHandler={scope.infinity}
            rowHeight={Global.LAYOUT_SEARCH_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>
      </FlexLayout>

      <ModalView
        width={600}
        height={500}
        isVisible={scope.state.detailModal}
        onClose={() => scope.onDetailModal(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>상세내용</LayoutTitle>

          <FlexLayout weight={7}>
            <LabelBox title="계획내용">
              <TextBox
                isMultiline={true}
                value={scope.state.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onDetailPopup()}>확인</Button>
              <Button onClick={() => scope.onDetailModal(false)}>취소</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={400}
        height={70}
        isVisible={scope.state.monthModal}
        onClose={() => scope.onMonthModal(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <SearchLayout>
            <FlexLayout size={200}>
              <span>월</span>
              <DateTextBox
                value={scope.state.yearmon}
                textAlign="center"
                format="####년 ##월"
                onChange={(value) => {
                  setData({ yearmon: value },
                    () => scope.onRetrieveEvent());
                }}
                onEnter={() => scope.onRetrieveEvent()}
                head={(<button
                  onClick={() => {
                    setData({ yearmon: new Date6Calculator(scope.state.yearmon).add(-1).toString() });
                  }}
                >
                  <MdKeyboardArrowLeft size={24}/>
                </button>)}
                trail={(<button
                  onClick={() => {
                    setData({ yearmon: new Date6Calculator(scope.state.yearmon).add().toString() });
                  }}
                >
                  <MdKeyboardArrowRight size={24}/>
                </button>)}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>

            <Button onClick={() => scope.onMonthPopup()}>
              확인
            </Button>

            <Button onClick={() => scope.onMonthModal(false)}>
              취소
            </Button>
          </SearchLayout>
        </FlexLayout>
      </ModalView>


      <ModalView
        width={600}
        height={500}
        isVisible={scope.state.progressModal}
        onClose={() => scope.onProgressModalModal(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>상세내용</LayoutTitle>

          <FlexLayout weight={7}>
            <LabelBox title="진행내용">
              <TextBox
                isMultiline={true}
                value={scope.state.content}
                onChange={(value) => setData({ content: value })}
              />
            </LabelBox>
          </FlexLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onProgressPopup()}>확인</Button>
              <Button onClick={() => scope.onProgressModalModal(false)}>취소</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
