// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderTab_container__332QG {\n  overflow: hidden;\n  display: block;\n  position: relative;\n  height: 40px;\n  background-color: var(--color-primary); }\n", "",{"version":3,"sources":["webpack://src/app/components/common/HeaderTab/HeaderTab.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,YAAY;EACZ,sCAAsC,EAAA","sourcesContent":[".container {\n  overflow: hidden;\n  display: block;\n  position: relative;\n  height: 40px;\n  background-color: var(--color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HeaderTab_container__332QG"
};
export default ___CSS_LOADER_EXPORT___;
