import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/md';
import { GiSave } from 'react-icons/all';
import {
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  FlexLayout,
  FormatNumberTextBox,
  GridLayout,
  LabelBox,
  LayoutTitle,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TableLayout,
  TextBox,
} from '../../../../components';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  GiveRegistration,
  ItemChange,
} from './GiveRegistration';
import { SearchBinding } from '../../../../models/common';
import { Format } from '../../../../utils/string';
import { Date8 } from '../../../../utils/time';
import { Sum } from '../../../../utils/array';

/**
 * 화면
 * @window w_tb_ca642_01_all
 * @category 지급등록
 */
export const GiveRegistrationTemplate: React.FC<TemplateProps<GiveRegistration>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;

  return (
    <FlexLayout isVertical={true}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout size={200}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() - 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.stdate));
                date.setDate(date.getDate() + 1);
                scope.setState({ stdate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() - 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowLeft size={24}/>
            </button>)}
            trail={(<button
              onClick={() => {
                const date = new Date(Date8.withDash(scope.state.enddate));
                date.setDate(date.getDate() + 1);
                scope.setState({ enddate: Date8.make(date) },
                  () => scope.onRetrieveEvent());
              }}
            >
              <MdKeyboardArrowRight size={24}/>
            </button>)}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout size={350}>
          <span style={{ color: 'var(--color-red)' }}>거래처</span>
          <TextBox
            textAlign="center"
            value={scope.state.cltcd}
            onChange={(value) => scope.setState({
              cltcd: value,
            })}
            onDoubleClick={() => scope.onGivePopupOpen(true)}
            onEnter={(value) => {
              scope.onGivePopupOpen(true);
              scope.SS({
                popupSearchQuery: value,
              });
            }}
          />
          <TextBox
            weight={2}
            value={scope.state.cltnm}
            onChange={(value) => scope.setState({ cltnm: value })}
            readonly={true}
          />
        </FlexLayout>
      </SearchLayout>

      <FlexLayout
        weight={0.5}
        isVertical={true}
      >
        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox title="고객명">
            <TextBox
              textAlign="left"
              value={model.cltnm || scope.state.cltnm}
              onChange={(value) => setData({ cltnm: value })}
              readonly={true}
            />
          </LabelBox>

          <LabelBox
            title="지급일자"
            size={200}
          >
            <DateTextBox
              format="####-##-##"
              value={model.snddate}
              textAlign="center"
              onChange={(item) => setData({ snddate: item },
                () => scope.onitemChange(item.value, ItemChange.snddate))}
              // readonly={true}
            />
          </LabelBox>

          <LabelBox
            title="지급번호"
             size={200}
            >
            <TextBox
              textAlign="left"
              value={model.sndnum}
              onChange={(value) => setData({ sndnum: value })}
              readonly={true}
            />
          </LabelBox>

          <LabelBox title="지급형태">
            <FlexLayout size={65}>
              <CheckBox
                value={model.hchk === '1'}
                onChange={(value) => setData({ hchk: value ? '1' : '0' })}
                isDisabledTrackingStateChange={true}
              />
              <label style={{ marginTop: 9 }}>현금</label>
            </FlexLayout>

            <FlexLayout size={65}>
              <CheckBox
                value={model.bchk === '1'}
                onChange={(value) => setData({ bchk: value ? '1' : '0' })}
                isDisabledTrackingStateChange={true}
              />
              <label style={{ marginTop: 9 }}>예금</label>
            </FlexLayout>

            <FlexLayout size={65}>
              <CheckBox
                value={model.gchk === '1'}
                onChange={(value) => setData({ gchk: value ? '1' : '0' })}
                isDisabledTrackingStateChange={true}
              />
              <label style={{ marginTop: 9 }}>기타</label>
            </FlexLayout>
          </LabelBox>

          <LabelBox title="이체수수료구분">
            <OptionBox
              value={model.bmarflag}
              data={[
                { value: '1', remark: '포함' },
                { value: '0', remark: '없음' },
              ]}
              onChange={(v) => setData({ bmarflag: v.value },
                () => scope.onitemChange(v.value, ItemChange.bmarflag))}
            />
          </LabelBox>

          <LabelBox title="현금지급">
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model?.hamt}
              onChange={(value) => setData({ hamt: value })}
              onBlur={() => scope.updateTotal()}
              readonly={model.hchk !== '1'}
            />
          </LabelBox>

          <LabelBox title="기타구분">
            <ComboBox
              value={model?.ggubun}
              textAlign="center"
              data={scope.state.gubun?.map((x: any) => new ComboBoxModel(x.code, x.cnam))}
              onSelect={(v) => setData({ ggubun: v.value })}
              isDisabledTrackingStateChange={true}
            />
          </LabelBox>

          <LabelBox
            title="기타지급"
            size={200}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model?.gamt}
              onChange={(value) => setData({ gamt: value })}
              onBlur={() => scope.updateTotal()}
              readonly={model.gchk !== '1'}
            />
          </LabelBox>
        </FlexLayout>

        <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
          <LabelBox
            title="적요"
            size={618}
          >
            <TextBox
              value={model.remark}
              onChange={(value) => setData({ remark: value })}
            />
          </LabelBox>

          <LabelBox
            title="예금지급"
            size={214}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model?.bamt}
              onChange={(value) => setData({ bamt: value })}
              onEnter={(value) => setData({ bamt: value }, () => scope.updateTotal())}
              onBlur={() => scope.updateTotal()}
              readonly={model.bchk !== '1'}
            />
          </LabelBox>

          <LabelBox
            title="이체수수료"
            size={213}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model?.bmar}
              readonly={model.bchk !== '1'}
              onChange={(value) => setData({ bmar: value })}
              onBlur={() => scope.updateTotal()}
            />
          </LabelBox>

          <LabelBox
            title="지급은행"
            style={{ color: 'var(--color-red)' }}
            footer={
              <Button
                isIcon={true}
                style={{ fontSize: 22 }}
                onClick={() => scope.onButtonclick(model.bankcd)}
              >
                <GiSave/>
              </Button>
            }
          >
            <TextBox
              textAlign="center"
              value={model.bchk === '1' ? model.bankcd : ' '}
              readonly={model.bchk !== '1'}
              onChange={(value) => setData({ bankcd: value })}
              bindSearchModal={new SearchBinding(
                scope.props.modalStore,
                'TB_AA040',
                {},
                true,
                (item) => {
                  setData({
                    bankcd: item.cd,
                    banknm: item.uv_arg3,
                  });
                },
              )}
            />
            <TextBox
              weight={1.3}
              value={model.banknm}
              onChange={(value) => setData({ banknm: value })}
              readonly={true}
            />
          </LabelBox>

          <LabelBox title="계좌번호">
            <TextBox
              textAlign="center"
              value={model.accnum}
              readonly={model.dchk !== '1'}
              onChange={(value) => setData({ accnum: value })}
            />
          </LabelBox>

          <LabelBox
            title="합계금액"
            size={200}
          >
            <FormatNumberTextBox
              char={','}
              charAt={3}
              textAlign="right"
              value={model?.total_amt}
              color={'var(--color-red)'}
              onChange={(value) => setData({ total_amt: value })}
              readonly={true}
            />
          </LabelBox>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout isVertical={true}>
        <SubHeader>
          <div>
            <span>미지급내역</span>
          </div>

          <FlexLayout justify="end">
          </FlexLayout>
        </SubHeader>

        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'chk',
              text: '선택',
              width: 50,
              render: (x, rowUpdate) => (
                <CheckBox
                  value={x.chk === '1'}
                  onChange={(v) => {
                    rowUpdate({ chk: v ? '1' : '0' });
                  }}
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
            {
              id: 'mijdate',
              text: '미지급일자',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                 합계
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.mijdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'mijnum',
              text: '번호',
              width: 70,
            },
            {
              id: 'cltnm',
              text: '발생거래처',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
            {
              id: 'remark',
              text: '적요',
              width: 200,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'mijamt',
              text: '발생금액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.mijamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.mijamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'janamt',
              text: '미지급잔액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.janamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.janamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'inamt',
              text: '기지급액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {scope.state.inamt_tot}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.inamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'winamt',
              text: '예정지급액',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.giveList, 'winamt_tot'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.winamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wbamt',
              text: '예금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.giveList, 'wbamt_tot'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wbamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'whamt',
              text: '현금',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.giveList, 'whamt_tot'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.whamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'wgamt',
              text: '기타',
              width: 100,
              trail: () => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(Sum.all(scope.state.giveList, 'gamt_tot'))}
                </FlexLayout>
              ),
              render: (x) => (
                <FlexLayout
                  justify="right"
                  align="center"
                >
                  {Format.number(x.wgamt)}
                </FlexLayout>
              ),
            },
            {
              id: 'schdate',
              text: '지급예정일',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.schdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'tax_spdate',
              text: '계산서일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.tax_spdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'tax_spnum',
              text: '번호',
              width: 50,
            },
            {
              id: 'acc_spdate',
              text: '전표일자',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {Date8.withDash(x.acc_spdate)}
                </FlexLayout>
              ),
            },
            {
              id: 'acc_spnum',
              text: '번호',
              width: 50,
            },
          ]}
          data={scope.state.giveList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowFocusEvent={(item, index) => scope.onMainRowFocusEvent(item, index)}
          onChange={(rows) => {
            scope.onUpdatedRows(rows);
          }}
        />
      </FlexLayout>

      <SubHeader>
        <div>
          <span>지급내역</span>
        </div>

        <FlexLayout justify="end">
        </FlexLayout>
      </SubHeader>

      <FlexLayout>
        <FlexLayout>
          <GridLayout
            ref={scope.grid}
            header={[
              {
                id: 'snddate',
                text: '지급일자',
                width: 40,
                trail: (_) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.snddate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'sndnum',
                text: '번호',
                width: 20,
              },
              {
                id: 'amt',
                text: '지급액',
                width: 40,
                trail: () => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {scope.state.iamt_tot}
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.amt)}
                  </FlexLayout>
                ),
              },
            ]}
            data={scope.state.giveDetailList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item) => scope.onRowFocusEvent(item)}
          />
        </FlexLayout>

        <FlexLayout weight={3}>
          <GridLayout
            ref={scope.detail2Grid}
            header={[
              {
                id: 'sndseq',
                text: '순번',
                width: 5,
              },
              {
                id: 'mijdate',
                text: '발생일자',
                width: 7,
                trail: (_) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    합 계
                  </FlexLayout>
                ),
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.mijdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'mijnum',
                text: '번호',
                width: 5,
              },
              {
                id: 'remark',
                text: '적요',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.remark}
                  </FlexLayout>
                ),
              },
              {
                id: 'iamt',
                text: '발생금액',
                width: 7,
                trail: (data) => {
                  const sumIamt = Sum.all(data, 'iamt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumIamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.iamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'bamt',
                text: '예금',
                width: 7,
                trail: (data) => {
                  const sumBamt = Sum.all(data, 'bamt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumBamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.bamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'banknm',
                text: '은행',
                width: 7,
              },
              {
                id: 'accnum',
                text: '계좌번호',
                width: 10,
              },
              {
                id: 'hamt',
                text: '현금',
                width: 7,
                trail: (data) => {
                  const sumHamt = Sum.all(data, 'hamt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumHamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.hamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'gamt',
                text: '기타',
                width: 7,
                trail: (data) => {
                  const sumGamt = Sum.all(data, 'gamt');
                  return (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {Format.number(sumGamt)}
                    </FlexLayout>
                  );
                },
                render: (x) => (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(x.gamt)}
                  </FlexLayout>
                ),
              },
              {
                id: 'acc_spdate',
                text: '전표일자',
                width: 7,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {Date8.withDash(x.acc_spdate)}
                  </FlexLayout>
                ),
              },
              {
                id: 'acc_spnum',
                text: '번호',
                width: 6,
              },
            ]}
            data={scope.state.giveDetailList2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          />
        </FlexLayout>
      </FlexLayout>


      <ModalView
        width={800}
        isVisible={scope.state.givePopupIsvisible}
        onClose={() => {
          scope.onGivePopupClose();
        }}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <LayoutTitle>매입거래처선택</LayoutTitle>

          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout>
              <span>검색어</span>
              <TextBox
                value={scope.state.popupSearchQuery}
                onChange={(value) => scope.SS({ popupSearchQuery: value })}
                onEnter={() => scope.onGivePopupRetrieve()}
              >
              </TextBox>
              <FlexLayout
                size={100}
                justify="center"
                align="center"
              >
                <CheckBox
                  noMargin={true}
                  value={scope.state.rtclafiChk === '1'}
                  onChange={(value) => scope.setState({ rtclafiChk: value ? '1' : '0' }, () => scope.onGivePopupRetrieve())}
                >
                </CheckBox>
                <span>퇴사자포함</span>
              </FlexLayout>
            </FlexLayout>
          </SearchLayout>

          <TableLayout
            ref={scope.cltcdPopupTable}
            header={[
              {
                id: 'gubun',
                text: '구분',
                width: 60,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {
                      x.gubun === '1' ? '거래처'
                        : x.gubun === '2' ? '사원'
                          : x.gubun === '3' ? '부서'
                            : x.gubun === '4' ? '은행'
                              : x.gubun === '5' ? '카드'
                                : x.gubun === '6' ? '현장'
                                  : x.gubun === '%' ? '전체' : ''
                    }
                  </FlexLayout>
                ),
              },
              {
                id: 'cltcd',
                text: '코드',
                width: 70,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.cltcd}
                  </FlexLayout>
                ),
              },
              {
                id: 'cltnm',
                text: '거래처명',
                width: 150,
                render: (x) => (
                  <FlexLayout
                    justify="left"
                    align="center"
                  >
                    {x.cltnm}
                  </FlexLayout>
                ),
              },
              {
                id: 'saupnum',
                text: '사업자번호',
                width: 100,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.saupnum.substr(0, 3)}-{x.saupnum.substr(3, 2)}-{x.saupnum.substr(5, 5)}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '대표자',
                width: 80,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                  >
                    {x.prenm}
                  </FlexLayout>
                ),
              },
            ]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            data={scope.state.givePopupData}
            onRowFocusEvent={(item) => scope.onGivePopupRowfocusEvent(item)}
          >
          </TableLayout>

          <SubHeader>
            <FlexLayout justify="end">
              <Button onClick={() => scope.onGivePopupConfirm()}
              >
                확인
              </Button>
              <Button
                onClick={() => {
                  scope.onGivePopupClose();
                }}
              >
                닫기
              </Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
