import { computed, observable } from 'mobx';
import { Sort } from '../../utils/array';
import { RetrieveFocusType } from '../../constants';

type InfinityRetrieveVoidCallback = () => void;

type InfinityRetrieveCaller = (params: any) => Promise<any>;

type InfinityRetrieveCallback = (items: Array<any>, next?: InfinityRetrieveVoidCallback) => void;

export default class InfinityRetrieve {
  page: number = -2; // -2:Init state, -1:Loaded, 0, 1, 2, ...:Pages

  params: any;

  @observable totalCount: number;

  count: number;

  @observable isEnabled: boolean;

  @observable isLoading: boolean;

  container: any;

  inventory: Array<any>;

  caller: InfinityRetrieveCaller;

  callback?: InfinityRetrieveCallback;

  onSort?: InfinityRetrieveVoidCallback;

  constructor(
    params: any = {},
    caller: InfinityRetrieveCaller,
    callback?: InfinityRetrieveCallback,
    onSort?: InfinityRetrieveVoidCallback,
  ) {
    this.params = params;
    this.caller = caller;
    this.callback = callback;
    this.onSort = onSort;
    this.totalCount = 0;
    this.count = 0;
    this.container = {};
    this.inventory = [];
    this.isEnabled = true;
    this.isLoading = false;
  }

  async init() {
    const data = await this.caller(this.params);
    this.inventory = data?.items || [];

    // eslint-disable-next-line no-prototype-builtins
    if (data?.hasOwnProperty('items')) {
      this.container = {
        ...data,
      };
      // @ts-ignore
      delete this.container.items;
    } else {
      this.container = data;
    }
  }

  async retrieveAll(): Promise<any> {
    if (this.page < -1) {
      await this.init();
      this.page = -1;
    }

    // eslint-disable-next-line no-async-promise-executor
    return new Promise((resolve) => {
      const data = {
        ...this.container,
        items: this.inventory,
      };
      this.totalCount = this.inventory.length;
      this.apply(data);
      resolve(this.inventory);
    });
  }

  async retrieve(callback?: InfinityRetrieveVoidCallback): Promise<any> {
    this.isEnabled = false;
    this.isLoading = true;

    if (this.page < -1) {
      await this.init();
      this.page = -1;
    }

    this.page += 1;

    const data = {
      ...this.container,
      page: this.page,
      items: this.inventory.slice(this.page * 50, this.page * 50 + 50),
    };

    return this.apply(data, callback);
  }

  async retrieveTo(
    column: string | Array<string>,
    value: string | Array<string | undefined> | undefined,
    type: RetrieveFocusType = RetrieveFocusType.DEFAULT,
    all?: boolean,
  ): Promise<number> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      this.isEnabled = false;
      this.isLoading = true;

      if (this.page < -1) {
        await this.init();
        this.page = -1;
      }

      let position = -1;
      if (typeof column === 'string') {
        position = this.inventory.findIndex((x) => x[column] === value);
      } else if (value && typeof value !== 'string' && value.filter((x) => x).length) {
        position = this.inventory.findIndex((x) => {
          // eslint-disable-next-line no-restricted-syntax
          for (let i = 0; i < column.length; i += 1) {
            // eslint-disable-next-line no-plusplus
            if (x[column[i]] !== value[i]) {
              return false;
            }
          }
          return true;
        });
      }

      let data = {};

      if (all) {
        this.page = -1;
        data = {
          ...this.container,
          page: this.page,
          items: this.inventory,
        };
        this.totalCount = this.inventory.length;
      } else {
        this.page = position === -1 ? this.page + 1 : Math.ceil(position / 50.0);

        if (type === RetrieveFocusType.END) {
          this.page = Math.ceil(this.inventory.length - 1 / 50.0);
        }

        data = {
          ...this.container,
          page: this.page,
          items: this.inventory.slice(0, this.page * 50 + 50),
        };
      }

      this.apply(data, () => {
        switch (type) {
          case RetrieveFocusType.DEFAULT:
            resolve(position < 0 ? 0 : position);
            break;

          case RetrieveFocusType.FIRST:
            resolve(0);
            break;

          case RetrieveFocusType.END:
            resolve(this.inventory.length - 1);
            break;

          default:
            resolve(0);
        }
      });
    });
  }

  // For react-virtualized
  async loadMoreRows(): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      await this.retrieve();
      resolve();
    });
  }

  apply(data: any, retrieveCallback?: InfinityRetrieveVoidCallback): any {
    this.totalCount = parseInt(data?.total || '0', 10);

    if (data
      && data.items
      && data.items instanceof Array
      && data.items.length > 0
      && this.count <= this.totalCount
    ) {
      this.count += data?.items?.length || 0;
      if (retrieveCallback) {
        this.callback && this.callback(data.items, retrieveCallback);
      } else {
        this.callback && this.callback(data.items);
      }

      this.isEnabled = this.count < this.totalCount;
      this.isLoading = false;
      return data;
    }

    this.isEnabled = false;
    this.isLoading = false;
    retrieveCallback && retrieveCallback();

    return {};
  }

  @computed
  get hasNext(): boolean {
    return this.isEnabled;
  }

  @computed
  get total(): number {
    return this.totalCount;
  }

  @computed
  get loaded(): number {
    return this.count;
  }

  @computed
  get box(): any {
    return this.container;
  }

  sortBy(key: string, isDesc: boolean = false) {
    if (!this.inventory.length) {
      return;
    }

    if (isDesc) {
      this.inventory = Sort.desc(key, this.inventory);
    } else {
      this.inventory = Sort.asc(key, this.inventory);
    }

    this.count = 0;
    this.page = -1;
    this.onSort && this.onSort();
  }
}
