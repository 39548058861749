// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OptionBox_item__2SLLr {\n  cursor: pointer;\n  color: var(--color-secondary); }\n  .OptionBox_item__2SLLr > span {\n    padding: 0 4px;\n    font-size: 12px;\n    -webkit-user-select: none;\n            user-select: none; }\n  .OptionBox_item__2SLLr > svg {\n    font-size: 16px; }\n  .OptionBox_item__2SLLr.OptionBox_active__2p8oD {\n    color: var(--color-blue); }\n  .OptionBox_item__2SLLr:hover {\n    color: #6c84a8; }\n  .OptionBox_item__2SLLr:active {\n    opacity: 0.7; }\n\n.OptionBox_readonly__2v7de {\n  -webkit-filter: grayscale(1);\n          filter: grayscale(1);\n  opacity: .5;\n  pointer-events: none; }\n", "",{"version":3,"sources":["webpack://src/app/components/forms/OptionBox/OptionBox.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,6BAA6B,EAAA;EAF/B;IAKI,cAAc;IACd,eAAe;IACf,yBAAiB;YAAjB,iBAAiB,EAAA;EAPrB;IAWI,eAAe,EAAA;EAXnB;IAeI,wBAAwB,EAAA;EAf5B;IAmBI,cAAc,EAAA;EAnBlB;IAuBI,YAAY,EAAA;;AAIhB;EACE,4BAAoB;UAApB,oBAAoB;EACpB,WAAW;EACX,oBAAoB,EAAA","sourcesContent":[".item {\n  cursor: pointer;\n  color: var(--color-secondary);\n\n  & > span {\n    padding: 0 4px;\n    font-size: 12px;\n    user-select: none;\n  }\n\n  & > svg {\n    font-size: 16px;\n  }\n\n  &.active {\n    color: var(--color-blue);\n  }\n\n  &:hover {\n    color: #6c84a8;\n  }\n\n  &:active {\n    opacity: 0.7;\n  }\n}\n\n.readonly {\n  filter: grayscale(1);\n  opacity: .5;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "OptionBox_item__2SLLr",
	"active": "OptionBox_active__2p8oD",
	"readonly": "OptionBox_readonly__2v7de"
};
export default ___CSS_LOADER_EXPORT___;
