import * as React from 'react';
import { action } from 'mobx';
import {
  PageProps,
  PageToolEvents,
} from '../../../../constants';
import { ChargeReceivableTemplate } from './ChargeReceivable.template';
import { ChargeReceivableModel } from './models/ChargeReceivable.model';
import {
  InfinityRetrieve,
  TabModel,
} from '../../../../models';
import { Today } from '../../../../utils/time';
import { ConfirmWarning } from '../../../../utils/confirm';
import {
  GridLayout,
  TableLayout,
} from '../../../../components';
import { PageComponent } from '../../../../utils/layout';
import { IntegrationModel } from './models/Integration.model';
import { HyunDaeModel } from './models/HyunDae.model';
import { WeekendLongReceivablesModel } from './models/WeekendLongReceivables.model';
import { WeekendReceivablesModel } from './models/WeekendReceivables.model';
import { SalesDetailPopupModel } from './models/SalesDetailPopup.model';

interface ChargeReceivableState {
  data: Array<ChargeReceivableModel>;
  dataIntegration: Array<IntegrationModel>,
  dataHyunDae: Array<HyunDaeModel>,
  dataWeekendLongReceivable: Array<WeekendLongReceivablesModel>,
  dataWeekendReceivable: Array<WeekendReceivablesModel>,

  yearmon: string;
  searchQuery: string;
  divicds: string;
  divinms: string;
  perids: string;
  pernms: string;

  // modal
  focused?: ChargeReceivableModel;
  focused2?: ChargeReceivableModel;
  textDetailModal: boolean;
  textDetailModal2: boolean;
  popupData: Array<ChargeReceivableModel>;
  popupData2: Array<ChargeReceivableModel>;
  taxDetailModalFocusedData: ChargeReceivableModel;
  salesDetailModal: boolean;
  salesDetailModalData: Array<SalesDetailPopupModel>;

  // trail
  bemon01_tot: string;
  bemon02_tot: string;
  bemon03_tot: string;
  totamt_tot: string;
  beamt_tot: string;
  iamt1_tot: string;
  iamt2_tot: string;
  iamt3_tot: string;
  iamt4_tot: string;
  iamt5_tot: string;
  rcvamt_tot: string;
  total: string;

  // popup trail
  shotmis_tot: string;
  longmis3_tot: string;
  mijamt_tot: string;
  iamt_tot: string;
  resuamt_tot2: string;

  // 주차별 날짜
  one_week: string;
  two_week: string;
  three_week: string;
  four_week: string;
  five_week: string;

  dayCount: number;
}

/**
 * 컨트롤러
 * @window w_input_da023w_04
 * @category 담당별미수체크
 */
export class ChargeReceivable extends PageComponent<PageProps, ChargeReceivableState>
  implements PageToolEvents {
  updatedRows?: Array<any>;

  grid: React.RefObject<GridLayout> = React.createRef();

  gridIntegration: React.RefObject<GridLayout> = React.createRef();

  gridHyunDae: React.RefObject<GridLayout> = React.createRef();

  gridWeekendLongReceivable: React.RefObject<GridLayout> = React.createRef();

  gridWeekendReceivable: React.RefObject<GridLayout> = React.createRef();

  taxDetailGrid: React.RefObject<GridLayout> = React.createRef();

  saleDetatilGrid: React.RefObject<GridLayout> = React.createRef();

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  detailItem?: Array<any>;

  tabIndex: number = 0;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    // state 기본값 정의
    this.state = props.state || {
      searchQuery: '',
      data: [],
      dataIntegration: [],
      dataHyunDae: [],
      dataWeekendLongReceivable: [],
      dataWeekendReceivable: [],

      focused: [],
      focused2: [],

      // modal
      popupData: [],
      popupData2: [],
      yearmon: Today.yearMon(),
      divicds: '',
      perids: '',
      taxDetailModalFocusedData: [],
      salesDetailModal: false,
      salesDetailModalData: [],

      // trail
      bemon03_tot: '0',
      bemon02_tot: '0',
      bemon01_tot: '0',
      totamt_tot: '0',
      total: '0',

      // popup trail
      shotmis_tot: '0',
      longmis3_tot: '0',
      mijamt_tot: '0',
      iamt_tot: '0',
      resuamt_tot2: '0',

      one_week: '',
      two_week: '',
      three_week: '',
      four_week: '',
      five_week: '',
      dayCount: 0,
    };
    this.table = React.createRef();
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    // 상세내역 모달
    const data = await api.dropdown('wf_da020_code');
    if (!data) return;
    this.detailItem = data.items.filter((x: any) => x.chk === '1').map((x: any) => x);

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(`tab_${this.tabIndex + 1}_retrieve`, {
      stmon: this.state.yearmon,
      as_nm: this.state.searchQuery,
      divicd: this.state.divicds,
      perid: this.state.perids,
    });

    // tab 이동 시 update
    if (this.tabIndex === 0) {
      await this.SS({ dataIntegration: data?.items || [] });
      await this.gridIntegration.current?.setFocus(0);
      await this.gridIntegration.current?.forceRepaint();
    } else if (this.tabIndex === 1) {
      await this.SS({ dataHyunDae: data?.items || [] });
      await this.gridHyunDae.current?.setFocus(0);
      await this.gridHyunDae.current?.forceRepaint();
    } else if (this.tabIndex === 2) {
      await this.SS({ dataWeekendLongReceivable: data?.items || [] });
      await this.gridWeekendLongReceivable.current?.setFocus(0);
      await this.gridWeekendLongReceivable.current?.forceRepaint();
    } else if (this.tabIndex === 3) {
      await this.SS({ dataWeekendReceivable: data?.items || [] });
      await this.gridWeekendReceivable.current?.setFocus(0);
      await this.gridWeekendReceivable.current?.forceRepaint();
    }

    await this.SS({
      bemon01_tot: data?.bemon01_tot || '0',
      bemon02_tot: data?.bemon02_tot || '0',
      bemon03_tot: data?.bemon03_tot || '0',
      totamt_tot: data?.totamt_tot || '0',
      beamt_tot: data?.beamt_tot || '0',
      iamt5_tot: data?.iamt5_tot || '0',
      rcvamt_tot: data?.rcvamt_tot || '0',
      total: data?.total || '0',
    });
  }

  @action
  async onRowFocusEvent(item: ChargeReceivableModel | undefined) {
    await this.setState({ focused: item });
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;
    if (this.state.focused === undefined) {
      ConfirmWarning.show('오류', '출력할 내역이 없습니다.');
      return;
    }

    await api.fxPrint(
      `tab_${this.tabIndex + 1}_print`,
      {
        stmon: this.state.yearmon,
        as_nm: this.state.searchQuery,
        divicd: this.state.divicds,
        perid: this.state.perids,
      },
    );
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.focused === undefined) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.fxExcel(
      `tab_${this.tabIndex + 1}_excel`,
      {
        stmon: this.state.yearmon,
        as_nm: this.state.searchQuery,
        divicd: this.state.divicds,
        perid: this.state.perids,
      },
    );
  }

  @action
  async textModal(isOpen: boolean) {
    await this.setState({ textDetailModal: isOpen });

    if (isOpen) {
      await this.modalRetrive();
    } else {
      await this.SS({
        popupData: [],
        shotmis_tot: '0',
        longmis3_tot: '0',
        mijamt_tot: '0',
        iamt_tot: '0',
        resuamt_tot2: '0',
      });
    }
  }

  @action
  async textModal2(isOpen: boolean) {
    await this.setState({ textDetailModal2: isOpen });

    if (isOpen) {
      await this.modalRetrive2();
    } else {
      await this.SS({
        popupData2: [],
      });
    }
  }

  @action
  async modalRetrive() {
    const { actionStore: api } = this.props;
    const date = `${this.state.yearmon}01`;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_popup_da023w_03',
        spjangcd: this.state.focused?.spjangcd,
        stdate: '19700101',
        enddate: date,
        cltcd: this.state.focused?.actcd,
        actcd: this.state.focused?.actcd,
        actflag: '1',
        items: this.detailItem,
      });
    if (data) {
      this.setState({
        popupData: data?.items?.map((x: any) => new ChargeReceivableModel(x)) || [],
        // popup trail
        shotmis_tot: data?.shotmis_tot || '0',
        longmis3_tot: data?.longmis3_tot || '0',
        mijamt_tot: data?.mijamt_tot || '0',
        iamt_tot: data?.iamt_tot || '0',
        resuamt_tot2: data?.resuamt_tot || '0',
      });
    }

    this.taxDetailGrid.current?.setFocus(0);
  }

  @action
  async modalRetrive2() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec('retrieve',
      {
        sub: 'w_tb_da023_mis_history',
        actcd: this.state.focused?.actcd,
      });
    if (data?.items) {
      this.setState({
        popupData2: data?.items,
      }, async () => {
        await this.table.current?.update(true);
        await this.table.current?.setFocus(0, 1);
      });
    }
  }

  @action
  async onRowFocusEvent2(item: ChargeReceivableModel) {
    await this.setState({ focused2: item });
  }

  @action
  async onUpdatedRows(rows: Array<ChargeReceivableModel>, updatedRows: Array<ChargeReceivableModel>) {
    this.updatedRows = updatedRows;
    await this.setState({ popupData2: rows });
  }

  @action
  async dw_2_new() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec(
      'new',
      {
        sub: 'w_tb_da023_mis_history',
        actcd: this.state.focused?.actcd,
      },
    );

    data && this.setState({
      popupData2: [
        new ChargeReceivableModel(data),
        ...this.state.popupData2,
      ],
    }, async () => {
      await this.table.current?.update(true);
      await this.table.current?.setFocus(0, 1);
    });
  }

  @action
  async onModalSaveEvent() {
    const { actionStore: api } = this.props;

    if (await api.save({
      sub: 'w_tb_da023_mis_history',
      items: this.updatedRows,
      chk: '1',
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async dw_2_delete() {
    const { actionStore: api } = this.props;

    const text = `순서: ${this.state.focused?.seq}`;
    await api.fxDelete(
      'delete',
      text,
      {
        sub: 'w_tb_da023_mis_history',
        seq: this.state.focused?.seq,
        actcd: this.state.focused?.actcd,
      },
    );
    await this.modalRetrive2();
  }

  @action
  async onTabChange(_: TabModel, index: number) {
    this.tabIndex = index;

    await this.SS({
      focused: undefined,
    });

    await this.onRetrieveEvent();
  }

  @action
  async taxDetailModalRowFocused(item: ChargeReceivableModel) {
    await this.SS({
      taxDetailModalFocusedData: new ChargeReceivableModel(item),
    });
  }

  @action
  async salesDetailModalOpen(isOpen: boolean) {
    await this.SS({
      salesDetailModal: isOpen,
    });

    if (isOpen) {
      await this.salesDetailModalRetrieve();
    } else {
      await this.SS({
        salesDetailModal: false,
      });
    }
  }

  @action
  async salesDetailModalRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.fxExec(
      'retrieve',
      {
        sub: 'w_popup_da023w_da024',
        misdate: this.state.taxDetailModalFocusedData?.misdate,
        misnum: this.state.taxDetailModalFocusedData?.misnum,
      },
    );

    await this.SS({
      salesDetailModalData: data?.items,
    });

    this.saleDetatilGrid.current?.setFocus(0);
  }

  render() {
    return (
      <ChargeReceivableTemplate
        scope={this}
        update={(change, callback) => {
          this.setState({
            ...this.state,
            ...change,
          }, () => callback && callback());
        }}
      />
    );
  }
}
