export * from './ManageStatusHeadTabArrival.template';
export * from './ManageStatusHeadTabContract.template';
export * from './ManageStatusHeadTabDelivery.template';
export * from './ManageStatusHeadTabInspect.template';
export * from './ManageStatusHeadTabPreinspect.template';
export * from './ManageStatusHeadTabRealCheckDate.template';
export * from './ManageStatusHeadTabDesignDate.template';
export * from './ManageStatusDetailTabDetail.template';
export * from './ManageStatusDetailTabBal.template';
export * from './ManageStatusDetailTabMemo.template';
export * from './ManageStatusDetailTabEstimate.template';
export * from './ManageStatusDetailTabSales.template';


