import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RowUpdate,
} from '../../../../constants';

import { SalesNationalTaxServiceModel } from './SalesNationalTaxService.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';

import { SalesNationalTaxServiceTemplate } from './SalesNationalTaxService.template';
import {
  Confirm,
  ConfirmFail,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { PageComponent } from '../../../../utils/layout';
import { FileSelector } from '../../../../utils/file';
import { Fix } from '../../../../utils/string';

interface SalesNationalTaxServiceState {
  // search
  stdate: string;
  enddate: string;
  searchQuery: string;

  // data
  data: Array<SalesNationalTaxServiceModel>;
  focused?: SalesNationalTaxServiceModel;
  focusIndex: number;
  pushTotalCheck: boolean;
  pushTotalCheck2: boolean;
  pushTotalCheck3: boolean;

  // trail
  totsamt_tot: string;
  tottamt_tot: string;
  total: string;
  totmamt_tot: string;
  qty_tot: string;
  uamt_tot: string;
  samt_tot: string;
  tamt_tot: string;
  misamt_tot: string;

  cltcdChk: boolean;
}

/**
 * 컨트롤러
 * @window w_td_da023_import
 * @category 매출등록[국세청]
 */

export class SalesNationalTaxService extends PageComponent<PageProps, SalesNationalTaxServiceState>
  implements PageToolEvents {
  updatedRows?: Array<SalesNationalTaxServiceModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  files?: any;

  missSaveFlag: boolean = false;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    // state 기본값 정의
    this.state = props.state || {
      // search
      stdate: `${year}${month}${date}`,
      enddate: `${year}${month}${date}`,
      searchQuery: '',

      // data
      data: [],
      focused: [],
      focusIndex: 0,

      // trail
      totsamt_tot: '0',
      tottamt_tot: '0',
      total: '0',
      totmamt_tot: '0',
      qty_tot: '0',
      uamt_tot: '0',
      samt_tot: '0',
      tamt_tot: '0',
      misamt_tot: '0',

      cltcdChk: false,
    };
  }

  @action
  async onFirstOpenEvent() {
    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent() {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 매출처리 시 중복발행 방지를 위한 변수 초기화
    this.missSaveFlag = false;
    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        as_nm: this.state.searchQuery,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new SalesNationalTaxServiceModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update(false);
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    this.setState({
      data: [],
      pushTotalCheck: false,
      pushTotalCheck2: false,
      pushTotalCheck3: false,
      totsamt_tot: '0',
      tottamt_tot: '0',
      total: '0',
      totmamt_tot: '0',
      qty_tot: '0',
      uamt_tot: '0',
      samt_tot: '0',
      tamt_tot: '0',
      misamt_tot: '0',
    });

    await this.infinity?.retrieveAll();
    if (this.state.data && this.state.data.length > 0) {
      await this.table.current?.update();
      this.table.current?.setFocus(0);
    }
    // 전체 체크
    await this.SS({
      totsamt_tot: this.infinity?.box?.totsamt_tot || '0',
      tottamt_tot: this.infinity?.box?.tottamt_tot || '0',
      total: this.infinity?.box?.total || '0',
      totmamt_tot: this.infinity?.box?.totmamt_tot || '0',
      qty_tot: this.infinity?.box?.qty_tot || '0',
      uamt_tot: this.infinity?.box?.uamt_tot || '0',
      samt_tot: this.infinity?.box?.samt_tot || '0',
      tamt_tot: this.infinity?.box?.tamt_tot || '0',
      misamt_tot: this.infinity?.box?.misamt_tot || '0',
    });
    await this.table.current?.update(true);
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    await Confirm.show('삭제', '선택한 내역을 삭제하겠습니까?', ConfirmType.QUESTION);

    const chkData: any[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.delflag === '1') {
        chkData.push({
          new: x.new,
          seq: x.seq,
          chk: x.delflag,
        });
      }
    });

    if (!chkData.length) {
      ConfirmWarning.show('확인', '삭제할 목록을 하나라도 선택해 주세요.');
      return;
    }

    await api.fxExec('delete',
      {
        items: chkData,
      });

    await this.onRetrieveEvent();
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  onRowFocusEvent(item: SalesNationalTaxServiceModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  onUpdatedRows(rows: Array<SalesNationalTaxServiceModel>, updatedRows: Array<SalesNationalTaxServiceModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async onChkChanged(rowUpdate: RowUpdate, item: SalesNationalTaxServiceModel, value: boolean) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_list_itemchanged', {
      chk: value ? '1' : '0',
      itemname: 'chk',
      data: value ? '1' : '0',
      cltcd: item.cltcd,
      totmamt: item.totmamt,
    });

    if (value) {
      rowUpdate({
        chk: '1',
        spjangcd: item.spjangcd || this.props.publicStore.user.spjangcd,
        actcd: result.actcd,
        actnm: result.actnm,
      });
    } else {
      rowUpdate({
        chk: '0',
        spjangcd: '',
      });
    }
  }

  @action
  async updateCheckAllToggle(checked: boolean, dataName: string) {
    const checkData: any = [];

    if (this.state.data?.length > 99 && checked) {
      ConfirmWarning.show('경고', '99개이상 매출취소를 한번에 할 수 없습니다.\n 최대단위인 99개로 체크되도록 변동됩니다');
    }

    this.state.data?.forEach((x: any, i) => {
      if (dataName === 'delflag') {
        this.setState({
          pushTotalCheck: checked,
          pushTotalCheck2: false,
          pushTotalCheck3: false,
        });
        checkData.push({
          ...x,
          delflag: checked === true && x.misflag2 === '0' ? '1' : '0',
        });
      } else if (dataName === 'chk') {
        this.setState({
          pushTotalCheck: false,
          pushTotalCheck2: checked,
          pushTotalCheck3: false,
        });
        if (checked) {
          checkData.push({
            ...x,
            chk: checked && x.misflag2 === '0' ? '1' : '0',
            spjangcd: 'ZZ',
          });
        } else {
          checkData.push({
            ...x,
            chk: checked && x.misflag2 === '0' ? '1' : '0',
            spjangcd: '',
          });
        }
      } else if (dataName === 'delchk') {
        this.setState({
          pushTotalCheck: false,
          pushTotalCheck2: false,
          pushTotalCheck3: checked,
        });
        checkData.push(i < 99 || !checked ? {
          ...x,
          delchk: checked === true && x.misflag2 === '1' ? '1' : '0',
        } : x);
      }
    });
    this.setState({
      data: checkData,
    }, async () => {
      await this.table.current?.update(true);
      this.table.current?.setFocus(this.state.focusIndex, 0);
    });
  }

  @action
  async onSubExcelEvent() {
    try {
      const files = await FileSelector.single(false);
      await this.excelUpload(files[0].name, files[0]);
      this.files = files;
    } catch {
      ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  async excelUpload(filename: string, file: Blob) {
    const {
      actionStore: api, waitQueueStore, modalStore, publicStore,
    } = this.props;

    const tempInfo = await api.tempUpload(file, filename);

    waitQueueStore.append('매출등록[국세청]', 'LAZYTASK-wb_excel', async (response) => {
      if (response.not !== undefined) {
        ConfirmWarning.show(response.titlebox, Fix.newline(response.not));
      } else {
        ConfirmWarning.show(response.titlebox, Fix.newline(response.messagebox));
      }
      await this.onRetrieveEvent();
    }, () => this.excelUpload(filename, file));
    modalStore?.openWaitQueue();

    await api.exec(
      Category.GENERAL,
      'wb_excel_lazytask',
      {
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    );
  }

  @action
  // eslint-disable-next-line class-methods-use-this
  async onNationalTaxEvent() {
    const data = await Confirm.show('확인', '국세청으로 이동합니다. 엑셀다운로드를 이용해주세요!', ConfirmType.QUESTION);
    if (data === true) window.open('https://www.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index.xml');
  }

  @action
  async onSalesEvent() {
    const { actionStore: api, waitQueueStore, modalStore } = this.props;

    if (this.missSaveFlag) {
      ConfirmWarning.show('경고', '처리중입니다. \n중복발행 방지를 위해 조회 후 \n매출처리를 다시 눌러주세요');
      return;
    }
    this.missSaveFlag = true;

    const chkData: any = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        chkData.push({
          spjangcd: x.spjangcd,
          misdate: x.misdate,
          accdate: x.accdate,
          remark: x.remark,
          gubun: x.gubun,
          bhflag: x.bhflag,
          cltcd: x.cltcd,
          cltnm: x.cltnm,
          actcd: x?.actcd || '',
          actnm: x?.actnm || '',
          misamt: x.misamt,
          size: x.size,
          qty: x.qty,
          uamt: x.uamt,
          samt: x.samt,
          tamt: x.tamt,
          totsamt: x.totsamt,
          tottamt: x.tottamt,
          receiptyn: x.receiptyn,
          cancleflag: x.cancleflag,
          chk: x.chk,
          seq: x.seq,
        });
      }
    });

    // 팝업창 한번만 뜨로록 aertview 변수 선언
    let alertView = false;
    for (let i = 0; i < chkData.length; i += 1) {
      if (chkData[i]?.actcd === '' && alertView === false) {
        // eslint-disable-next-line no-await-in-loop
        const warning = await Confirm.show('확인', '현장을 선택하지 않은 항목이 있습니다. 그래도 매출처리하시겠습니까?', ConfirmType.QUESTION);
        alertView = true;
        if (warning === false) return;
      }
    }

    // await api.fxExec('wb_missave',
    //   {
    //     items: chkData,
    //   });
    // await this.onRetrieveEvent();

    // Lazytask 등록
    waitQueueStore.append('매출처리', 'LAZYTASK-wb_missave', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
      await this.onRetrieveEvent();
    }, () => this.onSalesEvent());
    modalStore?.openWaitQueue();

    // 오래걸리는 작업 호출
    await api.fxExec('wb_missave_lazytask', {
      items: chkData,
    });
    // await this.onRetrieveEvent();
  }

  @action
  async onSalesCancelEvent() {
    const { actionStore: api, waitQueueStore, modalStore } = this.props;

    const chkData: any = [];
    this.state.data.forEach((x: any) => {
      if (x?.delchk === '1') {
        chkData.push({
          delchk: x.delchk,
          seq: x.seq,
          spjangcd: x.spjangcd,
          rmisdate: x.rmisdate,
          rmisnum: x.rmisnum,
          cltcd: x.cltcd,
          rtax_spdate: x.rtax_spdate,
          rtax_spnum: x.rtax_spnum,
        });
      }
    });

    waitQueueStore.append('매출취소', 'LAZYTASK-wb_misdelete', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
      await this.onRetrieveEvent();
    }, () => this.onSalesCancelEvent());
    modalStore?.openWaitQueue();

    // 오래걸리는 작업 호출
    await api.fxExec('wb_misdelete_lazytask', {
      items: chkData,
    });
  }

  render() {
    return (
      <SalesNationalTaxServiceTemplate
        scope={this}
      />
    );
  }
}
