// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CalendarLayout_header__2JZgt {\n  -webkit-user-select: none;\n          user-select: none;\n  border-bottom: solid 1px var(--color-form-focus); }\n  .CalendarLayout_header__2JZgt > div {\n    padding: 4px; }\n\n.CalendarLayout_dayRow__1HbMa {\n  border-bottom: solid 1px var(--color-primary-super-light); }\n  .CalendarLayout_dayRow__1HbMa:last-child {\n    border-bottom: 0; }\n\n.CalendarLayout_dayContainer__3zAwn {\n  padding: 4px;\n  border-right: solid 1px var(--color-primary-super-light); }\n  .CalendarLayout_dayContainer__3zAwn:nth-child(7n) {\n    border-right: 0; }\n\n.CalendarLayout_title__1NQyK {\n  -webkit-user-select: none;\n          user-select: none; }\n\n.CalendarLayout_disabled__2sxjo {\n  opacity: .3; }\n", "",{"version":3,"sources":["webpack://src/app/components/layout/CalendarLayout/CalendarLayout.module.scss"],"names":[],"mappings":"AAIA;EACE,yBAAiB;UAAjB,iBAAiB;EACjB,gDAAgD,EAAA;EAFlD;IAKI,YAAY,EAAA;;AAIhB;EACE,yDAAyD,EAAA;EAD3D;IAII,gBAAgB,EAAA;;AAIpB;EACE,YAAY;EACZ,wDAAwD,EAAA;EAF1D;IAKI,eAAe,EAAA;;AAOnB;EACE,yBAAiB;UAAjB,iBAAiB,EAAA;;AAGnB;EACE,WAAW,EAAA","sourcesContent":[".container {\n\n}\n\n.header {\n  user-select: none;\n  border-bottom: solid 1px var(--color-form-focus);\n\n  & > div {\n    padding: 4px;\n  }\n}\n\n.dayRow {\n  border-bottom: solid 1px var(--color-primary-super-light);\n\n  &:last-child {\n    border-bottom: 0;\n  }\n}\n\n.dayContainer {\n  padding: 4px;\n  border-right: solid 1px var(--color-primary-super-light);\n\n  &:nth-child(7n) {\n    border-right: 0;\n  }\n}\n\n.dayContainerBody {\n}\n\n.title {\n  user-select: none;\n}\n\n.disabled {\n  opacity: .3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "CalendarLayout_header__2JZgt",
	"dayRow": "CalendarLayout_dayRow__1HbMa",
	"dayContainer": "CalendarLayout_dayContainer__3zAwn",
	"title": "CalendarLayout_title__1NQyK",
	"disabled": "CalendarLayout_disabled__2sxjo"
};
export default ___CSS_LOADER_EXPORT___;
