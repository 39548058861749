import * as React from 'react';
import { FiTrash, FiUpload } from 'react-icons/all';
import { RiFolder2Line } from 'react-icons/ri';
import {
  AttachmentBox,
  Button,
  CheckBox,
  ComboBox,
  ComboBoxModel,
  DateTextBox,
  DefaultLayout,
  FlexLayout,
  FormatTextBox,
  GridLayout,
  ImageView,
  LabelBox,
  LayoutTitle,
  LoaderButton,
  ModalView,
  OptionBox,
  SearchLayout,
  SubHeader,
  TableLayout,
  TabView,
  TelnumTextBox,
  TextBox,
} from '../../../../components';
import {
  PersonnelResistration,
  PersonnelResistrationImages,
  PersonnelResistrationItemChangeTypes,
  PersonnelResistrationTabDataStateNames,
  PersonnelResistrationTabFocusedStateNames,
  PersonnelResistrationTabId,
  PersonnelResistrationTabUpdatesStateNames,
} from './PersonnelResistration';
import { Global, TemplateProps } from '../../../../constants';
import { SpjangImages } from '../../../general/public/Spjang/Spjang';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { AddressButton } from '../../../../components/forms/AddressButton';
import { SearchBinding } from '../../../../models/common';
import { Date8 } from '../../../../utils/time';


/**
 * 화면
 * @window w_tb_e001
 * @category 인사등록
 */
export const PersonnelResistrationTemplate: React.FC<TemplateProps<PersonnelResistration>> = ({
  scope,
  update,
  dataUpdate,
}) => {
  const model = scope.state.basic;
  const model2 = scope.state.body;
  const model3 = scope.state.userRegistrationData2;
  const model4 = scope.state.userModalData;
  const setData = update!;
  const setData2 = dataUpdate!;
  return (
    <FlexLayout isVertical={true} size={Global.LAYOUT_SEARCH_HEIGHT_1}>
      <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
        <FlexLayout weight={4}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="성명, 부서로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => setData({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout>
          <span>사업장</span>
          <ComboBox
            value={scope.state.spjangcd}
            data={scope.state.spjangcds?.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
            onSelect={(option) => setData({ spjangcd: option.value }, () => {
              scope.onRetrieveEvent();
            })}
          />
        </FlexLayout>
        <FlexLayout>
          <span>구분</span>
          <ComboBox
            value={scope.state.rtclafi}
            style={{
              padding: '0 0 0 6px',
              fontSize: 12,
            }}
            data={[
              { value: '%', remark: '전체' },
              { value: '001', remark: '재직자' },
              { value: '002', remark: '퇴직자' },
            ].map((y) => new ComboBoxModel(y.value, y.remark))}
            onSelect={(option) => setData({ rtclafi: option.value }, () => {
              scope.onRetrieveEvent();
            })}
          />
        </FlexLayout>

        <Button onClick={() => scope.userClicked()}>
          사용자등록
        </Button>

        <Button onClick={() => scope.textModal(true)}>
          관리자권한
        </Button>
      </SearchLayout>

      <FlexLayout>
        <FlexLayout
          isVertical={true}
          size={226}
        >
          <FlexLayout size={250}>
            <ImageView
              src={scope.state.img_pic}
              footer={<React.Fragment>
                <LoaderButton
                  isFull={true}
                  onClick={() => scope.imageSelect(PersonnelResistrationImages.PIC)}
                  percent={scope.state.percentImagePic}
                >
                  <FiUpload />
                  <span>등록</span>
                </LoaderButton>
                <Button
                  isFull={true}
                  theme={BUTTON_THEMES.RED}
                  onClick={() => scope.imageDelete(SpjangImages.CUST)}
                >
                  <FiTrash />
                  <span>삭제</span>
                </Button>
              </React.Fragment>}
            />
          </FlexLayout>

          <GridLayout
            header={[
              {
                id: 'divicd',
                text: '부서',
                width: 12,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.rtclafi === '002' ? 'var(--color-red)' : '' }}
                  >
                    {x.divinm}
                  </FlexLayout>
                ),
              },
              {
                id: 'pernm',
                text: '성명',
                width: 15,
                render: (x) => (
                  <FlexLayout
                    justify="center"
                    align="center"
                    style={{ color: x.rtclafi === '002' ? 'var(--color-red)' : '' }}
                  >
                    {x.pernm}
                  </FlexLayout>
                ),
              },
            ]}
            ref={scope.grid}
            data={scope.state.staffList}
            infinityHandler={scope.infinity2}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            onRowClick={(item, i) => scope.onRowFocusEvent(item, i)}
          />
        </FlexLayout>

        <FlexLayout
          weight={8}
          isVertical={true}
        >
          <FlexLayout
            size={Global.LAYOUT_GRID_HEIGHT_1}
          >
            <TabView
              tabs={scope.tabs}
              focused={scope.state.focusedTab}
              onChange={(tab) => scope.onTabChange(tab)}
            />
          </FlexLayout>

          {scope.tabIndex === PersonnelResistrationTabId.BASIC
            && <FlexLayout isVertical={true} weight={3}>
            <FlexLayout size={328}>
              <FlexLayout isVertical={true}>
                <LayoutTitle>기본 정보</LayoutTitle>
                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <LabelBox
                    title="사원번호"
                    isNeed={true}
                    size={180}
                  >
                    <TextBox
                      textAlign="center"
                      value={model?.perid ? model?.perid.slice(1, model?.perid.length) : scope.state.focuseddata?.perid.slice(1)}
                      readonly={model?.new !== '1'}
                    />
                  </LabelBox>

                  <LabelBox title="한글" weight={2} isNeed={true}>
                    <TextBox
                      value={model?.pernm}
                      onChange={(value) => setData2({ pernm: value })}
                    />
                  </LabelBox>

                  <LabelBox title="영문" weight={2}>
                    <TextBox
                      value={model?.pernme}
                      onChange={(value) => setData2({ pernme: value })}
                    />
                  </LabelBox>

                  <LabelBox title="한문" weight={2}>
                    <TextBox
                      value={model?.pernmc}
                      onChange={(value) => setData2({ pernmc: value })}
                    />
                  </LabelBox>

                  <LabelBox title="외국인여부">
                    <OptionBox
                      value={model?.perclasifi || '001'}
                      data={[
                        { value: '002', remark: '여' },
                        { value: '001', remark: '부' },
                      ]}
                      onChange={(item) => setData2({ perclasifi: item.value })}
                    />
                  </LabelBox>

                  <LabelBox
                    title='외국인번호'
                    size={160}
                  >
                    <TextBox
                      value={model?.forerrnum}
                      onChange={(value) => setData2({ forerrnum: value })}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <LabelBox
                    size={180}
                    title="주민번호"
                    isNeed={true}
                    footer={<FlexLayout size={80}>
                      <ComboBox
                        value={model?.rnumflag}
                        data={[
                          { value: '0', remark: '주민번호' },
                          { value: '1', remark: '사업자번호' },
                        ].map((x) => new ComboBoxModel(x.value, x.remark))}
                        onSelect={(option) => setData2({ rnumflag: option.value },
                          () => scope.itemChanged(option.value,
                            PersonnelResistrationItemChangeTypes.RNUMFLAG))}
                      />
                    </FlexLayout>}>
                    <FormatTextBox
                      format={model?.rnumflag === '1' ? '###-##-#####' : '######-#######'}
                      textAlign="center"
                      value={model?.rnum}
                      onChange={(value) => setData2({ rnum: value },
                        () => scope.itemChanged(value, PersonnelResistrationItemChangeTypes.RNUM))}
                    />
                  </LabelBox>

                  <LabelBox title="성별" isNeed={true}>
                    <OptionBox
                      value={model?.sex || '001'}
                      data={[
                        { value: '1', remark: '남' },
                        { value: '2', remark: '여' },
                      ]}
                      onChange={(item) => setData2({ sex: item.value })}
                    />
                  </LabelBox>

                  <LabelBox title="생년월일" isNeed={true}>
                    <FormatTextBox
                      format="####-##-##"
                      textAlign="center"
                      value={model?.birthday}
                      onChange={(value) => setData2({ birthday: value })}
                    />
                  </LabelBox>

                  <LabelBox title="양/음" isNeed={true}>
                    <OptionBox
                      value={model?.yuclafi || '001'}
                      data={[
                        { value: '1', remark: '양력' },
                        { value: '2', remark: '음력' },
                      ]}
                      onChange={(item) => setData2({ yuclafi: item.value })}
                    />
                  </LabelBox>

                  <LabelBox
                    title="핸드폰"
                    isNeed={true}
                    size={160}
                  >
                    <TelnumTextBox
                      textAlign="center"
                      value={model?.handphone}
                      onChange={(value) => setData2({ handphone: value })}
                    />
                  </LabelBox>

                  <LabelBox
                    title="전화번호"
                    size={160}
                  >
                    <TelnumTextBox
                      textAlign="center"
                      value={model?.tel}
                      onChange={(value) => setData2({ tel: value })}
                    />
                  </LabelBox>

                  <LabelBox
                    title="이메일"
                    size={160}
                  >
                    <TextBox
                      value={model?.email}
                      onChange={(value) => setData2({ email: value })}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <LabelBox
                    title="구분"
                    size={180}
                  >
                    <OptionBox
                      value={model?.rtclafi || '0'}
                      data={[
                        { value: '001', remark: '입사' },
                        { value: '002', remark: '퇴사' },
                      ]}
                      onChange={(item) => setData2({ rtclafi: item.value })}
                      readonly={true}
                    />
                  </LabelBox>

                  <LabelBox
                    title="입사일"
                    isNeed={true}
                  >
                    <DateTextBox
                      format="####-##-##"
                      textAlign="center"
                      readonly={model?.new !== '1'}
                      value={model?.entdate}
                      onChange={(value) => setData2({ entdate: value },
                        () => {
                          scope.itemChanged(value, PersonnelResistrationItemChangeTypes.ENTDATE);
                        })}
                    />
                  </LabelBox>

                  <LabelBox title="퇴사일" style={{ color: 'var(--color-red)' }}>
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: 'var(--color-red)' }}
                    >
                      {model?.rtclafi === '001' ? null : Date8.withDash(model?.rtdate)}
                    </FlexLayout>
                  </LabelBox>

                  <LabelBox
                    title="근속기준"
                    size={160}
                    isNeed={true}
                  >
                    <DateTextBox
                      format="####-##-##"
                      textAlign="center"
                      value={model?.badate}
                      onChange={(value) => setData2({ badate: value })}
                    />
                  </LabelBox>

                  <LabelBox
                    title="4대보험취득일"
                    size={160}
                  >
                    <DateTextBox
                      format="####-##-##"
                      textAlign="center"
                      value={model?.insudate}
                      onChange={(value) => setData2({ insudate: value })}
                    />
                  </LabelBox>

                  <LabelBox
                    title="4대보험상실일"
                    size={160}
                      >
                    <DateTextBox
                      format="####-##-##"
                      textAlign="center"
                      value={model?.inoutdate}
                      style={{ color: 'var(--color-red)' }}
                      readonly={true}
                    />
                  </LabelBox>
                </FlexLayout>

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <LabelBox
                    title="주민등록증주소"
                    style={{ color: 'var(--color-red)' }}
                    isNeed={true}
                    weight={4}
                    footer={<FlexLayout>
                      <AddressButton
                        publicStore={scope.props.publicStore}
                        modalStore={scope.props.modalStore}
                        onChange={(address) => setData2({
                          rzipcd: address.zonecode,
                          rzipadres: address.address,
                          radres: address.jibunAddress,
                          lat: address.y,
                          lng: address.x,
                        })}
                      >
                        주소검색
                      </AddressButton>
                      <Button
                        onClick={() => {
                          setData({
                            basicAddressToggle: !scope.state.basicAddressToggle,
                          });
                        }}
                      >
                        {scope.state.basicAddressToggle ? '신' : '구'}주소보기
                      </Button>
                      <DefaultLayout size={150}>
                        <TextBox
                          value={`위도: ${model?.lat}`}
                          readonly={true}
                          transparent={true}
                          size={180}
                        />
                      </DefaultLayout>
                      <DefaultLayout size={140}>
                        <TextBox
                          value={`경도: ${model?.lng}`}
                          readonly={true}
                          transparent={true}
                          size={180}
                        />
                      </DefaultLayout>
                    </FlexLayout>}
                  >
                    <TextBox
                      textAlign="center"
                      value={model?.rzipcd}
                      onChange={(value) => setData2({ rzipcd: value })}
                      readonly={true}
                    />

                    <TextBox
                      value={scope.state.basicAddressToggle
                        ? model?.rzipadres
                        : model?.old_rzipadres
                      }
                      onChange={(value) => setData2({
                        [scope.state.basicAddressToggle ? 'rzipadres' : 'old_rzipadres']: value,
                      })}
                      weight={2}
                    />

                    <TextBox
                      value={model?.radres}
                      onChange={(value) => setData2({ radres: value })}
                      weight={2}
                    />
                  </LabelBox>

                  <LabelBox
                    title="현주소"
                    style={{ color: 'var(--color-red)' }}
                    isNeed={true}
                    weight={4}
                    footer={<FlexLayout>
                      <Button
                        onClick={() => setData2({
                          zipcd: model?.rzipcd,
                          zipadres: model?.rzipadres,
                          adres: model?.rzipadres,
                          lat: model?.rlat,
                          lng: model?.rlng,
                        })}
                      >
                        상동
                      </Button>
                      <AddressButton
                        publicStore={scope.props.publicStore}
                        modalStore={scope.props.modalStore}
                        onChange={(address) => setData2({
                          zipcd: address.zonecode,
                          zipadres: address.address,
                          adres: address.jibunAddress,
                          rlat: address.y,
                          rlng: address.x,
                        })}
                      >
                        주소검색
                      </AddressButton>
                      <Button
                        onClick={() => {
                          setData({
                            basicAddressToggle2: !scope.state.basicAddressToggle2,
                          });
                        }}
                      >
                        {scope.state.basicAddressToggle2 ? '신' : '구'}주소보기
                      </Button>
                      <DefaultLayout size={1}>
                      </DefaultLayout>
                      <DefaultLayout size={160}>
                        <TextBox
                          value={`위도: ${model?.rlat}`}
                          readonly={true}
                          transparent={true}
                          size={180}
                        />
                      </DefaultLayout>
                      <DefaultLayout size={150}>
                        <TextBox
                          value={`경도: ${model?.rlng}`}
                          readonly={true}
                          transparent={true}
                          size={180}
                        />
                      </DefaultLayout>
                    </FlexLayout>}
                  >
                    <TextBox
                      textAlign="center"
                      value={model?.zipcd}
                      onChange={(value) => setData2({ zipcd: value })}
                      readonly={true}
                    />

                    <TextBox
                      value={scope.state.basicAddressToggle2
                        ? model?.zipadres
                        : model?.old_zipadres
                      }
                      onChange={(value) => setData2({
                        [scope.state.basicAddressToggle2 ? 'zipadres' : 'old_zipadres']: value,
                      })}
                      weight={2}
                    />

                    <TextBox
                      value={model?.adres}
                      onChange={(value) => setData2({ adres: value })}
                      weight={2}
                    />
                  </LabelBox>

                </FlexLayout>
              </FlexLayout>

              <FlexLayout isVertical={true} size={300}>
                <LayoutTitle>싸인 등록</LayoutTitle>
                <ImageView
                  // @ts-ignore
                  src={scope.state.img_sign}
                  footer={<React.Fragment>
                    <LoaderButton
                      isFull={true}
                      onClick={() => scope.imageSelect(PersonnelResistrationImages.SIGN)}
                      // @ts-ignore
                      percent={scope.state.percentImageSign}
                    >
                      <FiUpload />
                      <span>등록</span>
                    </LoaderButton>

                    <Button
                      isFull={true}
                      theme={BUTTON_THEMES.RED}
                      onClick={() => scope.imageDelete(PersonnelResistrationImages.SIGN)}
                    >
                      <FiTrash />
                      <span>삭제</span>
                    </Button>
                  </React.Fragment>}
                />

                <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <LabelBox
                    title="승강기관리원아이디"
                  >
                    <TextBox
                      textAlign="center"
                      value={model?.groupid}
                      onChange={(value) => setData2({ groupid: value })}
                    />
                  </LabelBox>

                  <LabelBox
                    title="비밀번호"
                  >
                    <TextBox
                      textAlign="center"
                      value={model?.grouppw}
                      onChange={(value) => setData2({ grouppw: value })}
                    />
                  </LabelBox>
                </FlexLayout>
              </FlexLayout>
            </FlexLayout>

            <LayoutTitle>소속 정보</LayoutTitle>
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="사업장" isNeed={true}>
                  <ComboBox
                    value={model?.spjangcd}
                    readonly={!scope.state.checkNew}
                    data={scope.props.publicStore.spjangs.map((y) => new ComboBoxModel(y.spjangcd, y.spjangnm))}
                  onSelect={(option) => setData2({ spjangcd: option.value },
                    () => {
                      scope.itemChanged(
                        option.value,
                        PersonnelResistrationItemChangeTypes.SPJANGCD,
                      );
                    })}
                  />
                </LabelBox>
                <LabelBox title="상위부서">
                  <TextBox
                    value={model?.kukcd}
                    textAlign="center"
                    readonly={true}
                  />
                  <TextBox
                    value={model?.kuknm}
                    onChange={(value) => setData2({ kuknm: value })}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox title="부서" style={{ color: 'var(--color-red)' }} isNeed={true}>
                  <TextBox
                    value={model?.divicd}
                    readonly={!scope.state.checkNew}
                    textAlign="center"
                    onChange={(value) => setData2({ divicd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_JC002_2',
                      { uv_arg1: model?.spjangcd },
                      true,
                      (item) => {
                        setData2({
                          divicd: item.cd,
                          divinm: item.cdnm,
                        });
                      },
                    )}
                  />
                  <TextBox
                    value={model?.divinm}
                    onChange={(value) => setData2({ divinm: value })}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox title="직위" style={{ color: 'var(--color-red)' }} isNeed={true}>
                  <TextBox
                    value={model?.rspcd}
                    readonly={!scope.state.checkNew}
                    textAlign="center"
                    onChange={(value) => setData2({ rspcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_PZ001',
                      {},
                      true,
                      (item) => {
                        setData2({
                          rspcd: item.cd,
                          rspnm: item.cdnm,
                        });
                      },
                    )}
                  />
                  <TextBox
                    value={model?.rspnm}
                    onChange={(value) => setData2({ rspnm: value })}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox title="팀장" size={100}>
                  <CheckBox
                    value={model?.reader === '1'}
                    onChange={(value) => setData2({ reader: value ? '1' : '0' })}
                  />
                </LabelBox>
                <LabelBox title="익일계획" size={100}>
                  <CheckBox
                    value={model?.overchk === '1'}
                    onChange={(value) => setData2({ overchk: value ? '1' : '0' })}
                  />
                </LabelBox>
                <LabelBox
                  title="사무실주소"
                  style={{ color: 'var(--color-red)' }}
                  isNeed={true}
                  weight={4}
                  footer={<FlexLayout>
                    <AddressButton
                      publicStore={scope.props.publicStore}
                      modalStore={scope.props.modalStore}
                      onChange={(address) => setData2({
                        office_zipcd: address.zonecode,
                        office_address: address.address,
                        office_address2: address.jibunAddress,
                        office_lat: address.y,
                        office_lng: address.x,
                      })}
                    >
                      주소검색
                    </AddressButton>
                    <Button
                      onClick={() => {
                        setData({
                          basicAddressToggle3: !scope.state.basicAddressToggle3,
                        });
                      }}
                    >
                      {scope.state.basicAddressToggle3 ? '신' : '구'}주소보기
                    </Button>
                    <DefaultLayout size={150}>
                      <TextBox
                        value={`위도: ${model?.office_lat}`}
                        readonly={true}
                        transparent={true}
                        size={180}
                      />
                    </DefaultLayout>
                    <DefaultLayout size={150}>
                      <TextBox
                        value={`경도: ${model?.office_lng}`}
                        readonly={true}
                        transparent={true}
                        size={180}
                      />
                    </DefaultLayout>
                  </FlexLayout>}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.office_zipcd}
                    onChange={(value) => setData2({ office_zipcd: value })}
                    readonly={true}
                  />

                  <TextBox
                    value={scope.state.basicAddressToggle3
                      ? model?.office_address
                      : model?.old_office_address
                    }
                    onChange={(value) => setData2({
                      [scope.state.basicAddressToggle3 ? 'office_address' : 'old_office_address']: value,
                    })}
                    weight={2}
                  />

                  <TextBox
                    value={model?.office_address2}
                    onChange={(value) => setData2({ office_address2: value })}
                    weight={2}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="직영구분" size={300}>
                  <OptionBox
                    value={model?.mpclafi || '0'}
                    data={[
                      { value: '0', remark: '직영' },
                      { value: '1', remark: '상주' },
                      { value: '2', remark: '비직원' },
                    ]}
                    onChange={(item) => setData2({ mpclafi: item.value })}
                  />
                </LabelBox>

                <LabelBox title="명함발매">
                  <OptionBox
                    value={model?.cardflag || '1'}
                    data={[
                      { value: '1', remark: '발급' },
                      { value: '0', remark: '미발급' },
                    ]}
                    onChange={(item) => setData2({ cardflag: item.value })}
                  />
                </LabelBox>

                <LabelBox title="직급" style={{ color: 'var(--color-red)' }}>
                  <TextBox
                    value={model?.clacd}
                    textAlign="center"
                    onChange={(value) => setData2({ clacd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_PZ002',
                      {},
                      true,
                      (item) => {
                        setData2({
                          clacd: item.cd,
                          clanm: item.cdnm,
                        });
                      },
                    )}
                  />
                  <TextBox
                    value={model?.clanm}
                    onChange={(value) => setData2({ clanm: value })}
                    readonly={true}
                  />
                </LabelBox>

                <LabelBox title="직군" style={{ color: 'var(--color-red)' }} >
                  <TextBox
                    value={model?.groupcd}
                    textAlign="center"
                    onChange={(value) => setData2({ groupcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_PZ004',
                      {},
                      true,
                      (item) => {
                        setData2({
                          groupcd: item.cd,
                          groupnm: item.cdnm,
                        });
                      },
                    )}
                  />
                    <TextBox
                      value={model?.groupcd}
                      onChange={(value) => setData2({ groupcd: value })}
                      readonly={true}
                    />
                </LabelBox>

                <LabelBox title="공지참여여부">
                  <OptionBox
                    // @ts-ignore
                    value={model?.noticeflag}
                    data={[
                      { value: '1', remark: '참여' },
                      { value: '0', remark: '미참여' },
                    ]}
                    onChange={(item) => setData2({ noticeflag: item.value })}
                  />
                </LabelBox>

                <LabelBox title="거주구분">
                  <OptionBox
                    value={model?.resiclafi || '1'}
                    data={[
                      { value: '1', remark: '거주' },
                      { value: '2', remark: '비거주' },
                    ]}
                    onChange={(item) => setData2({ resiclafi: item.value })}
                  />
                </LabelBox>
                <LabelBox
                  title="첨부파일"
                  footer={<FlexLayout>
                    <Button
                      theme={BUTTON_THEMES.BLUE}
                      onClick={() => scope.fileModal(true)}
                    >
                      <RiFolder2Line/>
                      첨부
                    </Button>
                  </FlexLayout>}>
                  <FlexLayout
                    style={{ color: model?.attcnt === 0 ? 'var(--color-red)' : 'var(--color-blue)' }}
                    align="center"
                    justify="center"
                   >
                    {model?.attcnt === 0 ? '0건 없음' : `${model?.attcnt}건 있음`}
                  </FlexLayout>
                </LabelBox>
              </FlexLayout>

              <LayoutTitle>급여 정보</LayoutTitle>
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="급여대상" size={70}>
                  <CheckBox
                    value={model?.payment === '1'}
                    onChange={(value) => setData2({ payment: value ? '1' : '0' })}
                  />
                </LabelBox>
                <LabelBox title="퇴직금대상" size={80}>
                  <CheckBox
                    value={model?.retire === '1'}
                    onChange={(value) => setData2({ retire: value ? '1' : '0' })}
                  />
                </LabelBox>
                <LabelBox title="연장대상" size={70}>
                  <CheckBox
                    value={model?.pay_overflag === '1'}
                    onChange={(value) => setData2({ pay_overflag: value ? '1' : '0' })}
                  />
                </LabelBox>
                <LabelBox title='직책수당' size={100}>
                  <TextBox
                    textAlign='right'
                    value={model?.pay_serallow}
                    onChange={(value) => setData2({ pay_serallow: value })}
                  />
                </LabelBox>
                <LabelBox title='연차산정대상 / 기본갯수' size={200}>
                  <CheckBox
                    value={model?.yearly_flag === '1'}
                    onChange={(value) => setData2({ yearly_flag: value ? '1' : '0' })}
                  />
                  <TextBox
                    textAlign='center'
                    value={model?.yearly_default}
                    onChange={(value) => setData2({ yearly_default: value })}
                  />
                </LabelBox>
                <LabelBox title='가족[본인제외]' size={100}>
                  <FormatTextBox
                    textAlign='center'
                    format='#명'
                    value={model?.fami60}
                    onChange={(value) => setData2({ fami60: value })}
                  />
                </LabelBox>
                <LabelBox title='20세미만자녀' size={90}>
                  <FormatTextBox
                    textAlign='center'
                    format='#명'
                    value={model?.fami20}
                    onChange={(value) => setData2({ fami20: value })}
                  />
                </LabelBox>
                <LabelBox title="결혼" size={70}>
                  <CheckBox
                    value={model?.mrgyn === '1'}
                    onChange={(value) => setData2({ mrgyn: value ? '1' : '0' })}
                  />
                </LabelBox>
                <LabelBox title="장애" size={70}>
                  <CheckBox
                    value={model?.disperyn === '1'}
                    onChange={(value) => setData2({ disperyn: value ? '1' : '0' })}
                  />
                </LabelBox>
                <LabelBox
                  title='퇴직연금시작일'
                  size={140}
                  footer={<FlexLayout>
                    <CheckBox
                      value={model?.retireflag === '1'}
                      onChange={(value) => setData2({ retireflag: value ? '1' : '0' })}
                    /></FlexLayout>}
                  >
                  <FormatTextBox
                    textAlign='center'
                    format='####-##-##'
                    value={model?.retiredate}
                    onChange={(value) => setData2({ retiredate: value })}
                  />
                </LabelBox>
                <LabelBox title='이체은행' style={{ color: 'var(--color-red)' }} >
                  <ComboBox
                    value={model?.bankcd}
                    data={scope.state.bankcds?.map((y) => new ComboBoxModel(y.bankcd, y.banknm))}
                    onSelect={(option) => setData2({ bankcd: option.value })}
                  />
                  <TextBox
                    size={250}
                    value={model?.accnum}
                    onChange={(value) => setData2({ accnum: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <LabelBox title="기타사항(특이사항)">
                <TextBox
                  value={model?.remark}
                  onChange={(value) => setData2({ remark: value })}
                  isMultiline={true}
                />
              </LabelBox>
            </FlexLayout>}

          {scope.tabIndex === PersonnelResistrationTabId.BODY
            && <FlexLayout isVertical={true} weight={3}>
            <FlexLayout isVertical={true}>
              <LayoutTitle>신체</LayoutTitle>
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="신장">
                  <FormatTextBox
                    format="### cm"
                    textAlign="center"
                    value={model2?.stan}
                    onChange={(value) => setData2({ stan: value })}
                  />
                </LabelBox>

                <LabelBox title="체중">
                  <FormatTextBox
                    format="### kg"
                    textAlign="center"
                    value={model2?.weig}
                    onChange={(value) => setData2({ weig: value })}
                  />
                </LabelBox>

                <LabelBox title="춘추복사이즈">
                  <FormatTextBox
                    format="### Size"
                    textAlign="center"
                    value={model2?.springsize}
                    onChange={(value) => setData2({ springsize: value })}
                  />
                </LabelBox>

                <LabelBox title="하복사이즈">
                  <FormatTextBox
                    format="### Size"
                    textAlign="center"
                    value={model2?.summersize}
                    onChange={(value) => setData2({ summersize: value })}
                  />
                </LabelBox>

                <LabelBox title="동복사이즈">
                  <FormatTextBox
                    format="### Size"
                    textAlign="center"
                    value={model2?.wintersize}
                    onChange={(value) => setData2({ wintersize: value })}
                  />
                </LabelBox>

                <LabelBox title="신발사이즈">
                  <FormatTextBox
                    format="### Size"
                    textAlign="center"
                    value={model2?.footsize}
                    onChange={(value) => setData2({ footsize: value })}
                  />
                </LabelBox>

                <LabelBox title="시력(좌)">
                  <TextBox
                    textAlign="center"
                    value={model2?.lsigh}
                    onChange={(value) => setData2({ lsigh: value })}
                  />
                </LabelBox>

                <LabelBox title="시력(우)" isNeed={true}>
                  <TextBox
                    textAlign="center"
                    value={model2?.rsigh}
                    onChange={(value) => setData2({ rsigh: value })}
                  />
                </LabelBox>

                <LabelBox title="색맹여부">
                    <OptionBox
                        value={model2?.colo || '0'}
                        data={[
                          { value: '1', remark: '여' },
                          { value: '0', remark: '부' },
                        ]}
                        onChange={(item) => setData2({ colo: item.value })}
                    />
                </LabelBox>

                <LabelBox title="혈액형">
                  <OptionBox
                    value={model2?.blood || 'A'}
                    data={[
                      { value: 'A', remark: 'A형' },
                      { value: 'B', remark: 'B형' },
                      { value: 'O', remark: 'O형' },
                      { value: 'AB', remark: 'AB형' },
                    ]}
                    onChange={(item) => setData2({ blood: item.value })}
                  />
                </LabelBox>

                <LabelBox title="RH">
                  <OptionBox
                    value={model2?.rh || '+'}
                    data={[
                      { value: '+', remark: 'RH+' },
                      { value: '-', remark: 'RH-' },
                    ]}
                    onChange={(item) => setData2({ rh: item.value })}
                  />
                </LabelBox>
              </FlexLayout>

              <LayoutTitle>병역</LayoutTitle>
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <FlexLayout isVertical={true}>
                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                    <LabelBox title="군필">
                      <OptionBox
                        value={model2?.army || 'A'}
                        data={[
                          { value: '1', remark: '군필' },
                          { value: '2', remark: '미필' },
                          { value: '0', remark: '비대상' },
                        ]}
                        onChange={(item) => setData2({ army: item.value })}
                      />
                    </LabelBox>

                    <LabelBox title="미필사유">
                      <TextBox
                        textAlign="center"
                        value={model2?.nmili}
                        onChange={(value) => setData2({ nmili: value })}
                      />
                    </LabelBox>

                    <LabelBox title="복무시작">
                      <FormatTextBox
                        format="####-##-##"
                        textAlign="center"
                        value={model2?.cterms}
                        onChange={(value) => setData2({ cterms: value })}
                      />
                    </LabelBox>

                    <LabelBox title="복무만료">
                      <FormatTextBox
                        format="####-##-##"
                        textAlign="center"
                        value={model2?.cterme}
                        onChange={(value) => setData2({ cterme: value })}
                      />
                    </LabelBox>
                  </FlexLayout>

                  <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                    <LabelBox title="병과">
                      <TextBox
                        textAlign="center"
                        value={model2?.arm}
                        onChange={(value) => setData2({ arm: value })}
                      />
                    </LabelBox>

                    <LabelBox title="계급">
                      <TextBox
                        textAlign="center"
                        value={model2?.grad}
                        onChange={(value) => setData2({ grad: value })}
                      />
                    </LabelBox>

                    <LabelBox title="병력">
                      <TextBox
                        textAlign="center"
                        value={model2?.sick}
                        onChange={(value) => setData2({ sick: value })}
                      />
                    </LabelBox>

                    <LabelBox title="제대구분">
                      <OptionBox
                        value={model2?.dcha || '1'}
                        data={[
                          { value: '1', remark: '만기' },
                          { value: '2', remark: '의가사' },
                        ]}
                        onChange={(item) => setData2({ dcha: item.value })}
                      />
                    </LabelBox>
                  </FlexLayout>
                </FlexLayout>

                <LabelBox title="군별" size={600}>
                  <FlexLayout isVertical={true}>
                    <OptionBox
                      value={model2?.carm || '0'}
                      data={[
                        { value: '0', remark: '육군' },
                        { value: '1', remark: '해군' },
                        { value: '2', remark: '공군' },
                      ]}
                      onChange={(item) => setData2({ carm: item.value })}
                    />
                    <OptionBox
                      value={model2?.carm || '0'}
                      data={[
                        { value: '3', remark: '공익' },
                        { value: '4', remark: '병역특례' },
                        { value: '5', remark: '기타' },
                      ]}
                      onChange={(item) => setData2({ carm: item.value })}
                    />
                  </FlexLayout>
                </LabelBox>
              </FlexLayout>

              <LayoutTitle>기타</LayoutTitle>
              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="취미">
                  <TextBox
                    textAlign="center"
                    value={model2?.hobby}
                    onChange={(value) => setData2({ hobby: value })}
                  />
                </LabelBox>

                <LabelBox title="특기">
                  <TextBox
                    textAlign="center"
                    value={model2?.ability}
                    onChange={(value) => setData2({ ability: value })}
                  />
                </LabelBox>

                <LabelBox title="종교">
                  <TextBox
                    textAlign="center"
                    value={model2?.religion}
                    onChange={(value) => setData2({ religion: value })}
                  />
                </LabelBox>

                <LabelBox title="주량">
                  <TextBox
                    textAlign="center"
                    value={model2?.drink}
                    onChange={(value) => setData2({ drink: value })}
                  />
                </LabelBox>

                <LabelBox title="담배(일)">
                  <TextBox
                    textAlign="center"
                    value={model2?.tabacco}
                    onChange={(value) => setData2({ tabacco: value })}
                  />
                </LabelBox>

                <LabelBox title="국가유공자">
                  <CheckBox
                    value={model2?.nationflag === '1'}
                    onChange={(value) => setData2({ nationflag: value ? '1' : '0' })}
                  />
                </LabelBox>

                <LabelBox title="장애부위">
                  <TextBox
                    textAlign="center"
                    value={model2?.injury}
                    onChange={(value) => setData2({ injury: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="주거형태">
                  <TextBox
                    textAlign="center"
                    value={model2?.houseflag}
                    onChange={(value) => setData2({ houseflag: value })}
                  />
                </LabelBox>

                <LabelBox title="주거명의">
                  <TextBox
                    textAlign="center"
                    value={model2?.housename}
                    onChange={(value) => setData2({ housename: value })}
                  />
                </LabelBox>

                <LabelBox title="주거구분">
                  <TextBox
                    textAlign="center"
                    value={model2?.houseshape}
                    onChange={(value) => setData2({ houseshape: value })}
                  />
                </LabelBox>

                <LabelBox title="주거명수">
                  <TextBox
                    textAlign="center"
                    value={model2?.housesize}
                    onChange={(value) => setData2({ housesize: value })}
                  />
                </LabelBox>

                <LabelBox title="호주성명">
                  <TextBox
                    textAlign="center"
                    value={model2?.hojuname}
                    onChange={(value) => setData2({ hojuname: value })}
                  />
                </LabelBox>

                <LabelBox title="호주관계">
                  <TextBox
                    textAlign="center"
                    value={model2?.hojurelation}
                    onChange={(value) => setData2({ hojurelation: value })}
                  />
                </LabelBox>

                <LabelBox title="호주직업">
                  <TextBox
                    textAlign="center"
                    value={model2?.hojujob}
                    onChange={(value) => setData2({ hojujob: value })}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>}

          {scope.tabIndex !== PersonnelResistrationTabId.BASIC
            && scope.tabIndex !== PersonnelResistrationTabId.BODY
            && scope.tabIndex !== PersonnelResistrationTabId.FILE
          && <TableLayout
            ref={scope.table}
            scope={scope}
            header={scope.tabHeaders[scope.tabIndex]}
            rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            infinityHandler={scope.infinity}
            // @ts-ignore
            data={scope.state[PersonnelResistrationTabDataStateNames[scope.tabIndex]]}
            onRowFocusEvent={(item) => setData({
              [PersonnelResistrationTabFocusedStateNames[scope.tabIndex]]: item,
            })}
            onChange={(rows, updatedRows) => setData({
              [PersonnelResistrationTabDataStateNames[scope.tabIndex]]: rows,
              [PersonnelResistrationTabUpdatesStateNames[scope.tabIndex]]: updatedRows,
            })}
          />}

          {scope.tabIndex === PersonnelResistrationTabId.FILE
            && <AttachmentBox
              appnum={`JA001-${scope.state.focuseddata?.perid.toUpperCase()}`}
              appnm={'인사등록'}
              isEnabledPreview={true}
            />
          }

          <ModalView
            width={1300}
            isVisible={scope.state.fileDetailModal}
            onClose={() => scope.fileModal(false)}
          >
            <FlexLayout style={{ padding: 8 }}>
              <AttachmentBox
                appnum={`JA001-${scope.state.focuseddata?.perid}`}
                appnm={'인사등록'}
                isEnabledPreview
                onChangeCount={async (count) => {
                  setData2({ attcnt: count.toString() });
                }}
              />
            </FlexLayout>
          </ModalView>
        </FlexLayout>
      </FlexLayout>

      <ModalView
        width={800}
        height={420}
        zIndex={-1}
        isVisible={scope.state.userDetailModal}
        onClose={() => scope.userModal(false)}
      >
        <FlexLayout
          style={{ padding: 8 }}
          isVertical={true}
        >
          <SubHeader>
            <div>
              <span>사용자등록</span>
            </div>

            <FlexLayout justify="end">
            </FlexLayout>
          </SubHeader>
          <FlexLayout>
            <FlexLayout isVertical={true}>
              <LayoutTitle>항목</LayoutTitle>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="주민번호"
                  isNeed={true}
                >
                  <FormatTextBox
                    textAlign="center"
                    format="######-#$$$$$$"
                    value={model?.rnum}
                    onChange={(value) => setData2({ rnum: value })}
                  />
                </LabelBox>
                <LabelBox title="사원번호">
                  <TextBox
                    textAlign="center"
                    value={model?.perid ? model?.perid.slice(1, model?.perid.length) : scope.state.focuseddata?.perid.slice(1)}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="사용자ID"
                  isNeed={true}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.perid ? model?.perid.slice(1, model?.perid.length) : scope.state.focuseddata?.perid.slice(1)}
                    readonly={true}
                  />
                </LabelBox>
                <LabelBox
                  title="사용자명"
                  isNeed={true}
                >
                  <TextBox
                    textAlign="center"
                    value={model?.pernm || ''}
                    onChange={(value) => setData2({ pernm: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox
                  title="비밀번호"
                  isNeed={true}
                >
                  <TextBox
                    type="password"
                    value={model4?.passwd1}
                    onChange={(value) => setData2({ passwd1: value })}
                  />
                </LabelBox>
                <LabelBox
                  title="비밀번호확인"
                  isNeed={true}
                >
                  <TextBox
                    type="password"
                    value={model4?.passwd2}
                    onChange={(value) => setData2({ passwd2: value })}
                  />
                </LabelBox>
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <LabelBox title="연관검색설정">
                  <OptionBox
                    value={model4?.search || '0'}
                    data={[
                      { value: '1', remark: '사용' },
                      { value: '0', remark: '미사용' },
                    ]}
                    onChange={(item) => setData2({ search: item.value })}
                    isDisabledTrackingStateChange={true}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>
            <FlexLayout isVertical={true}>
              <LayoutTitle>설명</LayoutTitle>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                  <TextBox
                    value={'사용할 직원의 주민번호를 입력합니다. 사번이 나오지 않을 경우 주민번호, 직원등록을 확인합니다.'}
                  />
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <TextBox
                  value={'사용할 아이디를 입력합니다. 사용자의 성명을 입력합니다.'}
                />
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <TextBox
                  value={'변경할 비밀번호를 입력합니다. 비밀번호를 한번더 입력해주세요.'}
                />
              </FlexLayout>

              <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
                <TextBox
                  value={'체크시 기본 연관검색을 실시합니다.'}
                />
              </FlexLayout>
            </FlexLayout>
          </FlexLayout>

          <FlexLayout
            justify="right"
            align="center"
          >
            <FlexLayout size={300}>
              <span style={{ color: 'var(--color-red)' }}>* 메뉴복사대상자</span>
              <TextBox
                value={scope.state.perid}
                textAlign="center"
                onChange={(value) => scope.SS({ perid: value })}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    scope.SS({
                      perid: item.cd,
                      pernm: item.cdnm,
                    });
                  },
                )}
              />
              <TextBox
                value={scope.state.pernm}
                textAlign="center"
                onChange={(value) => scope.SS({ pernm: value })}
                readonly={true}
              />
            </FlexLayout>
            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.userSave()}
            >
              저장
            </Button>

            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.userModal(false)}
            >
              닫기
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        width={350}
        height={220}
        isVisible={scope.state.modalVisible}
        onClose={() => scope.textModal(false)}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <label style={{ color: 'var(--color-red)' }}>로그인 하세요</label>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="관리자 아이디">
              <TextBox
                value={scope.state.managerId || ''}
                onChange={(value) => scope.setState({ managerId: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="관리자 비밀번호">
              <TextBox
                type="password"
                value={scope.state.managerPassword || ''}
                onChange={(value) => scope.setState({ managerPassword: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout
            justify="center"
            align="center"
          >
            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalLoginEvent()}
            >
              확인
            </Button>

            <Button
              style={{
                height: 25,
                width: 80,
              }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.textModal(false)}
            >
              취소
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.modalMenuState}
        onClose={() => scope.modalMenuChoice(false)}
        width={650}
        height={100}
      >
        <FlexLayout isVertical={true}>
          <LayoutTitle size={25}>출력구분</LayoutTitle>
          <FlexLayout
            align='center'
            justify='center'
          >
            <OptionBox
              size={500}
              value={scope.state.modalMenuChoice}
              data={[
                { value: '1', remark: '관리자암호변경' },
                { value: '2', remark: '관리자사용자등록' },
                { value: '3', remark: '관리자사용자메뉴권한설정' },
              ]}
              onChange={(item) => scope.setState({ modalMenuChoice: item.value })}
              isDisabledTrackingStateChange={true}
            />

            <Button
              style={{
                height: 25, width: 80, marginTop: 25,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.modalMenuChoice2()}>
              확인
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.passwdChange}
        onClose={() => scope.passwdChange(false)}
        width={520}
        height={140}
      >
        <FlexLayout
          isVertical={true}
          style={{ padding: 8 }}
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox title="관리자아이디">
              <TextBox
                value={scope.state.userid}
                onChange={(value) => scope.setState({ userid: value })}
              />
            </LabelBox>

            <LabelBox title="비밀번호">
              <TextBox
                type="password"
                value={scope.state.password1}
                onChange={(value) => scope.setState({ password1: value })}
              />
            </LabelBox>

            <LabelBox title="비밀번호확인">
              <TextBox
                type="password"
                value={scope.state.password2}
                onChange={(value) => scope.setState({ password2: value })}
              />
            </LabelBox>
          </FlexLayout>

          <FlexLayout
            align='center'
            justify='right'
          >
            <Button
              style={{
                height: 25,
                width: 80,
                marginTop: 25,
              }}
              theme={BUTTON_THEMES.BLUE}
              onClick={() => scope.passwdChangeSave()}>
              저장
            </Button>

            <Button
              style={{
                height: 25,
                width: 80,
                marginTop: 25,
              }}
              theme={BUTTON_THEMES.RED}
              onClick={() => scope.passwdChange(false)}>
              취소
            </Button>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.userRegistration}
        onClose={() => scope.userRegistration(false)}
        width={1000}
        height={800}
      >
        <FlexLayout
          justify="right"
          weight={0.01}
        >
          <Button
            style={{
              height: '25px',
              width: '80px',
              marginTop: 10,
              marginRight: 5,
            }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.userRegistrationSave()}
          >
            저장
          </Button>
        </FlexLayout>

        <FlexLayout style={{ padding: 8 }}>
          <FlexLayout>
            <GridLayout
              header={[
                {
                  id: 'userid',
                  text: '사용자ID',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.userid}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '사용자명',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.userRegistrationData}
              onRowClick={(item) => scope.userRegistrationRowfocus(item)}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>

          <FlexLayout
            isVertical={true}
            weight={2}
          >
            <LayoutTitle>항목</LayoutTitle>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="주민번호"
                isNeed={true}
              >
                <FormatTextBox
                  textAlign="center"
                  format="######-#$$$$$$"
                  value={model3?.rnum}
                  onChange={(value) => setData2({ rnum: value })}
                />
              </LabelBox>

              <LabelBox title="사원번호">
                <TextBox
                  textAlign="center"
                  value={model3?.perid}
                  onChange={(value) => setData2({ perid: value })}
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                title="설명"
                weight={2}
              >
                <FlexLayout isVertical={true}>
                  <div>사용할 직원의 주민번호를 입력합니다.</div>
                  <div>사번이 나오지 않을경우 주민번호, 직원등록을 확인합니다.</div>
                </FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="사용자ID"
                isNeed={true}
              >
                <TextBox
                  textAlign="center"
                  value={model3?.userid}
                  onChange={(value) => setData2({ userid: value })}
                  readonly={true}
                />
              </LabelBox>

              <LabelBox
                title="사용자성명"
                isNeed={true}
              >
                <TextBox
                  textAlign="center"
                  value={model3?.pernm}
                  onChange={(value) => setData2({ pernm: value })}
                />
              </LabelBox>

              <LabelBox
                title="설명"
                weight={2}
              >
                <FlexLayout isVertical={true}>
                  <div>사용할 아이디를 입력합니다.</div>
                  <div>사용자의 성명을 입력합니다.</div>
                </FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="비밀번호"
                isNeed={true}
              >
                <TextBox
                  textAlign="center"
                  type="password"
                  value={model3?.passwd1}
                  onChange={(value) => setData2({ passwd1: value })}
                />
              </LabelBox>

              <LabelBox
                title="비밀번호확인"
                isNeed={true}
              >
                <TextBox
                  textAlign="center"
                  type="password"
                  value={model3?.passwd2}
                  onChange={(value) => setData2({ passwd2: value })}
                />
              </LabelBox>

              <LabelBox
                title="설명"
                weight={2}
              >
                <FlexLayout isVertical={true}>
                  <div>변경할 비밀번호를 입력합니다.</div>
                  <div>비밀번호를 한번더 입력해주세요.</div>
                </FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="연관검색설정">
                <OptionBox
                  value={model3?.search || '0'}
                  data={[
                    { value: '1', remark: '사용' },
                    { value: '0', remark: '미사용' },
                  ]}
                  onChange={(item) => setData2({ search: item.value })}
                />
              </LabelBox>

              <LabelBox title="설명">
                <FlexLayout>채크시 기본 연관검색을 실시합니다.</FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="전자세금계산서 예약전송권한"
                weight={2}
              >
                <OptionBox
                  value={model3?.taxreserv || '0'}
                  data={[
                    { value: '1', remark: '사용' },
                    { value: '0', remark: '미사용' },
                  ]}
                  onChange={(item) => setData2({ taxreserv: item.value })}
                />
              </LabelBox>

              <LabelBox title="전송선택여부">
                <ComboBox
                  value={model3?.tax_funccode}
                  data={[
                    { value: '', remark: '' },
                    { value: 'DD', remark: '즉시신고' },
                    { value: 'CD', remark: '승인후신고' },
                    { value: 'DE', remark: '익일신고' },
                  ].map((x) => new ComboBoxModel(x.value, x.remark))}
                  onSelect={(item) => setData2({ paytype: item.value })}
                />
              </LabelBox>

              <LabelBox title="보수관리권한">
                <OptionBox
                  value={model3?.contyn || '0'}
                  data={[
                    { value: '1', remark: '사용' },
                    { value: '0', remark: '미사용' },
                  ]}
                  onChange={(item) => setData2({ contyn: item.value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox
                title="설치관리권한"
                weight={2}
              >
                <OptionBox
                  value={model3?.sulchi || '0'}
                  data={[
                    { value: '1', remark: '사용' },
                    { value: '0', remark: '미사용' },
                  ]}
                  onChange={(item) => setData2({ sulchi: item.value })}
                />
              </LabelBox>

              <LabelBox title="위치정보열람이용신청">
                <Button onClick={() => scope.toggleSettingsModal(true)}>신청</Button>

                <ComboBox
                  value={model3?.userid}
                  data={[
                    { value: '0', remark: '미신청' },
                    { value: '1', remark: '거부' },
                    { value: '2', remark: '신청' },
                  ].map((x) => new ComboBoxModel(x.value, x.remark))}
                  onSelect={(item) => setData2({ paytype: item.value })}
                />
              </LabelBox>

              <LabelBox title="공지사항관리자권한">
                <OptionBox
                  value={model3?.noticeflag || '0'}
                  data={[
                    { value: '1', remark: '사용' },
                    { value: '0', remark: '미사용' },
                  ]}
                  onChange={(item) => setData2({ noticeflag: item.value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="자체점검계획생성">
                <OptionBox
                  style={{
                    height: 30,
                    marginTop: 10,
                  }}
                  value={model3?.e035_autoflag || '0'}
                  data={[
                    { value: '0', remark: '위치기반생성' },
                    { value: '1', remark: '전월기반생성' },
                  ]}
                  onChange={(item) => setData2({ e035_autoflag: item.value })}
                />
              </LabelBox>

              <LabelBox title="설명">
                <FlexLayout isVertical={true}>
                  <div>자체점검은 매월 25일 익월 계획을 자동 생성합니다.</div>
                  <div>위치기반선택시 배정받은 현장이 가까운 순으로 자동계산되어 생성됩니다.</div>
                  <div>전월기반선택시 전월에 계획한 내용을 토대로 생성됩니다.</div>
                </FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="테마설정">
                <OptionBox
                  value={model3?.color || '0'}
                  data={[
                    { value: 'blue', remark: '블루' },
                    { value: 'brown', remark: '브라운' },
                    { value: 'pink', remark: '핑크' },
                    { value: 'gray', remark: '그레이' },
                  ]}
                  onChange={(item) => {
                    setData2({ color: item.value });
                    // @ts-ignore
                    // eslint-disable-next-line no-param-reassign
                    scope.props.publicStore.themeName = item.value;
                  }}
                />
              </LabelBox>

              <LabelBox title="설명">
                <FlexLayout>색상선택시 프로그램 테마색상이 변경됩니다.</FlexLayout>
              </LabelBox>
            </FlexLayout>

            <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
              <LabelBox title="사용유무">
                <CheckBox
                  value={model3?.useyn === '1'}
                  onChange={(value) => setData2({ useyn: value ? '1' : '0' })}
                />
              </LabelBox>

              <LabelBox title="설명">
                <FlexLayout>채크시 아이디가 사용됩니다.</FlexLayout>
              </LabelBox>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.menuAuthority}
        onClose={() => scope.menuAuthority(false)}
        width={1000}
        height={800}
        zIndex={-5}
      >
        <FlexLayout
          justify="right"
          weight={0.01}
        >
          <Button
            style={{
              height: '25px',
              width: '80px',
              marginTop: 10,
              marginRight: 5,
            }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.menuAuthoritySaveBtn()}
          >
            저장
          </Button>

          <Button
          style={{
            height: '25px',
            width: '80px',
            marginTop: 10,
            marginRight: 10,
          }}
          theme={BUTTON_THEMES.BLUE}
          onClick={() => scope.menuAuthorityBtn()}
        >
          메뉴갱신
        </Button>
        </FlexLayout>

        <FlexLayout style={{ padding: 8 }}>
          <FlexLayout>
            <TableLayout
              ref={scope.modalTable}
              header={[
                {
                  id: 'flag',
                  text: '선택',
                  width: 26,
                  render: (x, rowUpdate) => (
                    <CheckBox
                      value={x.flag === '1'}
                      onChange={(value) => rowUpdate({ flag: value ? '1' : '0' })}
                    />
                  ),
                },
                {
                  id: 'userid',
                  text: '사용자ID',
                  width: 37,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.userid}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '사용자명',
                  width: 37,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.menuAuthorityData}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows, updatedRows) => {
                scope.onModalUpdatedRows(rows, updatedRows);
              }}
              onRowFocusEvent={(item) => scope.menuAuthorityRowFocus(item)}
            />
          </FlexLayout>

          <FlexLayout weight={1.5}>
            <TableLayout
              ref={scope.modalDetailTable}
              header={[
                {
                  id: 'mtext',
                  text: '메뉴명칭',
                  width: 70,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                      style={{
                        color: x.mlevel === '1' ? 'var(--color-red)'
                          : x.mlevel === '2' ? 'var(--color-blue)' : '',
                      }}
                    >
                      {x.mtext}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'mlevel',
                  text: '레벨',
                  width: 10,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                      style={{ color: '#8106aa' }}
                    >
                      {x.mlevel}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'visible',
                  text: '사용여부',
                  width: 20,
                  render: (x, rowUpdate) => (
                    <OptionBox
                      value={x.visible}
                      data={[
                        { value: '1', remark: '사용' },
                        { value: '0', remark: '잠금' },
                      ]}
                      onChange={(v) => rowUpdate({ visible: v.value })}
                    />
                  ),
                },
              ]}
              data={scope.state.menuAuthorityDetailData}
              onChange={(rows, updatedRows) => {
                scope.onModalUpdatedRows2(rows, updatedRows);
              }}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
            />
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
