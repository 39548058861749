// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RowCover_container__1Erb- {\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: -2px;\n  bottom: 0;\n  opacity: .3;\n  -webkit-user-select: none;\n          user-select: none;\n  pointer-events: none; }\n", "",{"version":3,"sources":["webpack://src/app/components/layout/RowCover/RowCover.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,SAAS;EACT,WAAW;EACX,yBAAiB;UAAjB,iBAAiB;EACjB,oBAAoB,EAAA","sourcesContent":[".container {\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: -2px;\n  bottom: 0;\n  opacity: .3;\n  user-select: none;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RowCover_container__1Erb-"
};
export default ___CSS_LOADER_EXPORT___;
