import * as React from 'react';
import { RefObject } from 'react';
import { action } from 'mobx';
import update from 'react-addons-update';
import {
  AskType,
  ConfirmType,
  PageProps,
  PageToolEvents,
  PAPERCD,
  RetrieveFocusType,
  RowUpdate,
} from '../../../../constants';
import {
  InfinityRetrieve,
  TabModel,
} from '../../../../models';
import EnrollmentModel from './models/EnrollmentModel';
import { EnrollmentTemplate } from './Enrollment.template';
import { PageComponent } from '../../../../utils';
import {
  DragAndDropLayout,
  GridLayout,
  TabLayout,
  TableLayout,
} from '../../../../components';
import {
  Confirm,
  ConfirmWarning,
} from '../../../../utils/confirm';
import {
  Date6,
  Date8,
  DateStabilizer,
  Today,
} from '../../../../utils/time';
import EnrollmentDetailModel from './models/EnrollmentDetailModel';
import EnrollmentMaterialsModel from './models/EnrollmentMaterialsModel';
import EnrollmentHistoryModel from './models/EnrollmentHistoryModel';
import { Sum } from '../../../../utils/array';
import EnrollmentSelfInspectModel from './models/EnrollmentSelfInspectModel';
import EnrollmentAmountModel from './models/EnrollmentAmountModel';
import EnrollmentConferenceModel from './models/EnrollmentConferenceModel';
import { Format } from '../../../../utils/string';


export const Appgubuns = [
  { value: '%', remark: '전체', color: 'inherit' },
  { value: '101', remark: '결재', color: 'var(--color-blue)' },
  { value: '121', remark: '참조', color: 'inherit' },
  { value: '001', remark: '대기', color: 'var(--color-black)' },
  { value: '131', remark: '반려', color: 'var(--color-red)' },
  { value: '111', remark: '결재중', color: 'inherit' },
  { value: '301', remark: '전결', color: 'var(--color-blue)' },
  { value: '401', remark: '대결', color: 'var(--color-blue)' },
];

export const Compyns = [
  { value: '%', remark: '전체', color: 'inherit' },
  { value: '0', remark: '견적', color: 'inherit' },
  { value: '1', remark: '공사', color: 'var(--color-green)' },
  { value: '2', remark: '매출', color: 'var(--color-blue)' },
  { value: '3', remark: '폐기', color: 'var(--color-red)' },
  { value: '4', remark: '수주', color: 'var(--color-red)' },
  { value: '5', remark: '수금', color: 'var(--color-violet)' },
  // { value: '9', remark: '계획', color: 'inherit' },
  // { value: ' ', remark: '견적전', color: 'inherit' },
];

export const Comps = [
  { value: '0', remark: '견적' },
  { value: '1', remark: '공사' },
  { value: '2', remark: '매출' },
  { value: '3', remark: '폐기' },
  { value: '5', remark: '수금' },
  { value: '9', remark: '계획' },
  { value: ' ', remark: '견적전' },
];

export const Receflags = [
  { value: '0', remark: '미확인' },
  { value: '1', remark: '어플수령' },
  { value: '2', remark: '파일수령' },
  { value: '3', remark: '문서수령' },
];


interface EnrollmentState {
  searchQuery: string;
  stdate: string;
  enddate: string;
  divicd: string;
  divinm: string;
  perid: string;
  pernm: string;
  appgubun: string;
  gubun: string;
  compdate_stamp: string;
  costdate_stamp: string;
  remarkContent: string;
  wkactcds: Array<any>;

  data: Array<EnrollmentModel>;
  content: EnrollmentModel;
  lastSaveData: EnrollmentModel;

  dataDetail: Array<EnrollmentDetailModel>;
  lastSaveDetail: Array<EnrollmentDetailModel>;
  dataMaterials: Array<EnrollmentMaterialsModel>;
  dataHistory: Array<EnrollmentModel>;
  dataConference: Array<EnrollmentConferenceModel>; // 현장협의사항

  dataModalOrderMaterials: Array<any>;
  modalOrderMaterialsDate: string;
  modalOrderMaterialsRemark: string;

  copyDate: string;

  materialOrderDate: string;
  materialOrderRemark: string;

  // 부품내역 focus data
  MaterialsFocus?: EnrollmentMaterialsModel;

  modalSelfInspectSearchStmon: string;
  modalSelfInspectSearchQuery: string;
  modalSelfInspectSearchDivicd: string;
  modalSelfInspectSearchDivinm: string;
  modalSelfInspectSearchPerid: string;
  modalSelfInspectSearchPernm: string;
  modalSelfInspectDate: string;
  modalSelfInspectGubun: string;

  dataModalSelfInspect: Array<any>;

  dataModalAmount: Array<EnrollmentAmountModel>;

  isVisibleAttachment: boolean;
  isVisibleOrderMaterial: boolean;
  isVisibleCopy: boolean; // 견적서복사
  isVisibleSelfInspect: boolean;
  isVisibleAmount: boolean;

  isVisiblePrintType: boolean;
  printType: string;

  useCalc: string;

  materialModalData: any;

  materialDetailModal: boolean;

  modalStdate: string;
  modalEnddate: string;

  kakaoyn: string;
  kakaoModal: boolean;
  kakaoSendType: string;
  gubun1: string;
  gubun2: string;
  gubun3: string;

  // 상단 조회조건 구분
  gubunsAll: Array<any>;

  // 견적구분
  gubuns: Array<any>;

  // Email 사업자명
  emailSpjangnm: string;

  // 계약일 팝업창
  contractPopupVisible: boolean;
  contractPopupHajagigan: string;
  contractPopupDateGubun: string;
}

/**
 * 컨트롤러
 * @window w_tb_e451
 * @category 견적서등록
 */
export class Enrollment extends PageComponent<PageProps, EnrollmentState>
  implements PageToolEvents {
  tab: RefObject<TabLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  selfInspectInfinity?: InfinityRetrieve;

  grid: RefObject<GridLayout> = React.createRef();

  gridFocus?: EnrollmentModel;

  gridFocusIndex: number = 0;

  tableDetail: RefObject<TableLayout> = React.createRef();

  tableDetailFocus?: EnrollmentDetailModel;

  tableDetailFocusIndex: number = 0;

  tableMaterials: RefObject<DragAndDropLayout> = React.createRef();

  tableMaterialModal: RefObject<TableLayout> = React.createRef();

  tableMaterialsFocus?: EnrollmentMaterialsModel;

  tableMaterialsFocusIndex: number = 0;

  tableConference: RefObject<TableLayout> = React.createRef();

  tableConferenceFocus?: EnrollmentConferenceModel;

  tableConferenceFocusIndex: number = 0;

  tabIndex: number = 0;

  // Modal
  tableModalOrderMaterials: RefObject<TableLayout> = React.createRef();

  tableModalSelfInspect: RefObject<TableLayout> = React.createRef();

  tableModalAmount: RefObject<TableLayout> = React.createRef();

  // Print type
  choosePrintTypeResolve?: (value: string) => void;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const { user } = this.props.publicStore;

    const pageParams = this.props.publicStore?.getPageParams();
    let bosuPerid = '';
    let bosuPernm = '';
    let bosuDivicd = '';
    let bosuDivinm = '';

    if (user.kukcd !== '01' && pageParams.searchQuery === undefined) {
      bosuPerid = user.perid;
      bosuPernm = user.pernm;
      bosuDivicd = user.divicd;
      bosuDivinm = user.divinm;
    }

    this.state = props.state || {
      searchQuery: pageParams?.searchQuery || '',
      stdate: pageParams?.stdate || `${Date8.make().substr(0, 6)}01`,
      enddate: pageParams?.enddate || Date8.make(),
      modalStdate: '19700101',
      modalEnddate: Today.date(),
      divicd: bosuDivicd || '',
      divinm: bosuDivinm || '',
      perid: bosuPerid || '',
      pernm: bosuPernm || '',
      appgubun: '%',
      gubun: '%',
      compdate_stamp: '0',
      costdate_stamp: '0',
      useCalc: '0',
      wkactcds: [],

      data: [],
      dataDetail: [],
      dataMaterials: [],
      dataHistory: [],
      dataModalAmount: [],
      content: new EnrollmentModel(),

      dataModalOrderMaterials: [],
      modalOrderMaterialsDate: Date8.make(),
      modalOrderMaterialsRemark: '',

      copyDate: Date8.make(),

      dataModalSelfInspect: [],

      modalSelfInspectSearchStmon: Date6.make(),
      modalSelfInspectSearchQuery: '',
      modalSelfInspectSearchDivicd: '',
      modalSelfInspectSearchDivinm: '',
      modalSelfInspectSearchPerid: '',
      modalSelfInspectSearchPernm: '',
      modalSelfInspectDate: Date8.make(),
      modalSelfInspectGubun: '13',

      isVisibleAttachment: false,
      isVisibleOrderMaterial: false,
      isVisibleCopy: false,
      isVisibleSelfInspect: false,

      isVisiblePrintType: false,
      printType: '0',
      remarkContent: '부품교체확인서',

      materialDetailModal: false,

      // 부품내역 focus data
      MaterialsFocus: [],

      kakaoyn: '0',
      kakaoModal: false,
      kakaoSendType: '0',
      gubun1: '0',
      gubun2: '0',
      gubun3: '0',

      // 상단 조회조건 구분
      gubunsAll: [],

      // 견적구분
      gubuns: [],

      // 이메일 사업자명
      emailSpjangnm: '',

      // 계약일 팝업창
      contractPopupVisible: false,
      contractPopupHajagigan: '3',
      contractPopupDateGubun: '1',
    };
  }

  @action
  async componentDidRecover() {
    const pageParams = this.props.publicStore?.getPageParams();
    await this.SS({
      searchQuery: pageParams?.searchQuery || this.state.searchQuery || '',
      stdate: pageParams?.stdate || this.state.stdate || `${Date8.make().substr(0, 6)}01`,
      enddate: pageParams?.enddate || this.state.enddate || Date8.make(),
    });
    await this.onRetrieveEvent(RetrieveFocusType.FIRST);
  }

  @action
  async onFirstOpenEvent() {
    const { actionStore: api } = this.props;

    const openData = await api.fxExec('open');
    this.setState({
      kakaoyn: openData?.alim_kakao_useyn, emailSpjangnm: openData?.spjangnm,
    });

    const data = await api.dropdown('wf_dd_e018_1');
    if (!data) return;
    this.setState({ wkactcds: data?.items });

    // 상단 조회조건 구분
    const data2 = await api.dropdown('wf_dd_da020_all');
    if (!data2) return;
    this.setState({ gubunsAll: data2?.items });

    // 견적구분
    const data3 = await api.dropdown('wf_da020_code');
    if (!data3) return;
    this.setState({ gubuns: data3?.items });

    await this.onRetrieveEvent();
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT, autoLoad: boolean = true) {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        as_nm: this.state.searchQuery,
        stdate: this.state.stdate,
        enddate: this.state.enddate,
        divicd: this.state.divicd || '%',
        perid: this.state.perid || '%',
        appgubun: this.state.appgubun,
        gubun: this.state.gubun || '%',
        compflag: '%',
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.SS({
          data: [
            ...this.state.data,
            ...items.map((item) => new EnrollmentModel(item)),
          ],
        });
        next && next();
      },
      async () => {
        await this.SS({
          data: [],
        });
        await this.infinity?.retrieveAll();
        if (this.state.data.length > this.gridFocusIndex) {
          this.grid.current?.setFocus(this.gridFocusIndex);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    const lastSelected = this.state.content;

    await this.SS({
      data: [],
    });

    const index = await this.infinity?.retrieveTo(['costdate', 'costnum'],
      [lastSelected?.costdate, lastSelected?.costnum], type, true) || 0;

    if (!autoLoad) return;
    if (this.state.data && this.state.data.length > index) {
      await this.grid.current?.setFocus(index);
    }
  }

  @action
  async onNewEvent() {
    if (this.state.searchQuery !== '' || this.state.enddate < Today.date()) {
      await this.SS({
        searchQuery: '',
        stdate: `${Date8.make().substr(0, 6)}01`,
        enddate: Date8.make(),
      });
      await this.onRetrieveEvent(RetrieveFocusType.FIRST, false);
    }

    const { actionStore: api } = this.props;
    const data = await api.new();

    if (data) {
      const one = new EnrollmentModel(data, true);

      // tabIndex 가 0(견적항목)이 아닐때 견적항목 탭으로 이동
      this.tabIndex && this.tab.current?.selectTabById('DETAIL');

      await this.SS({
        content: one,
        lastSaveData: one,
        data: [
          one,
          ...this.state.data,
        ],
        dataDetail: data?.items2?.map((x: any) => new EnrollmentDetailModel(x)) || [],
        lastSaveDetail: data?.items2?.map((x: any) => new EnrollmentDetailModel(x)) || [],
        dataMaterials: [],
        dataConference: [],
        dataHistory: [],
      });

      this.gridFocus = one;
      this.gridFocusIndex = 0;
      await this.grid.current?.setFocus(0);

      await this.tableDetail.current?.update(true);
      await this.tableMaterials.current?.update(true);
      await this.tableConference.current?.update(true);
      if (this.state.dataDetail.length) {
        this.tableDetail.current?.setFocus(0);
      }
    }
  }

  @action
  async onSaveEvent(isRetrieve: boolean = true) {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return false;
    }

    const { actionStore: api } = this.props;

    const response = await api.save(
      {
        ...this.state.content,
        uamt: Sum.all(this.state.dataDetail, 'uamt'),
        samt: Sum.all(this.state.dataDetail, 'samt'),
        addamt: Sum.all(this.state.dataDetail, 'addamt'),
        costamt: Sum.all(this.state.dataDetail, 'costamt'),
        prow: this.state.dataDetail.length + this.state.dataMaterials.length,
        items: this.state.dataDetail,
        items2: this.state.dataMaterials,
        items3: this.state.dataConference,
      },
      this.state.content.isNew,
    );

    if (response) {
      const futureSearchRange = DateStabilizer.get(this.state.stdate, this.state.enddate, response.costdate);
      this.setState({
        content: new EnrollmentModel({ ...this.state.content, costnum: response.costnum }),
        stdate: futureSearchRange.stdate,
        enddate: futureSearchRange.enddate,
      });
      isRetrieve && await this.onRetrieveEvent();
      return true;
    }
    return false;
  }

  @action
  async onDeleteEvent() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (this.state.content?.isReported) {
      await this.onSubmitButtonClicked(true);
      return;
    }

    const { actionStore: api } = this.props;
    const text = `${this.state.content?.constnm}`;

    if (await api.delete(text, {
      costdate: this.state.content?.costdate,
      costnum: this.state.content?.costnum,
      cltcd: this.state.content?.cltcd,
      actcd: this.state.content?.actcd,
      nohuseq: this.state.content?.nohuseq,
      plandate: this.state.content?.plandate,
      appnum: this.state.content?.appnum,
      appgubun: this.state.content?.appgubun,
      compyn3: this.state.content?.compyn3,
    })) {
      await this.SS({
        data: update(this.state.data, {
          $splice: [[this.gridFocusIndex, 1]],
        }),
      });

      await this.onRetrieveEvent(RetrieveFocusType.FIRST);
    }
  }

  @action
  async onPrintEvent() {
    const { actionStore: api } = this.props;

    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state.data.length, '오류', '출력할 내역이 없습니다.')) {
      return;
    }

    // green_u, jul_os, kwon_i, wsin_s 제외
    let custChk = false;

    if (this.state.content.custcd !== 'green_u' && this.state.content.custcd !== 'jil_os'
      && this.state.content.custcd !== 'kwon_i' && this.state.content.custcd !== 'wsin_s') {
      custChk = true;
    }

    if (custChk && this.state.content.appgubun === '101' && this.state.content.wkactcd !== '001') {
      ConfirmWarning.show('확인', '아직 다른업체의 견적서는 준비되지 않았습니다!\n회사구분을 확인하세요');
      return;
    }

    if (this.state.content?.compyn === '3') {
      ConfirmWarning.show('오류', '폐기된 문서는 출력할 수 없습니다.');
      return;
    }

    if (this.state.content?.compyn !== '3') {
      if (this.state.content?.appgubun === '101') {
        const choosedPrintType = await this.choosePrintType();
        if (this.state.printType === '1') {
          await api.printWithElmanManager({
            costdate: this.state.content?.costdate,
            costnum: this.state.content?.costnum,
            remark: this.state.content?.remark,
            wkactcd: this.state.content?.wkactcd,
            state: this.state.content?.state,
            appgubun: this.state.content?.appgubun,
            gubun: this.state.content?.gubun,
            evidyn: this.state.content?.evidyn,
            attcnt: this.state.content?.attcnt,
            compyn: this.state.content?.compyn, // 견적상태
            prow: this.state.dataDetail.length + this.state.dataMaterials.length,
            compdate_stamp: this.state.compdate_stamp,
            // 결재상신된거 출력선택한거(0.견적서, 1.부품교체, 2.공사완료, 3.계약서)
            prtgubun: this.state.content?.appgubun === '101' ? choosedPrintType : '1',
          });
        } else if (this.state.printType === '0') {
          await api.printWithElmanManager({
            costdate: this.state.content?.costdate,
            costnum: this.state.content?.costnum,
            remark: this.state.content?.remark,
            wkactcd: this.state.content?.wkactcd,
            state: this.state.content?.state,
            appgubun: this.state.content?.appgubun,
            gubun: this.state.content?.gubun,
            evidyn: this.state.content?.evidyn,
            attcnt: this.state.content?.attcnt,
            compyn: this.state.content?.compyn, // 견적상태
            prow: this.state.dataDetail.length + this.state.dataMaterials.length,
            costdate_stamp: this.state.costdate_stamp,
            // 결재상신된거 출력선택한거(0.견적서, 1.부품교체, 2.공사완료, 3.계약서)
            prtgubun: this.state.content?.appgubun === '101' ? choosedPrintType : '0',
          });
        } else if (this.state.printType === '2') {
          await api.printWithElmanManager({
            costdate: this.state.content?.costdate,
            costnum: this.state.content?.costnum,
            remark: this.state.content?.remark,
            wkactcd: this.state.content?.wkactcd,
            state: this.state.content?.state,
            appgubun: this.state.content?.appgubun,
            gubun: this.state.content?.gubun,
            evidyn: this.state.content?.evidyn,
            attcnt: this.state.content?.attcnt,
            compyn: this.state.content?.compyn, // 견적상태
            prow: this.state.dataDetail.length + this.state.dataMaterials.length,
            costdate_stamp: this.state.costdate_stamp,
            // 결재상신된거 출력선택한거(0.견적서, 1.부품교체, 2.공사완료, 3.계약서)
            prtgubun: this.state.content?.appgubun === '101' ? choosedPrintType : '2',
          });
        } else if (this.state.printType === '3') {
          await api.printWithElmanManager({
            costdate: this.state.content?.costdate,
            costnum: this.state.content?.costnum,
            remark: this.state.content?.remark,
            wkactcd: this.state.content?.wkactcd,
            state: this.state.content?.state,
            appgubun: this.state.content?.appgubun,
            gubun: this.state.content?.gubun,
            evidyn: this.state.content?.evidyn,
            attcnt: this.state.content?.attcnt,
            compyn: this.state.content?.compyn, // 견적상태
            prow: this.state.dataDetail.length + this.state.dataMaterials.length,
            compdate_stamp: this.state.compdate_stamp,
            hajagigan: this.state.contractPopupHajagigan,
            // 결재상신된거 출력선택한거(0.견적서, 1.부품교체, 2.공사완료, 3.계약서)
            prtgubun: this.state.content?.appgubun === '101' ? choosedPrintType : '3',
          });
        }
      } else {
        await api.printWithElmanManager({
          costdate: this.state.content?.costdate,
          costnum: this.state.content?.costnum,
          remark: this.state.content?.remark,
          wkactcd: this.state.content?.wkactcd,
          state: this.state.content?.state,
          appgubun: this.state.content?.appgubun,
          gubun: this.state.content?.gubun,
          evidyn: this.state.content?.evidyn,
          attcnt: this.state.content?.attcnt,
          compyn: this.state.content?.compyn, // 견적상태
          prow: this.state.dataDetail.length + this.state.dataMaterials.length,

          // 결재상신된거 출력선택한거(0.견적서, 1.부품교체, 2.공사완료, 3.계약서)
          prtgubun: this.state.content?.appgubun === '101' ? await this.choosePrintType() : '0',
        });
      }
    }
  }


  @action
  async choosePrintType(): Promise<string> {
    // 13 - 1, 12|14|15 - 2, else 0
    let type = '0';
    switch (this.state.content?.gubun) {
      case '13':
        type = '1';
        break;

      case '12':
      case '14':
      case '15':
        type = '2';
        break;
    }

    return new Promise<string>((resolve) => {
      this.choosePrintTypeResolve = resolve;

      this.SS({
        isVisiblePrintType: true,
        printType: type,
      });
    });
  }

  @action
  async choosePrintTypeDone() {
    await this.SS({ isVisiblePrintType: false });

    if (this.state.printType === '0') {
      const confirm = await Confirm.ask('확인', '견적일을 표기하시겠습니까?', '예', '아니오');

      switch (confirm) {
        case AskType.YES:
          await this.SS({ costdate_stamp: '1' });
          break;

        case AskType.NO:
          await this.SS({ costdate_stamp: '0' });
          break;

        case AskType.CANCEL:
          return;
      }
      await this.SS({
        remarkContent: '견적서',
      });
    }

    if (this.state.printType === '1') {
      const confirm = await Confirm.ask('확인', '공사완료일을 표기하시겠습니까?', '예', '아니오');

      switch (confirm) {
        case AskType.YES:
          await this.SS({ compdate_stamp: '1' });
          break;

        case AskType.NO:
          await this.SS({ compdate_stamp: '0' });
          break;

        case AskType.CANCEL:
          return;
      }
      await this.SS({
        remarkContent: '부품교체확인서',
      });
    }

    if (this.state.printType === '2') {
      const confirm = await Confirm.ask('확인', '공사완료일을 표기하시겠습니까?\n*적용시 공사기간도 함께 적용됩니다', '예', '아니오');

      switch (confirm) {
        case AskType.YES:
          await this.SS({ compdate_stamp: '1' });
          break;

        case AskType.NO:
          await this.SS({ compdate_stamp: '0' });
          break;

        case AskType.CANCEL:
          return;
      }

      await this.SS({
        remarkContent: '공사완료확인서',
      });
    }

    if (this.state.printType === '3') {
      // const confirm = await Confirm.ask('확인', '계약일자를 표기하시겠습니까?', '예', '아니오');

      // switch (confirm) {
      //   case AskType.YES:
      //     await this.SS({ compdate_stamp: '1' });
      //     break;
      //
      //   case AskType.NO:
      //     await this.SS({ compdate_stamp: '0' });
      //     break;
      //
      //   case AskType.CANCEL:
      //     return;
      // }

      await this.SS({
        remarkContent: '계약서',
        contractPopupVisible: true,
      });
      return;
    }
    this.choosePrintTypeResolve && this.choosePrintTypeResolve(this.state.printType);

    await this.remarkContentSet();
  }

  @action
  async contractPrint() {
    this.setState({
      compdate_stamp: this.state.contractPopupDateGubun,
      contractPopupVisible: false,
    });
    this.choosePrintTypeResolve && this.choosePrintTypeResolve(this.state.printType);

    await this.remarkContentSet();
  }

  @action
  async remarkContentSet() {
    if (this.state.printType === '0') {
      await this.SS({
        remarkContent: '견적서',
      });
    }

    if (this.state.printType === '1') {
      await this.SS({
        remarkContent: '부품교체확인서',
      });
    }

    if (this.state.printType === '2') {
      await this.SS({
        remarkContent: '작업완료확인서',
      });
    }

    if (this.state.printType === '3') {
      await this.SS({
        remarkContent: '계약서',
      });
    }
  }


  @action
  async onRowFocusEvent(item: EnrollmentModel | undefined, index: number, isInstance: boolean = false) {
    if (!item) {
      await this.SS({
        content: new EnrollmentModel(),
        dataDetail: [],
        dataMaterials: [],
        dataHistory: [],
      });

      this.gridFocus = item;
      this.gridFocusIndex = 0;
      return;
    }

    if (item.isNew) {
      await this.SS({
        content: this.state.lastSaveData,
        dataDetail: this.state.lastSaveDetail,
        dataMaterials: [],
      });
      return;
    }

    this.gridFocus = item;
    this.gridFocusIndex = index;

    const { actionStore: api } = this.props;
    const data = await api.fxExec('dw_1_RowFocuschanged', this.gridFocus);

    if (data) {
      await this.SS({
        dataDetail: data?.items?.map((x: any) => new EnrollmentDetailModel(x)) || [],
        dataMaterials: data?.items2?.map((x: any) => new EnrollmentMaterialsModel(x)) || [],
        dataHistory: data?.items3?.map((x: any) => new EnrollmentHistoryModel(x)) || [],
        dataConference: data?.items4?.map((x: any) => new EnrollmentConferenceModel(x)) || [],
      });

      if (!isInstance) {
        await this.SS({
          content: new EnrollmentModel(data),
        });
      }
    }

    // 이전에 rowfocus 했던 detail리스트가 있고
    // detail 비어있는 리스트 rowfocus 시
    // 이전 리스트가 보이는현상 처리
    if (this.state.dataDetail.length < 1) {
      await this.tableDetail.current?.update(true);
    } else if (this.state.dataMaterials.length < 1) {
      await this.tableMaterials.current?.update(true);
    } else if (this.state.dataConference.length < 1) {
      await this.tableConference.current?.update(true);
    }

    // rowfocus 시 detail update
    await this.tableDetail.current?.update(true);
    await this.tableMaterials.current?.update(true);
    await this.tableConference.current?.update(true);

    await this.tableDetail.current?.setFocus(this.tableDetailFocusIndex);
    await this.tableMaterials.current?.setFocus(this.tableMaterialsFocusIndex);
    await this.tableConference.current?.setFocus(this.tableConferenceFocusIndex);
  }

  @action
  canTabChange() {
    if (this.gridFocus?.isNew) {
      ConfirmWarning.show('알림', '저장 먼저 해주세요');
    }
    return !this.gridFocus?.isNew;
  }

  @action
  async onTabChange(_: TabModel, index: number) {
    // if (this.gridFocus && !this.gridFocus?.isNew && !await this.onSaveEvent(false)) {
    //   ConfirmWarning.show('오류', '저장하지 못했습니다');
    //   return;
    // }
    this.tabIndex = index;
    // await this.onRowFocusEvent(this?.gridFocus, this.gridFocusIndex, true);
  }


  @action
  async openReferSelector() {
    if (!this.state.content?.appgubun || !this.state.content?.appnum) {
      ConfirmWarning.show('오류', '수신참조 추가는 먼저 결재상신을 하고 하세요!');
      return;
    }

    const { modalStore } = this.props;
    if (!await modalStore.openApprovalReferenceLine(PAPERCD.ESTIMATE, this.state.content?.appnum)) {
      ConfirmWarning.show('취소', '취소하셨습니다');
    }

    await this.onRowFocusEvent(this.gridFocus, this.gridFocusIndex);
  }

  @action
  async headerItemChanged(key: string, value: string) {
    const { actionStore: api } = this.props;
    const { content } = this.state;
    const result = await api.fxExec('dw_2_itemchanged', {
      ...content,
      [key]: value,
      itemname: key,
      data: value,
      new: content.isNew ? '1' : '0',
      ...(key === 'gubun' && this.state.dataDetail.length > 0 ? {
        costcd: this.state.dataDetail[0].costcd,
      } : {}),
    });

    const one = new EnrollmentModel({ ...content, ...result }, content.isNew);
    this.setState({ content: one });

    if (result.items2) {
      await this.SS({
        dataDetail: result?.items2?.map((x: any) => new EnrollmentDetailModel(x)) || [],
      });
    }
    await this.tableMaterials.current?.update(true);
    await this.tableDetail.current?.update(true);
  }


  // Detail
  @action
  async onDetailRowFocusEvent(item: EnrollmentDetailModel | undefined, index: number) {
    this.tableDetailFocus = item;
    this.tableDetailFocusIndex = index;
  }

  @action
  async onChangeTableDetail(rows: Array<EnrollmentDetailModel>) {
    await this.SS({ dataDetail: rows });
  }

  @action
  async tableDetailItemChanged(rowUpdate: RowUpdate, key: string, value: string, item: EnrollmentDetailModel, changes: any) {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_3_itemchanged', {
      ...{
        ...item,
        ...changes,
      },
      [key]: value,
      itemname: key,
      data: value,
    }, item.new === '1');

    if (result) {
      rowUpdate(result);
    }
  }

  @action
  async onClickDetailNew() {
    const { actionStore: api } = this.props;

    const seq = `000${parseInt(this.state.dataDetail[this.state.dataDetail.length - 1]?.seq || '000', 10) + 1}`
      .substr(-3);

    const data = await api.fxExec('tab_1_dw_3_new', {
      costdate: this.state.content?.costdate || '',
      costnum: this.state.content?.costnum || '',
      actcd: this.state.content?.actcd || '',
      gubun: this.state.content?.gubun || '',
      seq,
    });

    this.setState({
      dataDetail: [...this.state.dataDetail, new EnrollmentDetailModel(data, true)],
    }, async () => {
      await this.tableDetail.current?.update(true);
      this.tableDetail.current?.setFocus(this.state.dataDetail.length - 1);
    });
  }

  @action
  async onClickDetailRemove() {
    if (!ConfirmWarning.assert(this.tableDetailFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (this.tableDetailFocus?.seq === '001') {
      ConfirmWarning.show('오류', '첫 번째 항목은 삭제할 수 없습니다');
      return;
    }

    const item = this.state.dataDetail[this.tableDetailFocusIndex];

    const { actionStore: api } = this.props;
    await api.fxExec('tab_1_dw_3_delete', item);

    this.setState({
      dataDetail: update(this.state.dataDetail, {
        $splice: [[this.tableDetailFocusIndex, 1]],
      }),
    });

    await this.tableDetail.current?.update(false);

    // Focus next row
    if (this.state.dataDetail.length) {
      const next = this.tableDetailFocusIndex === 0 ? 0 : this.tableDetailFocusIndex - 1;
      this.tableDetailFocusIndex = next;
      this.tableDetail.current?.setFocus(next);
    }
  }


  // Materials
  @action
  async onMaterialsRowFocusEvent(item: EnrollmentMaterialsModel | undefined, index: number) {
    this.tableMaterialsFocus = item;
    this.tableMaterialsFocusIndex = index;

    await this.SS({
      MaterialsFocus: item,
    });
  }

  @action
  async onGenerateButtonClick() {
    if (!this.tableMaterialsFocus || !this.tableMaterialsFocus.equpcd) {
      ConfirmWarning.show('오류', '호기를 먼저 선택해주세요');
      return;
    }

    if (AskType.YES === await Confirm.ask('일괄 호기 생성', '현재 선택한 호기의 내용으로 전호기 일괄 적용 하시겠습니까?', '예', '아니요')) {
      const { actionStore: api } = this.props;
      const data = await api.fxExec('wb_equpall', {
        ...this.tableMaterialsFocus,
        actcd: this.state.content.actcd,
        costdate: this.state.content?.costdate,
        costnum: this.state.content.costnum,
        gubun: this.state.content.gubun,
      });

      if (data?.items) {
        const lst = data?.items?.map((x: any) => new EnrollmentMaterialsModel({
          ...x,
          seq: `000${parseInt(this.state.dataMaterials[this.state.dataMaterials.length - 1]?.seq || '000', 10) + 1}`
            .substr(-3),
        }, true));
        if (lst) {
          await this.SS({
            dataMaterials: [
              ...this.state.dataMaterials,
              ...lst,
            ],
          });

          this.tableMaterials.current?.update(false);
        }
      }
    }
  }

  @action
  async onChangeTableMaterials(rows: Array<EnrollmentMaterialsModel>) {
    await this.SS({
      dataMaterials: rows.map((x, i) => new EnrollmentMaterialsModel({
        ...x,
        seq: `000${i + 1}`.substr(-3),
      }, x.isNew)),
    });
  }

  @action
  async tableMaterialsItemChanged(rowUpdate: RowUpdate, key: string, value: string, item: EnrollmentMaterialsModel) {
    if (this.state.useCalc !== '0') {
      return;
    }

    const { actionStore: api } = this.props;
    const result = await api.fxExec('dw_4_itemchanged', {
      ...item,
      [key]: value,
      itemname: key,
      data: value,
    }, item.isNew, false);

    if (result) {
      rowUpdate(result);
    }
  }

  @action
  async onClickMaterialsNew() {
    const { actionStore: api } = this.props;

    if (!this.state.content.custcd) {
      ConfirmWarning.show('오류', '선택된 행이 없습니다');
      return;
    }

    const seq = `000${parseInt(this.state.dataMaterials[this.state.dataMaterials.length - 1]?.seq || '000', 10) + 1}`
      .substr(-3);

    const data = await api.fxExec('tab_2_dw_3_new', {
      costdate: this.state.content?.costdate || '',
      costnum: this.state.content?.costnum || '',
      actcd: this.state.content?.actcd || '',
      gubun: this.state.content?.gubun || '',
      seq,
    });

    if (data) {
      this.setState({
        dataMaterials: [
          ...this.state.dataMaterials,
          new EnrollmentMaterialsModel(data, true),
        ],
      }, async () => {
        await this.tableMaterials.current?.update(false);
        this.tableMaterials.current?.setFocus(this.state.dataMaterials.length - 1);
      });
    }
  }

  @action
  async appgubunPopup() {
    const { modalStore } = this.props;
    await modalStore.openApprovalReferenceRemark(this.state.content?.appnum);
  }

  @action
  async appgubunDetailPopup() {
    const { modalStore } = this.props;
    await modalStore.openApprovalRemark(this.state.content?.appnum);
  }

  @action
  async tabAutoCalc(item: any, rowUpdate: RowUpdate, isUamt: boolean = false, isSamt: boolean = false, isAddamt: boolean = false) {
    // 수동계산 체크 시 단가, 금액, 부가세, 견적금액 자동계산 x
    if (item?.autoexec === '1') {
      return;
    }

    if (this.state.useCalc !== '0') {
      return;
    }
    let isTax = this.state.content.taxflag === '1';

    isTax = false;
    const qty = Format.toNumber(item.qty);
    let uamt = Format.toNumber(item.uamt);

    let samt = Format.toNumber(item.samt);

    // 단가변경시 금액 * 수량 계산
    if (isUamt) {
      samt = Math.round((qty * uamt) / (isTax ? 1.1 : 1));
    } else if (!isSamt && samt >= 0) {
      // 부가세  마이너스 입력시 금액 0원되는현상 samt >= 0 으로 막음
      samt = Math.round((qty * uamt) / (isTax ? 1.1 : 1));
    } else {
      samt = isTax ? Math.round(samt / 1.1) : samt;
    }

    if (item.myn === '1' && !isSamt) {
      samt = 0;
      uamt = 0;
    }

    let tamt = Math.round(samt * 0.1);
    if (isAddamt) {
      tamt = Format.toNumber(item.addamt);
    }

    rowUpdate({
      ...item,
      samt: samt.toString(),
      addamt: tamt.toString(),
      costamt: (samt + tamt).toString(),
    });

    await this.tableMaterialsItemChanged;
  }

  @action
  async tabCostAmtCalc(item: any, rowUpdate: RowUpdate) {
    // 견적금액
    const costamt = Format.toNumber(item.costamt);

    // 금액 -> 견적금액 / 1.1 ( 반올림 )
    let samt = Format.toNumber(item.samt);
    samt = Math.round(costamt / 1.1);

    // 부가세 -> 견적금액 - 금액
    let addamt = Format.toNumber(item.addamt);
    addamt = costamt - samt;

    rowUpdate({
      ...item,
      costamt: costamt.toString(),
      samt: samt.toString(),
      addamt: addamt.toString(),
    });
  }

  @action
  async onClickMaterialsRemove() {
    if (!ConfirmWarning.assert(this.tableMaterialsFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const item = this.state.dataMaterials[this.tableMaterialsFocusIndex];

    const { actionStore: api } = this.props;
    await api.fxExec('tab_2_dw_3_delete', item);

    this.setState({
      dataMaterials: update(this.state.dataMaterials, {
        $splice: [[this.tableMaterialsFocusIndex, 1]],
      }),
    });

    await this.tableMaterials.current?.update(false);

    // Focus next row
    if (this.state.dataMaterials.length) {
      const next = this.tableMaterialsFocusIndex === 0 ? 0 : this.tableMaterialsFocusIndex - 1;
      this.tableMaterialsFocusIndex = next;
      this.tableMaterials.current?.setFocus(next);
    }
  }


  // Conference
  @action
  async onConferenceRowFocusEvent(item: EnrollmentConferenceModel | undefined, index: number) {
    this.tableConferenceFocus = item;
    this.tableConferenceFocusIndex = index;
  }

  @action
  async onChangeTableConference(rows: Array<EnrollmentConferenceModel>) {
    this.SS({ dataConference: rows });
  }

  @action
  async onClickConferenceNew() {
    const { actionStore: api } = this.props;

    const seq = `00${parseInt(this.state.dataConference[this.state.dataConference.length - 1]?.seq || '00', 10) + 1}`
      .substr(-2);

    const data = await api.fxExec('tab_4_dw_3_new', {
      costdate: this.state.content?.costdate || '',
      costnum: this.state.content?.costnum || '',
      actcd: this.state.content?.actcd || '',
      cltcd: this.state.content?.cltcd || '',
      gubun: this.state.content?.gubun || '',
      hisdate: Date8.make(),
      seq,
    });

    this.setState({
      dataConference: [...this.state.dataConference, new EnrollmentConferenceModel(data, true)],
    }, async () => {
      await this.tableConference.current?.update(false);
      this.tableConference.current?.setFocus(this.state.dataConference.length - 1);
    });
  }

  @action
  async onClickConferenceRemove() {
    if (!ConfirmWarning.assert(this.tableConferenceFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    const item = this.state.dataConference[this.tableConferenceFocusIndex];

    const { actionStore: api } = this.props;
    await api.fxExec('tab_4_dw_3_delete', {
      costdate: this.state.content.costdate,
      costnum: this.state.content.costnum,
      seq: item.seq,
    });

    this.setState({
      dataConference: update(this.state.dataConference, {
        $splice: [[this.tableConferenceFocusIndex, 1]],
      }),
    });

    await this.tableConference.current?.update(false);

    // Focus next row
    if (this.state.dataConference.length) {
      const next = this.tableConferenceFocusIndex === 0 ? 0 : this.tableConferenceFocusIndex - 1;
      this.tableConferenceFocusIndex = next;
      this.tableConference.current?.setFocus(next);
    }
  }


  // Button
  @action
  async onHeaderEmailButtonClicked() {
    const { actionStore: api } = this.props;
    const { content } = this.state;
    await api.fxExec('dw_2_buttonclicked', {
      ...content,
      itemname: 'b_email',
      new: content.isNew ? '1' : '0',
    });
  }

  @action
  async onSubmitButtonClicked(isForce: boolean = false) {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (this.state.content?.appgubun === '101' && this.state.content?.compyn3 === '0') {
      ConfirmWarning.show('오류', '현재 결재 상태에서는 취소할 수 없습니다');
      return;
    }

    const { user } = this.props.publicStore;
    const { actionStore: api } = this.props;
    const { modalStore } = this.props;

    let text = '';
    let appflag = '';

    // if (user.perid !== this.state.content?.costperid) {
    //   ConfirmWarning.show('경고', '본인이 작성하지 않은 문서는 결재할 수 없습니다');
    //   return;
    // }

    if (!isForce && this.state.content?.isReported && !this.state.content?.isReportedCancelable) {
      ConfirmWarning.show('오류', '현재 결재 상태에서는 취소할 수 없습니다');
      return;
    }

    if (this.state.content?.isReportedCancelable) {
      appflag = 'cancel';
      text = `${this.state.content?.actnm} 결재상신을 취소하시겠습니까?`;
    } else {
      appflag = 'ok';
      text = `${this.state.content?.actnm} 결재를 상신하시겠습니까?`;
    }

    await this.onSaveEvent();
    if (!await Confirm.show('확인', text, ConfirmType.QUESTION)) {
      return;
    }

    if (appflag === 'ok') {
      if (await modalStore.openApprovalLine(PAPERCD.ESTIMATE)) {
        await api.fxExec('wb_appreport', {
          papercd: PAPERCD.ESTIMATE.toString(),
          ...this.state.content,
          costperid: user.perid,
          perid: this.state.content.costperid,
          appflag,
        }) && this.onRetrieveEvent();
      }
    } else {
      await api.fxExec('wb_appreport', {
        papercd: PAPERCD.ESTIMATE.toString(),
        ...this.state.content,
        costperid: user.perid,
        perid: this.state.content.costperid,
        appflag,
      }) && this.onRetrieveEvent();
    }
  }

  @action
  async onKakaoButtonClicked(isOpen: boolean) {
    await this.SS({
      kakaoModal: isOpen,
    });
  }

  @action
  async kakaoSendType(value: boolean, type: string) {
    if (type === 'gubun1') {
      await this.SS({
        gubun1: value === true ? '1' : '0',
      });
    } else if (type === 'gubun2') {
      await this.SS({
        gubun2: value === true ? '1' : '0',
      });
    } else if (type === 'gubun3') {
      await this.SS({
        gubun3: value === true ? '1' : '0',
      });
    }
  }

  @action
  async onKakaoSend() {
    const { actionStore: api } = this.props;
    const result = await api.fxExec('wb_kakaosend', {
      gubun1: this.state.gubun1,
      gubun2: this.state.gubun2,
      gubun3: this.state.gubun3,
      appgubun: this.state.content?.appgubun,
      actnm: this.state.content?.actnm,
      constnm: this.state.content?.constnm,
      costnum: this.state.content?.costnum,
      costdate: this.state.content?.costdate,
      appnum: this.state.content?.appnum,
      perhp: this.state.content?.perhp,
    });

    if (result.messagebox === '전송되었습니다') {
      ConfirmWarning.show('확인', '전송되었습니다.');
      await this.SS({
        kakaoModal: false,
      });
    }
  }

  @action
  async onEmailButtonClicked() {
    setTimeout(() => this.remarkContentSet(), 50);
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state.content?.appgubun === '101', '오류', '먼저 결재를 받아주세요')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state.content.permail, '오류', '메일주소를 입력해주세요')) {
      return;
    }

    const { actionStore: api } = this.props;
    const prtgubun = await this.choosePrintType();

    api.fxEmail(
      this.state.content.permail,
      this.state.content.actnm,
      '',
      '',
      `${this.state.remarkContent}-${this.state.content?.actnm}-${this.state.content?.appnum}`,
      `안녕하세요 ${this.state.emailSpjangnm}입니다.\n\n${this.state.content?.actnm}의 무궁한 발전을 기원합니다`,
      {
        costdate: this.state.content?.costdate,
        costnum: this.state.content?.costnum,
        remark: this.state.content?.remark,
        wkactcd: this.state.content?.wkactcd,
        state: this.state.content?.state,
        appgubun: this.state.content?.appgubun,
        gubun: this.state.content?.gubun,
        evidyn: this.state.content?.evidyn,
        attcnt: this.state.content?.attcnt,
        prow: this.state.dataDetail.length + this.state.dataMaterials.length,
        prtgubun,
      },
    );
  }

  @action
  async onFaxButtonClicked() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(this.state.content?.appgubun === '101', '오류', '먼저 결재를 받아주세요')) {
      return;
    }

    const { actionStore: api } = this.props;
    api.fxFax(
      this.state.content.perfax,
      '',
      {
        costdate: this.state.content?.costdate,
        costnum: this.state.content?.costnum,
        remark: this.state.content?.remark,
        wkactcd: this.state.content?.wkactcd,
        state: this.state.content?.state,
        appgubun: this.state.content?.appgubun,
        gubun: this.state.content?.gubun,
        evidyn: this.state.content?.evidyn,
        attcnt: this.state.content?.attcnt,
        prow: this.state.dataDetail.length + this.state.dataMaterials.length,
        prtgubun: await this.choosePrintType(),
      },
    );
  }

  @action
  async onCopyButtonClicked() {
    if (!ConfirmWarning.assert(this.gridFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(!this.state.content.isNew, '오류', '저장 후 이용해주세요')) {
      return;
    }

    if (AskType.YES === await Confirm.ask(
      '견적서 복사',
      `견적일자 : ${Date8.withDash(this.state.content.costdate)}\n견적번호 : ${this.state.content.costnum}\n복사 하시겠습니까?`,
      '예',
      '아니요',
    )) {
      await this.SS({ isVisibleCopy: false });
      const { actionStore: api } = this.props;
      const data = await api.fxExec('wb_copy', {
        // ...this.state.content,
        costdate: this.state.content.costdate,
        costnum: this.state.content.costnum,
        costperid: this.state.content.costperid,
        costpernm: this.state.content.costpernm,
        costhp: this.state.content.costhp,
        cltcd: this.state.content.cltcd,
        actcd: this.state.content.actcd,
        actnm: this.state.content.actnm,
        wkactcd: this.state.content.wkactcd,
        wkactnm: this.state.content.wkactnm,
        constnm: this.state.content.constnm,
        gubun: this.state.content.gubun,
        pernm: this.state.content.pernm,
        permail: this.state.content.permail,
        pertel: this.state.content.pertel,
        perfax: this.state.content.perfax,
        refnm: this.state.content.refnm,
        refmail: this.state.content.refmail,
        zipcode: this.state.content.zipcode,
        address: this.state.content.address,
        address2: this.state.content.address2,
        iamt: this.state.content.iamt,
        taxflag: this.state.content.taxflag,
        addyn: this.state.content.addyn,
        evidyn: this.state.content.evidyn,
        samt: this.state.content.samt,
        tamt: this.state.content.tamt,
        mamt: this.state.content.mamt,

        appgubun: '',
        appnum: '',
        textnum: '',
        ncostdate: this.state.copyDate,
        appremark: '',
      });

      // 특기사항 초기화
      data.remark = '';

      // dataConference -> 현장협의사항 초기화
      if (data) {
        const one = new EnrollmentModel(data, true);
        await this.SS({
          content: one,
          data: [one, ...this.state.data],
          dataDetail: data?.items?.map((x: any) => new EnrollmentDetailModel(x, true)) || [],
          dataMaterials: data?.items2?.map((x: any) => new EnrollmentMaterialsModel(x, true)) || [],
          dataConference: [],
        });

        await this.grid.current?.setFocus(0);
        await this.tableDetail.current?.update(false);
        await this.tableMaterials.current?.update(false);

        this.gridFocus = one;
        this.gridFocusIndex = 0;
        await this.onSaveEvent();
      }
    }
  }

  // Popup material order
  @action
  async onMaterialOrderRetrieve() {
    const { actionStore: api } = this.props;
    const data = await api.retrieve({
      sub: 'w_popup_e451_ca606',
      costdate: this.state.content?.costdate,
      costnum: this.state.content?.costnum,
    });

    await this.SS({
      isVisibleOrderMaterial: true,
      dataModalOrderMaterials: data?.items,
    });

    this.tableModalOrderMaterials.current?.update(true);
  }

  @action
  async onMaterialOrderSubmit() {
    const { actionStore: api } = this.props;
    if (await api.save({
      sub: 'w_popup_e451_ca606',
      pumdate: this.state.modalOrderMaterialsDate,
      remark: this.state.modalOrderMaterialsRemark,
      costdate: this.state.content?.costdate,
      costnum: this.state.content?.costnum,
      actcd: this.state.content?.actcd,
      actnm: this.state.content?.actnm,
      constnm: this.state.content?.constnm,
      costperid: this.state.content?.costperid,
      costpernm: this.state.content?.costpernm,
      costdivicd: this.state.content?.compdivicd,
      costdivinm: this.state.content?.costdivinm,
      items: this.state.dataModalOrderMaterials?.filter((x) => x.chk === '1').map((x) => ({
        chk: x.chk,
        costdate: x.costdate,
        costnum: x.costnum,
        seq: x.seq,
      })),
    }, false)) {
      this.SS({ isVisibleOrderMaterial: false });
    }
  }


  // Popup self inspect results
  @action
  async onSelfInspectOpen() {
    this.onSelfInspectRetrieve();
    this.SS({ isVisibleSelfInspect: true });
  }

  @action
  async onSelfInspectRetrieve() {
    const { actionStore: api } = this.props;

    // 무한 스크롤바 헬퍼 초기화
    this.selfInspectInfinity = new InfinityRetrieve(
      {
        sub: 'w_popup_e471_e035_04',
        stmon: this.state.modalSelfInspectSearchStmon,
        as_nm: this.state.modalSelfInspectSearchQuery,
        divicd: this.state.modalSelfInspectSearchDivicd || '%',
        perid: this.state.modalSelfInspectSearchPerid || '%',
      },
      (params) => api.retrieve(params),
      async (items) => {
        await this.SS({
          dataModalSelfInspect: [
            ...this.state.dataModalSelfInspect,
            ...items.map((item) => new EnrollmentSelfInspectModel(item)),
          ],
        });
        this.tableModalSelfInspect.current?.update(false);
      },
      async () => {
        await this.SS({
          dataModalSelfInspect: [],
        });
        await this.selfInspectInfinity?.retrieve();
        await this.tableModalSelfInspect.current?.update(true);
      },
    );

    await this.SS({
      dataModalSelfInspect: [],
    });

    await this.selfInspectInfinity.retrieve();
    await this.tableModalSelfInspect.current?.update(true);
  }

  @action
  async onSelfInspectSubmit() {
    if (AskType.YES === await Confirm.ask(
      '견적서 생성',
      `현장명: ${this.state.content?.actnm}\n
견적일자: ${Date8.withDash(this.state.modalSelfInspectDate)}\n
구분: ${this.state.modalSelfInspectGubun}\n
위 내용으로 견적서를 생성하시겠습니까?`,
      '예',
      '아니오',
    )) {
      const { actionStore: api } = this.props;
      const items = this.state.dataModalSelfInspect.filter((x) => x.chk === '1').map((x) => ({
        chk: x.chk,
        plandate: x.plandate,
        actcd: x.actcd,
        equpcd: x.equpcd,
        resultcd: x.resultcd,
        pcode: x.pcode || '',
        pname: x.pname || '',
        psize: x.psize || '',
        punit: x.punit || '',
        samt: x.samt || '0',
        addamt: x.addamt || '0',
        costamt: x.costamt || '0',
        uamt: x.uamt || '0',
        myn: x.myn || '0',
        qty: x.qty || '0',
      }));

      if (items.filter((x) => !x.pcode).length > 0) {
        ConfirmWarning.show('오류', '선택한 항목의 품번을 선택해주세요');
        return;
      }

      if (await api.save({
        sub: 'w_popup_e471_e035_04',
        costdate: this.state.modalSelfInspectDate,
        gubun: this.state.modalSelfInspectGubun,
        stmon: this.state.modalSelfInspectSearchStmon,
        as_nm: this.state.modalSelfInspectSearchQuery,
        divicd: this.state.modalSelfInspectSearchDivicd || '%',
        perid: this.state.modalSelfInspectSearchPerid || '%',
        items,
      }, false)) {
        await this.SS({ isVisibleOrderMaterial: false });
        await this.onRetrieveEvent();
      }
    }
  }


  // Popup amount
  async openPopupAmount(index: number | undefined) {
    if (!ConfirmWarning.assert(this.tableDetailFocus, '오류', '선택된 항목이 없습니다')) {
      return;
    }
    const itemIndex = index || 0;
    const item = this.state.dataDetail[itemIndex];

    if (item.costcd === '001' || item.costnm === '부품비') {
      this.tab.current?.selectTabById('MATERIALS');
      return;
    }

    if (!ConfirmWarning.assert(item.unit === '%', '오류', '단위가 %여야만 선택할 수 있습니다')) {
      return;
    }

    if (!ConfirmWarning.assert(item.qty.toString() !== '0', '오류', '수량을 입력 후 선택해주세요')) {
      return;
    }

    const { actionStore: api } = this.props;
    const data = await api.retrieve({
      sub: 'w_popup_e452',
      costdate: item.costdate,
      costnum: item.costnum,
      seq: item.seq,
      items: this.state.dataDetail,
    });

    await this.SS({
      isVisibleAmount: true,
      dataModalAmount: data?.items?.map((x: any) => new EnrollmentAmountModel(x)) || [],
    });

    this.tableModalAmount.current?.update(true);
  }

  async saveModalAmount() {
    if (!ConfirmWarning.assert(
      this.state.dataModalAmount.filter((x) => x.chk === '1').length > 0,
      '오류',
      '적용할려면 1개이상 선택해야 합니다',
    )) {
      return;
    }

    const { actionStore: api } = this.props;
    const item = this.state.dataDetail[this.tableDetailFocusIndex];

    const data = await api.fxExec('save', {
      sub: 'w_popup_e452',
      costdate: item.costdate,
      costnum: item.costnum,
      costcd_s: item.costcd,
      seq: item.seq,
      qty: item.qty,
      items: this.state.dataModalAmount,
    }, false);

    if (!data?.items) {
      ConfirmWarning.show('오류', '서버 오류입니다');
      return;
    }

    await this.SS({
      isVisibleAmount: false,
      dataDetail: update(this.state.dataDetail, {
        [this.tableDetailFocusIndex]: {
          addamt: { $set: data.c_addamt.toString() },
          costamt: { $set: data.c_costamt.toString() },
          samt: { $set: data.c_samt.toString() },
        },
      }),
    });

    this.tableDetail.current?.update(false);
  }

  @action
  materialModal(isOpen: boolean) {
    this.setState({ materialDetailModal: isOpen });

    this.onMaterialModalRetrieve();
  }

  @action
  async onMaterialModalRetrieve() {
    const { actionStore: api } = this.props;

    setTimeout(async () => {
      const data = await api.fxExec('retrieve',
        {
          sub: 'w_popup_e451_uamt',
          stdate: this.state.modalStdate,
          enddate: this.state.modalEnddate,
          pname: this.state.MaterialsFocus?.pname,
        });
      if (data?.items) {
        this.setState({
          materialModalData: data?.items || [],
        });
      }
      await this.tableMaterialModal.current?.update(true);
    }, 1000);
  }

  @action
  async onReport() {
    const { modalStore } = this.props;

    // 자재 청구 결재라인 수정
    await modalStore.openApprovalLine(PAPERCD.REQUIRE, this.state.content.costperid);
  }

  // @action
  // async onMessageEvent(_: string, message: string) {
  //   const json = JSON.parse(JSON.parse(message));
  //
  //   ConfirmWarning.show('확인', json?.response.messagebox);
  //   await this.onRetrieveEvent();
  // }


  render() {
    return <EnrollmentTemplate
      scope={this}
    />;
  }
}
