export { PayCurrentState as AMPayCurrentState } from './PayCurrentState';
export { PayCalculate as AMPayCalculate } from './PayCalculate';
export { LastMonthPay as AMLastMonthPay } from './LastMonthPay';
export { YearPay as AMYearPay } from './YearPay';
export { YearSeverancePay as AMYearSeverancePay } from './YearSeverancePay';
export { BenefitItem as AMBenefitItem } from './BenefitItem';
export { Payslip as AMPayslip } from './Payslip';
export { PrepaymentBundle as AMPrepaymentBundle } from './PrepaymentBundle';
export { ServerancePayRegister as AMServerancePayRegister } from './ServerancePayRegister';
// @ts-ignore
export { SeverancePay as AMSeverancePay } from './SeverancePay';
export { YearInsuranceRatesRegister as AMYearInsuranceRatesRegister } from './YearInsuranceRatesRegister';
