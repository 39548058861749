// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckBox_container__3EEWO {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0 6px;\n  cursor: pointer; }\n  .CheckBox_container__3EEWO svg g > path {\n    stroke: var(--color-secondary); }\n  .CheckBox_container__3EEWO.CheckBox_active__1wKgh svg g > path {\n    stroke: var(--color-blue); }\n  .CheckBox_container__3EEWO:hover svg g > path {\n    stroke: #6c84a8; }\n  .CheckBox_container__3EEWO:active {\n    opacity: 0.7; }\n  .CheckBox_container__3EEWO.CheckBox_readonly__fDKjL {\n    -webkit-filter: grayscale(1);\n            filter: grayscale(1);\n    opacity: .5;\n    pointer-events: none; }\n  .CheckBox_container__3EEWO.CheckBox_noMargin__3w6rL {\n    margin: 0; }\n", "",{"version":3,"sources":["webpack://src/app/components/forms/CheckBox/CheckBox.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,eAAe,EAAA;EALjB;IAQI,8BAA8B,EAAA;EARlC;IAaM,yBAAyB,EAAA;EAb/B;IAmBM,eAAe,EAAA;EAnBrB;IAwBI,YAAY,EAAA;EAxBhB;IA4BI,4BAAoB;YAApB,oBAAoB;IACpB,WAAW;IACX,oBAAoB,EAAA;EA9BxB;IAkCI,SAAS,EAAA","sourcesContent":[".container {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0 6px;\n  cursor: pointer;\n\n  svg g > path {\n    stroke: var(--color-secondary);\n  }\n\n  &.active {\n    svg g > path {\n      stroke: var(--color-blue);\n    }\n  }\n\n  &:hover {\n    svg g > path {\n      stroke: #6c84a8;\n    }\n  }\n\n  &:active {\n    opacity: 0.7;\n  }\n\n  &.readonly {\n    filter: grayscale(1);\n    opacity: .5;\n    pointer-events: none;\n  }\n\n  &.noMargin {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CheckBox_container__3EEWO",
	"active": "CheckBox_active__1wKgh",
	"readonly": "CheckBox_readonly__fDKjL",
	"noMargin": "CheckBox_noMargin__3w6rL"
};
export default ___CSS_LOADER_EXPORT___;
