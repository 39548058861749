import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { NonDrivingMonthModel } from '../models';
import { NonDriving } from '../NonDriving';
import { FormatTextBox, TextBox } from '../../../../../components';

// 월별
const Month: GridLayoutHeader<NonDrivingMonthModel, NonDriving>[] = [
  {
    id: 'actnm',
    text: '현장명',
    width: 100,
    trail: () => (
      <FlexLayout
        justify="left"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.actnm}
      </FlexLayout>
    ),
  },
  {
    id: 'equpnm',
    text: '호기명',
    width: 150,
    render: (x) => (
      <TextBox
        textAlign="center"
        value={x.equpnm}
      />
    ),
  },
  {
    id: 'recedate',
    text: '접수일자',
    width: 150,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.recedate}
      />
    ),
  },
  {
    id: 'arrivdate',
    text: '도착일자',
    width: 200,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.arrivdate}
      />
    ),
  },
  {
    id: 'arrivtime',
    text: '도착시간',
    width: 150,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="##:##"
        value={x.arrivtime}
      />
    ),
  },
  {
    id: 'comptime',
    text: '완료일자',
    width: 200,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="####-##-##"
        value={x.comptime}
      />
    ),
  },
  {
    id: 'resutime',
    text: '대응시간',
    width: 130,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="##:##"
        value={x.resutime}
      />
    ),
  },
  {
    id: 'resulttime',
    text: '처리시간',
    width: 200,
    render: (x) => (
      <FormatTextBox
        textAlign="center"
        format="##:##"
        value={x.resulttime}
      />
    ),
  },
  {
    id: 'trouble',
    text: '사람갇힘',
    width: 80,
  },
  {
    id: 'contnm',
    text: '고장내용',
    width: 100,
  },
  {
    id: 'contremark',
    text: '고장내용상세',
    width: 200,
  },
  {
    id: 'pernm3',
    text: '기록',
    width: 200,
  },
  {
    id: 'deljugi',
    text: '청구주기',
    width: 200,
  },
];

export default Month;
