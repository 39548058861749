// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RadioBox_container__AsWl1 {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer; }\n  .RadioBox_container__AsWl1 svg g > path {\n    stroke: var(--color-secondary); }\n  .RadioBox_container__AsWl1.RadioBox_active__OmwG2 svg g > path {\n    stroke: var(--color-blue); }\n  .RadioBox_container__AsWl1:hover svg g > path {\n    stroke: #6c84a8; }\n  .RadioBox_container__AsWl1:active {\n    opacity: 0.7; }\n", "",{"version":3,"sources":["webpack://src/app/components/forms/RadioBox/RadioBox.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe,EAAA;EAJjB;IAOI,8BAA8B,EAAA;EAPlC;IAYM,yBAAyB,EAAA;EAZ/B;IAkBM,eAAe,EAAA;EAlBrB;IAuBI,YAAY,EAAA","sourcesContent":[".container {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n\n  svg g > path {\n    stroke: var(--color-secondary);\n  }\n\n  &.active {\n    svg g > path {\n      stroke: var(--color-blue);\n    }\n  }\n\n  &:hover {\n    svg g > path {\n      stroke: #6c84a8;\n    }\n  }\n\n  &:active {\n    opacity: 0.7;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RadioBox_container__AsWl1",
	"active": "RadioBox_active__OmwG2"
};
export default ___CSS_LOADER_EXPORT___;
