/**
 * 모델
 * @window w_popup_e037_equpcd
 * @category 호기생성
 */
import { computed } from 'mobx';

export class EqupPopupModel {
  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly chk: string;

  /**
   * 호기코드
   *
   * **data type** varchar(8)
   */
  readonly equpcd: string;

  /**
   * 호기명
   *
   * **data type** varchar(2)
   */
  readonly equpnm: string;

  readonly new: string;

  constructor(data: any = {}) {
    this.chk = data.chk || '0';
    this.equpcd = data.equpcd || '';
    this.equpnm = data.equpnm || '';
    this.new = data.new || '';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
