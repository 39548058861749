/**
 * 모델
 * @window w_tb_ae200
 * @category 지출결의서
 */

export class JournalPopupModel {
  /**
   *
   *
   * **data type** varchar(1)
   */
  readonly chk: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly rptdate: string;

  /**
   *
   *
   * **data type** varchar(8)
   */
  readonly kmflag: string;

  /**
   *
   *
   * **data type** varchar(3)
   */
  readonly km: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly uamt: string;

  /**
   *
   *
   * **data type** varchar(0)
   */
  readonly samt: string;

  constructor(data: any = {}) {
    this.chk = data.chk || '';
    this.rptdate = data.rptdate || '';
    this.kmflag = data.kmflag || '';
    this.km = data.km || '';
    this.uamt = data.uamt || '';
    this.samt = data.samt || '';
  }
}
