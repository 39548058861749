import * as React from 'react';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import {
  Supply,
  SupplyItemChangeTypes,
} from './Supply';
import {
  ComboBox,
  ComboBoxModel,
  FlexLayout,
  SearchLayout,
  DateTextBox,
  TextBox,
  Button,
  GridLayout,
  FormatTextBox,
  LabelBox,
  CheckBox,
  FormatNumberTextBox,
  LayoutTitle,
  TableLayout,
  ModalView,
  DefaultLayout,
  SubHeader,
} from '../../../../components';
import { Date8, Today } from '../../../../utils/time';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../models/common';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';

/**
 * 메인화면
 * @window w_tb_da034_01
 * @category 불출등록
 */
export const SupplyTemplate: React.FC<TemplateProps<Supply>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={400}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="현장, 수령자로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.loadBillModal(true)}>
          <div style={{ width: 80 }}>자재청구서</div>
        </Button>

        <Button onClick={() => scope.loadOrderModal(true)}>
          <div style={{ width: 80 }}>발주서</div>
        </Button>
      </SearchLayout>

      <FlexLayout size={298}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'deldate',
              text: '불출일자',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={x.deldate}
                />
              ),
            },
            {
              id: 'delnum',
              text: '번호',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.delnum}
                </FlexLayout>
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 300,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.actnm}
                </FlexLayout>
              ),
            },
            {
              id: 'pernm',
              text: '수령자명',
              width: 100,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.pernm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.supplyList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />

        <FlexLayout
          weight={2}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 입고를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="불출일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.deldate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ deldate: value }, () => scope.itemChanged(SupplyItemChangeTypes.DELDATE))}
                readonly={model?.new === '0'}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.delnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="창고"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.store}
                textAlign="center"
                onChange={(value) => setData({ store: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_CA504',
                  {},
                  true,
                  (item) => {
                    setData({
                      store: item.cd,
                      storenm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.storenm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="수령자명"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    },
                    () => scope.itemChanged(SupplyItemChangeTypes.PERID, item.cd));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.pernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="청구자부서"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.divicd}
                textAlign="center"
                onChange={(value) => setData({ divicd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.divinm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="현장명"
              weight={2}
              style={{ color: 'var(--color-red)' }}
              footer={
                <DefaultLayout size={175}>
                  <ComboBox
                    textAlign="center"
                    value={model?.contgubun}
                    data={[
                      { value: '01', remark: 'FM계약' },
                      { value: '02', remark: '다년계약' },
                      { value: '03', remark: '일반계약' },
                      { value: '04', remark: '상주' },
                      { value: '05', remark: '외주' },
                    ].map((x) => new ComboBoxModel(x.value, x.remark))}
                    onSelect={(option) => setData({ contgubun: option.value })}
                    isDisabledTrackingStateChange={true}
                  />
                </DefaultLayout>
              }
            >
              <TextBox
                value={model?.actcd}
                textAlign="center"
                onChange={(value) => setData({ actcd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_E601_1',
                  {},
                  true,
                  (item) => {
                    setData({
                      actcd: item.cd,
                      actnm: item.cdnm,
                    }, () => scope.itemChanged(SupplyItemChangeTypes.ACTCD, item.cd));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.actnm}
                weight={3}
                textAlign="left"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              weight={2}
              title="프로젝트"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                textAlign="center"
                value={model.projno}
                onChange={(value) => setData({ projno: value, isChanged: 'true' })}
                bindSearchModal={new SearchBindingProjects(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    setData({
                      projno: item.projno,
                      projnm: item.projnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.projnm}
                weight={3}
                textAlign="left"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox title="특이사항" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>

        <LayoutTitle size={230}>불출내역</LayoutTitle>
        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.custcd}
        disabledText="신규로 추가하거나 입고를 선택해주세요"
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'delseq',
              text: '순번',
              width: 50,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.delseq}
                  onChange={(value) => rowUpdate({ delseq: value })}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'myn',
              text: '유무상',
              width: 60,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.myn}
                  data={[
                    { value: '1', remark: '유상' },
                    { value: '0', remark: '무상' },
                    { value: '2', remark: 'FM' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ myn: option.value })}
                />
              ),
            },
            {
              id: 'pcode',
              text: '품목코드',
              width: 150,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.pcode}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ pcode: value })}
                  onDoubleClick={async () => {
                    const result = await scope.props.modalStore.openProductSelector(x.pcode);
                    rowUpdate({
                      pcode: result.phm_pcod,
                      pname: result.phm_pnam,
                      psize: result.phm_size,
                      punit: result.phm_unit,
                      uamt: result.phm_uamt || result.phm_samt,
                    });
                  }}
                  onEnter={async (value) => {
                    const result = await scope.props.modalStore.openProductSelector(value);
                    rowUpdate({
                      pcode: result.phm_pcod,
                      pname: result.phm_pnam,
                      psize: result.phm_size,
                      punit: result.phm_unit,
                      uamt: result.phm_uamt || result.phm_samt,
                    });
                  }}
                />
              ),
            },
            {
              id: 'pname',
              text: '품목명',
              width: 250,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="left"
                >
                  {x.pname}
                </FlexLayout>
              ),
            },
            {
              id: 'psize',
              text: '규격',
              width: 200,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="left"
                >
                  {x.psize}
                </FlexLayout>
              ),
            },
            {
              id: 'punit',
              text: '단위',
              width: 50,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="center"
                >
                  {x.punit}
                </FlexLayout>
              ),
            },
            {
              id: 'pserial',
              text: '품목시리얼번호',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.pserial}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ pserial: value })}
                />
              ),
            },
            {
              id: 'qty',
              text: '수량',
              width: 75,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.qty}
                  textAlign="center"
                  onChange={(value) => scope.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty')}
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
            {
              id: 'banqty',
              text: '반납수량',
              width: 75,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.banqty}
                  textAlign="center"
                  // readonly={true}
                  style={{ pointerEvents: 'none' }}
                  onChange={
                    (value) => rowUpdate({ banqty: value })}
                />
              ),
            },
            {
              id: 'uamt',
              text: '단가',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.uamt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
                />
              ),
            },
            {
              id: 'samt',
              text: '공급금액',
              width: 100,
              trail: (data) => {
                const sumSamt = Sum.all(data, 'samt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumSamt)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.samt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
                />
              ),
            },
            {
              id: 'equpcd',
              text: '동호기',
              width: 75,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.equpcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ equpcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E611',
                    { uv_arg1: model?.actcd },
                    true,
                    (item) => {
                      rowUpdate({
                        equpcd: item.cd,
                        equpnm: item.cdnm,
                      });
                    },
                  )}
                />
              ),
            },
            {
              id: 'equpnm',
              text: '동호기명',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.equpnm}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ equpnm: value })}
                />
              ),
            },
            {
              id: 'remark',
              text: '비고',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.remark}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ remark: value })}
                />
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.supplyDetailList}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.loadBillModal}
        onClose={() => scope.loadBillModal(false)}
        width={1000}
        height={600}
        zIndex={-1}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>자재청구서 불러오기</LayoutTitle>

          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            noMarginTop={true}
          >
            <FlexLayout weight={4}>
              <span style={{
                margin: 8,
                fontSize: 12,
              }}>
                검색어
              </span>
              <TextBox
                value={scope.state.popupSearchQuery}
                onChange={(value) => scope.setState({ popupSearchQuery: value })}
                onEnter={() => scope.modalBillRetrieve()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
            <Button
              onClick={() => scope.modalBillDelete()}>
              청구내역서 삭제
            </Button>
          </SearchLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupTable}
              header={[
                {
                  id: 'chk',
                  text: '선택',
                  width: 50,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={x.chk === '1'}
                        onChange={
                          (value) => {
                            rowUpdate({ chk: value ? '1' : '0' });
                          }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pumdate',
                  text: '청구일자',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.pumdate}
                    />
                  ),
                },
                {
                  id: 'pumnum',
                  text: '번호',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pumnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pernm',
                  text: '청구자',
                  width: 75,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.pernm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'actnm',
                  text: '현장명',
                  width: 225,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.actnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'equpnm',
                  text: '동호기',
                  width: 75,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.equpnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pname',
                  text: '품명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.pname}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'punit',
                  text: '단위',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.punit}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '수량',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.qty}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'uamt',
                  text: '단가',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      weight={2}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.uamt}
                    />
                  ),
                },
                {
                  id: 'samt',
                  text: '공급금액',
                  width: 100,
                  trail: () => (
                    <FlexLayout
                      justify="right"
                      align="center"
                    >
                      {scope.state.samt_tot}
                    </FlexLayout>
                  ),
                  render: (x) => (
                    <FormatNumberTextBox
                      weight={2}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.samt}
                    />
                  ),
                },
                {
                  id: 'title',
                  text: '제목',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.title}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.billPopupList}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout>
              <FlexLayout weight={2}>
                <LayoutTitle weight={1}>일자</LayoutTitle>
                <LabelBox weight={2.5}>
                  <DateTextBox
                    value={scope.state.deldate}
                    textAlign="center"
                    format="####-##-##"
                    onChange={(value) => scope.setState({ deldate: value })}
                  />
                </LabelBox>
              </FlexLayout>
              <FlexLayout weight={2.5}>
                <LayoutTitle
                  weight={1}
                  style={{ color: 'var(--color-red)' }}
                >
                  발주처
                </LayoutTitle>
                <LabelBox weight={4}>
                  <TextBox
                    value={scope.state?.cltcd}
                    textAlign="center"
                    onChange={(value) => scope.setState({ cltcd: value })}
                    bindSearchModal={new SearchBinding(
                      scope.props.modalStore,
                      'TB_XCLIENT',
                      {},
                      true,
                      (item) => {
                        scope.setState({
                          cltcd: item.cd,
                          cltnm: item.cdnm,
                        });
                      },
                    )}
                    isDisabledTrackingStateChange={true}
                  />
                  <TextBox
                    value={scope.state?.cltnm}
                    weight={3}
                    textAlign="left"
                    isDisabledTrackingStateChange={true}
                    readonly={true}
                  />
                </LabelBox>
              </FlexLayout>
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.modalBillSave()}>확인</Button>
              <Button onClick={() => scope.loadBillModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>

      <ModalView
        isVisible={scope.state.loadOrderModal}
        onClose={() => scope.loadOrderModal(false)}
        width={1100}
        height={600}
        zIndex={-1}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <LayoutTitle>발주서 불러오기</LayoutTitle>

          <SearchLayout
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
            noMarginTop={true}
          >
            <FlexLayout weight={4}>
              <span style={{
                margin: 8,
                fontSize: 12,
              }}>
                검색어
              </span>
              <TextBox
                value={scope.state.popupSearchQuery}
                onChange={(value) => scope.setState({ popupSearchQuery: value })}
                onEnter={() => scope.modalOrderRetrieve()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupTable}
              header={[
                {
                  id: 'chk',
                  text: '선택',
                  width: 50,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={x.chk === '1'}
                        onChange={
                          (value) => {
                            rowUpdate({ chk: value ? '1' : '0' });
                          }}
                        isDisabledTrackingStateChange={true}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'baldate',
                  text: '발주일자',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.baldate}
                    />
                  ),
                },
                {
                  id: 'balnum',
                  text: '번호',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.balnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cltnm',
                  text: '거래처명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.cltnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pname',
                  text: '품명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.pname}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'punit',
                  text: '단위',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.punit}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '발주수량',
                  width: 75,
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.qty}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'overqty',
                  text: '남은수량',
                  width: 75,
                  render: (x, _, ref) => (
                    <FlexLayout>
                      <TextBox
                        ref={ref}
                        value={x.overqty}
                        textAlign="center"
                      >
                      </TextBox>
                    </FlexLayout>
                  ),
                },
                {
                  id: 'delqty',
                  text: '불출수량',
                  width: 75,
                  render: (x, rowUpdate, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.delqty}
                      textAlign="center"
                      onChange={(value) => rowUpdate({ delqty: value })}
                    />
                  ),
                },
                {
                  id: 'uamt',
                  text: '단가',
                  width: 150,
                  render: (x, _, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.uamt}
                    />
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.samt}
                    />
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.tamt}
                    />
                  ),
                },
                {
                  id: 'deldate',
                  text: '납기일',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.deldate}
                    />
                  ),
                },
                {
                  id: 'bigo',
                  text: '납품장소',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.bigo}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'bal_remark',
                  text: '특이사항(공사명)',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.bal_remark}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.orderPopupList}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
            />
          </FlexLayout>

          <SubHeader>
            <FlexLayout weight={0.5}>
              <LayoutTitle weight={1}>일자</LayoutTitle>
              <LabelBox weight={2.5}>
                <DateTextBox
                  value={scope.state.deldate}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => scope.setState({ deldate: value })}
                />
              </LabelBox>
            </FlexLayout>

            <FlexLayout justify="end">
              <Button onClick={() => scope.modalOrderSave()}>확인</Button>
              <Button onClick={() => scope.loadOrderModal(false)}>닫기</Button>
            </FlexLayout>
          </SubHeader>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
