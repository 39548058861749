import { computed } from 'mobx';

/**
 * 기간별 모델
 * @window w_input_da023w_03_view
 * @category 장기미수현황
 */
export class TermModel {
  /**
   * 사업장코드
   *
   * **data type** varchar(13)
   */
  readonly spjangcd: string;

  /**
   * 현장코드
   *
   * **data type** varchar(13)
   */
  readonly cltcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(255)
   */
  cltnm: string;

  /**
   * 현장코드
   *
   * **data type** varchar(15)
   */
  actcd: string;

  /**
   * 현장명
   *
   * **data type** varchar(100)
   */
  actnm: string;

  /**
   * 매출액
   *
   * **data type** number
   */
  misamt: string;

  /**
   * 매출일자
   *
   * **data type** number
   */
  misdate: string;

  /**
   * 매출번호
   *
   * **data type** number
   */
  misnum: string;

  /**
   * 입금액
   *
   * **data type** number
   */
  rcvamt: string;

  /**
   * 미수잔액
   *
   * **data type** number
   */
  resuamt: string;

  new: string;

  constructor(data: any = {}, isNew: boolean = false) {
    this.cltcd = data.cltcd || '';
    this.spjangcd = data.spjangcd || '';
    this.cltnm = data.cltnm || '';
    this.actcd = data.actcd || '';
    this.actnm = data.actnm || '';
    this.misamt = data.misamt || '';
    this.misdate = data.misdate || '';
    this.misnum = data.misnum || '';
    this.rcvamt = data.rcvamt || '';
    this.resuamt = data.resuamt || '';
    this.new = isNew ? '1' : '0';
  }

  @computed
  get isNew() {
    return this.new === '1';
  }
}
