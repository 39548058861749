import * as React from 'react';
import { action } from 'mobx';
import {
  Category,
  ConfirmType,
  PageProps,
  PageToolEvents,
  RetrieveFocusType,
} from '../../../../constants';
import { BuyNationalTaxServiceModel } from './BuyNationalTaxService.model';
import { TableLayout } from '../../../../components';
import { InfinityRetrieve } from '../../../../models';
import { BuyNationalTaxServiceTemplate } from './BuyNationalTaxService.template';
import {
  Confirm,
  ConfirmFail,
  ConfirmWarning,
} from '../../../../utils/confirm';
import { FileSelector } from '../../../../utils/file';
import { PageComponent } from '../../../../utils/layout';
import { CltModalModel } from './CltModalModel.model';

interface BuyNationalTaxServiceTemplateState {
  // search
  stdate: string;
  enddate: string;

  // data
  data: Array<BuyNationalTaxServiceModel>;
  focusIndex: number;
  focused?: BuyNationalTaxServiceModel;
  pushTotalCheck: boolean;

  // trail
  totsamt_tot: string;
  tottamt_tot: string;
  total: string;
  totmamt_tot: string;
  qty_tot: string;
  uamt_tot: string;
  mijamt_tot: string;
  samt_tot: string;
  tamt_tot: string;

  // 미처리 체크 시 항목코드, 계정 값 넣기 위한 변수
  acccd: string;
  accnm: string;
  artcd: string;
  artnm: string;

  // 거래처 자동등록 팝업창
  cltModal: boolean;
  cltModalData: Array<CltModalModel>;

  filename: string;
  file: Blob;
}

/**
 * 컨트롤러
 * @window w_tb_ca640_import
 * @category 매입등록[국세청]
 */
export class BuyNationalTaxService extends PageComponent<PageProps, BuyNationalTaxServiceTemplateState>
  implements PageToolEvents {
  updatedRows?: Array<BuyNationalTaxServiceModel>;

  table: React.RefObject<TableLayout> = React.createRef();

  infinity?: InfinityRetrieve;

  files?: any;

  constructor(props: PageProps, context: any) {
    super(props, context);
    this.props.onMount && this.props.onMount(this);

    const today = new Date();

    const year = today.getFullYear(); // 년도

    let month:string | number = today.getMonth() + 1; // 월

    if (month < 10) {
      month = `0${month}`;
    }

    let date:string | number = today.getDate(); // 날짜

    if (date < 10) {
      date = `0${date}`;
    }

    const params = this.props.publicStore?.getPageParams();

    // state 기본값 정의
    this.state = props.state || {
      // search
      stdate: params?.stdate || `${year}${month}${date}`,
      enddate: params?.enddate || `${year}${month}${date}`,

      // data
      focusIndex: 0,
      data: [],

      // trail
      totsamt_tot: '0',
      tottamt_tot: '0',
      total: '0',
      totmamt_tot: '0',
      qty_tot: '0',
      uamt_tot: '0',
      mijamt_tot: '0',
      samt_tot: '0',
      tamt_tot: '0',

      // 미처리 체크 시 항목코드, 계정 값 넣기 위한 변수
      accnm: '0',
      acccd: '0',
      artcd: '0',
      artnm: '0',

      // 거래처 자동등록 팝업창
      cltModal: false,
      cltModalData: [],

      filename: '',
      file: new Blob(),
    };
  }

  @action
  async componentDidRecover() {
    const params = this.props.publicStore?.getPageParams();
    if (params) {
      await this.SS({
        stdate: params?.stdate || this.state.stdate,
        enddate: params?.enddate || this.state.enddate,
      });
      await this.onRetrieveEvent();
    }
  }

  @action
  async onFirstOpenEvent() {
    await this.open();
    await this.onRetrieveEvent();
  }

  @action
  async open() {
    const { actionStore: api } = this.props;
    const data = await api.fxExec('open', {
    });

    await this.SS({
      acccd: data?.acccd,
      accnm: data?.accnm,
      artcd: data?.artcd,
      artnm: data?.artnm,
    });
  }

  @action
  async onRetrieveEvent(type: RetrieveFocusType = RetrieveFocusType.DEFAULT) {
    const { actionStore: api } = this.props;
    this.updatedRows = [];

    // 무한 스크롤바 헬퍼 초기화
    this.infinity = new InfinityRetrieve(
      {
        stdate: this.state.stdate,
        enddate: this.state.enddate,
      },
      (params) => api.retrieve(params),
      (items, next) => {
        this.setState({
          data: [
            ...this.state.data,
            ...items.map((x: any) => new BuyNationalTaxServiceModel(x)),
          ],
        }, next);
      },
      async () => {
        await this.SS({
          data: [],
        });

        await this.infinity?.retrieveAll();
        if (this.state.data && this.state.data?.length > 0) {
          await this.table.current?.update();
          this.table.current?.setFocus(0, 0);
        }
      },
    );

    // 상단 조회 버튼을 누를때는 기존 배열 초기화
    await this.SS({
      data: [],
      pushTotalCheck: false,
      focused: new BuyNationalTaxServiceModel(),
      totsamt_tot: '0',
      tottamt_tot: '0',
      total: '0',
      totmamt_tot: '0',
      qty_tot: '0',
      uamt_tot: '0',
      mijamt_tot: '0',
      samt_tot: '0',
      tamt_tot: '0',
    });

    const index = await this.infinity?.retrieveTo(['seq', 'saupnum'],
      [this.state.focused?.seq, this.state.focused?.saupnum], type, true) || 0;
    if (this.state.data && this.state.data.length > index) {
      await this.table.current?.update();
      this.table.current?.setFocus(index, 0);
      this.onRowFocusEvent(this.state.data[0], 0);
    }

    // 전체 체크
    await this.SS({
      totsamt_tot: this.infinity?.box?.totsamt_tot || '0',
      tottamt_tot: this.infinity?.box?.tottamt_tot || '0',
      total: this.infinity?.box?.total || '0',
      totmamt_tot: this.infinity?.box?.totmamt_tot || '0',
      qty_tot: this.infinity?.box?.qty_tot || '0',
      uamt_tot: this.infinity?.box?.uamt_tot || '0',
      mijamt_tot: this.infinity?.box?.mijamt_tot || '0',
      samt_tot: this.infinity?.box?.samt_tot || '0',
      tamt_tot: this.infinity?.box?.tamt_tot || '0',
    });

    await this.table.current?.update();
    console.log(this.state.focused);
  }

  @action
  async onSaveEvent() {
    const { actionStore: api } = this.props;
    if (await api.save({
      new: '0',
      items: this.updatedRows,
    }, true)) {
      this.updatedRows = [];
      this.table.current?.resetUpdates();
    }
  }

  @action
  async onDeleteEvent() {
    const { actionStore: api } = this.props;
    const text = '선택한 내역을 삭제하겠습니까?';

    let check;
    let chkData: BuyNationalTaxServiceModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        if (x.mijflag !== '0') {
          check = false;
        }
        chkData.push(new BuyNationalTaxServiceModel({
          ...x,
        }));
      }
    });

    if (check === false) {
      ConfirmWarning.show('확인', '매입처리되있는 데이터가 있습니다! 삭제할수 없습니다!');
      return;
    }
    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.delete(text, { items: chkData }) && this.onRetrieveEvent(RetrieveFocusType.END);
      chkData = [];
      check = true;
    }
  }

  @action
  async onExcelEvent() {
    const { actionStore: api } = this.props;
    if (this.state.data.length < 1) {
      ConfirmWarning.show('오류', '엑셀전환할 내역이 없습니다.');
      return;
    }

    await api.excel({
      stdate: this.state.stdate,
      enddate: this.state.enddate,
    });
  }

  @action
  onRowFocusEvent(item: BuyNationalTaxServiceModel, index: number) {
    this.setState({
      focused: item,
      focusIndex: index,
    });
  }

  @action
  onUpdatedRows(rows: Array<BuyNationalTaxServiceModel>, updatedRows: Array<BuyNationalTaxServiceModel>) {
    this.updatedRows = updatedRows;
    this.setState({ data: rows });
  }

  @action
  async updateCheckAllToggle(checked: boolean) {
    let checkData: any = [];
    this.state.data?.forEach((x: any) => {
      checkData.push(new BuyNationalTaxServiceModel({
        ...x,
        chk: checked && x.mijchk === '0' ? '1' : '0',
      }));
    });

    // 미처리 체크시 계정과목 등등 값 넣어줌.
    checkData = checkData?.map((x: any) => ({
      ...x,
      acccd: x.chk === '1' ? this.state.acccd : '',
      accnm: x.chk === '1' ? this.state.accnm : '',
      artcd: x.chk === '1' ? this.state.artcd : '',
      artnm: x.chk === '1' ? this.state.artnm : '',
    }));

    this.setState({
      pushTotalCheck: checked,
      data: checkData,
    }, async () => {
      this.table.current?.update(false);
    });
  }


  /* wb_excel하기전 EXCEl데이터의 거래처가 거래처등록에서 사업자번호가 없는경우 찾는 함수 */
  @action
  async onCltcdNotChkExcelEvent() {
    try {
      const files = await FileSelector.single(false);
      await this.cltcdNotChkExcelUpload(files[0].name, files[0]);
    } catch {
      ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  async cltcdNotChkExcelUpload(filename: string, file: Blob) {
    const { actionStore: api, publicStore } = this.props;

    const tempInfo = await api.tempUpload(file, filename);

    const data = await api.fxExec(
      'wb_excel_cltcd_not',
      {
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    );

    // 거래처등록이 이미 되있다면 '0' 아니면 '1'
    // '0'인 경우 팝업창 안띄우고 바로 wb_excel() 실행
    // '1'인 경우 팝업창 띄우기
    if (data?.cltcd_not_chk === '0') {
      await this.excelUpload(filename, file);
    } else if (data?.cltcd_not_chk === '1') {
      await this.cltModalOpen();

      await this.SS({
        filename,
        file,
      });
    }
  }

  @action
  async cltModalOpen() {
    await this.SS({
      cltModal: true,
    });

    await this.cltModalRetrieve();
  }

  @action
  async cltModalRetrieve() {
    const { actionStore: api } = this.props;

    const data = await api.retrieve({
      sub: 'w_popup_xclient_ca640_import_temp',
    });

    if (data) {
      await this.SS({
        cltModalData: data.items.map((x: any) => new CltModalModel(x)),
      });
    }
  }

  @action
  async cltModalSave() {
    // 거래처 자동등록 하는 부분
    const { actionStore: api } = this.props;

    await api.fxExec('save', {
      sub: 'w_popup_xclient_ca640_import_temp',
      items: this.state.cltModalData.map((x: any) => ({
        saupnum: x.saupnum,
        cltnm: x.cltnm,
        taxmail: x.taxmail,
      })),
    });
    await this.excelUpload(this.state.filename, this.state.file);
    await this.cltModalClose();
  }

  @action
  async cltModalCancel() {
    // 해당 팝업DB 데이터 삭제후 창 닫기
    const { actionStore: api } = this.props;

    await api.fxExec('delete', {
      sub: 'w_popup_xclient_ca640_import_temp',
    });

    await this.cltModalClose();
  }

  @action
  async cltModalClose() {
    // 데이터 초기화
    await this.SS({
      cltModal: false,
      cltModalData: [],
      filename: '',
      file: new Blob(),
    });
  }

  @action
  async onSubExcelEvent() {
    try {
      const files = await FileSelector.single(false);
      this.excelUpload(files[0].name, files[0]);
    } catch {
      ConfirmFail.show('오류', '엑셀 처리중 알 수 없는 문제가 발생하였습니다.');
    }
  }

  async excelUpload(filename: string, file: Blob) {
    const {
      actionStore: api, waitQueueStore, modalStore, publicStore,
    } = this.props;

    const tempInfo = await api.tempUpload(file, filename);

    waitQueueStore.append('매입등록[국세청]', 'LAZYTASK-wb_excel', async (response) => {
      ConfirmWarning.show(response.titlebox, response.messagebox);
      this.onRetrieveEvent();
    }, () => this.excelUpload(filename, file));
    modalStore?.openWaitQueue();

    await api.exec(
      Category.GENERAL,
      'wb_excel_lazytask',
      {
        new: '0',
        custcd: publicStore.user.custcd,
        fileext: filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '',
        tempfile: tempInfo.data,
        dir: tempInfo.data.dir,
        filename: tempInfo.data.filename,
        size: tempInfo.data.size,
        src: tempInfo.data.src,
      },
      false,
    );
  }

  @action
  async onNationalTaxEvent() {
    const data = await Confirm.show('확인', '국세청으로 이동합니다. 엑셀다운로드를 이용해주세요!', ConfirmType.QUESTION);
    if (!data) {
      return;
    }
    window.open('https://www.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index.xml');
  }

  @action
  async onSalesEvent() {
    const { actionStore: api } = this.props;

    let check;
    let chkData: BuyNationalTaxServiceModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        if (x.mijflag === '1') {
          check = false;
        }
        chkData.push(new BuyNationalTaxServiceModel({
          ...x,
        }));
      }
    });

    if (check === false) {
      ConfirmWarning.show('확인', '이미 매입처리한 자료는 매입처리 선택을 할수 없습니다!');
      return;
    }
    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.fxExec('wb_mijsave',
        {
          items: chkData,
        });
      await this.onRetrieveEvent();
      chkData = [];
      check = true;
    }
  }

  @action
  async onSalesCancelEvent() {
    const { actionStore: api } = this.props;

    let check;
    let sndamt;
    let chkData: BuyNationalTaxServiceModel[] = [];
    this.state.data.forEach((x: any) => {
      if (x?.chk === '1') {
        if (x.mijflag !== '1') {
          check = false;
        }
        if (x.sndamt > '0') {
          sndamt = false;
        }
        chkData.push(new BuyNationalTaxServiceModel({
          ...x,
        }));
      }
    });

    if (check === false) {
      ConfirmWarning.show('확인', '아직 미처리건은 매입취소로 선택을 할수 없습니다!');
      return;
    }
    if (sndamt === false) {
      ConfirmWarning.show('확인', '이미 지급된 내역이 있습니다. 취소할 수 없습니다!');
      return;
    }
    if (!chkData.length) {
      ConfirmWarning.show('확인', '선택을 하나이상 하십시오!');
    } else {
      await api.fxExec('wb_mijdelete',
        {
          items: chkData,
        });
      this.onRetrieveEvent();
      chkData = [];
      check = true;
      sndamt = true;
    }
  }

  render() {
    return (
      <BuyNationalTaxServiceTemplate
        scope={this}
      />
    );
  }
}
