export { AcademicBackgroundModel } from './AcademicBackground.model';
export { AwardModel } from './Award.model';
export { BasicModel } from './Basic.model';
export { BodyModel } from './Body.model';
export { CarModel } from './Car.model';
export { CareerModel } from './Career.model';
export { CertificateModel } from './Certificate.model';
export { EducationModel } from './Education.model';
export { FamilyModel } from './Family.model';
export { FileModel } from './File.model';
export { ModalModel } from './Modal.model';
export { UserModalModel } from './UserModal.model';
