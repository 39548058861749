import { GridLayoutHeader } from '../../../../../constants';
import { FlexLayout } from '../../../../../components/layout/FlexLayout';
import { ProcessingModel } from '../models';
import { Response } from '../Response';
import { FormatTextBox, TextBox } from '../../../../../components';

// 처리시간
const Processing: GridLayoutHeader<ProcessingModel, Response>[] = [
  {
    id: 'pernm',
    text: '담당자명',
    width: 100,
    trail: () => (
      <FlexLayout
        justify="center"
        align="center"
      >
        총계
      </FlexLayout>
    ),
    render: (x) => (
      <FlexLayout
        justify="center"
        align="center"
      >
        {x.pernm}
      </FlexLayout>
    ),
  },
  {
    id: 'tottime',
    text: '고장건수',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.cnt_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="right"
        format="###건"
        value={x.tottime}
      />
    ),
  },
  {
    id: 'time5',
    text: '5분이내',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time5_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="right"
        format="###건"
        value={x.time5}
      />
    ),
  },
  {
    id: 'timeyul5',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul5_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        color= 'var(--color-blue)'
        textAlign="right"
        value={x.timeyul5}
      />
    ),
  },
  {
    id: 'time6',
    text: '6분~15분',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time6_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="right"
        format="###건"
        value={x.time6}
      />
    ),
  },
  {
    id: 'timeyul6',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul6_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        color= 'var(--color-blue)'
        textAlign="right"
        value={x.timeyul6}
      />
    ),
  },
  {
    id: 'time1',
    text: '16분~30분',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time1_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="right"
        format="###건"
        value={x.time1}
      />
    ),
  },
  {
    id: 'timeyul1',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul1_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        color= 'var(--color-blue)'
        textAlign="right"
        value={x.timeyul1}
      />
    ),
  },
  {
    id: 'time2',
    text: '30분~1시간',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="right"
        format="###건"
        value={x.time2}
      />
    ),
  },
  {
    id: 'timeyul2',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul2_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        color= 'var(--color-blue)'
        textAlign="right"
        value={x.timeyul2}
      />
    ),
  },
  {
    id: 'time3',
    text: '1시간~2시간',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time3_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="right"
        format="###건"
        value={x.time3}
      />
    ),
  },
  {
    id: 'timeyul3',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul3_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        color= 'var(--color-blue)'
        textAlign="right"
        value={x.timeyul3}
      />
    ),
  },
  {
    id: 'time4',
    text: '2시간이상',
    width: 120,
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
      >
        {scope?.state.time4_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <FormatTextBox
        textAlign="right"
        format="###건"
        value={x.time4}
      />
    ),
  },
  {
    id: 'timeyul4',
    text: '',
    width: 60,
    color: 'var(--color-blue)',
    trail: (_, scope) => (
      <FlexLayout
        justify="right"
        align="center"
        style={{ color: 'var(--color-blue)' }}
      >
        {scope?.state.timeyul4_tot}
      </FlexLayout>
    ),
    render: (x) => (
      <TextBox
        color= 'var(--color-blue)'
        textAlign="right"
        value={x.timeyul4}
      />
    ),
  },
];

export default Processing;
