import * as React from 'react';
import {
  AiOutlineUpload,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from 'react-icons/all';
import {
  Global,
  TemplateProps,
} from '../../../../constants';
import { FlexLayout } from '../../../../components/layout/FlexLayout';
import { SearchLayout } from '../../../../components/layout/SearchLayout';
import { DateTextBox } from '../../../../components/forms/DateTextBox';
import { Date8, Today } from '../../../../utils/time';
import { TextBox } from '../../../../components/forms/TextBox';
import {
  SearchBinding,
  SearchBindingProjects,
} from '../../../../models/common';
import { Button } from '../../../../components/forms/Button';
import {
  Stock,
  StockItemChangeTypes,
} from './Stock';
import { GridLayout } from '../../../../components/layout/GridLayout';
import { FormatTextBox } from '../../../../components/forms/FormatTextBox';
import { LabelBox } from '../../../../components/forms/LabelBox';
import { OptionBox } from '../../../../components/forms/OptionBox';
import { LayoutTitle } from '../../../../components/forms/LayoutTitle';
import { BUTTON_THEMES } from '../../../../components/forms/Button/Button';
import { TableLayout } from '../../../../components/layout/TableLayout';
import { FormatNumberTextBox } from '../../../../components/forms/FormatNumberTextBox';
import { ModalView } from '../../../../components/layout/ModalView';
import { CheckBox } from '../../../../components/forms/CheckBox';
import { Sum } from '../../../../utils/array';
import { Format } from '../../../../utils/string';
import {
  ComboBox,
  ComboBoxModel,
} from '../../../../components';

const taxflags = [
  { value: '0', remark: '입고' },
  { value: '1', remark: '비용' },
];

/**
 * 화면
 * @window w_tb_ca611_01
 * @category 입고등록
 */
export const StockTemplate: React.FC<TemplateProps<Stock>> = ({
  scope,
  update,
}) => {
  const setData = update!;
  const model = scope.state.data;
  return (
    <FlexLayout isVertical={true}>
      <SearchLayout
        size={Global.LAYOUT_SEARCH_HEIGHT_1}
      >
        <FlexLayout size={210}>
          <span>기간</span>
          <DateTextBox
            value={scope.state.stdate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ stdate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.stdate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ stdate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={180}>
          <span style={{ marginLeft: 8 }}>~</span>
          <DateTextBox
            value={scope.state.enddate}
            textAlign="center"
            format="####-##-##"
            onChange={(value) => scope.setState({ enddate: value })}
            onEnter={() => scope.onRetrieveEvent()}
            head={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() - 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowLeft size={24} />
              </button>
            )}
            trail={(
              <button
                onClick={() => {
                  const date = new Date(Date8.withDash(scope.state.enddate));
                  date.setDate(date.getDate() + 1);
                  scope.setState({ enddate: Date8.make(date) },
                    () => scope.onRetrieveEvent());
                }}
              >
                <MdKeyboardArrowRight size={24} />
              </button>
            )}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>
        <FlexLayout size={250}>
          <span style={{ color: 'var(--color-red)' }}>창고</span>
          <TextBox
            value={scope.state.store}
            textAlign="center"
            onChange={(value) => scope.setState({ store: value })}
            onEnter={() => scope.onRetrieveEvent()}
            bindSearchModal={new SearchBinding(
              scope.props.modalStore,
              'TB_CA504',
              {},
              true,
              (item) => {
                scope.setState({
                  store: item.cd,
                  storenm: item.cdnm,
                }, () => scope.onRetrieveEvent());
              },
            )}
            isDisabledTrackingStateChange={true}
          />
          <TextBox
            value={scope.state.storenm}
            weight={1.5}
            textAlign="center"
            isDisabledTrackingStateChange={true}
            readonly={true}
          />
        </FlexLayout>
        <FlexLayout size={400}>
          <span>검색어</span>
          <TextBox
            placeholderColor="#CACACA"
            placeholderAlign="left"
            placeholder="거래처, 프로젝트, 특이사항으로 검색하세요"
            value={scope.state.searchQuery}
            onChange={(value) => scope.setState({ searchQuery: value })}
            onEnter={() => scope.onRetrieveEvent()}
            isDisabledTrackingStateChange={true}
          />
        </FlexLayout>

        <FlexLayout/>
        <Button onClick={() => scope.loadOrderModal(true)}>
          <div style={{ width: 80 }}>발주서</div>
        </Button>
      </SearchLayout>

      <FlexLayout size={372}>
        <GridLayout
          ref={scope.grid}
          header={[
            {
              id: 'ibgdate',
              text: '입고일자',
              width: 100,
              render: (x) => (
                <FormatTextBox
                  textAlign="center"
                  format="####-##-##"
                  value={x.ibgdate}
                />
              ),
            },
            {
              id: 'ibgnum',
              text: '번호',
              width: 50,
              render: (x) => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  {x.ibgnum}
                </FlexLayout>
              ),
            },
            {
              id: 'taxflag',
              text: '상태',
              width: 50,
              render: (x) => (
                <FlexLayout
                  align='center'
                  justify='center'
                >
                  {taxflags.filter((y) => y.value === x.taxflag)[0]?.remark}
                </FlexLayout>
              ),
            },
            {
              id: 'cltnm',
              text: '거래처명',
              width: 300,
              render: (x) => (
                <FlexLayout
                  justify="left"
                  align="center"
                >
                  {x.cltnm}
                </FlexLayout>
              ),
            },
          ]}
          data={scope.state.stockList}
          infinityHandler={scope.infinity}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          onRowClick={(item) => scope.onRowFocusEvent(item)}
        />

        <FlexLayout
          weight={2}
          isVertical={true}
          disabled={!model?.custcd}
          disabledText="신규로 추가하거나 입고를 선택해주세요"
        >
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              title="입고일자"
              isNeed={true}
            >
              <DateTextBox
                value={model?.ibgdate || `${Today.yearMon()}${Today.date()}`}
                textAlign="center"
                format="####-##-##"
                onChange={(value) => setData({ ibgdate: value }, () => scope.itemChanged(StockItemChangeTypes.IBGDATE))}
                readonly={model?.new === '0'}
              />
              <TextBox
                weight={0.5}
                textAlign="center"
                value={model?.ibgnum}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="입고구분">
              <OptionBox
                value={model?.cdflag}
                data={[
                  { value: '0', remark: '자재입고' },
                  { value: '1', remark: '외주입고' },
                ]}
                onChange={(item) => update!({ cdflag: item.value })}
              />
            </LabelBox>
            <LabelBox
              title="작성자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.inperid}
                textAlign="center"
                onChange={(value) => setData({ inperid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      inperid: item.cd,
                      inpernm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.inpernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="작성일자">
              <FormatTextBox
                format="####-##-##"
                textAlign="center"
                value={model?.indate}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={2}
              title="거래처"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.cltcd}
                textAlign="center"
                onChange={(value) => setData({ cltcd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_XCLIENT',
                  {},
                  true,
                  (item) => {
                    setData({
                      cltcd: item.cd,
                      cltnm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.cltnm}
                weight={3}
                textAlign="left"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="담당자"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.perid}
                textAlign="center"
                onChange={(value) => setData({ perid: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JA001',
                  {},
                  true,
                  (item) => {
                    setData({
                      perid: item.cd,
                      pernm: item.cdnm,
                    }, () => scope.itemChanged(StockItemChangeTypes.PERID, item.cd));
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.pernm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox
              title="담당부서"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.divicd}
                textAlign="center"
                onChange={(value) => setData({ divicd: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_JC002',
                  {},
                  true,
                  (item) => {
                    setData({
                      divicd: item.cd,
                      divinm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.divinm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_HEIGHT_1}>
            <LabelBox
              weight={2}
              title="프로젝트"
              style={{ color: 'var(--color-red)' }}
              footer={
                <FlexLayout>
                  <Button
                    onClick={() => scope.onProjectButtonClicked()}
                    isIcon={true}
                  >
                    <AiOutlineUpload size={17} />
                  </Button>
                </FlexLayout>
              }
            >
              <TextBox
                textAlign="center"
                value={model.projno}
                onChange={(value) => setData({ projno: value, isChanged: 'true' })}
                bindSearchModal={new SearchBindingProjects(
                  scope.props.modalStore,
                  {},
                  true,
                  (item) => {
                    setData({
                      projno: item.projno,
                      projnm: item.projnm,
                    });
                  },
                )}
              />
              <TextBox
                value={model?.projnm}
                weight={3}
                textAlign="left"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
            <LabelBox title="입고구분">
              <OptionBox
                value={model?.taxcls}
                data={[
                  { value: '0', remark: '별도' },
                  { value: '1', remark: '포함' },
                ]}
                onChange={(item) => update!({ taxcls: item.value },
                  () => scope.itemChanged(StockItemChangeTypes.TAXCLS))}
              />
            </LabelBox>
            <LabelBox
              title="창고"
              style={{ color: 'var(--color-red)' }}
            >
              <TextBox
                value={model?.store}
                textAlign="center"
                onChange={(value) => setData({ store: value })}
                onEnter={() => scope.onRetrieveEvent()}
                bindSearchModal={new SearchBinding(
                  scope.props.modalStore,
                  'TB_CA504',
                  {},
                  true,
                  (item) => {
                    setData({
                      store: item.cd,
                      storenm: item.cdnm,
                    });
                  },
                )}
                isDisabledTrackingStateChange={true}
              />
              <TextBox
                value={model?.storenm}
                textAlign="center"
                isDisabledTrackingStateChange={true}
                readonly={true}
              />
            </LabelBox>
          </FlexLayout>
          <FlexLayout size={Global.LAYOUT_LABEL_BOX_MIN_HEIGHT_TEXTAREA_1}>
            <LabelBox title="특이사항" >
              <TextBox
                isMultiline={true}
                value={model?.remark}
                onChange={(value) => setData({ remark: value })}
              />
            </LabelBox>
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout size={Global.LAYOUT_BUTTON_HEIGHT_1}>
        <LayoutTitle size={230}>입고품목</LayoutTitle>
        <FlexLayout justify='right'>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.BLUE}
            onClick={() => scope.onNewEvent2()}
          >
            추가
          </Button>
          <Button
            style={{ height: '25px', width: '60px' }}
            theme={BUTTON_THEMES.RED}
            onClick={() => scope.onDeleteEvent2()}
          >
            삭제
          </Button>
        </FlexLayout>
      </FlexLayout>

      <FlexLayout
        disabled={!model?.custcd}
        disabledText="신규로 추가하거나 입고를 선택해주세요"
      >
        <TableLayout
          ref={scope.table}
          header={[
            {
              id: 'ibgseq',
              text: '순번',
              width: 50,
              trail: () => (
                <FlexLayout
                  justify="center"
                  align="center"
                >
                  합 계
                </FlexLayout>
              ),
              render: (x, _, ref) => (
                <TextBox
                  ref={ref}
                  value={x.ibgseq}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'myn',
              text: '유무상',
              width: 60,
              render: (x, rowUpdate) => (
                <ComboBox
                  value={x.myn}
                  data={[
                    { value: '0', remark: '무상' },
                    { value: '1', remark: '유상' },
                    { value: '2', remark: 'FM' },
                  ].map((y) => new ComboBoxModel(y.value, y.remark))}
                  onSelect={(option) => rowUpdate({ myn: option.value })}
                />
              ),
            },
            {
              id: 'pcode',
              text: '품목코드',
              width: 150,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.pcode}
                  readonly={x.new === '0'}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ pcode: value })}
                  onDoubleClick={async () => {
                    // @ts-ignore
                    const result = await scope.props.modalStore.openProductSelector(x.pcode);
                    rowUpdate({
                      pcode: result.phm_pcod,
                      pname: result.phm_pnam,
                      psize: result.phm_size,
                      punit: result.phm_unit,
                      uamt: result.phm_uamt || result.phm_samt,
                    });
                    await scope.detailItemChanged(StockItemChangeTypes.PCODE, result.phm_pcod);
                  }}
                  onEnter={async (value) => {
                    // @ts-ignore
                    const result = await scope.props.modalStore.openProductSelector(value);
                    rowUpdate({
                      pcode: result.phm_pcod,
                      pname: result.phm_pnam,
                      psize: result.phm_size,
                      punit: result.phm_unit,
                      uamt: result.phm_uamt || result.phm_samt,
                    });
                    await scope.detailItemChanged(StockItemChangeTypes.PCODE, result.phm_pcod);
                  }}
                />
              ),
            },
            {
              id: 'pname',
              text: '품목명',
              width: 250,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="left"
                >
                  {x.pname}
                </FlexLayout>
              ),
            },
            {
              id: 'psize',
              text: '규격',
              width: 200,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="left"
                >
                  {x.psize}
                </FlexLayout>
              ),
            },
            {
              id: 'punit',
              text: '단위',
              width: 50,
              render: (x) => (
                <FlexLayout
                  align="center"
                  justify="left"
                >
                  {x.punit}
                </FlexLayout>
              ),
            },
            {
              id: 'pserial',
              text: '품목시리얼번호',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.pserial}
                  textAlign="center"
                  onChange={(value) => {
                    rowUpdate({ pserial: value });
                    scope.detailItemChanged(StockItemChangeTypes.PSERIAL, value);
                  }}
                />
              ),
            },
            {
              id: 'qty',
              text: '수량',
              width: 75,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.qty}
                  textAlign="center"
                  onChange={(value) => {
                    // 마이너스 입력 시 NAN 뜨는 현상으로 tabAutoCalc 함수 안타도록 함
                    if (value === '-' && value.length === 1) {
                      rowUpdate({ qty: value });
                      return;
                    }

                    // 입력값이 '-'로 시작하거나 숫자가 아닌 경우 처리 중단
                    if (!/^-?\d*$/.test(value)) {
                      return;
                    }
                    scope.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty');
                  }}
                  onEnter={(value) => { scope.tabAutoCalc({ ...x, qty: value }, rowUpdate, 'qty'); }}
                />
              ),
            },
            {
              id: 'uamt',
              text: '단가',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.uamt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
                  onEnter={(value) => scope.tabAutoCalc({ ...x, uamt: value }, rowUpdate, 'uamt')}
                />
              ),
            },
            {
              id: 'samt',
              text: '공급금액',
              width: 100,
              trail: (data) => {
                const sumSamt = Sum.all(data, 'samt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumSamt)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.samt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
                  onEnter={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
                />
              ),
            },
            {
              id: 'tamt',
              text: '부가세',
              width: 100,
              trail: (data) => {
                const sumTamt = Sum.all(data, 'tamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumTamt)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.tamt}
                  onChange={(value) => scope.tabAutoCalc({ ...x, tamt: value }, rowUpdate, 'tamt')}
                  onEnter={(value) => scope.tabAutoCalc({ ...x, samt: value }, rowUpdate, 'samt')}
                />
              ),
            },
            {
              id: 'mamt',
              text: '합계',
              width: 100,
              trail: (data) => {
                const sumMamt = Sum.all(data, 'mamt');
                return (
                  <FlexLayout
                    justify="right"
                    align="center"
                  >
                    {Format.number(sumMamt)}
                  </FlexLayout>
                );
              },
              render: (x, rowUpdate, ref) => (
                <FormatNumberTextBox
                  ref={ref}
                  char={','}
                  charAt={3}
                  textAlign="right"
                  value={x.mamt}
                  onChange={(value) => rowUpdate({ mamt: value })}
                  onEnter={(value) => rowUpdate({ mamt: value })}
                />
              ),
            },
            {
              id: 'balqty',
              text: '발주수량',
              width: 75,
              render: (x, _, ref) => (
                <TextBox
                  ref={ref}
                  value={x.balqty}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'baldate',
              text: '발주일자',
              width: 150,
              render: (x, _, ref) => (
                <DateTextBox
                  ref={ref}
                  value={x.baldate}
                  textAlign="center"
                  format="####-##-##"
                  isDisabledTrackingStateChange={true}
                />
              ),
            },
            {
              id: 'balnum',
              text: '번호',
              width: 50,
              render: (x, _, ref) => (
                <TextBox
                  ref={ref}
                  value={x.balnum}
                  textAlign="center"
                />
              ),
            },
            {
              id: 'actcd',
              text: '현장코드',
              width: 75,
              color: 'var(--color-red)',
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.actcd}
                  textAlign="center"
                  onChange={(value) => rowUpdate({ actcd: value })}
                  bindSearchModal={new SearchBinding(
                    scope.props.modalStore,
                    'TB_E601_1',
                    {},
                    true,
                    (item) => {
                      rowUpdate({
                        actcd: item.cd,
                        actnm: item.cdnm,
                      });
                      scope.itemChanged(StockItemChangeTypes.ACTCD, item.cd);
                    },
                  )}
                />
              ),
            },
            {
              id: 'actnm',
              text: '현장명',
              width: 100,
              render: (x, _, ref) => (
                <TextBox
                  ref={ref}
                  value={x.actnm}
                  textAlign="left"
                  transparent={false}
                />
              ),
            },
            {
              id: 'bigo',
              text: '비고',
              width: 100,
              render: (x, rowUpdate, ref) => (
                <TextBox
                  ref={ref}
                  value={x.bigo}
                  textAlign="left"
                  onChange={(value) => rowUpdate({ bigo: value })}
                />
              ),
            },
          ]}
          infinityHandler={scope.infinity2}
          rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
          data={scope.state.stockDetailList}
          onChange={(rows, updatedRows) => {
            scope.onUpdatedRows(rows, updatedRows);
          }}
          onRowFocusEvent={(item) => scope.onRowFocusEvent2(item)}
        />
      </FlexLayout>

      <ModalView
        isVisible={scope.state.loadOrderModal}
        onClose={() => scope.loadOrderModal(false)}
        width={1100}
        height={600}
        zIndex={-1}
      >
        <FlexLayout isVertical={true} style={{ padding: 8 }}>
          <FlexLayout
            style={{ paddingBottom: 2 }}
            size={Global.LAYOUT_SEARCH_HEIGHT_1}
          >
            <LayoutTitle weight={1}>
              발주서 불러오기
            </LayoutTitle>
          </FlexLayout>
          <SearchLayout size={Global.LAYOUT_SEARCH_HEIGHT_1}>
            <FlexLayout weight={4}>
              <span style={{
                margin: 8,
                fontSize: 12,
              }}>
                검색어
              </span>
              <TextBox
                value={scope.state.popupSearchQuery}
                onChange={(value) => scope.setState({ popupSearchQuery: value })}
                onEnter={() => scope.modalRetrieve()}
                isDisabledTrackingStateChange={true}
              />
            </FlexLayout>
          </SearchLayout>
          <FlexLayout>
            <TableLayout
              ref={scope.popupTable}
              header={[
                {
                  id: 'chk',
                  text: '선택',
                  width: 50,
                  render: (x, rowUpdate) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      <CheckBox
                        value={x.chk === '1'}
                        onChange={(value) => {
                          scope.chkBalSameAll(x, value, rowUpdate);
                        }}
                      />
                    </FlexLayout>
                  ),
                },
                {
                  id: 'baldate',
                  text: '발주일자',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.baldate}
                    />
                  ),
                },
                {
                  id: 'balnum',
                  text: '번호',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.balnum}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'cltnm',
                  text: '거래처명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.cltnm}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'pname',
                  text: '품명',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.pname}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'psize',
                  text: '규격',
                  width: 200,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.psize}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'punit',
                  text: '단위',
                  width: 50,
                  render: (x) => (
                    <FlexLayout
                      justify="center"
                      align="center"
                    >
                      {x.punit}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'qty',
                  text: '수량',
                  width: 75,
                  render: (x, _, ref) => (
                    <TextBox
                      ref={ref}
                      value={x.qty}
                      textAlign="center"
                    />
                  ),
                },
                {
                  id: 'uamt',
                  text: '단가',
                  width: 150,
                  render: (x, _, ref) => (
                    <FormatNumberTextBox
                      ref={ref}
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.uamt}
                    />
                  ),
                },
                {
                  id: 'samt',
                  text: '공급가액',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.samt}
                    />
                  ),
                },
                {
                  id: 'tamt',
                  text: '부가세',
                  width: 100,
                  render: (x) => (
                    <FormatNumberTextBox
                      char={','}
                      charAt={3}
                      textAlign="right"
                      value={x.tamt}
                    />
                  ),
                },
                {
                  id: 'deldate',
                  text: '납기일',
                  width: 100,
                  render: (x) => (
                    <FormatTextBox
                      textAlign="center"
                      format="####-##-##"
                      value={x.deldate}
                    />
                  ),
                },
                {
                  id: 'bigo',
                  text: '납품장소',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.bigo}
                    </FlexLayout>
                  ),
                },
                {
                  id: 'bal_remark',
                  text: '특이사항(공사명)',
                  width: 300,
                  render: (x) => (
                    <FlexLayout
                      justify="left"
                      align="center"
                    >
                      {x.bal_remark}
                    </FlexLayout>
                  ),
                },
              ]}
              data={scope.state.popupList}
              infinityHandler={scope.infinity3}
              rowHeight={Global.LAYOUT_GRID_HEIGHT_1}
              onChange={(rows2, updatedRows2) => {
                scope.onUpdatedRows2(rows2, updatedRows2);
              }}
            />
          </FlexLayout>

          <FlexLayout size={Global.LAYOUT_GRID_HEIGHT_1}>
            <FlexLayout weight={2}>
              <LayoutTitle weight={1}>
                일자
              </LayoutTitle>
              <LabelBox weight={2.5}>
                <DateTextBox
                  value={scope.state?.ibgdate}
                  textAlign="center"
                  format="####-##-##"
                  onChange={(value) => scope.setState({ ibgdate: value })}
                />
              </LabelBox>
            </FlexLayout>
            <FlexLayout weight={1}>
              <Button
                isFull={true}
                theme={BUTTON_THEMES.BLUE}
                onClick={() => scope.modalSave()}
              >
                확인
              </Button>
              <Button
                isFull={true}
                theme={BUTTON_THEMES.RED}
                onClick={() => scope.loadOrderModal(false)}
              >
                닫기
              </Button>
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      </ModalView>
    </FlexLayout>
  );
};
