// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.Resister_back__2ThPD {\n  background-color: #feffff;\n  background: linear-gradient(to bottom, #feffff 0%, #d2ebf9 65%);\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffff', endColorstr='#d2ebf9',GradientType=0 ); }\n\n.Resister_test5__1rTUM {\n  border-bottom: 2px solid blue; }\n\nhr {\n  width: 100%;\n  height: 50%;\n  border-top: 4px double #bbb;\n  text-align: center; }\n\nhr:after {\n  content: '\\002665';\n  display: inline-block;\n  position: relative;\n  top: -15px;\n  padding: 0 10px;\n  color: #bbb;\n  font-size: 18px; }\n", "",{"version":3,"sources":["webpack://src/app/pages/human/account/Resister/Resister.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EAGzB,+DAA+D;EAC/D,mHAAmH,EAAA;;AAErH;EACE,6BAA6B,EAAA;;AAG/B;EACE,WAAW;EACX,WAAW;EACX,2BAA2B;EAC3B,kBAAkB,EAAA;;AAEpB;EACE,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,UAAU;EACV,eAAe;EACf,WAAW;EACX,eAAe,EAAA","sourcesContent":["div.back {\n  background-color: #feffff;\n  background: -moz-linear-gradient(top,  #feffff 0%, #d2ebf9 65%);\n  background: -webkit-linear-gradient(top,  #feffff 0%,#d2ebf9 65%);\n  background: linear-gradient(to bottom,  #feffff 0%,#d2ebf9 65%);\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#feffff', endColorstr='#d2ebf9',GradientType=0 );\n}\n.test5 {\n  border-bottom: 2px solid blue;\n}\n\nhr {\n  width: 100%;\n  height: 50%;\n  border-top: 4px double #bbb;\n  text-align: center;\n}\nhr:after {\n  content: '\\002665';\n  display: inline-block;\n  position: relative;\n  top: -15px;\n  padding: 0 10px;\n  color: #bbb;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back": "Resister_back__2ThPD",
	"test5": "Resister_test5__1rTUM"
};
export default ___CSS_LOADER_EXPORT___;
